import { GET_STUDENT_GEARS } from '../../../graphql/GET_STUDENT_GEARS';
import { GET_ALL_GEAR_MODELS } from '../../../graphql/GET_ALL_GEAR_MODELS';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useRootStore } from '../../../hooks';
import { EUserTypes } from '../../User/userTypes';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_OR_EDIT_USER_GEAR } from '../../../graphql/ADD_OR_EDIT_USER_GEAR';
import { toast } from 'react-toastify';
import { ADD_OR_EDIT_GEAR_MODEL } from '../../../graphql/ADD_OR_EDIT_GEAR_MODEL';
import { GET_ALL_GEAR_MANUFACTURERS } from '../../../graphql/GET_ALL_GEAR_MANUFACTURERS';
import GearModelForm from './GearModelForm';
import FormField from '../../FormField';
import * as Yup from 'yup';
import { Button, Card, Form, Grid } from 'tabler-react';
import Select from 'react-select';
import moment from 'moment';
import { Types } from '../../../types/graphql'

interface IGearFormProps {
  toggleModal?: any;
  userGear: any;
  studentId?: number;
  isModal?: boolean;
  setValue?: any;
}

const addUserGearValidationSchema = Yup.object().shape({
  gear_model_id: Yup.number().required('This field is required.')
});

const GearForm = ({
  toggleModal,
  userGear,
  studentId,
  isModal,
  setValue
}: IGearFormProps) => {
  const { currentUser } = useRootStore();
  const student_id =
    currentUser.type === EUserTypes.student ? currentUser.id : studentId;
  const [updateUserGear] = useMutation(ADD_OR_EDIT_USER_GEAR, {
    onCompleted: () => {
      handleClose();
      toast.success('Gear submitted.');
    }
  });

  const [updateModel] = useMutation(ADD_OR_EDIT_GEAR_MODEL);
  const [displayCanopy, setDisplayCanopy] = useState<boolean>(false);
  const [displayDOM, setDisplayDOM] = useState<boolean>(false);
  const [displayManufacturer, setDisplayManufacturer] =
    useState<boolean>(false);
  const [showModelForm, setShowModelForm] = useState<boolean>(false);

  const { data: dataManufacturer } = useQuery(GET_ALL_GEAR_MANUFACTURERS);

  const dom_required_type = [1, 2, 4, 5];
  const size_require_type = [1, 2];
  const handleClose = () => {
    toggleModal(false);
  };
  const { data } = useQuery(GET_ALL_GEAR_MODELS);

  useEffect(() => {
    setDisplayCanopy(
      size_require_type.includes(userGear?.gear_model?.gear_type_id)
    );
    setDisplayDOM(
      dom_required_type.includes(userGear?.gear_model?.gear_type_id)
    );
     
  }, [userGear]);

  const renderForm = ({
    values,
    handleSubmit,
    isSubmitting,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    touched
  }) => (
    <form onSubmit={handleSubmit}>
      <Grid.Row className="">
        {showModelForm ? (
          <Card className="p-3 m-3 bg-light">
            <GearModelForm toggleForm={setShowModelForm} />
          </Card>
        ) : (
          <>
            {isModal && (
              <Grid.Col lg={12}>
                {showModelForm ? (
                  <Button
                    className="btn-white"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowModelForm(false);
                    }}
                  />
                ) : (
                  <Button
                    className="text-primary"
                    color="white"
                    size="sm"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowModelForm(true);
                    }}
                  >
                    ADD MODEL
                  </Button>
                )}
              </Grid.Col>
            )}
            <Grid.Col xs={12} sm={12} md={6} lg={6}>
              <Form.Group label="">
                <Select
                  // className='w-50'
                  name="gear_model_id"
                  autoFocus={true}
                  placeholder="Search models..."
                  isMulti={false}
                  getOptionLabel={(option: Types.GearModel) =>
                    option.name
                  }
                  getOptionValue={(option: Types.GearModel) =>
                    option.id
                  }
                  options={data?.getGearModels}
                  onChange={(param) => {
                    setFieldValue('gear_model_id', param.id);
                    const gear_model = data?.getGearModels.find(
                      (model) => model.id === param.id
                    );
                    setDisplayCanopy(
                      size_require_type.includes(gear_model?.gear_type_id)
                    );
                    setDisplayDOM(
                      dom_required_type.includes(gear_model?.gear_type_id)
                    );
                    setDisplayManufacturer(!gear_model.gear_manufacturer_id);
                  }}
                  value={data?.getGearModels.filter(
                    (gearType) => gearType.id === values?.gear_model_id
                  )}
                />
                <span className="field-error text-danger">
                  {errors.gear_model_id &&
                    touched.gear_model_id &&
                    errors.gear_model_id}
                </span>
              </Form.Group>
            </Grid.Col>
            {displayManufacturer && (
              <Grid.Col xs={12} sm={12} md={6} lg={6}>
                <Form.Group label="">
                  <Select
                    name="gear_manufacturer_id"
                    placeholder="Search manufacturers..."
                    isMulti={false}
                    getOptionLabel={(
                      option: Types.GearManufacturer
                    ) => option.name}
                    getOptionValue={(
                      option: Types.GearManufacturer
                    ) => option.id}
                    options={dataManufacturer?.getGearManufacturers}
                    onChange={(param) => {
                      setFieldValue('model_manufacturer_id', param.id);
                    }}
                  />
                  <span className="field-error text-danger">
                    {errors.gear_model_id &&
                      touched.gear_model_id &&
                      errors.gear_model_id}
                  </span>
                </Form.Group>
              </Grid.Col>
            )}
          </>
        )}
      </Grid.Row>
      <Grid.Row>
        {displayCanopy && (
          <Grid.Col xs={6} sm={6} md={4} lg={4}>
            <Form.Group label="">
              <FormField
                type="number"
                placeholder="Canopy Size"
                name="gear_canopy_size"
                onChange={handleChange}
                value={values.gear_canopy_size}
              />
            </Form.Group>
          </Grid.Col>
        )}
        <Grid.Col xs={6} sm={6} md={6} lg={6}>
          <Form.Group label="">
            <FormField
              type="text"
              name="gear_serial"
              placeholder="Serial #"
              onChange={handleChange}
              value={values.gear_serial}
            />
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        {displayDOM && (
          <>
            <Grid.Col sm={6} md={6} lg={6} className="pl-3 pr-0">
              <Form.Group label="D.O.M. (YYYY-MM-DD)">
                <Form.MaskedInput
                  placeholder="YYYY-MM-DD"
                  mask={[
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/
                  ]}
                  name="gear_dom"
                  value={values.gear_dom}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Form.Group>
            </Grid.Col>
          </>
        )}
      </Grid.Row>
      {isModal && (
        <Grid.Row className="mb-4">
          <Grid.Col width={12}>
            <Form.Group label="Notes">
              <Form.Textarea
                name="gear_notes"
                onChange={handleChange}
                placeholder="Add notes"
                rows={2}
                type="text"
                value={values.gear_notes}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      )}
      <Grid.Row>
        {isModal && (
          <Grid.Col xs={12} sm={12} lg={6}>
            <Form.Group label="">
              <FormField
                type="text"
                name="name"
                placeholder="Name (optional)"
                onChange={handleChange}
                value={values.name}
              />
            </Form.Group>
          </Grid.Col>
        )}
        <Grid.Col className="pt-2" xs={12} sm={12} lg={isModal ? 6 : 12}>
          <Button
            type="submit"
            disabled={isSubmitting}
            pill
            className="btn-gray-dark float-right btn-sm"
          >
            {isSubmitting ? 'Processing...' : 'SAVE'}
          </Button>
          <Button
            pill
            color="white"
            className="float-right btn-sm"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
          >
            CANCEL
          </Button>
        </Grid.Col>
      </Grid.Row>
    </form>
  );

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={addUserGearValidationSchema}
      initialValues={{
        id: userGear?.id ?? null,
        name: userGear?.name ?? '',
        student_id: userGear?.student_id ?? student_id ?? 0,
        team_id: userGear?.team_id ?? null,
        gear_model_id: userGear?.gear_model?.id ?? '',
        gear_serial: userGear?.gear_serial ?? '',
        gear_dom: userGear?.gear_dom
          ? userGear?.gear_dom
          : '',
        gear_canopy_size: userGear?.gear_canopy_size ?? '',
        gear_notes: userGear?.gear_notes ?? '',
        model_manufacturer_id: null,
        status: userGear?.status ?? 'Active'
      }}
      onSubmit={async (values, { resetForm, setSubmitting, setFieldError }) => {
        const dom =
          values.gear_dom && displayDOM ? moment.utc(values.gear_dom) : null;
        if (!values.gear_model_id) {
          setFieldError('gear_model_id', 'Select Model');
          setSubmitting(false);
          return false;
        }

        if (
          displayCanopy &&
          (values.gear_canopy_size < 50 || values.gear_canopy_size > 400)
        ) {
          setFieldError('gear_canopy_size', 'Must be between 50 and 400');
          setSubmitting(false);
          return false;
        }

        const newGear = await updateUserGear({
          variables: {
            updateUserGearInput: {
              id: values?.id,
              name: values?.name,
              student_id: parseInt(values?.student_id),
              team_id: values?.team_id,
              gear_model_id: values?.gear_model_id,
              gear_serial: values?.gear_serial,
              gear_dom: dom,
              gear_canopy_size: displayCanopy
                ? parseInt(values?.gear_canopy_size)
                : 0,
              gear_notes: values?.gear_notes,
              status: values?.status,
              // legacy START - remove when DB is updated
              gear_manufacturer: '',
              gear_model: ''
              //   legacy END
            }
          },
          refetchQueries: [
            {
              query: GET_STUDENT_GEARS,
              variables: {
                getUserGearOptions: { student_id: student_id }
              }
            }
          ]
        });

        if (displayManufacturer) {
          updateModel({
            variables: {
              updateGearModelInput: {
                id: values?.gear_model_id,
                gear_manufacturer_id: values?.model_manufacturer_id
              }
            },
            refetchQueries: [{ query: GET_ALL_GEAR_MODELS }]
          });
        }

        if (!isModal) {
          setValue('user_gear_id', Number(newGear?.data?.updateUserGear));
        }
        setSubmitting(false);
        setDisplayManufacturer(false);
        resetForm();
      }}
    >
      {(formikData) => renderForm(formikData)}
    </Formik>
  );
};

export default GearForm;
