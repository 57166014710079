import { Page } from 'components/Loader';
import { useGetUserIsAdmin } from 'modules/common/hooks/useGetCurrentUserType';
import useGetNodeEnvironment, {
  ENodeEnvironment
} from 'modules/common/hooks/useGetNodeEnvironment';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import Routes from './routes'

const TRACKING_ID = 'G-L1Q4XE9WQ5';

export const App = () => {
  const isAdmin = useGetUserIsAdmin();
  const nodeEnv = useGetNodeEnvironment();

  useEffect(() => {
    // Check if the application is running in production mode
    const script = document.createElement('script');
    script.src = '//js.hs-scripts.com/42005218.js';
    script.async = true;
    script.defer = true;

    if (nodeEnv === ENodeEnvironment.PRODUCTION) {
      document.body.appendChild(script);
    }

    return () => {
      if (nodeEnv === ENodeEnvironment.PRODUCTION) {
        document.body.removeChild(script);
      }
    };
  }, [nodeEnv]);

  // Init google analytics 4 if not admin
  useEffect(() => {
    if (!isAdmin) {
      ReactGA.initialize(TRACKING_ID);
    }
  }, [isAdmin]);

  return <Page><Routes /></Page>
};
