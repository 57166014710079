import React from 'react';

import Wrapper from 'pages/layout';

const DZProfilePage = (props) => {
  return (
    <Wrapper {...props} title="Profile">
      <div className="container">Dropzone Profile</div>
    </Wrapper>
  );
};

export default DZProfilePage;
