import gql from 'graphql-tag';

export const GET_STUDENT_TIME_TRANSACTION_HISTORY = gql`
  query GET_STUDENT_TIME_TRANSACTION_HISTORY(
    $studentId: ID!
    $teamId: Int!
    $userType: String!
  ) {
    getStudentTimeTransactionHistory(
      studentId: $studentId
      teamId: $teamId
      userType: $userType
    ) {
      id
      coachFirstName
      coachLastName
      cancelledOn
      createdOn
      minutes
      notes
      timeAdded
      transactionName
      transactionSlug
      transactionTypeId
    }
  }
`;
