import React from 'react';
import { Formik } from 'formik';
import { Button, Form, Grid, Icon } from 'tabler-react';
import { toast } from 'react-toastify';
import { useRootStore } from '../../../hooks';
import ReactS3Uploader from 'react-s3-uploader';
import { useMutation } from '@apollo/client';
import { ADD_GROUP } from '../../../graphql/ADD_GROUP';
import { EDIT_GROUP } from '../../../graphql/EDIT_GROUP';

interface RegistrationGroupFormProps {
  toggle?: () => void;
  group?: any;
}

const RegistrationGroupForm = ({
  toggle,
  group
}: RegistrationGroupFormProps) => {
  const rootStore = useRootStore();

  const [addGroup] = useMutation(ADD_GROUP, {
    onCompleted: () => {
      toast.success('Group added successfully!');
    },
    refetchQueries: ['GET_GROUPS']
  });

  const [editGroup] = useMutation(EDIT_GROUP, {
    onCompleted: () => {
      toast.success('Group updated');
    },
    refetchQueries: ['GET_GROUPS']
  });

  const onError = (error) => {
    toast.error(error);
    rootStore.setLoading(false);
  };

  const renderForm = ({ values, setFieldValue, handleSubmit }) => {
    return (
      <div>
        <Grid.Row>
          <Grid.Col>
            <Form.Group label="Name">
              <Form.Input
                name="name"
                value={values.name}
                onChange={(e) => setFieldValue('name', e.target.value)}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <Form.Group label="Logo">
              {rootStore.loading ? (
                <Button
                  loading
                  color="secondary"
                  className="border-0 p-1 shadow-none"
                  block
                >
                  Loading...
                </Button>
              ) : (
                <label
                  htmlFor="s3-upload"
                  className="dropdown-item cursor-pointer mb-0"
                >
                  {values.image ? 'Change' : <Icon name={'camera'} />}
                </label>
              )}
              <ReactS3Uploader
                accept="image/*"
                autoUpload={true}
                className="d-none"
                contentDisposition="auto"
                id="s3-upload"
                signingUrl="/api/s3/sign"
                onError={onError}
                onFinish={(response) => {
                  setFieldValue('image', response.fileKey);
                  rootStore.setLoading(false);
                }}
                onProgress={() => rootStore.setLoading(true)}
                uploadRequestHeaders={{
                  'x-amz-acl': 'private'
                }}
              />
              {values.image && (
                <img
                  src={`/api/s3/uploads/${values.image}`}
                  alt={'group logo'}
                />
              )}
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <Button
              className={'float-right mr-2'}
              pill
              color={'gray-dark'}
              size={'small'}
              onClick={handleSubmit}
              disabled={rootStore.loading}
            >
              SUBMIT
            </Button>
            <Button
              className={'float-right mr-2'}
              pill
              color={'white'}
              size={'small'}
              onClick={() => toggle && toggle()}
            >
              CANCEL
            </Button>
          </Grid.Col>
        </Grid.Row>
      </div>
    );
  };
  return (
    <Formik
      initialValues={{
        id: group?.id ?? undefined,
        name: group?.name ?? '',
        team_id: group?.team_id ?? null,
        discipline_id: group?.discipline_id ?? null,
        image: group?.image ?? '',
        user_registration_id: group?.user_registration_id ?? null,
        status: group?.status ?? 'Active'
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        console.log(values);
        if (!values.id) {
          await addGroup({
            variables: { addGroupInput: values }
          });
        } else {
          await editGroup({
            variables: { updateGroupInput: values }
          });
        }
        setSubmitting(false);
        resetForm();
        if (toggle) {
          toggle()
        }
      }}
    >
      {(formData) => renderForm(formData)}
    </Formik>
  );
};

export default RegistrationGroupForm;
