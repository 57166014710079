import StudentAccountsPage from 'pages/Student/accounts';
import MyStudentsListPage from 'pages/coach/my-students';
import StudentsPage from 'pages/coach/students';
import PaymentsPage from 'pages/common/payments';
import JumpersPage from 'pages/dzm/jumpers';
import FlyersPage from 'pages/dzm/flyers';
import ParticipantsPage from 'pages/events/participants';
import CustomersPage from 'pages/rigger/customers';
import MembersPage from 'pages/common/members';
import JobGearStoragePage from 'pages/rigger/job-gear-storage';
import React from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import { Role } from './helpers/roles';
import Gears from './pages/Student/Gears';
import StudentEventsPage from './pages/Student/StudentEventsPage';
import StudentInvoicePage from './pages/Student/StudentInvoicePage';
import StudentParticipants from './pages/events/student-participants';
import StudentSignupForm from './pages/Student/StudentSignupForm';
import AccountTransactions from './pages/Student/AccountTransactions';
import AdminList from './pages/admin/admin-list';
import AdminAddCoachForm from './pages/admin/coach-form';
import AdminCoachList from './pages/admin/coach-list';
import AdminEventList from './pages/admin/event-list';
import AdminEventsRegistrationsPage from './pages/admin/events-registrations';
import GearList from './pages/admin/gear-list';
import AdminLocationDetails from './pages/admin/location-details';
import AdminLocationList from './pages/admin/location-list';
import OrgListPage from './pages/admin/org-list';
import LocationPage from './pages/admin/location-map';
import LogbookListPage from './pages/admin/logbook-list';
import AdminProductsListPage from './pages/admin/product-list';
import ReserveLogsList from './pages/admin/reservelog-list';
import AdminTeamList from './pages/admin/team-list';
import TemplatePage from './pages/admin/templates';
import AdminTransactionList from './pages/admin/transaction-list';
import UserEventsPage from './pages/admin/user-events';
import AdminUserList from './pages/admin/user-list';
import AdminUserRegistration from './pages/admin/user-registrations';
import CoachPricingPage from './pages/coach/pricing';
import AircraftListPage from './pages/dzm/aircraftList';
import AircraftList from './pages/admin/aircraft-list';
import StyleGuidePage from './pages/dzm/styleGuide';
import DzmCoachList from './pages/dzm/coachList';
import DZProfilePage from './pages/dzm/dzProfile';
import InvoicePage from './pages/dzm/invoice';
import JobListPage from './pages/dzm/job-list';
import DzmJumperInfoPage from './pages/dzm/jumperInfo';
import DzmLoadListPage from './pages/dzm/loads/load-list';
import LoadPassengerListPage from './pages/dzm/loads/load-passenger-list';
import ManifestPage from './pages/dzm/manifest';
import OnDeckPage from './pages/dzm/onDeck';
import PreferencesPage from './pages/dzm/preferences';
import DzmPricingPage from './pages/dzm/pricing';
import ShowProductPage from './pages/dzm/product/show';
import NewProductPage from './pages/dzm/products/new';
import ShowProductsPage from './pages/dzm/products/show';
import JumpManifestPage from './pages/dzm/public/jumpManifest';
import ReportsPage from './pages/dzm/reports';
import StaffListPage from './pages/dzm/staffList';
import DzmTeamList from './pages/dzm/teamList';
import DzmUserDetailsPage from './pages/dzm/user-details';
import UserListPage from './pages/dzm/userList';
import HelpPage from './pages/help';
import HomePage from './pages/home';
import LoginPage from './pages/login';
import SignUpPage from './pages/register';
import ReserveLogForm from './pages/reserveLog/reservelog-form';
import ResetPassword from './pages/reset-password';
import RiggerJobsListPage from './pages/rigger/my-jobs';
import AdminRiggerJobsListPage from './pages/admin/rigger-jobs';
import RiggerPricingPage from './pages/rigger/pricing';
import SetPaymentsPage from './pages/set-payments';
import SlotCalendarPage from './pages/team/slot-calendar';
import TeamDetailsPage from './pages/team/team-details';
import TeamFormPage from './pages/team/team-form';
import TeamPublicPage from './pages/team/team-public-page';
import UserDetailsPage from './pages/user/UserDetailsPage';
import CalendarPage from './pages/user/calendar/calendar';
import CalendarEventPage from './pages/user/calendar/calendar-event';
import EventRegistrationListPage from './pages/user/calendar/events/registration-list';
import EventGroups from './pages/events/groups';
import UserProfilePage from './pages/user/profile';
import UserSettingsPage from './pages/user/settings';
import UserUploadPage from './pages/user/upload';
import VerifyPage from './pages/verify';
import PageNotFound from './pages/PageNotFound';

const Routes = () => (
  <Router>
    <Switch>
      <PrivateRoute exact={true} path="/" component={HomePage} />
      <PrivateRoute
        exact={true}
        path="/user/settings"
        component={UserSettingsPage}
      />
      <PrivateRoute
        exact={true}
        path="/user-details"
        component={DzmUserDetailsPage}
        roles={[
          Role.Coach,
          Role.DZM,
          Role.Admin,
          Role.Org,
          Role.Rigger,
          Role.Events,
          Role.WTM,
          Role.Manufacturer
        ]}
      />
      <PrivateRoute
        exact={true}
        path="/admin/coach-list"
        component={AdminCoachList}
        roles={[Role.Admin]}
      />
      <PrivateRoute
        exact={true}
        path="/admin/admin-list"
        component={AdminList}
        roles={[Role.Admin]}
      />
      <PrivateRoute
        exact={true}
        path="/admin/add-coach-form"
        component={AdminAddCoachForm}
        roles={[Role.Admin]}
      />
      <PrivateRoute
        exact={true}
        path="/admin/event-list"
        component={AdminEventList}
        roles={[Role.Admin]}
      />
      <PrivateRoute
        exact={true}
        path="/events"
        component={AdminEventList}
        roles={[
          Role.Coach,
          Role.DZM,
          Role.Rigger,
          Role.Events,
          Role.WTM,
          Role.Manufacturer,
          Role.Org
        ]}
      />
      <PrivateRoute
        exact={true}
        path="/my-events"
        component={StudentEventsPage}
        roles={[Role.Student]}
      />
      <PrivateRoute
        exact={true}
        path="/admin/locations"
        component={AdminLocationList}
        roles={[Role.Admin]}
      />
      <PrivateRoute
        exact={true}
        path="/admin/org-list"
        component={OrgListPage}
        roles={[Role.Admin]}
      />
      <PrivateRoute
        exact={true}
        path="/admin/location-details/:locationId"
        component={AdminLocationDetails}
        roles={[Role.Admin]}
      />

      <PrivateRoute
        exact={true}
        path="/location-details/:locationId"
        component={AdminLocationDetails}
        roles={[Role.Coach, Role.Rigger, Role.DZM]}
      />

      <PrivateRoute
        exact={true}
        path="/team-details/:teamId"
        component={TeamDetailsPage}
        roles={[
          Role.Admin,
          Role.Coach,
          Role.DZM,
          Role.Rigger,
          Role.Events,
          Role.Org,
          Role.WTM
        ]}
      />

      <PrivateRoute
        exact={true}
        path="/admin/products"
        component={AdminProductsListPage}
        roles={[Role.Admin]}
      />
      <PrivateRoute
        exact={true}
        path="/admin/team-list"
        component={AdminTeamList}
        roles={[Role.Admin]}
      />
      <PrivateRoute
        exact={true}
        path="/admin/user-list"
        component={AdminUserList}
        roles={[Role.Admin]}
      />
      <PrivateRoute
        exact={true}
        path="/team/events/slot-calendar/:eventId"
        component={SlotCalendarPage}
        roles={[Role.Admin, Role.Coach, Role.Events]}
      />
      <PrivateRoute
        exact={true}
        path="/admin/transaction-list"
        component={AdminTransactionList}
        roles={[Role.Admin]}
      />
      <PrivateRoute
        exact={true}
        path="/admin/user-registrations"
        component={AdminUserRegistration}
        roles={[Role.Admin]}
      />
      <PrivateRoute
        exact={true}
        path="/event/registration-list/:campId"
        component={EventRegistrationListPage}
        roles={[Role.Admin, Role.Coach, Role.Events, Role.DZM, Role.Org]}
      />
      <PrivateRoute
        exact={true}
        path="/event/groups/:campId"
        component={EventGroups}
        roles={[Role.Admin, Role.Coach, Role.Events, Role.DZM, Role.Org]}
      />
      <PrivateRoute
        exact={true}
        path="/events/registrations/:userRegId/:studentId"
        component={AdminEventsRegistrationsPage}
      />
      <PrivateRoute
        exact={true}
        path="/team/team-form"
        component={TeamFormPage}
        roles={[Role.Admin]}
      />
      <PrivateRoute
        exact={true}
        path="/dzm/user-list"
        component={UserListPage}
      />
      <PrivateRoute
        exact={true}
        path="/dzm/public/jump_manifest"
        component={JumpManifestPage}
      />
      <PrivateRoute exact={true} path="/staff" component={StaffListPage} />
      <PrivateRoute
        exact={true}
        path="/dzm/dz_profile"
        component={DZProfilePage}
      />
      <PrivateRoute
        exact={true}
        path="/dzm/aircraft"
        component={AircraftListPage}
        roles={[Role.DZM]}
      />

      <PrivateRoute
        exact={true}
        path="/admin/aircraft"
        component={AircraftList}
        roles={[Role.Admin]}
      />
      <PrivateRoute
        exact={true}
        path="/admin/style-guide"
        component={StyleGuidePage}
        roles={[Role.Admin]}
      />
      <PrivateRoute exact={true} path="/dzm/reports" component={ReportsPage} />
      <PrivateRoute
        exact={true}
        path="/dzm/preferences"
        component={PreferencesPage}
      />
      <PrivateRoute
        exact={true}
        path="/dzm/manifest"
        component={ManifestPage}
        roles={[Role.DZM]}
      />
      <PrivateRoute exact={true} path="/dashboard" component={OnDeckPage} />
      <PrivateRoute
        exact={true}
        path="/user/calendar"
        component={CalendarPage}
      />
      <PrivateRoute
        exact={true}
        path="/user/calendar/:eventId"
        component={CalendarEventPage}
      />
      <PrivateRoute
        exact={true}
        path="/dzm/jumper_info"
        component={DzmJumperInfoPage}
        roles={[Role.DZM]}
      />
      <PrivateRoute
        exact={true}
        path="/dzm/loads"
        component={DzmLoadListPage}
        roles={[Role.DZM]}
      />
      <PrivateRoute
        exact={true}
        path="/dzm/loads/passenger/:loadId"
        component={LoadPassengerListPage}
        roles={[Role.DZM]}
      />

      <PrivateRoute
        exact={true}
        path="/payments"
        component={PaymentsPage}
        roles={[
          Role.Admin,
          Role.Coach,
          Role.DZM,
          Role.Rigger,
          Role.Events,
          Role.WTM,
          Role.Manufacturer,
          Role.Org
        ]}
      />
      <PrivateRoute
        exact={true}
        path="/admin/user-events"
        component={UserEventsPage}
      />
      <PrivateRoute
        exact={true}
        path="/admin/user-events/:studentId"
        component={UserEventsPage}
      />
      <PrivateRoute
        exact={true}
        path="/user/profile"
        component={UserProfilePage}
      />
      <PrivateRoute
        exact={true}
        path="/dzm/team-list"
        component={DzmTeamList}
      />
      <PrivateRoute
        exact={true}
        path="/dzm/coach-list"
        component={DzmCoachList}
      />
      <PrivateRoute
        exact={true}
        path="/admin/templates"
        component={TemplatePage}
        roles={[Role.Admin]}
      />
      <PrivateRoute
        exact={true}
        path="/coach/pricing"
        component={CoachPricingPage}
        roles={[Role.Coach, Role.Admin]}
      />
      <PrivateRoute
        exact={true}
        path="/rigger/pricing"
        component={RiggerPricingPage}
        roles={[Role.Rigger, Role.Admin]}
      />
      <PrivateRoute
        exact={true}
        path="/dzm/pricing"
        component={DzmPricingPage}
        roles={[Role.DZM, Role.Admin]}
      />
      <PrivateRoute
        exact={true}
        path="/products"
        component={ShowProductsPage}
        roles={[
          Role.DZM,
          Role.Rigger,
          Role.Coach,
          Role.Events,
          Role.WTM,
          Role.Manufacturer,
          Role.Org
        ]}
      />
      <PrivateRoute
        exact={true}
        path="/products/new"
        component={NewProductPage}
        roles={[
          Role.DZM,
          Role.Rigger,
          Role.Coach,
          Role.Events,
          Role.WTM,
          Role.Manufacturer,
          Role.Org
        ]}
      />
      <PrivateRoute
        exact={true}
        path="/products/:productId"
        component={ShowProductPage}
        roles={[
          Role.DZM,
          Role.Rigger,
          Role.Coach,
          Role.Admin,
          Role.Events,
          Role.WTM,
          Role.Manufacturer,
          Role.Org
        ]}
      />
      <PrivateRoute
        exact={true}
        path="/user/upload"
        component={UserUploadPage}
        roles={[Role.Admin]}
      />
      <PrivateRoute
        component={AccountTransactions}
        exact={true}
        path="/user-details/:userId/student-teams/:teamId"
        roles={[
          Role.Coach,
          Role.DZM,
          Role.Admin,
          Role.Org,
          Role.Rigger,
          Role.Events,
          Role.WTM,
          Role.Manufacturer
        ]}
      />
      <PrivateRoute
        component={ReserveLogsList}
        exact={true}
        path="/admin/reserve-logs"
        roles={[Role.Admin]}
      />
      <PrivateRoute
        component={ReserveLogsList}
        exact={true}
        path="/coach/reserve-logs"
        roles={[Role.DZM, Role.Rigger]}
      />
      <PrivateRoute
        component={ReserveLogForm}
        exact={true}
        path="/admin/reserve-log/:reserveLogId"
        roles={[Role.DZM, Role.Rigger, Role.Admin]}
      />
      <PrivateRoute
        component={GearList}
        exact={true}
        path="/admin/gear"
        roles={[Role.Admin]}
      />
      <PrivateRoute
        component={Gears}
        exact={true}
        path="/user/gear"
        roles={[Role.Student, Role.Rigger, Role.Admin]}
      />
      <PrivateRoute
        component={LogbookListPage}
        exact={true}
        path="/admin/logbook"
        roles={[Role.Admin]}
      />
      <PrivateRoute
        component={InvoicePage}
        exact={true}
        path="/dzm/invoice/:invoiceId"
        roles={[
          Role.Student,
          Role.Rigger,
          Role.DZM,
          Role.Coach,
          Role.Admin,
          Role.WTM,
          Role.Manufacturer
        ]}
      />
      <PrivateRoute
        component={InvoicePage}
        exact={true}
        path="/invoice/:studentId/:invoiceId"
        roles={[
          Role.Student,
          Role.Rigger,
          Role.DZM,
          Role.Coach,
          Role.Admin,
          Role.WTM,
          Role.Manufacturer,
          Role.Org
        ]}
      />
      <PrivateRoute
        component={StudentInvoicePage}
        exact={true}
        path="/invoices"
        roles={[
          Role.Student,
          Role.Rigger,
          Role.DZM,
          Role.Coach,
          Role.Admin,
          Role.Events,
          Role.WTM,
          Role.Manufacturer,
          Role.Org
        ]}
      />
      <PrivateRoute
        component={StudentParticipants}
        exact={true}
        path="/event/participants/:campId"
        roles={[Role.Student, Role.Admin]}
      />
      <PrivateRoute
        component={JobListPage}
        exact={true}
        path="/rigger/dashboard"
        roles={[Role.Rigger, Role.Admin]}
      />
      <PrivateRoute
        component={RiggerJobsListPage}
        exact={true}
        path="/rigger/myjobs"
        roles={[Role.Rigger]}
      />

      <PrivateRoute
        component={AdminRiggerJobsListPage}
        exact={true}
        path="/admin/jobs/:riggerId"
        roles={[Role.Admin, Role.Rigger]}
      />

      <PrivateRoute
        exact={true}
        path="/user/accounts"
        component={StudentAccountsPage}
        roles={[Role.Student]}
      />
      <PrivateRoute
        exact={true}
        path="/coach/students"
        component={StudentsPage}
        roles={[Role.Coach]}
      />
      <PrivateRoute
        exact={true}
        path="/events/participants"
        component={ParticipantsPage}
        roles={[Role.Events, Role.Org]}
      />
      <PrivateRoute
        exact={true}
        path="/user/signup"
        component={StudentSignupForm}
        roles={[Role.Rigger, Role.DZM]}
      />
      <PrivateRoute
        exact={true}
        path="/dzm/jumpers"
        component={JumpersPage}
        roles={[Role.DZM]}
      />
      <PrivateRoute
        exact={true}
        path="/flyers"
        component={FlyersPage}
        roles={[Role.WTM]}
      />
      <PrivateRoute
        exact={true}
        path="/customers"
        component={CustomersPage}
        roles={[Role.Rigger, Role.Manufacturer]}
      />
      <PrivateRoute
        exact={true}
        path="/members"
        component={MembersPage}
        roles={[Role.Org]}
      />
      <PrivateRoute
        exact={true}
        path="/rigger/job-gear-storage"
        component={JobGearStoragePage}
        roles={[Role.Rigger]}
      />
      <PrivateRoute
        exact={true}
        path="/coach/mystudents"
        component={MyStudentsListPage}
        roles={[Role.Coach]}
      />
      <Route exact={true} path="/login" component={LoginPage} />
      <Route exact={true} path="/verify" component={VerifyPage} />
      <Route exact={true} path="/signup" component={SignUpPage} />
      <Route exact={true} path="/reset-password" component={ResetPassword} />
      <Route exact={true} path="/help" component={HelpPage} />
      <Route exact={true} path="/:teamSlug" component={TeamPublicPage} />
      <Route
        exact={true}
        path="/locations/:locationId"
        component={LocationPage}
      />
      <PrivateRoute
        exact={true}
        path="/payments/:amount/:registration_code/:email/:camp_name/:registration_id"
        component={SetPaymentsPage}
        roles={[
          Role.Admin,
          Role.Coach,
          Role.DZM,
          Role.Events,
          Role.Student,
          Role.Org
        ]}
      />
      <PrivateRoute
        exact={true}
        path="/user/:token"
        component={UserDetailsPage}
      />

      <Route component={PageNotFound} />
    </Switch>
  </Router>
);

export default Routes;
