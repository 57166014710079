import StudentSearchForm from 'components/Students/Forms/StudentSearchForm';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Nav } from 'tabler-react';
import FeatureAccessGate from 'modules/common/components/FeatureAccessGate';


const DZMNav = () => {

  return (
    <>
      <Nav.Item icon="home" to="/" value="/" />
      <FeatureAccessGate feature="feature_manifest">
        <Nav.Item icon="arrow-up-circle" to="/dzm/loads" value="Loads" />
      </FeatureAccessGate>
      <Nav.Item icon="shopping-bag" to="/products" value="Products" />
      <Nav.Item
        icon="alert-octagon"
        to="/coach/reserve-logs"
        value="Reserves"
      />
    <FeatureAccessGate feature="feature_alpha">
      <Nav.Item hasSubNav value="WIP">
        <Link to="/dzm/jumper_info" className="dropdown-item">
          Jumper Info
        </Link>
        <Link to="/dzm/dz_profile" className="dropdown-item">
          Dropzone Profile
        </Link>
        <Link to="/dzm/checking_requirements" className="dropdown-item">
          Check-in Requirements
        </Link>
        <Link to="/dzm/preferences" className="dropdown-item">
          Dropzone Preferences
        </Link>
        <Link to="/dzm/public/jump_manifest" className="dropdown-item">
          Public Manifest
        </Link>
      </Nav.Item>
    </FeatureAccessGate>
      <li className="nav-item ml-lg-auto mb-4 mb-lg-0">
        <Dropdown
          toggle={false}
          icon="calendar"
          position="bottom-end"
          flex
          className="mr-4 dropdown-header-icon-lg"
          items={
            <>
              <Link to="/user/calendar" className="dropdown-item">
                Calendar
              </Link>
              <Link to="/events" className="dropdown-item">
                Events
              </Link>
            </>
          }
        />
        <Dropdown
          toggle={false}
          icon="pie-chart"
          position="bottom-end"
          flex
          className="mr-4 dropdown-header-icon-lg"
          items={
            <>
              <Link to="/dzm/aircraft" className="dropdown-item">
                Aircraft
              </Link>
              <Link to="/invoices" className="dropdown-item">
                Invoices
              </Link>
              <Link to="/payments" className="dropdown-item">
                Payments
              </Link>
            <FeatureAccessGate feature="feature_reports">
                <Link to="/dzm/reports" className="dropdown-item">
                  Reports
                </Link>
            </FeatureAccessGate>
            </>
          }
        />
        <Dropdown
          toggle={false}
          icon="users"
          position="bottom-end"
          flex
          className="mr-4 ml-4 dropdown-header-icon-lg"
          items={
            <>
              <Link to="/dzm/jumpers" className="dropdown-item">
                Jumpers
              </Link>
              <Link to="/staff" className="dropdown-item">
                TEAM Accounts
              </Link>
            </>
          }
        />
        <StudentSearchForm />
      </li>
    </>
  );
};

export default observer(DZMNav);
