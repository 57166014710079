import React from 'react';
import { Button, Card, Form, Grid } from 'tabler-react';

import Wrapper from '../layout';

const JumperDetails = () => {
  const CardDetail = (
    <div>
      <Card>
        <Card.Header>
          <Card.Title>Jumper Details (students)</Card.Title>
          <Card.Options>
            <label>SKYCRU.ID (student_id): 2</label>
          </Card.Options>
        </Card.Header>
        <Card.Body>
          <Form.Group label="First Name">
            <Form.Input name="first_name" placeholder="" />
          </Form.Group>
          <Form.Group label="Last Name">
            <Form.Input name="last_name" placeholder="" />
          </Form.Group>
          {/*
					<Form.Group label="Preferred Name">
						<Form.Input
						    name="nick_name"
						    placeholder=""
						  />
					</Form.Group>

					<Form.Group label="Company Name">
						<Form.Input
						    name="company_name"
						    placeholder=""
						  />
					</Form.Group>

					<Form.Group label="Legal Company Id">
						<Form.Input
						    name="legal_company_id"
						    placeholder=""
						  />
					</Form.Group>
*/}
          <Form.Group label="Address Line 1">
            <Form.Input name="address_line_1" placeholder="" />
          </Form.Group>

          <Form.Group label="Address Line 2">
            <Form.Input name="address_line_2" placeholder="" />
          </Form.Group>

          <Form.Group label="City">
            <Form.Input name="city" placeholder="" />
          </Form.Group>

          <Form.Group label="Province/State">
            <Form.Select>
              <option>Michigan</option>
              <option>Miami</option>
            </Form.Select>
          </Form.Group>

          <Form.Group label="Postal/Zip Code">
            <Form.Input name="zipcode" placeholder="" />
          </Form.Group>

          <Form.Group label="Country">
            <Form.Select>
              <option>United States</option>
              <option>Canada</option>
            </Form.Select>
          </Form.Group>
          <Form.Group label="D.O.B.">
            <Form.MaskedInput
              name="d_o"
              placeholder="00/00/0000"
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            />
          </Form.Group>

          <Form.Group label="Weight">
            <Form.InputGroup>
              <Form.Input name="weight" />
              <Form.InputGroupAppend>
                <Form.InputGroupText>lbs</Form.InputGroupText>
              </Form.InputGroupAppend>
            </Form.InputGroup>
          </Form.Group>
          <hr />
          <Form.Group
            // className="mb-0"
            label="Phone number"
          >
            <Form.Input name="phone_number" />
          </Form.Group>

          <Form.Group label="Email">
            <Form.Input name="email" />
          </Form.Group>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Card.Title>Emergency Contact (user_contacts)</Card.Title>
          <Form.Group
            // className="mb-0"
            label="Emergency Contact"
          >
            <Form.Input name="emergency_contact" />
          </Form.Group>

          <Form.Group label="Emergency Contact Phone">
            <Form.Input name="emergency_contact_phone" />
          </Form.Group>
        </Card.Body>
      </Card>
    </div>
  );

  return CardDetail;
};

const Credential = () => {
  const credentialInfo = (
    <div>
      <Card>
        <Card.Header>
          <Card.Title>Credentials (auth_merits, user_merits)</Card.Title>
          <Card.Options>
            <Button.List align="right">
              {/*
                  <Button color="blue" size="sm">Jump Log</Button>
                  <Button color="blue" size="sm">Reserves</Button>
*/}
              <Button color="blue" size="sm">
                Checkin
              </Button>
              <Button color="blue" size="sm" disabled>
                Checkout
              </Button>
            </Button.List>
          </Card.Options>
        </Card.Header>
        <Card.Body>
          {/*
		          <Form.Group label="Last Jumped">
						  	<Form.InputGroup>
								<Form.MaskedInput
								    name="last_jump"
								    value="01/01/2020"
								    placeholder="00/00/0000"
								    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
								    readOnly
								  />
								<Form.InputGroupAppend>
					      	<Form.InputGroupText>
								    <Form.Checkbox
										    label="Verified"
										    name="last_jump_dz_verified"
										    value="1"
										  />
					      		</Form.InputGroupText>
					      	</Form.InputGroupAppend>
						  	</Form.InputGroup>
						  </Form.Group>
		  				<Form.Group label="Reserve Expires">
						  <Form.InputGroup>
							<Form.MaskedInput
							    name="reserve_expiring"
							    placeholder="00/00/0000"
							    readOnly
							    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
							  />
								<Form.InputGroupAppend>
					      			<Form.InputGroupText>
								        <Form.Checkbox
										    label="Verified"
										    name="expring_soonest_dz_verified"
										    value="1"
										  />

					      			</Form.InputGroupText>
					      		</Form.InputGroupAppend>

						  </Form.InputGroup>
						</Form.Group>
*/}
          <Form.Group label="Organization">
            <Form.Select name="organization">
              <option value="uspa">USPA</option>
            </Form.Select>
          </Form.Group>

          <Form.Group label="Member #">
            <Form.InputGroup>
              <Form.Input name="organization_member_number" />
              <Form.InputGroupAppend>
                <Form.InputGroupText>
                  <Form.Checkbox
                    label="Verified"
                    name="org_member_number_dz_verified"
                    value="1"
                  />
                </Form.InputGroupText>
              </Form.InputGroupAppend>
            </Form.InputGroup>
          </Form.Group>

          <Form.Group label="Membership Expires">
            <Form.InputGroup>
              <Form.MaskedInput
                name="organization_expire"
                placeholder="00/00/0000"
                mask={[
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/
                ]}
              />
              <Form.InputGroupAppend>
                <Form.InputGroupText>
                  <Form.Checkbox
                    label="Verified"
                    name="organization_expire_dz_verified"
                    value="1"
                  />
                </Form.InputGroupText>
              </Form.InputGroupAppend>
            </Form.InputGroup>
          </Form.Group>

          <Form.Group label="License Type">
            <Form.Select name="license_type">
              <option value="d">D</option>
            </Form.Select>
          </Form.Group>

          <Form.Group label="Organization License #">
            <Form.InputGroup>
              <Form.Input name="organization_license_number" />
              <Form.InputGroupAppend>
                <Form.InputGroupText>
                  <Form.Checkbox
                    label="Verified"
                    name="org_license_number_dz_verified"
                    value="1"
                  />
                </Form.InputGroupText>
              </Form.InputGroupAppend>
            </Form.InputGroup>
          </Form.Group>

          <Form.Group label="AFF-I Rating Expires">
            <Form.InputGroup>
              <Form.MaskedInput
                name="rating_express"
                placeholder="00/00/0000"
                mask={[
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/
                ]}
              />
              <Form.InputGroupAppend>
                <Form.InputGroupText>
                  <Form.Checkbox
                    label="Verified"
                    name="rating_express_dz_verified"
                    value="1"
                  />
                </Form.InputGroupText>
              </Form.InputGroupAppend>
            </Form.InputGroup>
          </Form.Group>

          <Form.Group label="IAD LINE Rating Expires">
            <Form.InputGroup>
              <Form.MaskedInput
                name="line_rating_express"
                placeholder="00/00/0000"
                mask={[
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/
                ]}
              />
              <Form.InputGroupAppend>
                <Form.InputGroupText>
                  <Form.Checkbox
                    label="Verified"
                    name="line_rating_express_dz_verified"
                    value="1"
                  />
                </Form.InputGroupText>
              </Form.InputGroupAppend>
            </Form.InputGroup>
          </Form.Group>

          <Form.Group label="Coach Rating Expires">
            <Form.InputGroup>
              <Form.MaskedInput
                name="line_rating_express"
                placeholder="00/00/0000"
                mask={[
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/
                ]}
              />
              <Form.InputGroupAppend>
                <Form.InputGroupText>
                  <Form.Checkbox
                    label="Verified"
                    name="coach_rating_express_dz_verified"
                    value="1"
                  />
                </Form.InputGroupText>
              </Form.InputGroupAppend>
            </Form.InputGroup>
          </Form.Group>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          <Card.Title>Account</Card.Title>
          <Card.Options>
            <strong>Balance:</strong>
            <span>$3,886.00</span>
          </Card.Options>
        </Card.Header>
        <Card.Body>
          <Button.List align="center">
            <Button color="blue" type="button">
              Take Payment
            </Button>
            <Button color="blue" type="button">
              Cash Out
            </Button>
            <Button color="blue" type="button">
              Other Transactions
            </Button>
            <Button color="blue" type="button">
              Book Jump Lookup
            </Button>
            <Button color="blue" type="button">
              Add Book Jump
            </Button>
            <Button color="blue" type="button">
              Transaction History
            </Button>
          </Button.List>

          <Form.Group label="Price">
            <Form.InputGroup>
              <Form.InputGroupPrepend>
                <Form.InputGroupText>Overlimit</Form.InputGroupText>
              </Form.InputGroupPrepend>
              <Form.Input name="overlimit" />
            </Form.InputGroup>
          </Form.Group>
          <Form.Group label="">
            <Form.Checkbox
              label="Allow Neg Balance"
              name="allow_neg_balance"
              value="1"
            />
          </Form.Group>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          <Card.Title>Notes (student_notes)</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form.Group label="">
            <Form.Textarea
              defaultValue=""
              name="notes"
              placeholder="Content.."
              rows={6}
            />
          </Form.Group>
        </Card.Body>
      </Card>
    </div>
  );
  return credentialInfo;
};

const Profile = () => {
  const ProfileInput = (
    <div>
      <Card>
        <Card.Header>
          <Card.Title>Permissions (user_roles)</Card.Title>
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col width={4}>
              <Form.Group label="User Roles">
                <Form.Checkbox
                  label="AFF Student"
                  name="aff_student"
                  value="1"
                />
                <Form.Checkbox label="Cost AFF" name="cost_aff" value="1" />
              </Form.Group>
            </Grid.Col>
            {/*
						<Grid.Col width={4}>
							<Form.Group label="Professional">
								  <Form.Checkbox
								    label="AFF Student"
								    name="aff_student"
								    value="1"
								  />
								  <Form.Checkbox
								    label="Cost AFF"
								    name="cost_aff"
								    value="1"
								  />
								</Form.Group>
						</Grid.Col>
						<Grid.Col width={4}>
							<Form.Group label="Professional Non-Jumper">
								  <Form.Checkbox
								    label="AFF Student"
								    name="aff_student"
								    value="1"
								  />
								  <Form.Checkbox
								    label="Cost AFF"
								    name="cost_aff"
								    value="1"
								  />
							</Form.Group>
            </Grid.Col>
*/}
          </Grid.Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          <Card.Title>Preferences</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form.Checkbox
            checked
            label="Jumper want to show themselves to be granted"
            name="id_scanned"
            value="1"
          />
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          <Card.Title>Documents</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form.Group label="Electronic Waiver">
            <Form.InputGroup>
              <Form.MaskedInput
                name="waiver"
                value="07/22/2015"
                placeholder="00/00/0000"
                mask={[
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/
                ]}
              />
              <Form.InputGroupAppend>
                <Form.InputGroupText>
                  <label>
                    Expires <span>12/31/2015</span>
                  </label>
                </Form.InputGroupText>
              </Form.InputGroupAppend>
            </Form.InputGroup>
          </Form.Group>
          {/*
					<Form.Group label="Affidavit Signed">
						<Form.InputGroup>
								<Form.MaskedInput
								    name="waiver"
								    value=""
								    placeholder="00/00/0000"
								    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}

								  />
							<Form.InputGroupAppend>
				      			<Form.InputGroupText>
					    		 <label>Expires <span></span></label>
				      			</Form.InputGroupText>
				      		</Form.InputGroupAppend>
					  	</Form.InputGroup>
					</Form.Group>
					<Form.Group label="Affidavit Signed">
						<Form.InputGroup>
								<Form.MaskedInput
								    name="affidavit_signed"
								    value="05/13/2015"
								    placeholder="00/00/0000"
								    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}

								  />
							<Form.InputGroupAppend>
				      			<Form.InputGroupText>
					    		 <label>Expires <span>12/31/2015</span></label>
				      			</Form.InputGroupText>
				      		</Form.InputGroupAppend>
					  	</Form.InputGroup>
					</Form.Group>
*/}
          <Form.Group label="Paper Waiver Signed">
            <Form.InputGroup>
              <Form.MaskedInput
                name="waiver"
                value="05/13/2015"
                placeholder="00/00/0000"
                mask={[
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/
                ]}
              />
              <Form.InputGroupAppend>
                <Form.InputGroupText>
                  <label>
                    Expires <span>12/31/2015</span>
                  </label>
                </Form.InputGroupText>
              </Form.InputGroupAppend>
            </Form.InputGroup>
          </Form.Group>

          <Form.Group label="">
            <Form.InputGroup>
              <Form.Checkbox label="ID Scanned" name="id_scanned" value="1" />
            </Form.InputGroup>
          </Form.Group>
        </Card.Body>
      </Card>
    </div>
  );

  return ProfileInput;
};
// const ForStudent = () => {

// }

const NewStudentPage = (props) => {
  return (
    <Wrapper {...props} title="Info">
      <div className="pr-3 pl-3">
        <Grid.Row gutters="xs">
          <Grid.Col xl={3} lg={6} md={12} sm={12} xs={12}></Grid.Col>
          <Grid.Col xl={6} lg={12} md={12} sm={12} xs={12}></Grid.Col>
          <Grid.Col xl={3} lg={6} md={12} sm={12} xs={12}></Grid.Col>
        </Grid.Row>

        <form method="POST">
          <Grid.Row gutters="xs">
            <Grid.Col xl={3} lg={6} md={12} sm={12} xs={12}>
              <JumperDetails />
            </Grid.Col>
            <Grid.Col xl={3} lg={6} md={12} sm={12} xs={12}>
              <Credential />
            </Grid.Col>
            <Grid.Col xl={6} lg={12} md={12} sm={12} xs={12}>
              <Profile />
            </Grid.Col>
          </Grid.Row>
        </form>
      </div>
    </Wrapper>
  );
};

export default NewStudentPage;
