import React from 'react';

import Modal from '../../Modal';
import AddLoad from './AddLoad';

interface IAddLoadModalProps {
  isModalOpen: boolean;
  toggleModal: () => void;
}

const AddLoadModal = ({ isModalOpen, toggleModal }: IAddLoadModalProps) => {
  return (
    <Modal
      content={<AddLoad toggleModal={toggleModal} />}
      open={isModalOpen}
      title="Load Management"
      onClose={toggleModal}
    />
  );
};

export default AddLoadModal;
