import { Loading } from "components/Loading";
import StudentCreateFromSearch from "components/Students/Modal/StudentCreateFromSearch";
import { UserStoreContext } from "contexts/userStoreContext";
import Downshift from "downshift";
import debounce from "lodash.debounce";
import { observer } from "mobx-react";
import {
  useGetUserIsCoachSuperAdmin,
  useGetUserIsDzm,
  useGetUserIsRigger,
  useRoles,
} from "modules/common/hooks/useGetCurrentUserType";
import React, { useContext, useMemo, useRef, useState } from "react";
import { Button, Icon } from "tabler-react";
import useReactRouter from "use-react-router";
import useGetSearchStudentQuery from "../../../modules/student/hooks/useGetSearchStudentQuery";
import { Types } from "../../../types/graphql";

const StudentSearchForm = () => {
  const { loading } = useRoles();
  if (loading) {
    return <Loading />;
  }

  return <StudentSearchFormInner />;
};

const StudentSearchFormInner = () => {
  const userStore = useContext(UserStoreContext);
  const { user } = userStore;
  const { history } = useReactRouter();

  const { isCoachAdmin } = useRoles();
  const isRigger = useGetUserIsRigger();
  const isDzm = useGetUserIsDzm();
  const isCoachSuperAdmin = useGetUserIsCoachSuperAdmin();

  //const [filteredStudents, setFilteredStudents] = useState([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const searchRef = useRef("");

  const routeToStudent = (searchedUser: Types.Student) => {
    userStore.detach(user);
    userStore.loadUser(searchedUser);
    history.push("/user-details");
    searchRef.current = "";
  };

  const { data, queryFilter, setQueryFilter, loading } =
    useGetSearchStudentQuery();

  // Set filtered students everytime data changes
  const filteredStudents = useMemo(() => {
    if (data) {
      return data;
    }
    return [];
  }, [data]);

  const handleChange = debounce(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setQueryFilter({ ...queryFilter, q: e.target.value });
    },
    360,
  );

  const handleClickCreate = () => {
    setIsOpen(true);
  };

  return (
    <>
      {(isCoachAdmin || isCoachSuperAdmin) && (
        <Button
          icon="user-plus"
          color="white"
          className="text-success mr-2 d-lg-none"
          onClick={handleClickCreate}
        />
      )}
      <div className="search-container w-150">
        <Downshift onChange={routeToStudent}>
          {({
            getInputProps,
            getItemProps,
            isOpen,
            inputValue,
            highlightedIndex,
          }) => (
            <div>
              <input
                {...getInputProps({
                  className: "form-control",
                  id: "search",
                  placeholder: "Search by name...",
                  type: "search",
                  value: searchRef.current,
                  onChange: (e) => {
                    searchRef.current = e.target.value;
                    e.persist();
                    handleChange(e);
                  },
                })}
              />
              {isOpen && (
                <div className="search-container-dropdown">
                  {filteredStudents.map((item, idx) => (
                    <div
                      {...getItemProps({ item })}
                      className={`search-container-dropdown-item ${
                        idx === highlightedIndex &&
                        "search-container-dropdown-item-highlighted"
                      }`}
                      key={item.id}
                    >
                      {!item?.registration_id && !item?.token ? (
                        <Icon
                          name="user-x"
                          className="mr-2 ml-0 text-secondary"
                        />
                      ) : (
                        <Icon name="user" className="mr-2 ml-0 text-success" />
                      )}
                      <span>{`${item.first_name} ${item.last_name}`}</span>
                      <span className="d-block small text-primary ml-1">
                        {item.id}
                      </span>
                    </div>
                  ))}
                  {!filteredStudents.length && !loading && (
                    <>
                      <div className="search-container-dropdown-item">
                        No results for {inputValue}.
                      </div>
                      <div className="search-container-dropdown-item d-none">
                        <i className="fe fe-send mr-2 text-primary" />
                        SEND INVITE
                      </div>
                      <div
                        className="search-container-dropdown-item"
                        onClick={handleClickCreate}
                      >
                        <i className="fe fe-user-plus mr-2 text-success" />
                        ADD USER
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </Downshift>
      </div>
      {(isRigger || isDzm) && (
        <a href="/user/signup" target="_blank">
          <i className="fe fe-user-plus btn-lg ml-2 text-success" />
        </a>
      )}
      <StudentCreateFromSearch
        name={searchRef.current}
        isModalOpen={isOpen}
        toggleModal={setIsOpen}
      />
    </>
  );
};

export default observer(StudentSearchForm);
