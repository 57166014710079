export enum EUserFilterOptions {
  all = 'all',
  disabled = 'disabled',
  enabled = 'enabled',
  fans = 'fans',
  following = 'following',
  mycru = 'mycru',
  positive = 'positive',
  negative = 'negative',
  zero = 'zero'
}
