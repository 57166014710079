import { Document, Page, Text as PDFText, View } from '@react-pdf/renderer';
import React from 'react';
import moment from 'moment';
import { formatMoney } from 'utils/numberFormat';
import { formatPhoneNumber } from '../../utils/phoneFormat';
import styles from 'utils/pdfStyles';

const JobsPDF = (
  jobList: any //Types.Job[],
) => (
  <Document>
    <Page style={styles.body}>
      <View
        style={{
          flexDirection: 'column',
          gap: 30
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderBottom: '1px solid black',
            paddingBottom: 5
          }}
        >
          <View>
            {/*top left TEAM INFO
          Name
          Contact
          */}
            <PDFText>{jobList[0].team.name}</PDFText>
            <PDFText style={styles.textSmall}>
              {jobList[0].team.team_email}
            </PDFText>
            <PDFText style={styles.textSmall}>
              {jobList[0].team.team_phone}
            </PDFText>
          </View>
          <View>
            {/*top right COACH INFO
          Name
          Contact
          */}
            <PDFText>
              {jobList[0].rigger.first_name} {jobList[0].rigger.last_name}
            </PDFText>
            <PDFText style={styles.textSmall}>
              {jobList[0].rigger.email ?? ''}
            </PDFText>
            <PDFText style={styles.textSmall}>
              {jobList[0].rigger.phone_country_code &&
                jobList[0].rigger.phone_number &&
                formatPhoneNumber(
                  jobList[0].rigger.phone_country_code +
                    jobList[0].rigger.phone_number
                )}
            </PDFText>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <PDFText style={{ ...styles.tableCol }}>CUSTOMER</PDFText>
            <PDFText style={{ ...styles.tableCol }}>SERVICE</PDFText>
            <PDFText style={{ ...styles.tableCol }}>GEAR</PDFText>
            <PDFText style={{ ...styles.tableCol }}>STATUS</PDFText>
            <PDFText style={{ ...styles.tableCol, textAlign: 'right' }}>
              AMOUNT
            </PDFText>
          </View>

          {jobList.map((job) => (
            <View key={job.id} style={styles.tableRow}>
              <View style={{ ...styles.tableCol }}>
                <PDFText>
                  {job.student.first_name} {job.student.last_name}
                  {'\n'}
                  <PDFText style={styles.textSmall}>
                    {moment(job.completed_on).format('MM/DD/YYYY')}
                  </PDFText>
                </PDFText>
              </View>
              <View style={{ ...styles.tableCol }}>
                <PDFText>
                  {job.product.title}
                  {'\n'}
                  <PDFText style={styles.textSmall}>
                    {formatMoney(job.unit_price * job.quantity)}
                  </PDFText>
                </PDFText>
              </View>
              <View style={{ ...styles.tableCol }}>
                <PDFText>
                  {job?.user_gear?.gear_model?.name}
                  {'\n'}
                  <PDFText style={styles.textSmall}>
                    {job?.user_gear?.gear_serial}
                  </PDFText>
                </PDFText>
              </View>
              <View style={{ ...styles.tableCol, ...styles.textSmall }}>
                <PDFText>{job.status}</PDFText>
              </View>
              <View style={{ ...styles.tableCol, ...styles.textSmall }}>
                <PDFText style={{ ...styles.textSmall, textAlign: 'right' }}>
                  {formatMoney(job.unit_price * job.quantity * 0.75)}
                </PDFText>
              </View>
            </View>
          ))}

          <View style={styles.tableRow}>
            <View style={styles.tableRow}>
              <PDFText style={{ ...styles.tableCol }}></PDFText>
              <PDFText style={{ ...styles.tableCol }}></PDFText>
              <PDFText style={{ ...styles.tableCol }}></PDFText>
              <PDFText style={{ ...styles.tableCol }}>TOTAL</PDFText>
              <PDFText
                style={{
                  ...styles.tableCol,
                  textAlign: 'right'
                }}
              >
                {formatMoney(
                  jobList.reduce((total, job) => {
                    return total + job.unit_price * job.quantity * 0.75;
                  }, 0)
                )}
              </PDFText>
            </View>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default JobsPDF;
