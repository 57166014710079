import TShirtSummary from 'components/Event/Modals/TShirtSummary'
import React from 'react'
import { Dropdown } from 'tabler-react'

type TShirtDetailsButtonProps = {
  registrationList: {
    id: string
    first_name: string
    last_name: string
    status: string
    email: string
    phone_country_code: number
    phone_number: string
    registration_code: string
    rating: number
    paid_status: string
    uspa: string
  }[]
}

const TShirtDetailsButton = ({
  registrationList
}: TShirtDetailsButtonProps) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <>
      <Dropdown.Item
        icon="list"
        className="cursor-pointer"
        color="white"
        onClick={() => setIsOpen(true)}
      >
        T-SHIRTS
      </Dropdown.Item>
      <TShirtSummary
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        registrations={registrationList}
      />
    </>
  )
}

export default TShirtDetailsButton
