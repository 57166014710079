import { useMutation } from '@apollo/client';
import FormField from 'components/FormField';
import { EUserTypes } from 'components/User/userTypes';
import { Formik } from 'formik';
import { UPDATE_STUDENT_MUTATION } from 'graphql/UPDATE_STUDENT_MUTATION';
import { UPDATE_USER_MUTATION } from 'graphql/UPDATE_USER_MUTATION';
import { useRootStore } from 'hooks/useRootStore';
import { observer } from 'mobx-react';
import { applySnapshot } from 'mobx-state-tree';
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Card, Grid, Text } from 'tabler-react';
import { formatPhoneNumber } from 'utils/phoneFormat';
import * as Yup from 'yup';
import { capitalizeName } from "utils/stringFormat";

const updateUserValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("A first name is required"),
  last_name: Yup.string().required("A last name is required"),
});

const EditProfileForm = () => {
  const rootStore = useRootStore();
  const { currentUser } = rootStore;
  const { isStudent, isAdmin } = useGetCurrentUserType();

  const {
    id,
    first_name,
    last_name,
    email,
    phone_number,
    type,
    email_private,
    phone_private,
  } = currentUser;

  const [updateStudent] = useMutation(UPDATE_STUDENT_MUTATION, {
    onCompleted: () => toast.success("Profile Updated."),
  });

  const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
    onCompleted: () => toast.success("Profile Updated."),
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id,
        first_name,
        last_name,
        email,
        phone_number,
      }}
      validationSchema={updateUserValidationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        if (type === EUserTypes.student) {
          await updateStudent({
            variables: {
              student: {
                ...values,
                first_name: capitalizeName(values.first_name),
                last_name: capitalizeName(values.last_name),
              },
            },
          });
        } else {
          await updateUser({
            variables: {
              user: {
                ...values,
                type,
                first_name: capitalizeName(values.first_name),
                last_name: capitalizeName(values.last_name),
              },
            },
          });
        }

        applySnapshot(rootStore, {
          ...rootStore,
          currentUser: { ...currentUser, ...values },
        });

        setSubmitting(false);
        resetForm();
      }}
    >
      {({
        values,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm,
      }) => (
        <form className="card" onSubmit={handleSubmit}>
          <Card.Body>
            <Grid.Row>
              <Grid.Col md={6} sm={12} xs={12}>
                <FormField
                  name="first_name"
                  label="First Name"
                  placeholder="First Name"
                  type="text"
                  value={values.first_name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={isAdmin}
                />
              </Grid.Col>
              <Grid.Col md={6} sm={12} xs={12}>
                <FormField
                  name="last_name"
                  label="Last Name"
                  placeholder="Last Name"
                  type="text"
                  value={values.last_name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={isAdmin}
                />
              </Grid.Col>
            </Grid.Row>
            {!isStudent && !isAdmin && (
              <>
                <h4 className="mt-5">Contact Info</h4>
                <Grid.Row>
                  <Grid.Col md={6} sm={12} xs={12}>
                    <FormField
                      appendleft={
                        <Button icon={"phone"} color={"secondary"} disabled />
                      }
                      name="phone"
                      label="Phone"
                      placeholder="Phone"
                      type="text"
                      value={formatPhoneNumber(values.phone_number)}
                      appendright={
                        <Button
                          icon={phone_private === 1 ? "lock" : "unlock"}
                          color={"white"}
                          className={
                            phone_private === 1
                              ? " text-gray-dark "
                              : " text-muted "
                          }
                          onClick={async (e) => {
                            e.preventDefault();
                            await updateUser({
                              variables: {
                                user: {
                                  ...values,
                                  phone_private: phone_private === 1 ? 0 : 1,
                                  type,
                                },
                              },
                            });

                            applySnapshot(rootStore, {
                              ...rootStore,
                              currentUser: {
                                ...currentUser,
                                ...values,
                                phone_private: phone_private === 1 ? 0 : 1,
                              },
                            });

                            resetForm();
                          }}
                        />
                      }
                      disabled
                    />
                    <Text.Small className="float-right text-muted">
                      Your phone number is{" "}
                      <strong>
                        {phone_private === 1 ? "private" : "public"}
                      </strong>
                      .
                    </Text.Small>
                  </Grid.Col>
                  <Grid.Col md={6} sm={12} xs={12}>
                    <FormField
                      appendleft={<Button icon={"mail"} color={"secondary"} disabled />}
                      name="email"
                      label="Email"
                      placeholder="Email"
                      type="text"
                      value={values.email}
                      appendright={
                        <Button
                          icon={email_private === 1 ? "lock" : "unlock"}
                          color={"white"}
                          className={
                            email_private === 1
                              ? " text-gray-dark "
                              : " text-muted "
                          }
                          onClick={async (e) => {
                            e.preventDefault();
                            await updateUser({
                              variables: {
                                user: {
                                  ...values,
                                  email_private: email_private === 1 ? 0 : 1,
                                  type,
                                },
                              },
                            });

                            applySnapshot(rootStore, {
                              ...rootStore,
                              currentUser: {
                                ...currentUser,
                                ...values,
                                email_private: email_private === 1 ? 0 : 1,
                              },
                            });

                            resetForm();
                          }}
                        />
                      }
                      disabled
                    />
                    <Text.Small className="float-right text-muted">
                      Your email is{" "}
                      <strong>
                        {email_private === 1 ? "private" : "public"}
                      </strong>
                      .
                    </Text.Small>
                  </Grid.Col>
                  {/*
                  <Grid.Col md={6} sm={12} xs={12}>
                    <FormField
                      appendleft={
                        <Button icon={'phone'} color={'secondary'} disabled />
                      }
                      name="whatsapp"
                      label="WhatsApp"
                      placeholder="WhatsApp Number"
                      type="text"
                    //  value={formatPhoneNumber(values.phone_number)}
                      appendright={
                        <Button
                          icon={phone_private === 1 ? 'lock' : 'unlock'}
                          color={'white'}
                          className={phone_private === 1 ? ' text-gray-dark ' : ' text-muted '}
                          onClick={async (e) => {
                            e.preventDefault();
                            await updateUser({
                              variables: {
                                user: {
                                  ...values,
                                  phone_private: phone_private === 1 ? 0 : 1,
                                  type
                                }
                              }
                            });

                            applySnapshot(rootStore, {
                              ...rootStore,
                              currentUser: {
                                ...currentUser,
                                ...values,
                                phone_private: phone_private === 1 ? 0 : 1
                              }
                            });

                            resetForm();
                          }}
                          disabled
                        />
                      }
                      disabled
                    />
                    <Text.Small className="float-right text-muted">
                      Your WhatsApp number is{' '}
                      <strong>{phone_private === 1 ? 'private' : 'public'}</strong>.
                    </Text.Small>
                  </Grid.Col>
*/}
                </Grid.Row>
              </>
            )}
          </Card.Body>
          <Card.Footer className="text-right">
            <Button
              pill
              className="btn btn-gray-dark"
              disabled={isSubmitting}
              loading={isSubmitting}
              type="submit"
              size="sm"
            >
              UPDAT{isSubmitting ? "ING" : "E"}
            </Button>
          </Card.Footer>
        </form>
      )}
    </Formik>
  );
};

export default observer(EditProfileForm);
