import { useMutation, useQuery } from "@apollo/client";
import { useRootStore } from "hooks";
import React, { useMemo } from "react";
import { toast } from "react-toastify";
import { Button, Card, Dropdown, Grid, Icon } from "tabler-react";
import { ACCOUNT_BALANCE_TRANSACTION } from "../../graphql/ACCOUNT_BALANCE_TRANSACTION";
import { DELETE_GROUP } from "../../graphql/DELETE_GROUP";
import { DELETE_STUDENT_FROM_GROUP } from "../../graphql/DELETE_STUDENT_FROM_GROUP";
import { EDIT_GROUP } from "../../graphql/EDIT_GROUP";
import { GET_ACCOUNT_TYPE_BY_TEAM_ID } from "../../graphql/GET_ACCOUNT_TYPE_BY_TEAM_ID";
import { GET_GROUPS } from "../../graphql/GET_GROUPS";
import { GET_ACCOUNT_TYPE_BY_TEAM_ID as GET_ACCOUNT_TYPE_BY_TEAM_ID_TYPE } from "../../graphql/types/GET_ACCOUNT_TYPE_BY_TEAM_ID";
import { Types } from "../../types/graphql";
import GroupFormModal from "../Calendar/Modal/GroupFormModal";
import AddGroupToEventModal from "./Forms/AddGroupToEventModal";

interface DashboardGroupsItemProps {
  group: Types.Group;
  handleDrop: (e: any, groupId: string) => void;
}

const DashboardGroupsItem = ({
  group,
  handleDrop,
}: DashboardGroupsItemProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [removeStudent] = useMutation(DELETE_STUDENT_FROM_GROUP, {
    onCompleted: () => {
      toast.success("User removed from group");
    },
  });

  const [deleteGroup] = useMutation(DELETE_GROUP, {
    onCompleted: () => {
      toast.success("Group deleted");
    },
  });

  const [removeGroup] = useMutation(EDIT_GROUP, {
    onCompleted: () => {
      toast.success("Group archived!");
    },
  });

  const { currentCoachTeam, currentUser } = useRootStore();
  const isTime = currentCoachTeam.account_type_slug === "time";
  const handleDragover = (e) => {
    e.preventDefault();
  };

  const handleDeleteStudent = (studentId: number) => {
    if (
      window.confirm(
        "Are you sure you want to remove this user from the group?",
      )
    ) {
      removeStudent({
        variables: {
          groupId: group.id,
          studentId: studentId,
        },
        refetchQueries: [
          {
            query: GET_GROUPS,
            variables: {
              filter: {
                camp_id: null,
                team_id: Number(group.team_id),
                status: "Active",
              },
            },
          },
        ],
      });
    }
  };
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const moveOption = currentCoachTeam?.feature_camps
    ? [{ icon: "home", value: "MOVE", onClick: () => setIsOpen(true) }]
    : [];

  const handleDelete = () => {
    if (group.members.length > 0) {
      return;
    }
    if (window.confirm("Are you sure you want to delete this group?")) {
      deleteGroup({
        variables: {
          id: group.id,
        },
        refetchQueries: [
          {
            query: GET_GROUPS,
            variables: {
              filter: {
                camp_id: null,
                team_id: Number(group.team_id),
                status: "Active",
              },
            },
          },
        ],
      });
    }
  };

  const { data: accountType, loading: accountTypeLoading } =
    useQuery<GET_ACCOUNT_TYPE_BY_TEAM_ID_TYPE>(GET_ACCOUNT_TYPE_BY_TEAM_ID, {
      variables: {
        teamId: currentCoachTeam.id,
      },
    });

  const accountTypeId = useMemo(() => {
    if (accountType?.getAccountTypeByTeamId) {
      return accountType.getAccountTypeByTeamId.id;
    }
  }, [accountType?.getAccountTypeByTeamId, accountTypeLoading]);

  const [accountBalanceTransaction] = useMutation(ACCOUNT_BALANCE_TRANSACTION, {
    refetchQueries: ["GET_ACCOUNT_BALANCE"],
  });

  const handleTransaction = (minutes) => {
    if (
      window.confirm(
        `Are you sure you want to remove ${minutes} minutes from members of ${group.name}?`,
      )
    ) {
      group.members.forEach(async (member) => {
        await handleRemoveMinutes(minutes, member);
      });
    }
  };

  const handleRemoveMinutes = async (minutes, member) => {
    await accountBalanceTransaction({
      variables: {
        payload: {
          account_type_id: accountTypeId,
          created_by: currentUser.id,
          minutes: -minutes,
          student_id: member.id,
          team_id: currentCoachTeam.id,
          transaction_type_id: 2,
        },
      },
    });
  };

  const handleRemove = () => {
    if (window.confirm("Are you sure you want to archive this group?")) {
      removeGroup({
        variables: {
          updateGroupInput: {
            id: group.id,
            status: "Archived",
          },
        },
        refetchQueries: [
          {
            query: GET_GROUPS,
            variables: {
              filter: {
                camp_id: null,
                team_id: Number(group.team_id),
                status: "Active",
              },
            },
          },
        ],
      });
    }
  };

  const timeOptions = isTime
    ? [
        {
          icon: "clock",
          value: "-0:10",
          onClick: () => handleTransaction(10),
        },
        {
          icon: "clock",
          value: "-0:15",
          onClick: () => handleTransaction(15),
        },
      ]
    : [];

  const onDragStart = (e, studentId, groupId) => {
    e.dataTransfer.setData("studentId", studentId);
    e.dataTransfer.setData("previousGroupId", groupId);
    e.dataTransfer.setData("action", "transfer");
  };

  return (
    <Grid.Col xs={12} sm={12} md={5} lg={3}>
      <div onDrop={(e) => handleDrop(e, group.id)} onDragOver={handleDragover}>
        <Card className="group-card">
          <Card.Header
            backgroundURL={group.image && `/api/s3/uploads/${group.image}`}
            className={
              !group.image && group.color
                ? "px-3 py-1 text-white bg-" + group.color
                : "image-header"
            }
            style={{ maxHeight: "100%", maxWidth: "10px" }}
          >
            <Card.Title
              className={
                group.color
                  ? "px-3 py-1 text-white bg-" + group.color
                  : "px-3 py-1 text-white opacity bg-gray-dark"
              }
            >
              {group.name}
            </Card.Title>
            <Card.Options>
              <Dropdown
                className={
                  "float-right cursor-pointer" + (group.color && " text-white")
                }
                toggle={false}
                arrow
                triggerContent={
                  <Icon name="more-vertical" className={"cursor-pointer"} />
                }
                itemsObject={[
                  {
                    icon: "edit",
                    value: "EDIT",
                    onClick: () => setIsEditModalOpen(true),
                  },
                  ...moveOption,
                  {
                    icon: "minus-circle",
                    value: "ARCHIVE",
                    onClick: handleRemove,
                  },
                  {
                    icon: "x-circle",
                    value: "DELETE",
                    onClick: handleDelete,
                  },
                  ...timeOptions,
                ]}
              />
            </Card.Options>
          </Card.Header>
          {group?.members.length > 0 && (
            <Card.Body>
              <div>
                {group?.members.map((member) => (
                  <div
                    draggable
                    onDragStart={(e) => onDragStart(e, member.id, group.id)}
                  >
                    <Button
                      outline
                      block
                      color="primary"
                      size="sm"
                      className="text-left"
                      key={`${member.id}-${group.id}`}
                    >
                      {member.first_name} {member.last_name}
                      <Icon
                        name="x-circle"
                        className={"float-right cursor-pointer"}
                        onClick={() => handleDeleteStudent(Number(member.id))}
                      />
                      <span
                        className={
                          "float-right cursor-pointer mr-1 text-warning"
                        }
                      ></span>
                    </Button>
                  </div>
                ))}
              </div>
            </Card.Body>
          )}
        </Card>
      </div>
      <AddGroupToEventModal
        groupId={group.id}
        toggle={toggleModal}
        isOpen={isOpen}
      />
      <GroupFormModal
        isOpen={isEditModalOpen}
        toggle={setIsEditModalOpen}
        group={group}
      />
    </Grid.Col>
  );
};

export default DashboardGroupsItem;
