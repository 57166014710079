import { useQuery } from '@apollo/client'
import { GET_CAMP_TOTALS } from 'graphql/GET_CAMP_TOTALS'
import { GET_CAMP_TOTALS as GET_CAMP_TOTALS_TYPE } from 'graphql/types/GET_CAMP_TOTALS'
import { Loading } from 'components/Loading';
import React from 'react'
import { useParams } from 'react-router-dom'
import { Grid, StampCard, Text } from 'tabler-react'
import formatNumber from 'utils/numberFormat'

const EventRegistrationTotalConfirmed = () => {
  const { campId } = useParams<{ campId: string }>()
  const { loading, error, data } =
    useQuery<GET_CAMP_TOTALS_TYPE>(GET_CAMP_TOTALS, {
      variables: {
        campId: Number(campId)
      }
    })

  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    console.log(error)
    return <p>Error: {error.message}</p>
  }

  console.log(data)
  const payments = data.payments ?? []
  const pending = data.pending ?? {}
  return <EventRegistrationTotalConfirmedInner pending={pending} payments={payments} />
}


const EventRegistrationTotalConfirmedInner = ({payments, pending}) => {
  return (
    <Grid.Col sm={6} lg={3} xs={12} className="px-0 mb-2">
      <StampCard
        className="mb-0"
        color="primary"
        icon="users"
        header={
          <Text>
            {formatNumber(payments.confirmedRegistrationsCount)}{' '}
            <small>Confirmed</small>
          </Text>
        }
        footer={
          <Text>
            {formatNumber(payments.pendingRegistrationsCount)} Pending
          </Text>
        }
      />
    </Grid.Col>
  )
}

export default EventRegistrationTotalConfirmed
