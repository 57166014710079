import React from "react";
import Modal from "../../Modal";
import StudentTeamProgressions from "../StudentTeamProgressions";

interface IStudentTeamProgressionModalProps {
  studentId: number;
  teamId: number;
  isOpen: boolean;
  toggle: () => void;
  userRegistrationDetails: any;
}

const StudentTeamProgressionModal = ({
  studentId,
  teamId,
  isOpen,
  toggle,
  userRegistrationDetails,
}: IStudentTeamProgressionModalProps) => {
  return (
    <Modal
      content={
        <StudentTeamProgressions
          studentId={studentId}
          teamId={teamId}
          isModal={true}
          userRegistrationDetails={userRegistrationDetails}
        />
      }
      open={isOpen}
      onClose={toggle}
    />
  );
};

export default StudentTeamProgressionModal;
