import InvoiceModalForm from "components/Invoices/Modals/InvoiceModalForm";
import JobItem from "components/Jobs/JobItem";
import StudentLink from "components/Students/StudentLink";
import { observer } from "mobx-react";
import useGetCurrentUserType from "modules/common/hooks/useGetCurrentUserType";
import GroupedJobCard from "modules/job/components/cards/GroupedJobCard";
import React, { useState } from "react";
import { Button, Card, Grid } from "tabler-react";
import { formatMoney } from "utils/numberFormat";
import { Types } from "../../types/graphql";

interface JobListItemProps {
  jobList: Types.Job[];
  setJob: any;
  toggleModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: () => void;
}

const JobListItem = ({ jobList, setJob, toggleModal, onSubmit }: JobListItemProps) => {
  const { isAdmin } = useGetCurrentUserType();

  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const student = jobList[0]?.student;

  const total = jobList.reduce((sum, item) => {
    return sum + item.unit_price * item.quantity;
  }, 0);

  /**
   * Groups jobs according to the rig they are associated with then according to serial number
   *
   * Steps:
   * 1. If job has rig id, group jobs by rig id
   * 2. If job has no rig id, group jobs by serial number
   * 3. If job has no serial number and no rig id, group jobs by undefined
   * 4. Return grouped jobs as an object of 3 arrays groupedJobs = { "byRigs": Types.Job[], "bySerials": Types.Job[], "undefined": Types.Job[] }
   */
  const groupedJobs = jobList.reduce((acc, job) => {
    if (job?.user_gear?.rig?.id) {
      if (!acc["byRigId"]) {
        acc["byRigId"] = {};
      }
      if (!acc["byRigId"][job?.user_gear?.rig?.id]) {
        acc["byRigId"][job?.user_gear?.rig?.id] = [];
      }

      acc["byRigId"][job?.user_gear?.rig?.id].push(job);
    } else if (job?.user_gear?.gear_serial) {
      if (!acc["byGearSerial"]) {
        acc["byGearSerial"] = {};
      }
      if (!acc["byGearSerial"][job?.user_gear?.gear_serial]) {
        acc["byGearSerial"][job?.user_gear?.gear_serial] = [];
      }

      acc["byGearSerial"][job?.user_gear?.gear_serial].push(job);
    } else {
      if (!acc["none"]) {
        acc["none"] = {};
      }
      if (!acc["none"]["undefined"]) {
        acc["none"]["undefined"] = [];
      }

      acc["none"]["undefined"].push(job);
    }

    return acc;
  }, {}) as { [key: string]: { [key: string]: Types.Job[] } };

  const completedJobIds = jobList
    .filter((job) => job.status === "Complete")
    .map((job) => job.id);

  return (
    <>
      <InvoiceModalForm
        completedJobIds={completedJobIds}
        jobs={jobList}
        invoice={invoice}
        toggleModal={setIsInvoiceModalOpen}
        isModalOpen={isInvoiceModalOpen}
        onSubmit={onSubmit}
      />
      <Card>
        <Card.Body>
          <Grid.Row>
            <Grid.Col width={12}>
              <h4 className="float-right text-success">{formatMoney(total)}</h4>
              <h4>
                <StudentLink
                  studentId={Number(student.id)}
                  studentName={`${student.first_name} ${student.last_name}`}
                />
              </h4>

              {Object.keys(groupedJobs).map((key) => {
                // If key is none, render individual jobs
                if (key === "none" && groupedJobs[key]["undefined"]) {
                  return groupedJobs[key]["undefined"].map((job) => (
                    <JobItem
                      job={job}
                      key={job.id}
                      toggleModal={toggleModal}
                      setJob={setJob}
                    />
                  ));
                }
                return Object.keys(groupedJobs[key]).map((groupKey) => {
                  return (
                    <GroupedJobCard
                      type={key === "byRigId" ? "rig" : "gear"}
                      onSubmit={onSubmit}
                      key={groupKey}
                      jobs={groupedJobs[key][groupKey]}
                      setJob={setJob}
                      toggleModal={toggleModal}
                    />
                  );
                });
              })}

              <Button.List className="d-block">
                {!isAdmin && (
                  <>
                    <Button
                      className="mr-3 mt-3"
                      color="secondary"
                      icon="plus"
                      size="sm"
                      onClick={() => {
                        setJob({ student_id: student?.id, student: student });
                        toggleModal(true);
                      }}
                    >
                      JOB
                    </Button>

                    <Button
                      className="mr-3 mt-3"
                      color="secondary"
                      icon="file"
                      size="sm"
                      //  temporarily disable
                      //  disabled={!enableInvoice && 'disabled'}
                      onClick={() => {
                        setInvoice({
                          student_id: student?.id,
                          student: student,
                          invoice_amount: jobList.reduce((sum, item) => {
                            if (item.status === "Complete")
                              return sum + item.unit_price * item.quantity;

                            return sum;
                          }, 0),
                          job_ids: jobList
                            .filter((job) => job.status === "Complete")
                            .map((job) => job.id),
                        });
                        setIsInvoiceModalOpen(true);
                      }}
                    >
                      INVOICE ALL
                    </Button>
                  </>
                )}
              </Button.List>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default observer(JobListItem);
