import { useQuery } from '@apollo/client'
import { Loading } from 'components/Loading';
import { GET_STUDENT_CAMPS } from '../../graphql/GET_STUDENT_CAMPS'
import React from 'react'
import { Alert, Button, Card, Icon, Text } from 'tabler-react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import useReactRouter from 'use-react-router'
import { STATUS_ICON_COLOR_MAPPING } from 'modules/user/registrations/constants/registrationStatusMapping'

interface IStudentRecentCamps {
  studentId: number
  camps: any
}

const StudentRecentCamps = ({ studentId }: { studentId: number }) => {
  const { data, error, loading } = useQuery(GET_STUDENT_CAMPS, {
    variables: { getStudentCampsInput: { studentId: Number(studentId) } }
  });

  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    console.log(error)
    return <p>Error: {error.message}</p>
  }

  const camps = data.getStudentCamps.filter(
          (camp) =>
            ((camp.status_type &&
              camp.status_type.toLowerCase() === 'confirmed') ||
              (!camp.status_type && camp.status === 'Confirmed')) &&
            moment(camp.start) < moment()
        )
        .slice(0, 5) || []

  return <StudentRecentCampsInner studentId={studentId} camps={camps} />
}

const StudentRecentCampsInner = ({ studentId, camps }: IStudentRecentCamps) => {
  const { history } = useReactRouter();

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <i className="fe fe-calendar text-primary mr-2" />
          Other Events
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {camps.length <= 0 && (
          <Alert type="info text-center">
            <strong>No Events</strong>
          </Alert>
        )}
        {camps.length > 0 &&
          camps.map((camp) => {
            return (
              <div key={camp.camp_id}>
                <span className="ml-3 text-muted float-right">
                  {camp.user_registrations_id && (
                    <Button
                      size="sm"
                      color="white"
                      className="text-success"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`/events/registrations/${camp.user_registrations_id}/${studentId}`);
                      }}
                    //  disabled={camp.team_id}
                    >
                      <Icon
                        name={
                          STATUS_ICON_COLOR_MAPPING.get(camp?.status)?.icon ?? 'x-circle'
                        }
                        className={'ml-0 ' +
                          STATUS_ICON_COLOR_MAPPING.get(camp?.status)?.className ?? 'text-danger'
                        }
                      />
                    </Button>
                  )}
                </span>
                <p>
                  <span className="">
                    <Link to={'/user/calendar/' + camp.camp_id}>
                      {camp.camp_name}
                    </Link>
                  </span>
                  <Text.Small className="d-block text-muted">
                    {moment(camp.start).format('MMM D, YYYY')}
                  </Text.Small>
                </p>
              </div>
            );
          })}
      </Card.Body>
    </Card>
  );
};

export default StudentRecentCamps;
