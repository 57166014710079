import React from 'react';
import { Container, Grid, Icon, List, Text } from 'tabler-react';
import { IconBrandX } from '@tabler/icons-react';

const FooterBottom = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <>
    <footer className="footer">
      <Container>
        <Grid.Row className="align-items-center">
          <Grid.Col>
            © {year}{' '}
            <a
              href="https://www.skycru.com"
              target="_blank"
              rel="noopener noreferrer"
            >SKYCRU |{' '}</a>

            <a
              href="/help"
            >
              Support
            </a>
            <a
              href="https://www.facebook.com/skycru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="facebook" className="btn btn-md" />
            </a>
            <a
              href="https://x.com/skycru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fe btn btn-md"><IconBrandX size={14} /></i>
            </a>
{/*  HIDE TEMPORARILY
            <a
              href="https://www.instagram.com/fly.skycru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="instagram" className="btn btn-md" />
            </a
*/}
            <a
              href="https://www.linkedin.com/company/skycru"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="linkedin" className="btn btn-md" />
            </a>
          </Grid.Col>
          <Grid.Col auto={true} className="ml-auto">
            <Grid.Row className="align-items-center">
              <Grid.Col className="col-auto">
                <List inline className="list-inline-dots mb-0">
                  <List.Item inline>
                    <Text.Small className="text-muted">v1.16.103</Text.Small>
                  </List.Item>
                </List>
              </Grid.Col>
{/*
              <Grid.Col>
                <a
                  className="btn btn-sm btn-outline-primary"
                  href="https://apps.apple.com/us/app/skycru/id1292785676"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon prefix="fa" name="apple" aria-hidden="true" /> iOS
                </a>
              </Grid.Col>
              <Grid.Col className="col-auto">
                <a
                  className="btn btn-sm btn-outline-primary"
                  href="https://play.google.com/store/apps/details?id=com.skycru.app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon prefix="fa" name="android" aria-hidden="true" /> Android
                </a>
              </Grid.Col>
*/}
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      </Container>
    </footer>
</>
  );
};

export default FooterBottom;
