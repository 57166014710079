import React, { useState } from 'react'
import { Formik } from 'formik'
import { useMutation, useQuery } from '@apollo/client'
import { GET_ALL_GEAR_MODELS } from '../../../graphql/GET_ALL_GEAR_MODELS'
import GearModelForm from './GearModelForm'
import FormField from '../../FormField'
import { Button, Card, Form, Grid } from 'tabler-react'
import Select from 'react-select'
import * as Yup from 'yup'
import { ADD_OR_EDIT_USER_GEAR } from '../../../graphql/ADD_OR_EDIT_USER_GEAR'
import { toast } from 'react-toastify'
import { Types } from '../../../types/graphql'

interface AADGearFormProps {
  toggle: any
  student_id: number
  team_id: number
}

const addUserGearValidationSchema = Yup.object().shape({
  gear_model_id: Yup.number().required('This field is required.')
})

const AadGearForm = ({ toggle, student_id, team_id }: AADGearFormProps) => {
  const { data } = useQuery(GET_ALL_GEAR_MODELS)
  const [updateUserGear] = useMutation(ADD_OR_EDIT_USER_GEAR, {
    onCompleted: () => {
      toggle()
      toast.success('Gear submitted.')
    },
    refetchQueries: ['GET_STUDENT_GEARS']
  })
  const [showModelForm, setShowModelForm] = useState<boolean>(false)

  const renderForm = ({
    values,
    handleSubmit,
    isSubmitting,
    handleChange,
    setFieldValue,
    errors,
    touched
  }) => (
    <>
      <Grid.Row className="mb-4">
        {showModelForm ? (
          <Card className="p-3 m-3">
            <GearModelForm toggleForm={setShowModelForm} />
          </Card>
        ) : (
          <Grid.Col xs={12} sm={12} lg={6}>
            <Form.Group label="Add AAD">
              <Select
                name="gear_model_id"
                autoFocus={true}
                placeholder="Search AAD models..."
                isMulti={false}
                getOptionLabel={(option: Types.GearModel) =>
                  option.name
                }
                getOptionValue={(option: Types.GearModel) =>
                  option.id
                }
                options={data?.getGearModels.filter(
                  (model) => model.gear_type_id === 4
                )}
                onChange={(param) => {
                  setFieldValue('gear_model_id', param.id)
                }}
                // value={data?.getGearModels.filter((gearType) => gearType.id === values?.gear_model_id)}
              />
              <span className="field-error text-danger">
                {errors.gear_model_id &&
                  touched.gear_model_id &&
                  errors.gear_model_id}
              </span>
            </Form.Group>
          </Grid.Col>
        )}
        <Grid.Col xs={12} sm={12} lg={6}>
          <Form.Group label="Serial # (optional)">
            <FormField
              type="text"
              name="gear_serial"
              onChange={handleChange}
              value={values.gear_serial}
            />
          </Form.Group>
        </Grid.Col>
        <Grid.Col className="pt-2" xs={12} sm={12} lg={12}>
          <Button
            pill
            type="submit"
            disabled={isSubmitting}
            className="btn-gray-dark float-right btn-sm"
            onClick={(e) => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            {isSubmitting ? 'Processing...' : 'SAVE'}
          </Button>
          <Button pill color="white" className="float-right btn-sm mr-2" onClick={toggle}>
            CANCEL
          </Button>
        </Grid.Col>
      </Grid.Row>
    </>
  )
  return (
    <Formik
      enableReinitialize={true}
      validationSchema={addUserGearValidationSchema}
      initialValues={{
        name: '',
        student_id: student_id,
        team_id: team_id,
        gear_model_id: '',
        gear_serial: '',
        gear_dom_day: '',
        gear_dom_month: '',
        gear_dom_year: '',
        gear_canopy_size: 0,
        gear_notes: ''
      }}
      onSubmit={async (values, { resetForm, setSubmitting, setFieldError }) => {
        if (!values.gear_model_id) {
          setFieldError('gear_model_id', 'Select Model')
          setSubmitting(false)
          return false
        }

        await updateUserGear({
          variables: {
            updateUserGearInput: {
              name: values?.name,
              student_id: values?.student_id,
              team_id: values?.team_id,
              gear_model_id: values?.gear_model_id,
              gear_serial: values?.gear_serial,
              // gear_dom: dom,
              gear_canopy_size: values?.gear_canopy_size,
              gear_notes: values?.gear_notes,
              // legacy START - remove when DB is updated
              gear_manufacturer: '',
              gear_model: ''
              //   legacy END
            }
          }
        })
        setSubmitting(false)
        resetForm()
        toggle()
      }}
    >
      {(formikData) => renderForm(formikData)}
    </Formik>
  )
}

export default AadGearForm
