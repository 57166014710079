import gql from 'graphql-tag';

export const GET_DISCIPLINES = gql`
  query GET_DISCIPLINES {
    disciplines {
      id
      name
      slug
    }
  }
`;
