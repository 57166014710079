import { useMutation, useQuery, ApolloError } from '@apollo/client';
import { LoadStoreContext } from 'contexts/LoadStoreContext';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, Dimmer, Form, Grid } from 'tabler-react';
import * as Yup from 'yup';
import FormField from '../../../components/FormField';
import { CREATE_NEW_LOAD } from '../../../graphql/CREATE_NEW_LOAD';
import { GET_LOADS_BY_TEAM } from '../../../graphql/GET_LOADS_BY_TEAM';
import { useRootStore } from '../../../hooks/useRootStore';
import { formatDate } from '../../../utils/dateFormat';
import { loadStatusOptions } from '../../constants';
import DatePicker from '../../DatePicker';
import TimePicker from '../../TimePicker';

const addLoadValidationSchema = Yup.object().shape({
  load_name: Yup.string().required('This field is required.'),
  date: Yup.string().required('This field is required.'),
  status: Yup.string().required('This field is required.'),
  aircraft_id: Yup.string().required('This field is required.')
});

enum EReactSelectInputNames {
  aircraft_id = 'aircraft_id',
  status = 'status',
  time = 'time',
  date = 'date'
}

interface IAddLoadProps {
  toggleModal: () => void;
}

const AddLoad = ({ toggleModal }: IAddLoadProps) => {
  const { aircrafts } = useContext(LoadStoreContext);
  const { currentCoachTeam } = useRootStore();

  const [loadCount, setLoadCount] = useState<number>(0);

  const [createNewLoad] = useMutation(CREATE_NEW_LOAD, {
    onError: (error: ApolloError) =>
      toast.error(error?.graphQLErrors[0]?.message),
    onCompleted: () => toast.success('Load Added.')
  });

  const { loading, error, data } = useQuery(GET_LOADS_BY_TEAM, {
    variables: {
      team_id: currentCoachTeam.id,
      date: formatDate(new Date())
    }
  });

  useEffect(() => {
    if (!loading && data) {
      setLoadCount(data.loads.length + 1);
    }
  }, [data]);  

  if (error) {
    return <p>{error.message}</p>;
  }

  return (
    <Dimmer active={loading} loader={loading}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          load_name: `Load ${loadCount}`,
          date: new Date(),
          time: new Date(
            Math.round(new Date(new Date()).getTime() / (1000 * 60 * 5)) *
              (1000 * 60 * 5)
          ),
          status: '',
          load_notes: '',
          aircraft_id: '',
          team_id: currentCoachTeam.id,
          location_id: currentCoachTeam.default_location_id
        }}
        validationSchema={addLoadValidationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await createNewLoad({
            variables: {
              load: {
                load_name: values.load_name,
                date: moment(
                  `${moment(values.date).format(
                    moment.HTML5_FMT.DATE
                  )} ${new Date(values.time).toLocaleTimeString()}`,
                  'YYYY-MM-DD HH:mm:ss'
                ).format(),
                status: values.status,
                load_notes: values.load_notes,
                aircraft_id: values.aircraft_id,
                team_id: values.team_id,
                location_id: values.location_id
              }
            },
            refetchQueries: [
              {
                query: GET_LOADS_BY_TEAM,
                variables: {
                  team_id: currentCoachTeam.id
                }
              },
              {
                query: GET_LOADS_BY_TEAM,
                variables: {
                  team_id: currentCoachTeam.id,
                  date: formatDate(new Date())
                }
              }
            ]
          });
          setSubmitting(false);
          resetForm();
          toggleModal();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <Card.Body>
              <Grid.Row>
                <Grid.Col>
                  <FormField
                    name="load_name"
                    label=""
                    placeholder="Load Name"
                    type="text"
                    onChange={handleChange}
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <div className="form-group">
                    <div className="form-label">Date</div>
                    <DatePicker
                      name={EReactSelectInputNames.date}
                      className="form-control"
                      disablePast={true}
                      minDate={values.date}
                      value={values.date}
                      placeholder="dd / mm / yyyy"
                      onChange={(date) =>
                        setFieldValue(EReactSelectInputNames.date, date)
                      }
                    />
                  </div>
                </Grid.Col>
                <Grid.Col lg="3">
                  <div className="form-group">
                    <div className="form-label">Time</div>
                    <TimePicker
                      name={EReactSelectInputNames.time}
                      className="form-control"
                      value={values.time}
                      onChange={(time) =>
                        setFieldValue(EReactSelectInputNames.time, time)
                      }
                      minutesStep={5}
                    />
                  </div>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group label="Aircraft">
                    <Button.List align="left">
                      {aircrafts.map((aircraftsOption, idx) => (
                        <Button
                          color={
                            values.aircraft_id === aircraftsOption.id
                              ? 'primary'
                              : 'secondary'
                          }
                          key={idx}
                          onClick={(e) => {
                            e.preventDefault();
                            setFieldValue(
                              EReactSelectInputNames.aircraft_id,
                              aircraftsOption.id
                            );
                          }}
                        >
                          {aircraftsOption.name}
                        </Button>
                      ))}
                    </Button.List>
                    <span className="field-error text-danger">
                      {errors.aircraft_id &&
                        touched.aircraft_id &&
                        errors.aircraft_id}
                    </span>
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group label="Status">
                    <Button.List align="left">
                      {loadStatusOptions.map((loadStatusOption, idx) => (
                        <Button
                          key={idx}
                          color={
                            values.status === loadStatusOption.value
                              ? 'primary'
                              : 'secondary'
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setFieldValue(
                              EReactSelectInputNames.status,
                              loadStatusOption.value
                            );
                          }}
                        >
                          {loadStatusOption.label}
                        </Button>
                      ))}
                    </Button.List>
                    <span className="field-error text-danger">
                      {errors.status && touched.status && errors.status}
                    </span>
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group label="">
                    <Form.Textarea
                      name="load_notes"
                      onChange={handleChange}
                      placeholder="Add notes (optional)"
                      rows={3}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
                <Button.List align="right">
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    type="submit"
                  >
                    ADD{isSubmitting ? 'ING' : ''} LOAD
                  </Button>
                </Button.List>
            </Card.Body>
          </form>
        )}
      </Formik>
    </Dimmer>
  );
};

export default observer(AddLoad);
