import { clone } from 'mobx-state-tree';
import React, { useState } from 'react';
import { Button, Card, Icon, Text } from 'tabler-react';

import CalendarLink from '../Calendar/CalendarLink';
import moment from 'moment';
import UpdateEventCard from './Forms/UpdateEventCard';
import useGetCurrentUserType from '../../modules/common/hooks/useGetCurrentUserType';
import { Types } from '../../types/graphql'

interface IEventListItemCardProps {
  event: Types.Camp;
}

const EventListItemCard = ({ event }) => {
  const { isAdmin } = useGetCurrentUserType();
  const [isEditing, setIsEditing] = useState(false);
  const [eventClone, setEventClone] = useState(event);

  const onToggleEdit = () => {
    setIsEditing(true);
    setEventClone(clone(event));
  };

  return isEditing ? (
    <UpdateEventCard
      event={event}
      eventClone={eventClone}
      setIsEditing={setIsEditing}
    />
  ) : (
    <>
      <Card key={event.id} className="card-profile">
        <Card.Header
          backgroundURL={
            (event?.camp_image && `/api/s3/uploads/${event.camp_image}`) ||
            '//via.placeholder.com/374x144.png?text=+'
          }
        ></Card.Header>
        <Card.Body>
          {isAdmin && (
            <Text RootComponent="span" muted>
              {event.id}
            </Text>
          )}
          <p>
            <span className="float-right">
              {event.is_published ? (
                <span className="mr-1">
                  <Icon name="check-circle" className="text-success"/>
                </span>
              ) : (
                <span className="mr-1">
                  <Icon name="lock" className="text-muted" />
                </span>
              )}
              {event.is_public ? (
                <span className="mr-1">
                  <Icon name="calendar" className="text-primary" />
                </span>
              ) : (
                <span className="mr-1">
                  <Icon name="users" className="text-muted" />
                </span>
              )}
            </span>
            <span className="">
              <CalendarLink
                eventName={event.camp_name}
                eventId={Number(event.id)}
              />
            </span>
            <Text.Small className="d-block text-muted">
              {moment(event.start).format('MMM D, YYYY')}
              {' - '}
              {event.team_name}
            </Text.Small>
          </p>
          <p>
            <Button icon="map-pin" size="sm" color="secondary" className="text-muted" disabled>{event.location}</Button>
          </p>
          {isAdmin && (
            <Icon
              link
              name="edit"
              onClick={onToggleEdit}
              className={'d-none'}
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default EventListItemCard;
