import React, { useMemo } from 'react';
import { Button, Card } from 'tabler-react';
import RegistrationGroupFormModal from './Modals/RegistrationGroupFormModal';
import { useQuery } from '@apollo/client';
import { GET_GROUPS } from '../../graphql/GET_GROUPS';
import RegistrationGroupItem from './RegistrationGroupItem';

interface RegistrationGroupsProps {
  registrationId?: number;
  disciplineId?: number;
  teamId?: number;
}

const RegistrationGroups = ({
  registrationId,
  disciplineId,
  teamId
}: RegistrationGroupsProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [registrationGroup, setRegistrationGroup] = React.useState(null);
  const { data, loading } = useQuery(GET_GROUPS, {
    variables: {
      filter: {
        user_registration_id: registrationId,
        status: 'Active'
      }
    }
  });
  const groups = useMemo(() => {
    if (!loading && data) {
      setRegistrationGroup(data.groupList[0]);
      return data.groupList;
    }

    return [];
  }, [data, loading]);

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h5">Team</Card.Title>
        <Card.Options>
          {groups.length === 0 && (
            <Button
              pill
              color={'white'}
              icon={'plus'}
              onClick={() => setIsOpen(true)}
            >
              Add Team
            </Button>
          )}
          {groups.length > 0 && (
            <Button
              color={'white'}
              icon={'more-vertical'}
              onClick={() => setIsOpen(true)}
            />
          )}
        </Card.Options>
      </Card.Header>
      <Card.Body>
        {groups.map((group) => (
          <RegistrationGroupItem group={group} key={group.id} />
        ))}
      </Card.Body>
      <RegistrationGroupFormModal
        group={
          registrationGroup != null
            ? registrationGroup
            : {
                team_id: teamId,
                user_registration_id: registrationId,
                discipline_id: disciplineId
              }
        }
        toggle={() => setIsOpen(false)}
        isOpen={isOpen}
      />
    </Card>
  );
};

export default RegistrationGroups;
