import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, List, Text } from "tabler-react";
import { ADD_STUDENT_TEAM_PROGRESSION } from "../../graphql/ADD_STUDENT_TEAM_PROGRESSION";
import { EDIT_STUDENT_TEAM_PROGRESSION } from "../../graphql/EDIT_STUDENT_TEAM_PROGRESSION";
import { GET_STUDENT_TEAM_PROGRESSION } from "../../graphql/GET_STUDENT_TEAM_PROGRESSION";
import StudentProgressionNotesForm from "./Forms/StudentProgressionNotesForm";

const StudentTeamProgressionItem = ({ progress, studentId }) => {
  const [isEdittingNote, setIsEdittingNote] = useState(false);
  const [rating, setRating] = useState(progress.rating ?? 0);
  const [isEdittingRating, setIsEdittingRating] = useState(false);

  const [addStudentTeamProgression] = useMutation(ADD_STUDENT_TEAM_PROGRESSION);
  const [editStudentTeamProgression] = useMutation(
    EDIT_STUDENT_TEAM_PROGRESSION,
  );

  const handleToggleCheck = async (item) => {
    if (!item.is_exist) {
      await addStudentTeamProgression({
        variables: {
          addStudentProgressionInput: {
            student_id: Number(studentId),
            team_id: progress.team_id,
            progression_id: item.id,
            status: 1,
          },
        },
        refetchQueries: [
          {
            query: GET_STUDENT_TEAM_PROGRESSION,
            variables: {
              teamId: progress.team_id,
              studentId: Number(studentId),
            },
          },
        ],
      });
    } else {
      await editStudentTeamProgression({
        variables: {
          editStudentProgressionInput: {
            id: item.studentProgressionId,
            status: item.studentProgressionStatus === 1 ? 0 : 1,
          },
        },
        refetchQueries: [
          {
            query: GET_STUDENT_TEAM_PROGRESSION,
            variables: {
              teamId: progress.team_id,
              studentId: Number(studentId),
            },
          },
        ],
      });
    }
  };

  const updateRating = async () => {
    await editStudentTeamProgression({
      variables: {
        editStudentProgressionInput: {
          id: progress.studentProgressionId,
          rating: rating,
        },
      },
      refetchQueries: [
        {
          query: GET_STUDENT_TEAM_PROGRESSION,
          variables: {
            teamId: progress.team_id,
            studentId: Number(studentId),
          },
        },
      ],
    });
    setIsEdittingRating(false);
  };
  return (
    <>
      <List.GroupItem action>
        <div>
          {progress.is_exist && (
            <>
              <Button
                className={`cursor-pointer float-right ${
                  progress.studentProgressionStatus === 1
                    ? "text-success"
                    : "text-muted"
                }`}
                color={"white"}
                icon={"check-circle"}
                size={"sm"}
                onClick={() => handleToggleCheck(progress)}
              />
              {isEdittingRating ? (
                <input
                  autoFocus
                  className={"float-right mr-2"}
                  min={1}
                  max={5}
                  type="number"
                  value={rating}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (value > 5) setRating(5);
                    if (value < 0) setRating(1);
                    if (value >= 0 && value <= 5) setRating(value);
                  }}
                  onBlur={async () => {
                    updateRating();
                  }}
                  onKeyUp={(event) => {
                    switch (event.keyCode) {
                      case 13:
                        updateRating();
                        break;
                      case 27:
                        setIsEdittingRating(false);
                        break;
                    }
                  }}
                />
              ) : (
                <Button
                  icon={progress.rating > 0 ? "" : "star"}
                  color="white"
                  size={"sm"}
                  className={"text-center float-right mr-2"}
                  onClick={() => setIsEdittingRating(true)}
                >
                  {progress.rating > 0 && (
                    <span className="text-success px-1">{progress.rating}</span>
                  )}
                </Button>
              )}
            </>
          )}
          <span
            className={"cursor-pointer"}
            onClick={() => {
              if (progress.studentProgressionId) {
                setIsEdittingNote(true);
              }
            }}
          >
            {progress.title}
          </span>
          <Text.Small className="d-block text-muted">
            {isEdittingNote ? (
              <StudentProgressionNotesForm
                studentTeamProgression={progress}
                setIsEdittingNote={setIsEdittingNote}
                studentId={studentId}
              />
            ) : (
              <>
                {progress.studentProgressionNotes && (
                  <>
                    <i className="fe fe-file mr-1" />
                    <span>{progress.studentProgressionNotes}</span>
                  </>
                )}
              </>
            )}
          </Text.Small>
        </div>
      </List.GroupItem>
    </>
  );
};

export default StudentTeamProgressionItem;
