import gql from 'graphql-tag';

export const GET_TEAMS_BY_COACH_ID = gql`
  query GET_TEAMS_BY_COACH_ID($coachId: Int!, $fetch: String) {
    getTeamsByCoachId(coachId: $coachId, fetch: $fetch) {
      id
      account_type_id
      account_type_slug
      coach_business_type
      default_location_id
      feature_accounting
      feature_alpha
      feature_app_reports
      feature_booking_calendar
      feature_camps
      feature_cancel_transactions
      feature_coach_account
      feature_crm
      feature_dashboard
      feature_dashboard_groups
      feature_disable_students
      feature_dzm_account
      feature_edit_transactions
      feature_invoices
      feature_locked_account_actions
      feature_manifest
      feature_open_add_students
      feature_paid_status
      feature_payments
      feature_products
      feature_recurring_transactions
      feature_registrations
      feature_require_uspa
      feature_rigger_account
      feature_schedule_open
      feature_slot_registrations
      feature_student_progression
      feature_transfer_time
      feature_waivers
      is_active
      name
      preference_units
      slug
      stripe_account_id
      student_contact_required
      team_email
      team_favicon
      team_logo_header
    }
  }
`;
