import React from 'react'
import { Card, Form, Grid } from 'tabler-react'
import { useMutation, useQuery } from '@apollo/client'
import { GET_REGISTRATION_REQUIREMENTS } from '../../../graphql/GET_REGISTRATION_REQUIREMENTS'
import { DELETE_TEAM_REGISTRATION_REQUIREMENT } from '../../../graphql/DELETE_TEAM_REGISTRATION_REQUIREMENT'
import { CREATE_TEAM_REGISTRATION_REQUIREMENT } from '../../../graphql/CREATE_TEAM_REGISTRATION_REQUIREMENT'
import { EXCLUDED_REQUIREMENT } from '../../constants'

const TeamRegistrationRequirement = (props) => {
  const { data, loading } = useQuery(GET_REGISTRATION_REQUIREMENTS, {
    variables: {
      filter: {
        team_id: Number(props?.team_id)
      }
    },
    fetchPolicy: 'network-only'
  })
  const [addTeamRegistrationRequirement] = useMutation(
    CREATE_TEAM_REGISTRATION_REQUIREMENT
  )
  const [removeTeamRegistrationRequirement] = useMutation(
    DELETE_TEAM_REGISTRATION_REQUIREMENT
  )

  const optionToggle = (requirement, isChecked) => {
    const refetch = [
      {
        query: GET_REGISTRATION_REQUIREMENTS,
        variables: {
          filter: {
            team_id: Number(props?.team_id)
          }
        }
      }
    ]
    if (isChecked) {
      addTeamRegistrationRequirement({
        variables: {
          option: {
            requirement_type_id: requirement.id,
            team_id: Number(props?.team_id),
            name: requirement.requirement_type_name
          }
        },
        refetchQueries: refetch
      })
    } else {
      removeTeamRegistrationRequirement({
        variables: {
          id: Number(requirement.registration_requirement_id)
        },
        refetchQueries: refetch
      })
    }
  }
  return (
    <Card>
      <Card.Header>
        <Card.Title>Registration Requirements</Card.Title>
      </Card.Header>
      <Card.Body>
        {!loading &&
          data?.registrationRequirements.map((requirement) => {
            if (
              EXCLUDED_REQUIREMENT.includes(requirement.requirement_type_slug)
            ) {
              return
            }

            return (
              <Grid.Row key={requirement.id}>
                <Grid.Col>
                  <Form.Checkbox
                    name="status"
                    label={
                      <>
                        <p className="mb-0">
                          {requirement.requirement_type_name}
                        </p>
                      </>
                    }
                    checked={requirement.is_exist}
                    onChange={(e) => {
                      optionToggle(requirement, e.target.checked)
                    }}
                  />
                </Grid.Col>
              </Grid.Row>
            )
          })}
      </Card.Body>
    </Card>
  )
}

export default TeamRegistrationRequirement;
