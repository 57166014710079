import React, { useState } from 'react';
import { formatAccountBalance } from '../../utils/formatAccountBalance';
import { Icon, Table, Text, Tooltip } from 'tabler-react';
import { useRootStore } from '../../hooks';
import { useQuery } from '@apollo/client';
import { GET_ACCOUNT_BALANCE as GET_ACCOUNT_BALANCE_TYPE } from '../../graphql/types/GET_ACCOUNT_BALANCE';
import { GET_ACCOUNT_BALANCE } from '../../graphql/GET_ACCOUNT_BALANCE';
import EditTransactionForm from './Modal/EditTransactionForm';
import ViewTransactionDetails from './Modal/ViewTransactionDetails';

const TransactionIcons = {
  cancel: 'x',
  used: 'minus',
  expiry: 'minus',
  purchased: 'plus',
  credit: 'plus',
  purchase: 'credit-card',
  transfer: 'arrow-right'
};

const AccountTransactionsPageTransactionItem = ({
  transaction,
  studentId,
  teamId
}) => {
  const { currentCoachTeam } = useRootStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const { data: accountBalance } = useQuery<GET_ACCOUNT_BALANCE_TYPE>(
    GET_ACCOUNT_BALANCE,
    {
      variables: {
        studentId,
        teamId
      }
    }
  );
  return (
    <Table.Row key={transaction.id}>
      <EditTransactionForm
        transaction={transaction}
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
      />
      <ViewTransactionDetails
        transaction={transaction}
        isModalOpen={isViewModalOpen}
        toggleModal={setIsViewModalOpen}
        teamId={teamId}
        studentId={studentId}
      />
      <Table.Col>
        <Icon
          className={`float-left mr-3 mt-3 ${
            transaction.transactionSlug === 'used'
              ? 'text-secondary'
              : transaction.transactionSlug === 'purchased'
              ? 'text-success'
              : transaction.transactionSlug === 'purchase'
              ? 'text-success'
              : 'text-muted'
          }`}
          name={TransactionIcons[transaction.transactionSlug]}
        />
        <Text
          className="d-flex flex-column"
          muted={transaction.transactionSlug === 'cancel'}
        >
          <span>
            {new Intl.DateTimeFormat('en-US', {
              month: 'short',
              day: 'numeric'
            }).format(new Date(transaction.createdOn))}
          </span>
          <div className="d-flex flex-row align-items-center gap-1">
            <Text.Small>
              {new Intl.DateTimeFormat('en-US', {
                hour: 'numeric',
                minute: 'numeric'
              }).format(new Date(transaction.createdOn))}{' '}
              | {transaction.coachFirstName}{' '}
              {transaction?.transactionName
                ? `- ${
                    transaction.transactionSlug === 'cancel'
                      ? `voided by ${transaction.coachFirstName} ${
                          transaction.coachLastName
                        } ${
                          transaction?.cancelledOn
                            ? `on ${new Intl.DateTimeFormat('en-US', {
                                month: '2-digit',
                                day: '2-digit',
                                year: '2-digit',
                                hour: 'numeric',
                                minute: 'numeric'
                              }).format(new Date(transaction.cancelledOn))}`
                            : ''
                        }`
                      : transaction.transactionName
                  }`
                : ''}
            </Text.Small>
            {transaction?.notes ? (
              <Tooltip content={transaction.notes} placement="top">
                <span>
                  <Icon name="file" thin={true} />
                </span>
              </Tooltip>
            ) : (
              ''
            )}
          </div>
        </Text>
      </Table.Col>
      <Table.Col></Table.Col>
      <Table.Col className="text-right cursor-pointer">
        <Text
          color={transaction.timeAdded ? 'success' : 'secondary'}
          muted={transaction.transactionSlug === 'cancel'}
          onClick={() => setIsModalOpen(true)}
        >
          {transaction.timeAdded ? '+' : '-'}
          {formatAccountBalance(
            transaction.minutes,
            currentCoachTeam?.account_type_slug ??
              accountBalance?.getAccountBalance?.account_type_slug
          )}
        </Text>
      </Table.Col>
    </Table.Row>
  );
};

export default AccountTransactionsPageTransactionItem;
