import React from 'react';
import ReactQuill from 'react-quill';

const modules = {
  toolbar: [
  // hidden, save for later use
  //  ['strike']
  //
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline'],
    ['link'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ],
    [
      {
        color: [
          '#000000',
          '#e60000',
          '#ff9900',
          '#ffff00',
          '#008a00',
          '#0066cc',
          '#9933ff',
          '#ffffff',
          '#facccc',
          '#ffebcc',
          '#ffffcc',
          '#cce8cc',
          '#cce0f5',
          '#ebd6ff',
          '#bbbbbb',
          '#f06666',
          '#ffc266',
          '#ffff66',
          '#66b966',
          '#66a3e0',
          '#c285ff',
          '#888888',
          '#a10000',
          '#b26b00',
          '#b2b200',
          '#006100',
          '#0047b2',
          '#6b24b2',
          '#444444',
          '#5c0000',
          '#663d00',
          '#666600',
          '#003700',
          '#002966',
          '#3d1466',
          'custom-color'
        ]
      }
    ]
  ]
};

const formats = [
  'header',
  'height',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'color',
  'bullet',
  'indent',
  'link',
  'size'
];

type Props = {
  placeholder?: string;
  value: string;
  onChange: (content: string) => void;
};

const TextEditor = ({
  placeholder = 'Add your content ....',
  value,
  onChange
}: Props) => {
  return (
    <ReactQuill
      theme="snow"
      modules={modules}
      formats={formats}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    ></ReactQuill>
  );
};

export default TextEditor;
