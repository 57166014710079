import { useQuery } from '@apollo/client';
import { observer } from 'mobx-react';
import { applySnapshot } from 'mobx-state-tree';
import React, { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Alert, Card, Dimmer, Grid, Table } from 'tabler-react';

import { TransactionContext } from '../../contexts/TransactionContext';
import { GET_TRANSACTIONS } from '../../graphql/GET_TRANSACTIONS';
import TransactionListItem from './TransactionListItem';

export const DEFAULT_LIMIT: number = 30;

const TransactionList = () => {
  const [page, setPage] = useState<number>(2);
  const { transactions } = useContext(TransactionContext);
  const { loading, error, data, fetchMore } = useQuery(GET_TRANSACTIONS, {
    variables: {
      limit: DEFAULT_LIMIT,
      page: 1,
      filter: {}
    }
  });

  useEffect(() => {
    if (!loading && data) {
      applySnapshot(transactions, data.getTransactions.transactions);
    }
  }, [data]);  

  if (error) {
    return <p>{`Error: ${error.message}`}</p>;
  }

  const onFetchMore = () => {
    setPage((prevNum) => prevNum + 1);
    return fetchMore({
      updateQuery: (cache, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return cache;
        }

        return {
          ...cache,
          getTransactions: {
            ...cache.getTransactions,
            transactions: [
              ...cache.getTransactions.transactions,
              ...fetchMoreResult.getTransactions.transactions
            ]
          }
        };
      },
      variables: {
        limit: DEFAULT_LIMIT,
        page: page
      }
    });
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Transactions</Card.Title>
      </Card.Header>
      <Card.Body>
        <Grid.Row>
          <Grid.Col>
            <Dimmer active={loading} loader={loading}>
              <Grid.Col width={12}>
                <InfiniteScroll
                  className="overflow-visible pb-3"
                  dataLength={transactions.length}
                  hasMore={
                    transactions.length <
                    (data?.getTransactions.totalCount || 0)
                  }
                  loader={<Dimmer active={true} loader={true} />}
                  next={onFetchMore}
                  scrollThreshold="210px"
                  endMessage={
                    <Alert className="text-center" type="primary">
                      No {transactions.length !== 0 && 'more'} transaction to
                      show.
                    </Alert>
                  }
                >
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.ColHeader>ID</Table.ColHeader>
                        <Table.ColHeader>USER</Table.ColHeader>
                        <Table.ColHeader>TYPE</Table.ColHeader>
                        <Table.ColHeader>TIMESTAMP</Table.ColHeader>
                        <Table.ColHeader>TEAM</Table.ColHeader>
                        <Table.ColHeader>COACH</Table.ColHeader>
                        <Table.ColHeader>AMOUNT</Table.ColHeader>
                        <Table.ColHeader>NOTES</Table.ColHeader>
                        <Table.ColHeader></Table.ColHeader>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {transactions.map((transaction) => (
                        <Table.Row key={transaction.id}>
                          <TransactionListItem transaction={transaction} />
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </InfiniteScroll>
              </Grid.Col>
            </Dimmer>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </Card>
  );
};

export default observer(TransactionList);
