import Manifest from 'components/manifest/manifest';
import React from 'react';

const DZMHome = () => {
  return (
    <div className="container">
      <Manifest />
    </div>
  );
};

export default DZMHome;
