import { Form } from 'tabler-react';
import React, { FC } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface CurrencyInputFieldProps {
  name: string;
  onChangeSideEffect?: (value: number) => void;
  setFieldValue: (field: string, value: number) => void;
}

const CurrencyInputField: FC<CurrencyInputFieldProps & NumericFormatProps> = ({
  name,
  value,
  onChangeSideEffect,
  setFieldValue,
  ...restProps
}) => {
  return (
    <NumericFormat
      customInput={Form.Input}
      name={name}
      prefix="$"
      thousandSeparator
      onChange={(e) => {
        const value = e.target.value.replace(/[$,]+/g, '');
        setFieldValue(name, Number(value));
        onChangeSideEffect?.(Number(value));
      }}
      value={value}
      {...restProps}
    />
  );
};

export default CurrencyInputField;
