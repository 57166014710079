import React from 'react';
import Modal from '../../Modal';
import { Card, Text } from 'tabler-react';
import { formatDate } from '@fullcalendar/react';
import { Types } from '../../../types/graphql'


interface ShowGearModalProps {
  isModalOpen: boolean;
  toggleModal: any;
  userGear: Types.UserGear;
}

const ShowGearModal = ({
  isModalOpen,
  toggleModal,
  userGear
}: ShowGearModalProps) => {
  return (
    <Modal
      content={
        <Card className="mb-0 bg-light">
          <Card.Header>
            <Card.Title>{userGear?.gear_model?.name} <span className='text-muted'>{userGear?.gear_model?.gear_manufacturer_name}</span></Card.Title>
          </Card.Header>
          <Card.Body>
            {userGear?.gear_serial && (
              <Text>Serial: {userGear?.gear_serial}</Text>
            )}
            {userGear?.gear_dom && <Text>DOM: {formatDate(userGear?.gear_dom)}</Text>}
            {userGear?.gear_canopy_size > 0 && (
              <Text>Canopy Size: {userGear?.gear_canopy_size}</Text>
            )}
            {userGear?.gear_notes && <Text>Notes: {userGear?.gear_notes}</Text>}
          </Card.Body>
        </Card>
      }
      open={isModalOpen}
      //  title={userGear?.id ? "Edit Gear" : "Add Gear"}
      onClose={() => toggleModal(!isModalOpen)}
    />
  );
};

export default ShowGearModal;
