import { useLazyQuery, useMutation } from "@apollo/client";
import { EUserEvents, EUserEventsId } from "components/User/UserEvents";
import { ADD_USER_EVENT } from "graphql/ADD_USER_EVENT";
import { ADD_USER_RESERVE_LOGS } from "graphql/ADD_USER_RESERVE_LOG";
import { GET_JOB_LIST } from "graphql/GET_JOB_LIST";
import { GET_SEAL_BY_COACH } from "graphql/GET_SEAL_BY_COACH";
import { UPDATE_JOB } from "graphql/UPDATE_JOB";
import { useRootStore } from "hooks";
import useGetCurrentUserType from "modules/common/hooks/useGetCurrentUserType";
import { statusColor } from "modules/job/constants/statusColor";
import moment from "moment";
import React from "react";
import { toast } from "react-toastify";
import { Button, Dropdown, Text } from "tabler-react";
import { jobsStatusList } from "./JobList";

const JobStatusSelectForm = ({ job }) => {
  const { currentCoachTeam, currentUser } = useRootStore();
  const { isAdmin } = useGetCurrentUserType();
  const filterOption = !isAdmin ? { team_id: currentCoachTeam?.id } : {};
  const statusList = [
    {
      value: <Text className={"text-secondary"}>Open</Text>,
      onClick: () => statusHandleClick("Open"),
    },
    {
      value: <Text className={"text-orange"}>In Progress</Text>,
      onClick: () => statusHandleClick("Progress"),
    },
    {
      value: <Text className={"text-teal"}>Hold</Text>,
      onClick: () => statusHandleClick("Hold"),
    },
    {
      value: <Text className={"text-teal"}>Waiting on Parts</Text>,
      onClick: () => statusHandleClick("Parts"),
    },
    {
      value: <Text className={"text-success"}>Complete</Text>,
      onClick: async () => statusHandleClick("Complete"),
    },
    {
      value: <Text className={"text-warning"}>For Sale</Text>,
      onClick: () => statusHandleClick("Selling"),
    },
    {
      value: <Text className={"text-danger"}>Delete</Text>,
      onClick: () => statusHandleClick("Delete"),
    },
    //  { value: <Text className={'text-success'}>Closed</Text>, onClick: () => statusHandleClick('Closed') }
  ];

  const [userEvent] = useMutation(ADD_USER_EVENT);
  const [updateJob] = useMutation(UPDATE_JOB);
  const [addReserveLog] = useMutation(ADD_USER_RESERVE_LOGS);
  const [getSealByCoach, { data: coachSeal }] = useLazyQuery(
    GET_SEAL_BY_COACH,
    {
      onCompleted: async (data) => {
        const formattedDate = moment().format("YYYY-MM-DD");
        const reserveLogClone = {
          event_date: formattedDate,
          student_id: job.student_id,
          coach_id: job.rigger_id,
          team_id: job.team_id,
          location_id: currentCoachTeam.default_location_id,
          reserve_notes: job.job_notes,
          user_gear_id: job.user_gear_id,
          user_rigger_seal_id: coachSeal?.sealByCoach?.id,
          created_by: currentUser.id,
          job_id: job.id,
        };
        await addReserveLog({ variables: { reserveLog: reserveLogClone } });
      },
    },
  );

  //const filterOption = !isAdmin ? { team_id: currentCoachTeam?.id } : {};
  const statusHandleClick = async (status) => {
    const completedData = { completed_on: undefined };
    const formattedDate = moment().format("YYYY-MM-DD");

    if (status === "Complete") completedData.completed_on = formattedDate;
    const updated = await updateJob({
      variables: {
        updateJobInput: {
          id: job.id,
          status: status,
          ...completedData,
        },
      },
      refetchQueries: [
        {
          query: GET_JOB_LIST,
          variables: {
            jobListFilter: {
              status: Object.keys(jobsStatusList).map((stat) => {
                if (jobsStatusList[stat].adminOnly && !isAdmin)
                  return undefined;
                return stat;
              }), //Object.keys(jobsStatusList),
              order: "desc",
              ...filterOption,
            },
          },
        },
      ],
    });
    if (updated.data.updateJob) {
      userEvent({
        variables: {
          userEvent: {
            user_event_type_id: EUserEventsId.job_status,
            student_id: job.student_id,
            status: `${EUserEvents.job_status} from ${job.status} to ${status}`,
            team_id: job.team_id,
            job_id: job.id,
            coach_id: currentUser.id,
          },
        },
      });
    }
    if (
      updated.data.updateJob &&
      status === "Complete" &&
      job.job_type_id === 2
    ) {
      getSealByCoach({
        variables: { coach_id: parseInt(job.rigger_id) },
      });
    }

    toast.success(`Status updated to: ${status}`);
  };

  return (
    <Dropdown
      toggle={false}
      className={"ml-2"}
      triggerContent={
        <Button className={`btn-sm ${statusColor[job.status]}`}>
          {job.status}
        </Button>
      }
      itemsObject={statusList}
    />
  );
};

export default JobStatusSelectForm;
