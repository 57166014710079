import { useQuery } from "@apollo/client";
import { EUserTypes } from "components/User/userTypes";
import { GET_TEAM_BY_ID } from "graphql/GET_TEAM_BY_ID";
import { GET_USER_ROLES } from "graphql/GET_USER_ROLES";
import { GET_USER_ROLES as GET_USER_ROLES_TYPE } from "graphql/types/GET_USER_ROLES";
import { useRootStore } from "hooks";
import { Types } from "../../../types/graphql";

export const useGetUserIsAdmin = () => {
  const { currentUser } = useRootStore()
  return currentUser?.type === EUserTypes.admin
}

export const useGetUserIsCoach = () => {
  const { currentUser } = useRootStore()
  return currentUser?.type === EUserTypes.coach
}

export const useGetUserIsEventOrganizer = () => {
  const { currentUser } = useRootStore()
  return currentUser?.type === EUserTypes.events
}

export const useGetUserIsRigger = () => {
  const { currentUser } = useRootStore()
  return currentUser?.type === EUserTypes.rigger
}

export const useGetUserIsStudent = () => {
  const { currentUser } = useRootStore()
  return currentUser?.type === EUserTypes.student
}

export const useGetUserIsOrg = () => {
  const { currentUser } = useRootStore()
  return currentUser?.type === EUserTypes.org
}

export const useGetUserIsDzm = () => {
  const { currentUser } = useRootStore()
  return currentUser?.type === EUserTypes.dzm
}

export const useGetUserIsCoachSuperAdmin = () => {
  const { currentUser } = useRootStore()
  return currentUser?.id === 16
}

export const useRoles = () => {
  const { currentUser, currentCoachTeam } = useRootStore()
  const teamId = currentCoachTeam?.id

  const roles = {
    isCoachSuperAdmin: useGetUserIsCoachSuperAdmin(),
    isDzm: useGetUserIsDzm(),
    isOrg: useGetUserIsOrg(),
    isStudent: useGetUserIsStudent(),
    isRigger: useGetUserIsRigger(),
    isEventOrganizer: useGetUserIsEventOrganizer(),
    isCoach: useGetUserIsCoach(),
    isAdmin: useGetUserIsAdmin(),
    isManufacturer: currentUser?.type === EUserTypes.manufacturer,
    isWtm: currentUser?.type === EUserTypes.wtm,
    isSuperAdmin:
      currentUser?.type === EUserTypes.admin && currentUser?.id === 1
  }

  if (teamId) {
    return {
      ...roles,
      ...useTeamRoles()
    }
  } else {
    return {
      roles: [],
      loading: false,
      isCoachAdmin: false,
      isTeamAdmin: false,
      ...roles
    }
  }
}

export const useTeamRoles = () => {
  const { currentUser, currentCoachTeam } = useRootStore()
  const teamId = currentCoachTeam?.id
  const coachId = currentUser?.id
  const { data, loading, error } = useQuery<GET_USER_ROLES_TYPE>(
    GET_USER_ROLES,
    {
      variables: {
        params: {
          coachId,
          teamId
        }
      }
    }
  )

  let roles = []
  if (teamId && data) {
    roles = [...data?.getUserRolesByTeamId ?? []]
  }

  const isTeamAdmin = roles.some((role) => role.id === 1) // 1 is for Admin role

  return {
    roles,
    isTeamAdmin,
    isCoachAdmin: isTeamAdmin,
    loading,
    error
  }
}

export const useGetCurrentUserType = () => {
  return useRoles()
}

export const useCoachTeam = ({teamId}) => {
  const { currentCoachTeam } = useRootStore()

  const { loading, data, error } = useQuery(GET_TEAM_BY_ID, {
    variables: {
      teamId: teamId || currentCoachTeam?.id
    }
  });

  return { loading, error, team: data?.team as Types.Team }
}

export default useGetCurrentUserType
