import StudentSearchForm from 'components/Students/Forms/StudentSearchForm';
import { EUserTypes } from 'components/User/userTypes';
import { useRootStore } from 'hooks';
import { observer } from 'mobx-react';
import useIsFeatureAccessible from 'modules/common/hooks/useIsFeatureAccessible';
import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Nav } from 'tabler-react';

const CoachNav = () => {
  const { currentUser, currentCoachTeam } = useRootStore();

  const { isFeatureAccessible: isFeatureProductsAccessible } =
    useIsFeatureAccessible({
      currentCoachTeam,
      currentUserType: currentUser?.type as EUserTypes,
      feature: 'feature_products'
    });

  const { isFeatureAccessible: isFeatureReportsAccessible } =
    useIsFeatureAccessible({
      currentCoachTeam,
      currentUserType: currentUser?.type as EUserTypes,
      feature: 'feature_app_reports'
    });

    const { isFeatureAccessible: isFeatureCRMAccessible } =
    useIsFeatureAccessible({
      currentCoachTeam,
      currentUserType: currentUser?.type as EUserTypes,
      feature: 'feature_crm'
    });

  return (
    <>
      <Nav.Item icon="home" to="/" value="/" />
      {isFeatureProductsAccessible && (
        <Nav.Item icon="shopping-bag" to="/products" value="Products" />
      )}
      <li className="nav-item ml-lg-auto mb-4 mb-lg-0">
        <Dropdown
          toggle={false}
          icon="calendar"
          position="bottom-end"
          flex
          className="mr-4 dropdown-header-icon-lg"
          items={
            <>
              <Link to="/user/calendar" className="dropdown-item">
                Calendar
              </Link>
              <Link to="/events" className="dropdown-item">
                Events
              </Link>
            </>
          }
        />
        <Dropdown
          toggle={false}
          icon="pie-chart"
          position="bottom-end"
          flex
          className="mr-4 dropdown-header-icon-lg"
          items={
            <>
              <Link to="/invoices" className="dropdown-item">
                Invoices
              </Link>
              <Link to="/payments" className="dropdown-item">
                Payments
              </Link>
              {isFeatureReportsAccessible && (
                <Link to="/dzm/reports" className="dropdown-item">
                  Reports
                </Link>
              )}
            </>
          }
        />
        <Dropdown
          toggle={false}
          icon="users"
          position="bottom-end"
          flex
          className="mr-4 ml-4 dropdown-header-icon-lg"
          items={
            <>
              {isFeatureCRMAccessible && (
              <Link to="/coach/mystudents" className="dropdown-item">
                My Students
              </Link>
              )}
              <Link to="/coach/students" className="dropdown-item">
                Students
              </Link>
              <Link to="/staff" className="dropdown-item">
                TEAM Accounts
              </Link>
            </>
          }
        />
        <StudentSearchForm />
      </li>
    </>
  );
};

export default observer(CoachNav);
