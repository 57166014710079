import { useMutation, useQuery } from '@apollo/client';
import { Token } from '@stripe/stripe-js';
import { Loading } from 'components/Loading';
import AccountBalanceSuccessModal from 'components/Stripe/account-balance/AccountBalanceSuccessModal';
import StripeAccountBalanceModal from 'components/Stripe/account-balance/StripeAccountBalanceModal';
import { DEFAULT_PRODUCT_LIMIT } from 'components/constants';
import { UserStoreContext } from 'contexts/userStoreContext';
import { ADD_STUDENT_ACCOUNT_BALANCE } from 'graphql/ADD_STUDENT_ACCOUNT_BALANCE';
import { ADD_STUDENT_ACCOUNT_BALANCE as ADD_STUDENT_ACCOUNT_BALANCE_TYPE } from 'graphql/types/ADD_STUDENT_ACCOUNT_BALANCE';
import { GET_ACCOUNT_BALANCE } from 'graphql/GET_ACCOUNT_BALANCE';
import { GET_ACCOUNT_BALANCE as GET_ACCOUNT_BALANCE_TYPE } from 'graphql/types/GET_ACCOUNT_BALANCE';
import { GET_ACCOUNT_BALANCE_PAYMENT_HISTORY } from 'graphql/GET_ACCOUNT_BALANCE_PAYMENT_HISTORY';
import { GET_ACCOUNT_TYPE_BY_TEAM_ID } from 'graphql/GET_ACCOUNT_TYPE_BY_TEAM_ID';
import { GET_PAYMENT_FEE_BY_TEAM_ID } from 'graphql/GET_PAYMENT_FEE_BY_TEAM_ID';
import { GET_PRODUCTS_BY_FILTER_QUERY } from 'graphql/GET_PRODUCTS_BY_FILTER_QUERY';
import { GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID } from 'graphql/GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID';
import { GET_STUDENT_BY_ID } from 'graphql/GET_STUDENT_BY_ID';
import { GET_STUDENT_TEAM_ACCOUNT_BALANCE_HISTORY } from 'graphql/GET_STUDENT_TEAM_ACCOUNT_BALANCE_HISTORY';
import { GET_STUDENT_TIME_TRANSACTION_HISTORY } from 'graphql/GET_STUDENT_TIME_TRANSACTION_HISTORY';
import { GET_TEAM_BY_ID } from 'graphql/GET_TEAM_BY_ID';
import { GET_ACCOUNT_TYPE_BY_TEAM_ID as GET_ACCOUNT_TYPE_BY_TEAM_ID_TYPE } from 'graphql/types/GET_ACCOUNT_TYPE_BY_TEAM_ID';
import { GET_PAYMENT_FEE_BY_TEAM_ID as GET_PAYMENT_FEE_BY_TEAM_ID_TYPE } from 'graphql/types/GET_PAYMENT_FEE_BY_TEAM_ID';
import { GET_PRODUCTS_BY_FILTER_QUERY as GET_PRODUCTS_BY_FILTER_QUERY_TYPE } from 'graphql/types/GET_PRODUCTS_BY_FILTER_QUERY';
import { GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID as GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID_TYPE } from 'graphql/types/GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID';
import { GET_TEAM_BY_ID as GET_TEAM_BY_ID_TYPE } from 'graphql/types/GET_TEAM_BY_ID';
import { EOrderOptions } from 'hooks/useAutocomplete';
import { useRootStore } from 'hooks/useRootStore';
import React, { FC, useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card } from 'tabler-react';
import { parseIntNullable } from 'utils/numberParser';
import FeatureAccessGate from 'modules/common/components/FeatureAccessGate';
import useGetCurrentUserType, { useCoachTeam } from 'modules/common/hooks/useGetCurrentUserType';
import AccountBalanceModal from '../../modules/account-balance/components/modals/AccountBalanceModal';
import { formatAccountBalance } from '../../utils/formatAccountBalance';

const StudentTeamsPageHeader = ({teamId}:{teamId: number}) => {
  const { user } = useContext(UserStoreContext);
  const { currentCoachTeam } = useRootStore()

  const { loading: loading1, team, error: error1 } = useCoachTeam({teamId: currentCoachTeam.id})

  const { data: stripeAccountData, loading: loading2, error: error2 } =
    useQuery<GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID_TYPE>(
      GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID,
      {
        variables: {
          teamId
        }
      }
  );

  const { data: accountBalance, refetch: refetchAccountBalance } = useQuery<GET_ACCOUNT_BALANCE_TYPE>(
    GET_ACCOUNT_BALANCE,
    {
      variables: {
        studentId: Number(user.id),
        teamId
      }
    }
  );

  const { data: productsData, loading: loadingProducts } = useQuery<GET_PRODUCTS_BY_FILTER_QUERY_TYPE>(
    GET_PRODUCTS_BY_FILTER_QUERY,
    {
      variables: {
        order: EOrderOptions.alphabet,
        filter: {
          team_id: teamId,
          limit: DEFAULT_PRODUCT_LIMIT,
          offset: 0,
          status: 'account'
        }
      }
    }
  );

  const products = useMemo(() => {
    if (!productsData) {
      return [];
    }

    return productsData.products.map(
      ({ id, title, amount, quantity, currency }) => {
        return {
          value: id,
          label: title,
          id,
          title,
          amount,
          quantity,
          currency
        };
      }
    );
  }, [productsData]);


  if (loading1 || loading2 || loadingProducts || !team || !stripeAccountData) {
    return <Loading />
  }

  if (error1) {
    console.log(error1)
    return <p>Error: {error1.message}</p>
  }

  if (error2) {
    console.log(error2)
    return <p>Error: {error2.message}</p>
  }

  const stripeAccountId = stripeAccountData.getStripeAccountIdByTeamId?.stripe_account_id
  const refetch = () => {
    refetchAccountBalance()
  }

  const props = {user, products, stripeAccountId, team, teamId, accountBalance, refetch}
  return <StudentTeamsPageHeaderInner {...props} />
}

const StudentTeamsPageHeaderInner = ({
  user,
  teamId,
  team,
  products,
  stripeAccountId,
  accountBalance,
  refetch
}) => {
  const { currentUser } = useRootStore();
  const { isAdmin } = useGetCurrentUserType();
  const [amount, setAmount] = useState(0);
  const [email, setEmail] = useState('');

  const [isAccountBalanceModalOpen, setIsAccountBalanceModalOpen] =
    useState(false);
  const [isAccountBalanceFormShown, setIsAccountBalanceFormShown] =
    useState(false);
  const [isAccountBalanceStatusModalOpen, setIsAccountBalanceStatusModalOpen] =
    useState(false);
  // const [transactionType, setTransactionType] =
  //   useState<keyof typeof TransactionType>();

  const toggleAccountBalanceStatusModal = () =>
    setIsAccountBalanceStatusModalOpen(!isAccountBalanceStatusModalOpen);

  // const handleSetAccountBalanceFormShown = (value: boolean) => {
  //   setIsAccountBalanceFormShown(value);
  // };

  const toggleAccountBalanceModal = () =>
    setIsAccountBalanceModalOpen(!isAccountBalanceModalOpen);

  const [addStudentAccountBalance] =
    useMutation<ADD_STUDENT_ACCOUNT_BALANCE_TYPE>(ADD_STUDENT_ACCOUNT_BALANCE);

  const { data: accountTypeData, loading: accountTypeLoading } =
    useQuery<GET_ACCOUNT_TYPE_BY_TEAM_ID_TYPE>(GET_ACCOUNT_TYPE_BY_TEAM_ID, {
      variables: {
        teamId
      }
    });

  const accountType = useMemo(
    () => {
      if (accountTypeData?.getAccountTypeByTeamId) {
        return accountTypeData.getAccountTypeByTeamId;
      }
    },  
    [accountTypeData?.getAccountTypeByTeamId, accountTypeLoading]
  );

  const { data: paymentFee } = useQuery<GET_PAYMENT_FEE_BY_TEAM_ID_TYPE>(
    GET_PAYMENT_FEE_BY_TEAM_ID,
    {
      variables: {
        teamId
      }
    }
  );

  return (
    <Card.Header>
      <Card.Title>
        {team.name}
        <Link to="/user-details" className="font-weight-bold">
          {`${user.first_name} ${user.last_name}`}
        </Link>
      </Card.Title>
      <Card.Options className="d-block">
        <Button.List className="d-flex ml-auto gap-1">
          {isAdmin && (
            <Button icon="x-circle" color="secondary" disabled>
              REMOVE
            </Button>
          )}
          {/*{isAccountBalanceFormShown ? (*/}
          {/*  <AccountBalanceForm*/}
          {/*    accountTypeId={accountType.id}*/}
          {/*    accountTypeSlug={accountType.slug}*/}
          {/*    defaultTransactionType={transactionType}*/}
          {/*    studentBalance={formatAccountBalance(*/}
          {/*      accountBalance?.getAccountBalance?.total_time ?? 0,*/}
          {/*      accountTypeData?.getAccountTypeByTeamId.slug*/}
          {/*    )}*/}
          {/*    studentName={`${user.first_name} ${user.last_name}`}*/}
          {/*    handleSetAccountBalanceFormShown={*/}
          {/*      handleSetAccountBalanceFormShown*/}
          {/*    }*/}
          {/*  />*/}
          {/*) : (*/}
          {/*  <Button.Dropdown value="Actions" color="secondary">*/}
          {/*    <Dropdown.Item*/}
          {/*      to="#!"*/}
          {/*      onClick={(e) => {*/}
          {/*        e.preventDefault();*/}
          {/*        setTransactionType('CREDIT');*/}
          {/*        setIsAccountBalanceFormShown(true);*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <i className="fe fe-plus mr-2 text-muted" />*/}
          {/*      Credit*/}
          {/*    </Dropdown.Item>*/}
          {/*    <Dropdown.Item*/}
          {/*      to="#!"*/}
          {/*      onClick={(e) => {*/}
          {/*        e.preventDefault();*/}
          {/*        setTransactionType('REMOVE');*/}
          {/*        setIsAccountBalanceFormShown(true);*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <i className="fe fe-minus mr-2 text-muted" />*/}
          {/*      Remove*/}
          {/*    </Dropdown.Item>*/}
          {/*    <Dropdown.Item*/}
          {/*      to="#!"*/}
          {/*      onClick={(e) => {*/}
          {/*        e.preventDefault();*/}
          {/*        setTransactionType('TRANSFER');*/}
          {/*        setIsAccountBalanceFormShown(true);*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <i className="fe fe-arrow-right mr-2 text-muted" />*/}
          {/*      Transfer*/}
          {/*    </Dropdown.Item>*/}
          {/*  </Button.Dropdown>*/}
          {/*)}*/}
          <Button
            outline
            color="secondary"
            onClick={() => setIsAccountBalanceFormShown(true)}
          >
            Actions
          </Button>
          <FeatureAccessGate feature="feature_payments">
            <Button
              color="success"
              icon="credit-card"
              className="d-none d-md-block"
              onClick={(e) => {
                e.preventDefault();
                setIsAccountBalanceModalOpen(true);
              }}
            >
              Payment
            </Button>
            <Button
              color="success"
              icon="credit-card"
              className="d-md-none d-sm-block"
              onClick={(e) => {
                e.preventDefault();
                setIsAccountBalanceModalOpen(true);
              }}
            />
          </FeatureAccessGate>
          <StripeAccountBalanceModal
            stripeAccountId={stripeAccountId}
            accountTypeSlug={accountTypeData?.getAccountTypeByTeamId.slug}
            paymentFee={
              paymentFee?.getPaymentFeeByTeamId.payment_fee_customer
                ? paymentFee?.getPaymentFeeByTeamId.payment_fee_customer / 100
                : 0.03
            }
            products={products}
            isModalOpen={isAccountBalanceModalOpen}
            studentAccountBalance={
              accountBalance?.getAccountBalance?.total_time ?? 0
            }
            user={user}
            team={team}
            teamName={team.name}
            onCompleted={() => refetch()}
            toggleModal={toggleAccountBalanceModal}
            toggleStatusModal={toggleAccountBalanceStatusModal}
          />
          <AccountBalanceModal
            isOpen={isAccountBalanceFormShown}
            toggleModal={setIsAccountBalanceFormShown}
            accountTypeId={accountType?.id}
            accountTypeSlug={accountType?.slug}
            transactionType={accountType?.slug === 'time' ? 'CREDIT' : 'REMOVE'}
            studentBalance={formatAccountBalance(
              accountBalance?.getAccountBalance?.total_time ?? 0,
              accountTypeData?.getAccountTypeByTeamId.slug
            )}
            student={user}
          />
          <AccountBalanceSuccessModal
            amount={(amount / 100).toFixed(2)}
            email={email}
            isModalOpen={isAccountBalanceStatusModalOpen}
            toggleModal={toggleAccountBalanceStatusModal}
          />
        </Button.List>
      </Card.Options>
    </Card.Header>
  );
};

export default StudentTeamsPageHeader;
