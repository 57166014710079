import { useMutation, useQuery, ApolloError } from '@apollo/client';
import productImagePlaceholder from 'assets/images/product-image-placeholder.png';
import CurrencyInputField from 'components/CurrencyInputField';
import FormField from 'components/FormField';
import { ProductStatus } from 'components/Product/Forms/ProductListItemEdit';
import { DEFAULT_PRODUCT_LIMIT } from 'components/constants';
import TextEditor from 'components/text-editor/TextEditor';
import { ProductStoreContext } from 'contexts/productContext';
import { Formik } from 'formik';
import { GET_ALL_ACCOUNT_TYPES } from 'graphql/GET_ALL_ACCOUNT_TYPES';
import { GET_PRODUCTS_BY_FILTER_QUERY } from 'graphql/GET_PRODUCTS_BY_FILTER_QUERY';
import { GET_PRODUCT_TYPES } from 'graphql/GET_PRODUCT_TYPES';
import { INSERT_PRODUCT_MUTATION } from 'graphql/INSERT_PRODUCT_MUTATION';
import { useRootStore } from 'hooks';
import { EOrderOptions } from 'hooks/useAutocomplete';
import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';
// import { NumericFormat } from 'react-number-format';
import ReactS3Uploader from 'react-s3-uploader';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  Dropdown,
  Form,
  GalleryCard,
  Grid,
  Text
} from 'tabler-react';
import useReactRouter from 'use-react-router';
import * as Yup from 'yup';
import { Types } from '../../../types/graphql'

export enum EStatusOptions {
  inactive = 'inactive',
  active = 'active',
  account = 'account',
  archived = 'archived'
}

export const EAllowedAccountTypes = [
  // 1, //time
  2, //usd
  5, //can
  8, // eur
  9, //aud
  10 //gbp
];

const addProductValidationSchema = Yup.object().shape({
  title: Yup.string().required('This field is required.'),
  //  description: Yup.string().required('This field is required.'),
  amount: Yup.number().required('This field is required.'),
  product_type_id: Yup.number().min(1).required('This field is required.')
});

const ProductListItemEntry = () => {
  const productStore = useContext(ProductStoreContext);
  const { history } = useReactRouter();

  const rootStore = useRootStore();
  const { currentCoachTeam } = rootStore;

  const [productImage, setProductImage] = useState<string>('');

  const { data } = useQuery(GET_PRODUCT_TYPES);
  const { data: accountData } = useQuery(GET_ALL_ACCOUNT_TYPES);

  const [addProduct] = useMutation(INSERT_PRODUCT_MUTATION, {
    onError: (error: ApolloError) =>
      toast.error(error?.graphQLErrors[0]?.message),
    onCompleted: () => {
      toast.success('Product Added.');
    },
    refetchQueries: [
      {
        query: GET_PRODUCTS_BY_FILTER_QUERY,
        variables: {
          order: EOrderOptions.alphabet,
          filter: {
            limit: DEFAULT_PRODUCT_LIMIT,
            offset: 0,
            team_id: currentCoachTeam.id
          }
        }
      }
    ]
  });

  const onError = (error) => {
    toast.error(error);
    rootStore.setLoading(false);
  };

  const onFinished = (response: { fileKey: string }) => {
    updateProductImage(response.fileKey);
    rootStore.setLoading(false);
  };

  const updateProductImage = async (fileKey: string | null) =>
    setProductImage(fileKey);

  const renderForm = ({
    errors,
    touched,
    values,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue
  }) => (
    <Form onSubmit={handleSubmit}>
      <Grid.Row className="product-details">
        <Grid.Col lg={9} sm={12} xs={12}>
          <Card>
            <Card.Header>
              <Card.Title>Add Product</Card.Title>
            </Card.Header>
            <Card.Body>
              <Grid.Row>
                <Grid.Col>
                  <FormField
                    name="title"
                    label="Title"
                    placeholder="Title"
                    type="text"
                    value={values.title}
                    onChange={handleChange}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row className="mb-3 ws-prewrap">
                <Grid.Col>
                  <Form.Group label="Description">
                    <TextEditor
                      placeholder="Description"
                      value={values.description}
                      onChange={(value) => setFieldValue('description', value)}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>

              <Grid.Row className="mb-3">
                <Grid.Col xs={12} md={6}>
                  <Form.Group label="Product type">
                    <Select
                      name="product_type_id"
                      isMulti={false}
                      getOptionLabel={(
                        option: Types.ProductType
                      ) => option.name}
                      getOptionValue={(
                        option: Types.ProductType
                      ) => option.id}
                      options={data?.productTypes}
                      onChange={(param) => {
                        setFieldValue('product_type_id', param.id);
                      }}
                      value={data?.productTypes?.filter(
                        (productType) =>
                          productType.id === parseInt(values.product_type_id)
                      )}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col xs={12} md={6}>
                  <Form.Group label="Status">
                    <Select
                      name="status"
                      isMulti={false}
                      options={ProductStatus}
                      onChange={(param) => {
                        setFieldValue('status', param.value);
                      }}
                      value={ProductStatus.filter(
                        (status) => status.value === values.status
                      )}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>

              <Grid.Row className="mb-3">
                <Grid.Col lg={3} xs={12}>
                  <Form.Group label="Amount" className="mb-0">
                    <CurrencyInputField
                      name="amount"
                      value={values.amount}
                      placeholder="Amount"
                      setFieldValue={setFieldValue}
                    />
                  </Form.Group>
                  <Text.Small color="secondary" disabled>
                    The purchase amount
                  </Text.Small>
                </Grid.Col>
                {values.status === 'account' && (
                  <Grid.Col lg={3} xs={12}>
                    <FormField
                      name="quantity"
                      label="Quantity"
                      placeholder="Quantity"
                      type="number"
                      value={values.quantity}
                      onChange={handleChange}
                    />
                    <Text>
                      <Text.Small color="secondary" disabled>
                        The account value
                      </Text.Small>
                    </Text>
                  </Grid.Col>
                )}

                <Grid.Col xs={12} md={6}>
                  <Form.Group label="Account type">
                    <Select
                      name="account_type_id"
                      isMulti={false}
                      getOptionLabel={(
                        option: Types.ProductType
                      ) => option.name}
                      getOptionValue={(
                        option: Types.ProductType
                      ) => option.id}
                      options={
                        currentCoachTeam.account_type_id === 1
                          ? [
                              {
                                name: 'Time',
                                id: 1
                              }
                            ]
                          : accountData?.getAllAccountTypes.filter((item) =>
                              EAllowedAccountTypes.includes(item.id)
                            )
                      }
                      onChange={(param) => {
                        setFieldValue('account_type_id', param.id);
                      }}
                      value={
                        accountData?.getAllAccountTypes &&
                        accountData?.getAllAccountTypes.filter(
                          (accountType) =>
                            accountType.id === parseInt(values.account_type_id)
                        )
                      }
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
            <Card.Footer>
              <Button.List align="right">
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  type="submit"
                >
                  Add Product
                </Button>
              </Button.List>
            </Card.Footer>
          </Card>
        </Grid.Col>

        <Grid.Col lg={3} sm={10} xs={12}>
          <GalleryCard>
            <GalleryCard.Image
              className="product-image w-100"
              src={
                productImage
                  ? `/api/s3/uploads/${productImage}`
                  : productImagePlaceholder
              }
            />

            <Dropdown
              items={
                <>
                  {rootStore.loading ? (
                    <Button
                      loading
                      color="secondary"
                      className="border-0 p-1 shadow-none"
                      block
                    >
                      Loading...
                    </Button>
                  ) : (
                    <label
                      htmlFor="s3-upload"
                      className="dropdown-item cursor-pointer mb-0"
                    >
                      {productImage ? 'Change' : 'Upload'}
                    </label>
                  )}
                  <ReactS3Uploader
                    accept="image/*"
                    autoUpload={true}
                    className="d-none"
                    contentDisposition="auto"
                    id="s3-upload"
                    signingUrl="/api/s3/sign"
                    onError={onError}
                    onFinish={onFinished}
                    onProgress={() => rootStore.setLoading(true)}
                    uploadRequestHeaders={{
                      'x-amz-acl': 'private'
                    }}
                  />
                  {productImage && (
                    <Dropdown.Item
                      to="#!"
                      onClick={(e) => {
                        e.preventDefault();
                        updateProductImage(null);
                      }}
                    >
                      Remove
                    </Dropdown.Item>
                  )}
                </>
              }
              toggle={false}
              triggerContent={
                <Button block color="primary" size="sm">
                  Image Options
                </Button>
              }
            />
          </GalleryCard>
        </Grid.Col>
      </Grid.Row>
    </Form>
  );

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={addProductValidationSchema}
      initialValues={{
        amount: '',
        quantity: '',
        description: '',
        image: productImage,
        status: EStatusOptions.active,
        product_type_id: 4,
        team_id: currentCoachTeam.id,
        title: '',
        account_type_id: currentCoachTeam.account_type_id
      }}
      onSubmit={async (values, { resetForm, setSubmitting, setFieldError }) => {
        if (values.status === 'account' && !values.quantity) {
          setFieldError('quantity', 'Quantity is required');
          setSubmitting(false);
          return false;
        }

        console.log(values);

        if (values.status !== 'account') {
          values.quantity = '';
        }

        const newProduct = await addProduct({
          variables: {
            product: {
              amount: values.amount,
              quantity: values.quantity ? values.quantity.toString() : null,
              description: values.description,
              image: productImage,
              status: values.status,
              product_type_id: values.product_type_id.toString(),
              team_id: values.team_id,
              title: values.title,
              account_type_id: 2
            }
          }
        });
        resetForm();
        productStore.addProduct(newProduct.data.addProduct);
        history.push(`/products/${newProduct.data.addProduct.id}`);
      }}
    >
      {(formikData) => renderForm(formikData)}
    </Formik>
  );
};

export default observer(ProductListItemEntry);
