import { useQuery } from "@apollo/client";
import { GET_STUDENT_BY_ID } from "graphql/GET_STUDENT_BY_ID";
import { GET_STUDENT_BY_ID as GET_STUDENT_BY_ID_TYPE } from "graphql/types/GET_STUDENT_BY_ID";

import { useGetUserIsStudent } from "../../common/hooks/useGetCurrentUserType";

const useGetStudentById = (student_id: string) => {
  const isStudent: boolean = useGetUserIsStudent();
  const { data, loading } = useQuery<GET_STUDENT_BY_ID_TYPE>(
    GET_STUDENT_BY_ID,
    {
      variables: {
        student_id,
      },
    },
  );

  return { data, loading };
};

export default useGetStudentById;
