import React from 'react';
import { EUserTypes } from 'components/User/userTypes';
import { Dropdown, Nav } from 'tabler-react';
import { Link } from 'react-router-dom';
import { useRootStore } from 'hooks';
import StudentSearchForm from 'components/Students/Forms/StudentSearchForm';
import useIsFeatureAccessible from 'modules/common/hooks/useIsFeatureAccessible';


const EventsNav = () => {
  const { currentUser, currentCoachTeam } = useRootStore();
  const { isFeatureAccessible: isFeatureProductsAccessible } =
    useIsFeatureAccessible({
      currentCoachTeam,
      currentUserType: currentUser?.type as EUserTypes,
      feature: 'feature_products'
    });

  return (
    <>
      <Nav.Item icon='home' to='/' value='/' />
      {isFeatureProductsAccessible && (
        <Nav.Item icon="shopping-bag" to="/products" value="Products" />
      )}
      <li className="nav-item ml-lg-auto mb-4 mb-lg-0">
        <Dropdown
          toggle={false}
          icon="calendar"
          position="bottom-end"
          flex
          className="mr-4 dropdown-header-icon-lg"
          items={
            <>
              <Link to="/user/calendar" className="dropdown-item">
                Calendar
              </Link>
              <Link to="/events" className="dropdown-item">
                Events
              </Link>
            </>
          }
        />
        <Dropdown
          toggle={false}
          icon="pie-chart"
          position="bottom-end"
          flex
          className="mr-4 dropdown-header-icon-lg"
          items={
            <>
              <Link to="/invoices" className="dropdown-item">
                Invoices
              </Link>
              <Link to="/payments" className="dropdown-item">
                Payments
              </Link>
            </>
          }
        />
        <Dropdown
          toggle={false}
          icon="users"
          position="bottom-end"
          flex
          className="mr-4 ml-4 dropdown-header-icon-lg"
          items={
            <>
              <Link to="/events/participants" className="dropdown-item">
                Participants
              </Link>
              <Link to="/staff" className="dropdown-item">
                TEAM Accounts
              </Link>
            </>
          }
        />
        <StudentSearchForm />
      </li>
    </>
  );
};

export default EventsNav;
