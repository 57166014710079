import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, StampCard } from 'tabler-react';

import { useRootStore } from '../../hooks/useRootStore';
import formatNumber from '../../utils/numberFormat';
import { observer } from 'mobx-react';

const StudentsTotal = () => {
  const rootStore = useRootStore();
  const { studentsTotal, registeredStudentsTotal } = rootStore;

  return (
    <Grid.Col sm={6} lg={3} xs={12} className="mb-0">
      <StampCard
        color="primary"
        icon="users"
        header={
          <Link to="/admin/user-list">
            {formatNumber(registeredStudentsTotal)} <small>Activated</small>
          </Link>
        }
        footer={`${formatNumber(studentsTotal)} total users`}
      />
    </Grid.Col>
  );
};

export default observer(StudentsTotal);
