import { useMutation, useQuery } from '@apollo/client'
import { manualPaymentOpts } from 'components/constants'
import DatePicker from 'components/DatePicker'
import { DELETE_PAYMENT } from 'graphql/DELETE_PAYMENT'
import { GET_PAYMENTS } from 'graphql/GET_PAYMENTS'
import { REFUND_PAYMENT } from 'graphql/REFUND_PAYMENT'
import {
  GET_PAYMENTS as GET_PAYMENTS_TYPE,
} from 'graphql/types/GET_PAYMENTS'
import { useRootStore } from 'hooks/useRootStore'
import { observer } from 'mobx-react'
import ViewPaymentBtn from 'modules/payment/components/buttons/ViewPaymentBtn'
import { EPaymentStatus } from 'modules/payment/constants/paymentStatus'
import PaymentListHeader from 'modules/payment/lists/PaymentListHeader'
import DeletePaymentModal from 'modules/payment/modals/DeletePaymentModal'
import RefundPaymentModal from 'modules/payment/modals/RefundPaymentModal'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import {
  Alert,
  Button,
  Card,
  Dimmer,
  Dropdown,
  Form,
  Grid,
  Icon,
  Table,
  Tag
} from 'tabler-react'
import { formatMoney } from 'utils/numberFormat'
import useGetCurrentUserType, {
  useGetUserIsAdmin
} from '../../../modules/common/hooks/useGetCurrentUserType'
import UpdatePayementModal from '../modals/UpdatePaymentModal'
import { UPDATE_PAYMENT_STATUS } from '../../../graphql/UPDATE_PAYMENT_STATUS'
import { Types } from '../../../types/graphql'

const PaymentsListItem = ({
  payment
}: {
  payment: Types.Payments
}) => {
  const { currentCoachTeam } = useRootStore()
  const isAdmin = useGetUserIsAdmin()
  const [isDeletePaymentModalOpen, setisDeletePaymentModalOpen] =
    useState(false)
  const [isUpdatePaymentStatusModalOpen, setIsUpdatePaymentStatusModalOpen] =
    useState(false)
  const [isStripeRefundModalOpen, setIsStripeRefundModalOpen] = useState(false)
  const [paymentId, setPaymentId] = useState<string | null>(null)
  const [status, setStatus] = useState<string>('')
  const { isCoachAdmin } = useGetCurrentUserType()

  const toggleDeletePaymentModal = () => {
    setisDeletePaymentModalOpen(!isDeletePaymentModalOpen)
  }

  const toggleUpdatePaymentStatusModal = () => {
    setIsUpdatePaymentStatusModalOpen(!isUpdatePaymentStatusModalOpen)
  }

  const toggleStripeRefundModal = () => {
    setIsStripeRefundModalOpen(!isStripeRefundModalOpen)
  }

  const [deletePaymentMutation] = useMutation(DELETE_PAYMENT, {
    onCompleted: () => {
      toggleDeletePaymentModal()
      toast.success('Payment Deleted.')
    },
    refetchQueries: [
      {
        query: GET_PAYMENTS,
        variables: {
          filter: {
            team_id: currentCoachTeam?.id
          }
        }
      }
    ]
  })

  const [updatePaymentStatus] = useMutation(UPDATE_PAYMENT_STATUS, {
    onCompleted: () => {
      toggleUpdatePaymentStatusModal()
      toast.success(`Payment ${status}.`)
    },
    refetchQueries: [
      {
        query: GET_PAYMENTS,
        variables: {
          filter: {
            team_id: currentCoachTeam?.id
          }
        }
      }
    ]
  })

  const [refundPaymentMutation] = useMutation(REFUND_PAYMENT, {
    onCompleted: () => {
      toggleStripeRefundModal()
      toast.success('Payment Refunded.')
    },
    refetchQueries: [
      {
        query: GET_PAYMENTS,
        variables: {
          filter: {
            team_id: currentCoachTeam?.id
          }
        }
      }
    ]
  })

  const onDeletePayment = async () => {
    await deletePaymentMutation({
      variables: {
        payment_id: Number(paymentId)
      }
    })
  }

  const onUpdatePaymentStatus = async () => {
    await updatePaymentStatus({
      variables: {
        payment_id: Number(paymentId),
        status
      }
    })
  }

  const onRefundPayment = async () => {
    await refundPaymentMutation({
      variables: {
        payment_id: Number(paymentId)
      },
      refetchQueries: [
        {
          query: GET_PAYMENTS
        }
      ]
    })
  }

  return (
    <Table.Row>
      {isAdmin && <Table.Col>{payment.id}</Table.Col>}
      <Table.Col>{`${payment?.first_name ?? ''} ${
        payment?.last_name ?? ''
      }`}</Table.Col>
      <Table.Col>
        {payment?.camp_name && `Camp: ${payment.camp_name}`}
        {payment?.product_title && payment.product_title}
        {payment?.invoice_id && `Invoice: ${payment.invoice_id}`}
      </Table.Col>
      <Table.Col><Tag>
        <span className="text-uppercase">{payment?.card_brand}</span>
        {payment.last4 && <Tag className="ml-2">{' x' + payment.last4}</Tag>}
        {payment?.manual_payment}</Tag>
      </Table.Col>
      <Table.Col>
        {moment(payment.created_on).format('DD MMM YYYY h:mm a')}
      </Table.Col>
      <Table.Col>
        {formatMoney(payment.amount)} {payment.currency?.toUpperCase()}
      </Table.Col>
      <Table.Col>
        <Button
          size="sm"
          color={
            payment.status === EPaymentStatus.paid ? 'success' : 'secondary'
          }
          className={
            payment.status === EPaymentStatus.paid ? '' : 'text-danger'
          }
        >
          {payment.status}
        </Button>
      </Table.Col>
      <Table.Col>
        <Dropdown
          arrow
          arrowPosition="right"
          triggerContent={<Icon name={'more-vertical'} />}
          position="bottom-end"
          toggle={false}
          items={
            <>
              {payment.processed_by === 'Manual Payment' && (
                <>
                  {isAdmin || isCoachAdmin ? (
                    <Dropdown.Item
                      icon="x-circle"
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault()
                        setPaymentId(payment.id)
                        toggleDeletePaymentModal()
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  ) : (
                    <>
                      <Dropdown.Item
                        icon="minus-circle"
                        className="cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault()
                          if (payment.status === 'Void') return
                          setPaymentId(payment.id)
                          setStatus('Void')
                          toggleUpdatePaymentStatusModal()
                        }}
                      >
                        Void
                      </Dropdown.Item>
                      <Dropdown.Item
                        icon="x-circle"
                        className="cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault()
                          if (payment.status === 'Refund') return
                          setPaymentId(payment.id)
                          setStatus('Refund')
                          toggleUpdatePaymentStatusModal()
                        }}
                      >
                        Refund
                      </Dropdown.Item>
                    </>
                  )}
                </>
              )}
              {payment.processed_by === 'Stripe' &&
                payment.status !== 'Refunded' && (
                  <Dropdown.Item
                    icon="x-circle"
                    className="cursor-pointer"
                    onClick={() => {
                      setPaymentId(payment.id)
                      toggleStripeRefundModal()
                    }}
                  >
                    Refund
                  </Dropdown.Item>
                )}
                {/*
                  <Dropdown.Item icon="edit" className="text-muted">
                    Update
                  </Dropdown.Item>
                */}
              <ViewPaymentBtn payment_id={Number(payment.id)} />
            </>
          }
        />
      </Table.Col>
      <DeletePaymentModal
        isModalOpen={isDeletePaymentModalOpen}
        onConfirm={onDeletePayment}
        toggleModal={toggleDeletePaymentModal}
      />
      <UpdatePayementModal
        isModalOpen={isUpdatePaymentStatusModalOpen}
        onConfirm={onUpdatePaymentStatus}
        toggleModal={toggleUpdatePaymentStatusModal}
        status={status}
      />
      <RefundPaymentModal
        isModalOpen={isStripeRefundModalOpen}
        onConfirm={onRefundPayment}
        toggleModal={toggleStripeRefundModal}
      />
    </Table.Row>
  )
}

const PaymentsList = () => {
  const { currentCoachTeam } = useRootStore()
  const isAdmin = useGetUserIsAdmin()
  const [dateFilter, setDateFilter] = useState(new Date())
  const [manualPayment, setManualPayment] = useState('')
  const [cardBrand, setCardBrand] = useState('')

  const { loading, data } = useQuery<GET_PAYMENTS_TYPE>(GET_PAYMENTS, {
    variables: {
      params: {
        team_id: currentCoachTeam?.id,
        date_filter: dateFilter,
        manual_payment: manualPayment,
        card_brand: cardBrand
      }
    }
  })

  const payments = useMemo(
    () => data?.getPayments.payments ?? [],  
    [data?.getPayments, loading]
  )

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (
      e.target.value === 'American Express' ||
      e.target.value === 'MasterCard' ||
      e.target.value === 'Visa'
    ) {
      setCardBrand(e.target.value)
      setManualPayment(null)
    } else {
      setManualPayment(e.target.value)
      setCardBrand(null)
    }
  }

  return (
    <div className="container">
      <Dimmer active={loading} loader={loading}>
        <Grid.Row>
          <PaymentListHeader
            total={data?.getPayments.total ?? 0}
            totalComp={data?.getPayments.totalComp ?? 0}
            totalPaid={data?.getPayments.totalPaid ?? 0}
          />
        </Grid.Row>

        <Grid.Row>
          <Grid.Col>
            <Card>
              <Card.Header>
                <Card.Title>
                  <Icon name="credit-card" className="mr-2 ml-0 text-success" />
                  Payments
                </Card.Title>
                <Card.Options className="gap-2">
                  <Button
                    className="text-secondary"
                    color="white"
                    icon="chevron-left"
                    size="sm"
                    onClick={() => {
                      setDateFilter(
                        moment(dateFilter).subtract(1, 'months').toDate()
                      )
                    }}
                  />
                  <DatePicker
                    name="created_on"
                    placeholder="Select Date"
                    onChange={(date) => setDateFilter(date)}
                    value={dateFilter}
                    views={['year', 'month']}
                    format="MMMM yyyy"
                    className="w-50"
                  />

                  <Form.Select
                    name="payment_method"
                    className="w-50"
                    onChange={handleChange}
                  >
                    <option value="">All</option>
                    {manualPaymentOpts
                      .concat(
                        {
                          value: 'American Express',
                          label: 'AMEX'
                        },
                        { value: 'MasterCard', label: 'MasterCard' },
                        { value: 'Visa', label: 'VISA' }
                      )
                      .map((paymentType) => (
                        <option
                          value={paymentType.value}
                          key={paymentType.value}
                        >
                          {paymentType.label}
                        </option>
                      ))}
                  </Form.Select>
                  {isAdmin && (
                    <Button icon="download" color="white" size="sm">
                      CSV
                    </Button>
                  )}
                </Card.Options>
              </Card.Header>
              <Card.Body>
                <Table className="card-table">
                  <Table.Header>
                    <Table.Row>
                      {isAdmin && <Table.ColHeader>ID</Table.ColHeader>}
                      <Table.ColHeader>Customer</Table.ColHeader>
                      <Table.ColHeader>Description</Table.ColHeader>
                      <Table.ColHeader>Method</Table.ColHeader>
                      <Table.ColHeader>Created</Table.ColHeader>
                      <Table.ColHeader>Amount</Table.ColHeader>
                      <Table.ColHeader>Status</Table.ColHeader>
                      <Table.ColHeader></Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {payments.length > 0 ? (
                      payments.map((payment) => (
                        <PaymentsListItem key={payment.id} payment={payment} />
                      ))
                    ) : (
                      <Table.Row>
                        <Table.Col colSpan="12">
                          <Alert type="info text-center">
                            <strong>No Data</strong>
                          </Alert>
                        </Table.Col>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Dimmer>
    </div>
  )
}

export default observer(PaymentsList)
