import { useQuery } from '@apollo/client';
import CoachStudentList from 'components/Students/CoachStudentList';
import { EUserFilterOptions } from 'components/enum';
import { GET_STUDENTS_BY_FILTER_QUERY } from 'graphql/GET_STUDENTS_BY_FILTER_QUERY';
import {
  GET_STUDENTS_BY_FILTER_QUERY as GET_STUDENTS_BY_FILTER_QUERY_TYPE
} from 'graphql/types/GET_STUDENTS_BY_FILTER_QUERY';
import { EOrderOptions } from 'hooks/useAutocomplete';
import { useRootStore } from 'hooks/useRootStore';
import Wrapper from 'pages/layout';
import React, { useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { Button, Card, Container, Icon } from 'tabler-react';
import { formatDateToLocalString } from 'utils/dateFormat';

const MembersPage = (props) => {
  const { currentCoachTeam } = useRootStore();

  const { data, loading } = useQuery<GET_STUDENTS_BY_FILTER_QUERY_TYPE>(
    GET_STUDENTS_BY_FILTER_QUERY,
    {
      variables: {
        order: EOrderOptions.newest,
        filter: {
          team_id: currentCoachTeam?.id.toString(),
          filter_by: EUserFilterOptions.enabled
        }
      }
    }
  );

  const studentsRow = useMemo(
    () =>
      data?.students.map(
        ({
          id,
          first_name,
          last_name,
          phone_country_code,
          phone_number,
          email,
          current_balance,
          last_jump,
          last_tunnel_session,
          last_event
        }) => ({
          id,
          first_name,
          last_name,
          phone_country_code,
          phone_number,
          email,
          current_balance,
          last_jump: formatDateToLocalString(last_jump),
          last_tunnel_session: formatDateToLocalString(last_tunnel_session),
          last_event: formatDateToLocalString(last_event)
        })
      ) ?? [],  
    [data?.students, loading]
  );

  return (
    <Wrapper {...props} title="Customers">
      <Container>
        <Card>
          <Card.Header>
            <Card.Title>
              <Icon name="users" className="mr-2 ml-0 text-blue" />
              <strong><a href="/">{currentCoachTeam.name}</a></strong> // Members
            </Card.Title>
            <Card.Options>
              <Button.List className="m-0 p-0" align="right">
                <CSVLink data={studentsRow} filename={currentCoachTeam.name + '-members.csv'}>
                  <Button
                    icon="download"
                    color="white"
                    disabled={loading}
                  />
                </CSVLink>
              </Button.List>
            </Card.Options>
          </Card.Header>
        </Card>
        <Card>
          <Card.Body>
            <CoachStudentList />
          </Card.Body>
        </Card>
      </Container>
    </Wrapper>
  );
};

export default MembersPage;
