import gql from 'graphql-tag';

export const GET_EVENTS_AS_ORGANIZER = gql`
  query GET_EVENTS_AS_ORGANIZER($coach_id: Int!) {
    getEventsAsOrganizer(coach_id: $coach_id) {
      id
      camp_name
      end
      location
      start
    }
  }
`;
