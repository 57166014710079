import { useLazyQuery } from '@apollo/client';
import { EUserTypes } from 'components/User/userTypes'
import { UserStoreProvider } from 'contexts/userStoreContext'
import { ADMIN_HOME_QUERY } from 'graphql/ADMIN_HOME_QUERY'
import { GET_TEAMS_BY_COACH_ID } from 'graphql/GET_TEAMS_BY_COACH_ID'
import { ADMIN_HOME_QUERY as ADMIN_HOME_QUERY_TYPE } from 'graphql/types/ADMIN_HOME_QUERY'
import { GET_TEAMS_BY_COACH_ID as GET_TEAMS_BY_COACH_ID_TYPE } from 'graphql/types/GET_TEAMS_BY_COACH_ID'
import { useRootStore } from 'hooks'
import { EOrderOptions } from 'hooks/useAutocomplete'
import { observer } from 'mobx-react'
import { applySnapshot } from 'mobx-state-tree'
import FooterBottom from 'pages/layout/FooterBottom'
import FooterNav from 'pages/layout/FooterNav'
import Header from 'pages/layout/Header'
import HeaderVisitor from 'pages/layout/HeaderVisitor'
import Navbar from 'pages/layout/Navbar'
import React, { ReactNode, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ScrollToTop from 'utils/scrollToTop'
import AdminNav from 'views/navigation/AdminNav'
import CoachNav from 'views/navigation/CoachNav'
import DZMNav from 'views/navigation/DZMNav'
import EventsNav from 'views/navigation/EventsNav'
import ManufacturerNav from 'views/navigation/ManufacturerNav'
import RiggerNav from 'views/navigation/RiggerNav'
import StudentNav from 'views/navigation/StudentNav'
import WTMNav from 'views/navigation/WTMNav'
import OrgNav from 'views/navigation/OrgNav'
import VisitorNav from 'views/navigation/VisitorNav'
import FreshChatWidget from 'widgets/FreshChatWidget'

const NavViews = {
  [EUserTypes.admin]: AdminNav,
  [EUserTypes.coach]: CoachNav,
  [EUserTypes.dzm]: DZMNav,
  [EUserTypes.events]: EventsNav,
  [EUserTypes.manufacturer]: ManufacturerNav,
  [EUserTypes.org]: OrgNav,
  [EUserTypes.rigger]: RiggerNav,
  [EUserTypes.student]: StudentNav,
  [EUserTypes.wtm]: WTMNav,
  visitor: VisitorNav
}

interface IWrapperProps {
  children: ReactNode;
  title?: string;
}

const Wrapper = ({ children, title }: IWrapperProps) => {
  const rootStore = useRootStore()

  const { currentUser, currentCoachTeam } = rootStore

  const [collapse, setCollapse] = useState(true)

  const [getTeamsByCoachId, { loading, error, data }] =
    useLazyQuery<GET_TEAMS_BY_COACH_ID_TYPE>(GET_TEAMS_BY_COACH_ID)

  const [
    getAdminHome,
    { loading: adminLoading, error: adminError, data: adminData }
  ] = useLazyQuery<ADMIN_HOME_QUERY_TYPE>(ADMIN_HOME_QUERY)

  // Run every time adminData changes
  useEffect(() => {
    if (!adminLoading && adminData) {
      const {
        getCampsTotal,
        getCoaches,
        getMeritsTotal: { auth_merits_total, user_merits_total },
        getPaymentsTotal,
        getPaymentsProcessedTotal,
        getRegistrationsTotal,
        getStudentsTotal: { totalStudent, registeredStudent }
      } = adminData

      applySnapshot(rootStore, {
        ...rootStore,
        authMeritTotal: auth_merits_total,
        campTotal: getCampsTotal,
        paymentProcessedTotal: getPaymentsProcessedTotal,
        paymentsTotal: getPaymentsTotal,
        recentCoaches: getCoaches,
        registeredStudentsTotal: registeredStudent,
        registrationsTotal: getRegistrationsTotal,
        studentsTotal: totalStudent,
        userMeritTotal: user_merits_total
      })
    }
  }, [adminLoading, adminData])  

  useEffect(() => {
    if (!loading && data && !rootStore.coachTeams.length) {
      const { getTeamsByCoachId: getTeamsByCoachIdData } = data

      applySnapshot(rootStore, {
        ...rootStore,
        coachTeams: getTeamsByCoachIdData,
        currentCoachTeam: getTeamsByCoachIdData[0]
      })
    }
  }, [loading, data])  

  // Run query on mount once
  useEffect(() => {
    if (
      [
        EUserTypes.coach.toString(),
        EUserTypes.dzm.toString(),
        EUserTypes.rigger.toString()
      ].includes(currentUser?.type)
    ) {
      getTeamsByCoachId({
        variables: {
          coachId: currentUser?.id
        }
      })
    }

    if (currentUser?.type === EUserTypes.admin) {
      getAdminHome({
        variables: {
          coachLimit: 5,
          coachOrder: 'recent',
          studentOrder: EOrderOptions.newest,
          studentFilter: { limit: 5 },
          registeredStudentFilter: 'Confirmed',
          registeredStudentLimit: 5,
          registeredStudentOrder: 'recent'
        }
      })
    }
     
  }, [])

  if (error || adminError) {
    return <p>Error: {error || adminError}</p>
  }

  const NavView =
    (currentUser &&
      NavViews[
        [EUserTypes.admin, EUserTypes.student].includes(
          currentUser?.type as EUserTypes
        )
          ? currentUser?.type
          : currentCoachTeam?.coach_business_type
      ]) ??
    NavViews.visitor

  return (
    <ScrollToTop>
      <Helmet>
        <title>SKYCRU | {title || ''}</title>
      </Helmet>
      <div className="page">
        <div className="page-main">
          <UserStoreProvider>
            {currentUser ? (
              <Header collapse={collapse} setCollapse={setCollapse} />
            ) : (
              <HeaderVisitor collapse={collapse} setCollapse={setCollapse} />
            )}

            <Navbar collpase={collapse}>
              <NavView />
            </Navbar>
            <div className="page-content">{children}</div>
          </UserStoreProvider>
        </div>
        <FooterNav />
        <FooterBottom />
      </div>
      <FreshChatWidget user={currentUser} />
    </ScrollToTop>
  )
};

export default observer(Wrapper);
