import { useQuery } from "@apollo/client";
import axios from "axios";
import {
  ABCDLicenses,
  BASE_ORG_ID,
  EUSPATitles,
  lifetimeMemberTemplateId,
  meritsWithExpiration,
} from "components/constants";
import { EUserTypes } from "components/User/userTypes";
import { UserStoreContext } from "contexts/userStoreContext";
import { GET_USER_MERITS_BY_TEMPLATE_TITLE } from "graphql/GET_USER_MERITS_BY_TEMPLATE_TITLE";
import EmailMeritsInstructionsButton from "modules/auth-merit/components/buttons/EmailMeritInstructionsButton";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Alert,
  Button,
  Card,
  Dimmer,
  Form,
  Grid,
  Icon,
  Text,
} from "tabler-react";
import useReactRouter from "use-react-router";
import useRouter from "use-react-router";

import { GET_ORG_CREDENTIALS } from "../../../graphql/GET_ORG_CREDENTIALS";
import { GET_SPORTS_BY_STUDENT_ID } from "../../../graphql/GET_SPORTS_BY_STUDENT_ID";
import { GET_TEAM_ORGS } from "../../../graphql/GET_TEAM_ORGS";
import { GET_SPORTS_BY_STUDENT_ID as GET_SPORTS_BY_STUDENT_ID_TYPE } from "../../../graphql/types/GET_SPORTS_BY_STUDENT_ID";
import { useRootStore } from "../../../hooks";
import {
  useGetUserIsAdmin,
  useGetUserIsStudent,
} from "../../../modules/common/hooks/useGetCurrentUserType";
import OrgCredentialMembershipCard from "../../OrgCredentials/OrgCredentialMembershipCard";
import ManualCredentialForm from "../../Students/Forms/ManualCredentialForm";
import { Types } from '../../../types/graphql'

const UserCredentialsForm = () => {
  const { currentCoachTeam, currentUser } = useRootStore();
  const { user } = useContext(UserStoreContext);
  const isAdmin = useGetUserIsAdmin();
  const isStudent = useGetUserIsStudent();
  const { location } = useReactRouter();
  const { history } = useRouter();
  const hasLifetimeMembership = user?.uspa.some(
    (item) => item.template_id === lifetimeMemberTemplateId,
  );

  const coachLicense = useMemo(
    () =>
      user?.uspa.find(
        (item): boolean => item.template_title === EUSPATitles.CoachRating,
      ),
    [user?.uspa], // Eslint-disable-line
  );

  const otherMeritsWithExpiration = useMemo(
    () =>
      user?.uspa
        .filter((item) => meritsWithExpiration.includes(item.template_title))
        .sort((a, b) => a.template_title.localeCompare(b.template_title)) ?? [],
    [user?.uspa], // Eslint-disable-line
  );

  const isCoachLicenseExpired = useMemo(
    () =>
      coachLicense?.merits_uspa_member_expiration &&
      new Date(coachLicense.merits_uspa_member_expiration) < new Date(),
    [coachLicense],
  );

  const memberName = useMemo(
    () =>
      user?.uspa[0]?.first_name && user?.uspa[0]?.last_name
        ? `${user?.uspa[0].first_name} ${user?.uspa[0].last_name}`
        : null,
    [user?.uspa], // Eslint-disable-line
  );

  const memberEmail = useMemo(
    () => user?.uspa[0]?.email ?? null,
    [user?.uspa], // Eslint-disable-line
  );

  const memberUpdated = useMemo(
    () =>
      user?.uspa[0]?.updated_on
        ? new Date(user?.uspa[0]?.updated_on).toLocaleString()
        : null,
    [user?.uspa], // Eslint-disable-line
  );

  const memberLicense = useMemo(() => {
    if (hasLifetimeMembership) {
      return user?.uspa.find(
        (item) => item.template_title === "Lifetime Member",
      );
    }

    return user?.uspa.find(
      (item) => item.template_title === EUSPATitles.Member,
    );
  }, [hasLifetimeMembership, user?.uspa]); // Eslint-disable-line

  const isMemberLicenseExpired = useMemo(
    () =>
      memberLicense?.merits_uspa_member_expiration &&
      new Date(memberLicense.merits_uspa_member_expiration) < new Date(),
    [memberLicense],
  );

  const [loading, setLoading] = useState(false);
  const [meritConfig, setMeritConfig] = useState(null);

  const sortedABCDLicenses = useMemo(
    () =>
      user?.uspa
        .filter((item) => ABCDLicenses.includes(item.template_title))
        .sort((a, b) => a.template_title.localeCompare(b.template_title)) ?? [],
    [user?.uspa], // Eslint-disable-line
  );

  // Sort the array of licenses
  const sortedOtherLicenses =
    user?.uspa
      .filter(
        (item) =>
          item.template_id !== lifetimeMemberTemplateId &&
          ![
            ...ABCDLicenses,
            ...meritsWithExpiration,
            EUSPATitles.CoachRating,
            EUSPATitles.Member,
          ].includes(item.template_title),
      )
      .sort((a, b) => a.template_title.localeCompare(b.template_title)) ?? [];

  const hasAuthMerits = user?.auth_merits.length > 0;

  const {
    loading: userMeritsLoading,
    error: userMeritsError,
    data: userMeritsData,
  } = useQuery(GET_USER_MERITS_BY_TEMPLATE_TITLE, {
    variables: {
      student_id: user?.id,
      template_title: EUSPATitles.Member,
    },
  });

  useEffect(() => {
    if (!userMeritsLoading && !userMeritsError && userMeritsData) {
      setMeritConfig(
        userMeritsData.getUserMeritsByTemplateTitle || meritConfig,
      );
    }
  }, [userMeritsData]); // Eslint-disable-line

  const queryServer = async (url: string) => {
    setLoading(true);

    try {
      const response = await axios.post(url, {
        user_id: user?.id,
        user_type: EUserTypes.student,
      });

      const meritConfigQuery = response.data.merits?.filter(
        (merit: { template_title: EUSPATitles }) =>
          merit.template_title === EUSPATitles.Member,
      );

      if (meritConfigQuery) {
        setMeritConfig(meritConfigQuery[0]);
      }
    } catch (error: any) {
      toast.error(error.message);
    }

    setLoading(false);
    toast.success("Merits synced.");
  };

  const refresh = () => {
    queryServer("/auth/merit/refresh");
  };

  const unlink = () => {
    queryServer("/auth/merit/unlink");
  };

  const current = useCallback(async () => {
    setLoading(true);

    try {
      const response = await axios.post("/auth/merit/current");

      setMeritConfig(response.data);
    } catch (err: any) {
      console.log(err.message);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    current(); // Eslint-disable-next-line
  }, []);

  const [selectedOrg, setSelectedOrg] = useState<number>(null);
  const [selectedOrgCredentials, setSelectedOrgCredentials] = useState([]);
  const orgFilter = !isAdmin && !isStudent ? currentCoachTeam.id : null;
  const { data: orgData, loading: orgLoading } = useQuery(GET_TEAM_ORGS, {
    variables: { teamId: orgFilter },
  });

  const { data: studentSports, loading: studentSportsLoading } =
    useQuery<GET_SPORTS_BY_STUDENT_ID_TYPE>(GET_SPORTS_BY_STUDENT_ID, {
      variables: {
        student_id: user?.id,
      },
    });

  const hasBaseSport = useMemo(() => {
    if (!studentSportsLoading && studentSports) {
      return !!studentSports?.getSportsByStudentId.find(
        (sport) => sport.is_active && sport.name === "BASE",
      );
    }

    return false;
  }, [studentSports, studentSportsLoading]);

  const { data: studentManualCredentials, loading: manualCredentialsLoading } =
    useQuery(GET_ORG_CREDENTIALS, {
      variables: { studentId: isStudent ? currentUser?.id : user?.id },
    });

  useEffect(() => {
    if (studentManualCredentials) {
      if (selectedOrg && !!studentManualCredentials.orgCredentials) {
        setSelectedOrgCredentials(
          studentManualCredentials.orgCredentials.filter(
            (oc) => Number(oc.org_id) === selectedOrg,
          ),
        );
      } else {
        setSelectedOrgCredentials([]);
      }
    }
  }, [studentManualCredentials, manualCredentialsLoading, selectedOrg]);

  const handleOrgChange = (orgId: number) => {
    setSelectedOrg(orgId);
  };

  const orgs = useMemo(
    () => {
      let orgFiltered;
      const defaultOrg = {
        __typename: "TeamOrg",
        org_id: null,
        slug: "USPA",
        name: "USPA (Merits)",
        image: "",
        exist: true,
        team_id: null,
        is_primary: false,
      };
      if (!orgLoading && orgData && !manualCredentialsLoading) {
        if (!isAdmin && !isStudent) {
          orgFiltered = orgData.teamOrgs.filter(
            (teamOrg: Types.TeamOrg) => {
              if (
                (hasAuthMerits || memberLicense) &&
                Number(teamOrg.org_id) === 1
              ) {
                return false;
              }

              if (Number(teamOrg.org_id) === BASE_ORG_ID) {
                return hasBaseSport;
              }

              if (
                studentManualCredentials?.orgCredentials &&
                studentManualCredentials?.orgCredentials.length > 0
              ) {
                if (
                  studentManualCredentials.orgCredentials.some(
                    (oc) => oc.org_id === teamOrg.org_id,
                  ) ||
                  teamOrg.exist
                ) {
                  return true;
                }
              }

              return teamOrg.exist;
            },
          );

          if (hasAuthMerits) {
            orgFiltered.unshift(defaultOrg);
          }
          if (!selectedOrg) {
            handleOrgChange(Number(orgFiltered[0]?.org_id));
          }

          return orgFiltered;
        }

        orgFiltered = orgData.teamOrgs.filter(
          (teamOrg: Types.TeamOrg) => {
            if (
              (hasAuthMerits || memberLicense) &&
              Number(teamOrg.org_id) === 1
            ) {
              return false;
            }

            if (isAdmin || isStudent) {
              return true;
            }

            return teamOrg.exist;
          },
        );

        if (hasAuthMerits) {
          orgFiltered.unshift(defaultOrg);
        }
        if (!selectedOrg) {
          handleOrgChange(Number(orgFiltered[0].org_id));
        }

        return orgFiltered;
      }

      return [];
    },
    // Eslint-disable-next-line
    [
      orgData,
      orgLoading,
      hasAuthMerits,
      studentManualCredentials,
      manualCredentialsLoading,
      isAdmin,
      isStudent,
      memberLicense,
    ],
  );

  const canEdit = useMemo(() => {
    if (isAdmin) {
      return true;
    }

    if (isStudent) {
      return false;
    }

    const currentOrg = orgs.find(
      (org: Types.TeamOrg) => Number(org.org_id) === selectedOrg,
    );

    return currentOrg?.exist;
  }, [orgs, selectedOrg, isAdmin, isStudent]);

  const customFilter = (option, searchText) => {
    if (
      option.data.name.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.slug.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    }

    return false;
  };

  if (userMeritsError) {
    return <p>{userMeritsError.message}</p>;
  }

  return (
    <Dimmer
      active={
        manualCredentialsLoading || orgLoading || userMeritsLoading || loading
      }
      loader={
        manualCredentialsLoading || orgLoading || userMeritsLoading || loading
      }
    >
      <Card statusColor="blue">
        <Card.Header className="d-block d-md-flex pt-3 pl-3">
          <Card.Title className="mb-3 mb-md-0">
            <Icon name="award" className="mr-2 ml-0 text-success" />
            Credentials
          </Card.Title>
          <Card.Options>
            <Button.List align="right">
              {hasAuthMerits && location.pathname === "/user/settings" && (
                <Button
                  icon={"refresh-cw"}
                  color={"white"}
                  size="sm"
                  className="text-success"
                  loading={loading}
                  onClick={refresh}
                  disabled={loading}
                >
                  REFRESH
                </Button>
              )}
              {hasAuthMerits && location.pathname === "/user/settings" && (
                <Button
                  icon="x-circle"
                  color="white"
                  size="sm"
                  className="text-muted"
                  disabled={loading}
                  onClick={unlink}
                >
                  UNLINK MERIT
                </Button>
              )}
              {!hasAuthMerits && orgs.find((o) => Number(o.org_id) === 1) && (
                <EmailMeritsInstructionsButton />
              )}
            </Button.List>
          </Card.Options>
        </Card.Header>
        {location.pathname !== "/user/profile" && (
          <Card.Body>
            <Dimmer active={userMeritsLoading} loader={userMeritsLoading}>
              {orgs.length > 0 && (
                <Grid.Row>
                  <Grid.Col sm={6} md={6}>
                    <Form.Group label="Organization">
                      <Select
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        menuPortalTarget={document.body}
                        isDisabled={orgs.length === 0}
                        name="organization"
                        getOptionLabel={(option: Types.TeamOrg) => (
                          <>
                            <span className="mr-1 font-weight-bold">
                              {option.slug.toUpperCase()}
                            </span>
                            {option.name}
                          </>
                        )}
                        getOptionValue={(option: Types.TeamOrg) =>
                          option.org_id
                        }
                        filterOption={customFilter}
                        options={orgs}
                        onChange={(e) => {
                          handleOrgChange(Number(e.org_id));
                        }}
                        value={
                          orgs.find(
                            (org: Types.TeamOrg) =>
                              Number(org.org_id) === selectedOrg,
                          ) || orgs[0]
                        }
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
              )}

              {/* MANUALLY ENTERED USPA FORM IS USED WHEN THERE ARE NO AUTH MERITS */}
              {selectedOrg > 0 && (
                <ManualCredentialForm
                  orgCredentials={selectedOrgCredentials}
                  orgId={selectedOrg}
                  studentId={isStudent ? currentUser?.id : Number(user?.id)}
                  editable={canEdit}
                />
              )}

              {!selectedOrg && hasAuthMerits && (
                <>
                  <Grid.Row className="mt-5 mb-5">
                    <Grid.Col sm={6} md={6}>
                      <Form.Group label="USPA Member #">
                        <Form.Input
                          className={"w-50"}
                          readOnly
                          invalid={!memberLicense?.merits_uspa_member_id}
                          value={
                            !memberLicense?.merits_uspa_member_id
                              ? "Unknown"
                              : memberLicense?.merits_uspa_member_id ?? ""
                          }
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col sm={6} md={6}>
                      <Form.Group label="Expires">
                        {hasLifetimeMembership ? (
                          <Grid.Row className="mb-6">
                            <Grid.Col>
                              <Form.Input
                                valid={true}
                                tick={true}
                                value="Lifetime Member"
                              />
                            </Grid.Col>
                          </Grid.Row>
                        ) : (
                          <Form.Input
                            name="merits_uspa_member_expiration"
                            placeholder="MM/DD/YYYY"
                            className={
                              isMemberLicenseExpired ? "text-danger" : ""
                            }
                            readOnly
                            invalid={
                              isMemberLicenseExpired ||
                              !memberLicense?.merits_uspa_member_expiration
                            }
                            valid={
                              !isMemberLicenseExpired &&
                              memberLicense?.merits_uspa_member_expiration
                            }
                            value={
                              memberLicense?.merits_uspa_member_expiration
                                ? new Intl.DateTimeFormat().format(
                                    new Date(
                                      memberLicense.merits_uspa_member_expiration,
                                    ),
                                  )
                                : ""
                            }
                            cross={isMemberLicenseExpired}
                            tick={
                              !isMemberLicenseExpired &&
                              memberLicense?.merits_uspa_member_expiration
                            }
                          />
                        )}
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                </>
              )}

              {coachLicense && (
                <Grid.Row>
                  <Grid.Col sm={6} md={6}>
                    <Form.Group>
                      <Form.Input
                        readOnly
                        name="organization_coach_number"
                        value={coachLicense.template_title}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col sm={6} md={6}>
                    <Form.Group>
                      <Form.Input
                        name="organization_coach_expire"
                        placeholder="00/00/0000"
                        className={isCoachLicenseExpired ? "text-danger" : ""}
                        readOnly
                        invalid={isCoachLicenseExpired}
                        valid={!isCoachLicenseExpired}
                        value={
                          coachLicense?.merits_uspa_member_expiration
                            ? new Intl.DateTimeFormat().format(
                                new Date(
                                  coachLicense.merits_uspa_member_expiration,
                                ),
                              )
                            : ""
                        }
                        cross={isCoachLicenseExpired}
                        tick={!isCoachLicenseExpired}
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
              )}

              {otherMeritsWithExpiration.length > 0 &&
                otherMeritsWithExpiration.map((merit) => (
                  <Grid.Row key={merit.template_id}>
                    <Grid.Col sm={6} md={6}>
                      <Form.Group>
                        <Form.Input readOnly value={merit.template_title} />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col sm={6} md={6}>
                      <Form.Group>
                        <Form.Input
                          name="organization_coach_expire"
                          placeholder="00/00/0000"
                          className={
                            merit.merits_uspa_member_expiration &&
                            new Date(merit.merits_uspa_member_expiration) <
                              new Date()
                              ? "text-danger"
                              : ""
                          }
                          readOnly
                          invalid={
                            merit.merits_uspa_member_expiration &&
                            new Date(merit.merits_uspa_member_expiration) <
                              new Date()
                          }
                          valid={
                            merit.merits_uspa_member_expiration &&
                            new Date(merit.merits_uspa_member_expiration) >=
                              new Date()
                          }
                          value={
                            merit.merits_uspa_member_expiration
                              ? new Intl.DateTimeFormat().format(
                                  new Date(merit.merits_uspa_member_expiration),
                                )
                              : ""
                          }
                          cross={
                            merit.merits_uspa_member_expiration &&
                            new Date(merit.merits_uspa_member_expiration) <
                              new Date()
                          }
                          tick={
                            merit.merits_uspa_member_expiration &&
                            new Date(merit.merits_uspa_member_expiration) >=
                              new Date()
                          }
                        />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                ))}

              {!selectedOrg && user?.uspa.length !== 0 && (
                <>
                  <Grid.Row className="mt-5">
                    <Grid.Col sm={6} md={6}>
                      <Form.Group label="License" className="mb-0" />
                    </Grid.Col>
                    <Grid.Col sm={6} md={6}>
                      <Form.Group label="License Number" className="mb-0" />
                    </Grid.Col>
                  </Grid.Row>

                  {sortedABCDLicenses.length > 0 &&
                    sortedABCDLicenses.map((licenseType) => (
                      <Grid.Row key={licenseType.template_id}>
                        <Grid.Col sm={6} md={6} className="grid-col col-xs-6">
                          <Form.Group>
                            <Form.Input
                              readOnly
                              value={licenseType.template_title}
                            />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col sm={6} md={6} className="grid-col col-xs-6">
                          <Form.Group>
                            <Form.Input readOnly value={""} />
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>
                    ))}

                  {sortedOtherLicenses.length > 0 && (
                    <Grid.Row className="mt-5">
                      {sortedOtherLicenses.map((licenseType) => (
                        <Grid.Col
                          sm={6}
                          md={6}
                          className="py-2 grid-col col-xs-12 mb-0"
                          key={licenseType.template_id}
                        >
                          <Icon name="award" className="text-success" />{" "}
                          {licenseType.template_title}
                        </Grid.Col>
                      ))}
                    </Grid.Row>
                  )}
                </>
              )}
            </Dimmer>
          </Card.Body>
        )}
        {hasAuthMerits && (
          <Card.Footer>
            <Grid.Row>
              <Grid.Col xs={12} sm={12} md={12} lg={3}>
                <img
                  src="https://app.merits.com/favicon-16x16.png"
                  alt=""
                  className="mr-2"
                />
                Synced with {""}
                <span>
                  <a
                    target="_blank"
                    href="https://www.merits.com"
                    rel="noopener noreferrer"
                  >
                    Merit
                  </a>
                </span>
              </Grid.Col>
              <Grid.Col xs={12} sm={12} md={12} lg={9} className="text-right">
                {hasAuthMerits && user?.uspa.length === 0 && (
                  <Text.Small className="text-danger">
                    <strong>Error Syncing Merits:</strong> We have successfully
                    synced this user's Merits account, however we cannot obtain
                    credentials. This error generally occurs when the user's{" "}
                    <strong>USPA Account Email</strong> and{" "}
                    <strong>Merit Account Email</strong> do not match.
                    <a href="/help"> Need Help?</a>
                  </Text.Small>
                )}
                {memberUpdated && (
                  <>
                    <span>{`Updated - ${memberUpdated}`}</span>
                    <span className="d-block small">
                      {`${memberName}`}
                      {user?.uspa[0].first_name !== user?.first_name &&
                        user?.uspa[0].last_name !== user?.last_name && (
                          <i
                            title="Merit name does not match account name!"
                            className="fe fe-alert-triangle text-warning ml-1 mr-1"
                          />
                        )}
                      {` - ${memberEmail}`}
                      {memberEmail !== user?.email && (
                        <i
                          title="Merit email does not match account email!"
                          className="fe fe-alert-triangle text-warning ml-1"
                        />
                      )}
                    </span>
                  </>
                )}
              </Grid.Col>
            </Grid.Row>
          </Card.Footer>
        )}
        {location.pathname === "/user/profile" &&
          !hasAuthMerits &&
          user?.uspa.length >= 0 &&
          (!studentManualCredentials?.orgCredentials ||
            studentManualCredentials?.orgCredentials.length <= 0) && (
            <Card.Body>
              <Button.List align="center">
                <Button
                  icon="plus"
                  color="primary"
                  size="sm"
                  className=""
                  onClick={(): void => history.push("/user/settings")}
                >
                  Add Credentials
                </Button>
              </Button.List>
            </Card.Body>
          )}
        {location.pathname === "/user/profile" &&
          studentManualCredentials?.orgCredentials && (
            <Card.Body>
              <Grid.Row>
                {orgs.map((org: Types.TeamOrg) => {
                  const membership: Types.OrgCredential =
                    studentManualCredentials.orgCredentials.find(
                      (credential: Types.OrgCredential) =>
                        credential.org_id === org.org_id &&
                        credential.membership,
                    );
                  if (!membership) {
                    return "";
                  }

                  return (
                    <Grid.Col sm={12} md={6} lg={6} key={org.org_id}>
                      <OrgCredentialMembershipCard
                        membershipCredential={membership}
                        org={org}
                      />
                    </Grid.Col>
                  );
                })}
              </Grid.Row>
            </Card.Body>
          )}
      </Card>
    </Dimmer>
  );
};

export default UserCredentialsForm;
