import { useQuery } from '@apollo/client';
import OnDeckListItem from 'components/OnDeck/OnDeckListItem';
import { GET_STUDENTS_ON_DASHBOARD } from 'graphql/GET_STUDENTS_ON_DASHBOARD';
import { GET_STUDENTS_ON_DASHBOARD as GET_STUDENTS_ON_DASHBOARD_TYPE } from 'graphql/types/GET_STUDENTS_ON_DASHBOARD';
import { useRootStore } from 'hooks';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { Dimmer, Grid } from 'tabler-react';

const StudentsOnDashboard = () => {
  const { currentCoachTeam } = useRootStore();

  const { loading, data } = useQuery<GET_STUDENTS_ON_DASHBOARD_TYPE>(
    GET_STUDENTS_ON_DASHBOARD,
    {
      variables: {
        team_id: currentCoachTeam?.id
      }
    }
  );

  const onDeckStudentRows = data?.studentsOnDashboard.map((item) => item) ?? []

  return (
    <Dimmer active={loading} loader={loading}>
      <Grid.Col className="pl-0 pr-0" width={12}>
        <Grid.Row>
          {onDeckStudentRows.map((onDeck) => (
            <Grid.Col lg={3} sm={6} xs={12} key={onDeck.id}>
              <OnDeckListItem onDeck={onDeck} />
            </Grid.Col>
          ))}
        </Grid.Row>
      </Grid.Col>
    </Dimmer>
  );
};

export default observer(StudentsOnDashboard);
