import React from 'react';
import { Text } from 'tabler-react';
import Wrapper from 'pages/layout';

const HelpPage = (props) => {
  return (
    <Wrapper {...props} title="Help">
      <h1 className="container">Support</h1>
      <div className="container">
        <Text>
          Create Support Ticket:{' '}
          <a
            href="mailto:tickets@skycru.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            tickets@skycru.com
          </a>
        </Text>
        <Text>
          Email Support:{' '}
          <a
            href="mailto:help@skycru.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            help@skycru.com
          </a>
        </Text>
        <Text>
          Facebook Page:{' '}
          <a
            href="https://www.facebook.com/skycru/"
            target="_blank"
            rel="noopener noreferrer"
          >
            SKYCRU
          </a>
        </Text>
        <Text className="mt-3">
          <a
            href="https://www.skycru.com/meetings/marshall43"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click Here
          </a> to schedule a Demo, Training Session, or other Meeting
        </Text>


        {/* 
        <Text>
          WhatsApp:{' '}
          <a
            href="https://wa.me/18567778838"
            target="_blank"
            rel="noopener noreferrer"
          >
            +1 (856) 777-8838
          </a>
        </Text>
        */}
      </div>
      <div className="container mt-7">
        <Text>
          Join the{' '}
          <a
            href="https://x.com/i/communities/1703409688359145857"
            target="_blank"
            rel="noopener noreferrer"
          >
            SKYDIVING community
          </a>
          {' '}on X!
        </Text>
      </div>
    </Wrapper>
  );
};

export default HelpPage;
