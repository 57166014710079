import { Formik } from 'formik';
import Modal from '../../Modal';
import React from 'react';
import { Button, Form, Grid } from 'tabler-react';
import { useMutation } from '@apollo/client';
import { UPDATE_STUDENT_MUTATION } from '../../../graphql/UPDATE_STUDENT_MUTATION';
import { GET_USER_REGISTRATIONS_BY_IDS } from '../../../graphql/GET_USER_REGISTRATIONS_BY_IDS';
import moment from 'moment';

interface IRegistrationStudentDetailDOBModalProps {
  isModalOpen: boolean;
  toggleModal: any;
  student: any;
}

const RegistrationStudentDetailDOBModal = ({
                                             isModalOpen,
                                             toggleModal,
                                             student
                                           }: IRegistrationStudentDetailDOBModalProps) => {

  const [updateStudent] = useMutation(UPDATE_STUDENT_MUTATION);

  const renderForm = ({ values, handleSubmit, isSubmitting, handleChange, errors, touched }) => (
    <form onSubmit={handleSubmit}>
      <Grid.Row className='mb-4'>
        <Grid.Col xs={12} sm={12} lg={6}>
          <Form.Group label='DOB'>
            <Form.MaskedInput
              placeholder='MM/DD/YYYY'
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
              name='dob'
              onChange={handleChange}
              value={values.dob}
            />
            <span className='field-error text-danger'>
              {errors.dob && touched.dob && errors.dob}
            </span>
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col className='pt-2' xs={12} sm={12} lg={12}>
          <Button
            type='submit'
            disabled={isSubmitting}
            pill
            className='btn-gray-dark float-right btn-sm'
          >
            {isSubmitting
              ? 'Processing...'
              : 'SUBMIT'}
          </Button>

        </Grid.Col>
      </Grid.Row>
    </form>);
  return (
    <Modal
      content={
        <Formik
          enableReinitialize={true}
          initialValues={{
            dob: student?.dob ? moment(student?.dob).format('MM/DD/YYYY') : ''
          }}
          onSubmit={async (
            values,
            { resetForm, setSubmitting, setFieldError }
          ) => {
            const date = moment(values.dob);
            const dateValue = !date.isValid()
              ? null
              : moment(values.dob).format('YYYY-MM-DD');

            updateStudent({
              variables: {
                student: {
                  id: student?.student_id,
                  dob: dateValue
                }
              },
              refetchQueries: [
                {
                  query: GET_USER_REGISTRATIONS_BY_IDS,
                  variables: {
                    userRegistrationsId: Number(student?.user_registrations_id)
                  }
                }
              ]
            });
            toggleModal(!isModalOpen);
            setSubmitting(false);
            resetForm();
          }}
        >
          {(formikData) => renderForm(formikData)}
        </Formik>
      }
      open={isModalOpen}
      title={`${student.first_name} ${student.last_name}`}
      onClose={() => toggleModal(!isModalOpen)}
    />
  );
};

export default RegistrationStudentDetailDOBModal;
