import React from 'react';
import { Icon } from 'tabler-react';

const CustomColorCheckItem = ({ color, checked = false, onChange, name }) => {
  const toggleCheck = () => {
    const newChecked = !checked;
    console.log('toggleCheck', color, newChecked);
    if (onChange) {
      onChange(newChecked ? color : '')
    }
  };

  const checkboxStyle = {
    border: `none`,
    cursor: 'pointer',
    display: 'inline-block',
    height: '25px',
    marginRight: '15px',
    paddingTop: '2px',
    width: '25px'
  };

  return (
    <label
      htmlFor={`${name}-${color}`}
      style={{
        cursor: 'pointer',
        display: 'inline-flex',
        alignItems: 'center'
      }}
    >
      <input
        id={`${name}-${color}`}
        name={name}
        type="checkbox"
        style={{ display: 'none' }} // Hide the actual checkbox input
        checked={checked}
        onClick={toggleCheck}
      />
      <span
        style={{ textAlign: 'center', ...checkboxStyle }}
        className={`bg-${color}`}
      >
        {' '}
        {checked && <Icon name="check" className={'text-white'} />}
      </span>
      {/* This span acts as the visual checkbox */}
    </label>
  );
};

export default CustomColorCheckItem;
