import { useLazyQuery } from '@apollo/client';
import Downshift from 'downshift';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react';
import { Icon } from 'tabler-react';
import React, { useEffect, useRef, useState } from 'react';
import { GET_STUDENTS_BY_FILTER_QUERY } from '../../../graphql/GET_STUDENTS_BY_FILTER_QUERY';
import { EOrderOptions, IArgsOptions } from '../../../hooks/useAutocomplete';
import { useRootStore } from '../../../hooks/useRootStore';
import { EUserFilterOptions } from '../../enum';
import { Types } from '../../../types/graphql'

const SEARCH_LIMIT = 10;

interface StudentSearchFieldProps {
  onChange: any;
  defaultValue: string;
  isDisabled?: boolean;
  autoFocus?: boolean;
}

const StudentSearchField = ({ onChange, defaultValue, isDisabled, autoFocus }: StudentSearchFieldProps) => {
  const rootStore = useRootStore();
  const { currentCoachTeam } = rootStore;

  const [filteredStudents, setFilteredStudents] = useState([]);
  const searchRef = useRef(defaultValue);

  let argsOptions: IArgsOptions = {
    order: EOrderOptions.alphabet,
    filter: { filter_by: EUserFilterOptions.all }
  };

  if (currentCoachTeam?.id) {
    argsOptions = {
      ...argsOptions,
      filter: {
        team_id: currentCoachTeam?.id.toString(),
        filter_by: EUserFilterOptions.enabled
      }
    };
  }

  const setValue = (
    searchedUser: Types.Student
  ) => {
    searchRef.current = `${searchedUser.first_name} ${searchedUser.last_name}`;
  };

  const changeEvent = (searchedUser: Types.Student) => {
    if (searchedUser) {
      onChange(searchedUser);
      setValue(searchedUser);
    }
  };

  const [students, { loading, error, data }] = useLazyQuery(
    GET_STUDENTS_BY_FILTER_QUERY
  );

  // Set filtered students everytime data changes
  useEffect(() => {
    if (!loading && !error && data) {
      setFilteredStudents(data[Object.keys(data)[0]]);
    }
  }, [data]);  

  const handleChange = debounce(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      students({
        variables: {
          ...argsOptions,
          filter: {
            ...argsOptions.filter,
            limit: SEARCH_LIMIT,
            search: e.target.value
          }
        }
      });
    },
    360
  );

  return (
    <div className="search-container w-150">
      <Downshift onChange={changeEvent}>
        {({
            getInputProps,
            getItemProps,
            isOpen,
            inputValue,
            highlightedIndex
          }) => (
          <div>
            <input
              {...getInputProps({
                className: 'form-control',
                id: 'search',
                placeholder: 'Search by name...',
                type: 'search',
                value: searchRef.current,
                disabled: isDisabled,
                autoFocus: autoFocus || false,
                onChange: (e) => {
                  searchRef.current = e.target.value;
                  e.persist();
                  handleChange(e);
                }
              })}
            />
            {isOpen && (
              <div className="search-container-dropdown">
                {filteredStudents.map((item, idx) => (
                  <div
                    {...getItemProps({ item })}
                    className={`search-container-dropdown-item ${
                      idx === highlightedIndex &&
                      'search-container-dropdown-item-highlighted'
                    }`}
                    key={item.id}
                  >
                    {!item?.registration_id ? (
                      <Icon
                        name="user-x"
                        className="mr-2 ml-0 text-secondary"
                      />
                    ) : (
                      <Icon
                        name="user"
                        className="mr-2 ml-0 text-success"
                      />
                    )}
                    <span>
                      {`${item.first_name} ${item.last_name}`}
                    </span>
                    <span className="d-block small text-primary ml-1">{item.id}</span>
                  </div>
                ))}
                {!filteredStudents.length && !loading && (
                  <div className="search-container-dropdown-item">
                    No results for {inputValue}.
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </Downshift>
    </div>
  );
};

export default observer(StudentSearchField);
