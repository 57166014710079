import { Document, Page, Text as PDFText, View } from "@react-pdf/renderer";
import React from "react";
import styles from "utils/pdfStyles";
import { Types } from "../../../types/graphql";
import { groupBySubHeader } from "../StudentTeamProgressionHeading";

interface ISkillsPDFProps {
  progression: Types.StudentTeamProgression[];
  heading: string;
  studentDetails: any;
}

const SkillsPdf = ({
  progression,
  heading,
  studentDetails,
}: ISkillsPDFProps) => {
  const groupedProgression = groupBySubHeader(progression);
  return (
    <Document>
      <Page style={styles.body}>
        <View>
          <PDFText style={{ ...styles.title, marginBottom: "20px" }}>
            {studentDetails.studentName} Skills Progression
          </PDFText>
          <PDFText style={{ ...styles.textMedium, marginBottom: "20px" }}>
            {heading}
          </PDFText>
        </View>

        {Object.keys(groupedProgression).map((key) => (
          <View style={styles.table} key={key}>
            <View style={styles.tableRow}>
              <PDFText
                style={{
                  ...styles.tableCol,
                  ...styles.textMedium,
                  width: "100%",
                }}
              >
                {key}
              </PDFText>
            </View>
            {groupedProgression[key].map(
              (pr: Types.StudentTeamProgression, j) => (
                <View key={j} style={styles.tableRow}>
                  <PDFText
                    style={{
                      ...styles.tableCol,
                      ...styles.textSmall,
                      width: "30%",
                    }}
                  >
                    {pr.title}
                  </PDFText>
                  <PDFText
                    style={{
                      ...styles.tableCol,
                      ...styles.textSmall,
                      width: "60%",
                    }}
                  >
                    {pr.studentProgressionNotes ?? ""}
                  </PDFText>
                  <PDFText
                    style={{
                      ...styles.tableCol,
                      ...styles.textSmall,
                      width: "10%",
                      textAlign: "right",
                    }}
                  >
                    {pr.rating}
                  </PDFText>
                </View>
              ),
            )}
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default SkillsPdf;
