import React from 'react';
import { Avatar, Button, Table, Tag, Text } from 'tabler-react';
import { DELETE_LOAD_USER } from '../../graphql/DELETE_LOAD_USER';
import { useMutation } from '@apollo/client';
import { ADD_USER_EVENT } from '../../graphql/ADD_USER_EVENT';
import { toast } from 'react-toastify';
import { EUserEvents, EUserEventsId } from '../User/UserEvents';
import { useRootStore } from '../../hooks';
import EditLoadPassengerModalForm from './Form/EditLoadPassengerModalForm';
import { formatMoney } from 'utils/numberFormat';

interface ILoadPassengerListItemProps {
  passenger: any;
  load: any;
  getLoads?: any;
}

const LoadPassengerListItem = ({
  passenger,
  load,
  getLoads
}: ILoadPassengerListItemProps) => {
  const { currentCoachTeam } = useRootStore();
  const [isOpen, setIsOpen] = React.useState(false);
  const [addUserEvent] = useMutation(ADD_USER_EVENT);
  const [deleteUserFromLoad] = useMutation(DELETE_LOAD_USER, {
    onCompleted: async () => {
      toast.success('Student added to load');
      await addUserEvent({
        variables: {
          userEvent: {
            status: EUserEvents.user_unloaded,
            user_event_type_id: EUserEventsId.user_unloaded,
            location_id: currentCoachTeam.default_location_id,
            team_id: currentCoachTeam.id,
            student_id: passenger.studentDetails.id
          }
        }
      });
      getLoads();
    }
  });
  const deletePassengerHandler = async () => {
    if (window.confirm('Are you sure you want to remove this passenger?')) {
      // delete passenger
      await deleteUserFromLoad({
        variables: {
          loadAssociatedUserId: passenger.id
        }
      });
    }
  };
  return (
    <Table.Row>
      <Table.Col>
        <Avatar className="float-left mr-2" size="lg" />
        {passenger.studentDetails.first_name}{' '}
        {passenger.studentDetails.last_name}
        <Tag color="white" className="text-success">{formatMoney(passenger.balance ?? 0)}</Tag>
        <Text.Small className="text-muted d-block">{passenger.association_type_name}</Text.Small>
      </Table.Col>
      <Table.Col><Tag>{passenger.jump_type_name}</Tag></Table.Col>
      <Table.Col><Tag color="white" className="text-muted">Full Altitude / {formatMoney(passenger.compensation ?? 0)}</Tag></Table.Col>
      <Table.Col className="text-right">
        <Button
          color="white"
          icon="edit"
          size="sm"
          className=""
          onClick={() => setIsOpen(true)}
        >
          Edit
        </Button>
        <Button
          color="white"
          size="sm"
          className="text-danger"
          onClick={deletePassengerHandler}
        >
          Remove
        </Button>
        <EditLoadPassengerModalForm
          passenger={passenger}
          isOpen={isOpen}
          toggleModal={() => setIsOpen(false)}
          load={load}
          getLoads={getLoads}
        />
      </Table.Col>
    </Table.Row>
  );
};

export default LoadPassengerListItem;
