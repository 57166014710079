import React, { useMemo, useState } from 'react';
import moment from 'moment/moment';
import { Formik } from 'formik';
import useGetCurrentUserType from '../../../modules/common/hooks/useGetCurrentUserType';
import { useRootStore } from '../../../hooks';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_STUDENT_ORG_CREDENTIAL } from '../../../graphql/ADD_STUDENT_ORG_CREDENTIAL';
import { toast } from 'react-toastify';
import { Button, Form, Grid } from 'tabler-react';
import Select from 'react-select';
import { BASE_ORG_ID } from '../../constants';
import { GET_TEAM_ORGS } from '../../../graphql/GET_TEAM_ORGS';
import { GET_SPORTS_BY_STUDENT_ID as GET_SPORTS_BY_STUDENT_ID_TYPE } from '../../../graphql/types/GET_SPORTS_BY_STUDENT_ID';
import { GET_SPORTS_BY_STUDENT_ID } from '../../../graphql/GET_SPORTS_BY_STUDENT_ID';
import * as Yup from 'yup';
import { Types } from '../../../types/graphql'

const manualCredentialsValidationSchema = Yup.object().shape({
  membership: Yup.string().required('Member number is required'),
  expires: Yup.date().required('Expiration date is required')
});

const RegistrationOrgCredentialForm = ({ studentId, toggle, team_id }) => {
  const { isAdmin, isStudent } = useGetCurrentUserType();
  const { currentUser } = useRootStore();

  const [addStudentOrgCredential] = useMutation(ADD_STUDENT_ORG_CREDENTIAL, {
    refetchQueries: ['GET_ORG_CREDENTIALS'],
    onCompleted: () => {
      toast.success('Credential added successfully');
    }
  });

  const [selectedOrg, setSelectedOrg] = useState<number>(null);
  const handleOrgChange = (orgId: number) => {
    setSelectedOrg(orgId);
  };

  const { data: studentSports, loading: studentSportsLoading } =
    useQuery<GET_SPORTS_BY_STUDENT_ID_TYPE>(GET_SPORTS_BY_STUDENT_ID, {
      variables: {
        student_id: studentId
      }
    });

  const hasBaseSport = useMemo(() => {
    if (!studentSportsLoading && studentSports) {
      return !!studentSports?.getSportsByStudentId.find(
        (sport) => sport.is_active && sport.name === 'BASE'
      );
    }
    return false;
  }, [studentSports, studentSportsLoading]);

  const orgFilter = !isAdmin && !isStudent ? team_id : null;
  const { data: orgData, loading: orgLoading } = useQuery(GET_TEAM_ORGS, {
    variables: { teamId: orgFilter }
  });

  const orgs = useMemo(
    () => {
      let orgFiltered;

      if (!orgLoading && orgData) {
        if (!isAdmin && !isStudent) {
          orgFiltered = orgData.teamOrgs.filter(
            (teamOrg: Types.TeamOrg) => {
              if (Number(teamOrg.org_id) === BASE_ORG_ID) {
                return hasBaseSport;
              }

              return teamOrg.exist;
            }
          );

          if (!selectedOrg) {
            handleOrgChange(Number(orgFiltered[0]?.org_id));
          }
          return orgFiltered;
        }

        orgFiltered = orgData.teamOrgs.filter(
          (teamOrg: Types.TeamOrg) => {
            if (isAdmin || isStudent) return true;

            return teamOrg.exist;
          }
        );

        if (!selectedOrg) {
          handleOrgChange(Number(orgFiltered[0].org_id));
        }
        return orgFiltered;
      }
      return [];
    },
     
    [orgData, orgLoading, isAdmin, isStudent]
  );

  const customFilter = (option, searchText) => {
    if (
      option.data.name.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.slug.toLowerCase().includes(searchText.toLowerCase())
    )
      return true;

    return false;
  };

  const renderForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleSubmit
  }) => {
    return (
      <>
        <Grid.Row>
          <Grid.Col sm={6} md={6}>
            <Form.Group label="Organization">
              <Select
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 })
                }}
                menuPortalTarget={document.body}
                isDisabled={orgs.length === 0}
                name="organization"
                getOptionLabel={(option: Types.TeamOrg) => (
                  <>
                    <span className="mr-1 font-weight-bold">
                      {option.slug.toUpperCase()}
                    </span>
                    {option.name}
                  </>
                )}
                getOptionValue={(option: Types.TeamOrg) =>
                  option.org_id
                }
                filterOption={customFilter}
                options={orgs}
                onChange={(e) => {
                  handleOrgChange(Number(e.org_id));
                }}
                value={
                  orgs.find((org: Types.TeamOrg) => {
                    return Number(org.org_id) === selectedOrg;
                  }) || orgs[0]
                }
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        {selectedOrg !== 6 && (
          <>
            <Grid.Row className="mt-5">
              <Grid.Col xs={12} sm={12} md={6} lg={6}>
                <Form.Group label="Member #">
                  <Form.InputGroup>
                    <Form.Input
                      autoFocus={true}
                      name="membership"
                      placeholder="Enter member number"
                      value={values.membership}
                      onChange={handleChange}
                      disabled={isStudent ? values.id : values.verified_on}
                    />
                  </Form.InputGroup>
                  <span className="field-error text-danger">
                    {errors.membership &&
                      touched.membership &&
                      errors.membership}
                  </span>
                </Form.Group>
              </Grid.Col>
              <Grid.Col xs={12} sm={12} md={6} lg={6}>
                <Form.Group label="Expires">
                  <Form.InputGroup>
                    <Form.Input
                      valid={moment().isBefore(values.expires)}
                      tick={moment().isBefore(values.expires)}
                      invalid={moment().isAfter(values.expires)}
                      cross={moment().isAfter(values.expires)}
                      type="date"
                      name="expires"
                      value={values.expires}
                      onChange={handleChange}
                      disabled={isStudent && values.id}
                    />
                  </Form.InputGroup>
                  <span className="field-error text-danger">
                    {errors.expires && touched.expires && errors.expires}
                  </span>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Button
                  pill
                  size="sm"
                  color="gray-dark"
                  className="ml-2 float-right"
                  onClick={handleSubmit}
                >
                  SAVE
                </Button>
                <Button
                  pill
                  size="sm"
                  color="white"
                  className="ml-2  float-right"
                  onClick={toggle}
                >
                  CANCEL
                </Button>
              </Grid.Col>
            </Grid.Row>
          </>
        )}
      </>
    );
  };
  return (
    <Formik
      validationSchema={manualCredentialsValidationSchema}
      enableReinitialize={true}
      initialValues={{
        org_id: selectedOrg,
        student_id: studentId,
        membership: '',
        expires: '',
        verified_on: null,
        verified_by: null
      }}
      onSubmit={async (values, { setSubmitting }) => {
        values.expires = moment(values.expires).format('YYYY-MM-DD');

        if (!isStudent) {
          values.verified_by = currentUser.id.toString();
          values.verified_on = moment().format('YYYY-MM-DD');
        }

        if (values.membership) {
          await addStudentOrgCredential({
            variables: {
              addOrgCredentialInput: values
            }
          });
        }

        setSubmitting(false);
        toggle();
      }}
    >
      {(formikData) => renderForm(formikData)}
    </Formik>
  );
};

export default RegistrationOrgCredentialForm;
