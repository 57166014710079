import { useMutation, useQuery } from "@apollo/client";
import { observer } from "mobx-react";
import useGetCurrentUserType from "modules/common/hooks/useGetCurrentUserType";
import React, { useMemo, useState } from "react";
import { Button, Card, Icon } from "tabler-react";
import { ADD_STUDENT_TEAM_PROGRESSIONS } from "../../graphql/ADD_STUDENT_TEAM_PROGRESSIONS";
import { GET_STUDENT_TEAM_PROGRESSION } from "../../graphql/GET_STUDENT_TEAM_PROGRESSION";
import { Types } from "../../types/graphql";
import PrintStudentSkillsButton from "./Pdf/PrintStudentSkillsButton";
import StudentTeamProgressionsHeading from "./StudentTeamProgressionHeading";

interface IStudentTeamProgressionsProps {
  studentId: number;
  teamId: number;
  isModal: boolean;
  userRegistrationDetails?: any;
}

const StudentTeamProgressions = ({
  studentId,
  teamId,
  isModal,
  userRegistrationDetails,
}: IStudentTeamProgressionsProps) => {
  const { isAdmin, isCoachSuperAdmin } = useGetCurrentUserType();
  const [activeSkills, setActiveSkills] = useState<string>("");
  const { data: studentTeamProgressionsData, loading } = useQuery(
    GET_STUDENT_TEAM_PROGRESSION,
    {
      variables: {
        teamId: teamId,
        studentId: studentId,
      },
    },
  );

  const studentDetails = useMemo(() => {
    if (userRegistrationDetails) {
      return {
        studentName: `${userRegistrationDetails.first_name} ${userRegistrationDetails.last_name}`,
      };
    }
    return null;
  }, [userRegistrationDetails]);

  const [addStudentTeamProgressions] = useMutation(
    ADD_STUDENT_TEAM_PROGRESSIONS,
  );

  const progressions: { string: Types.GET_STUDENT_TEAM_PROGRESSIONQuery } =
    useMemo(() => {
      if (!loading && studentTeamProgressionsData?.getStudentTeamProgressions) {
        return studentTeamProgressionsData?.getStudentTeamProgressions.reduce(
          function (storage, item) {
            const key = item?.heading;
            storage[key] = storage[key] || [];
            storage[key].push(item);
            return storage;
          },
          {},
        );
      }

      return [];
    }, [studentTeamProgressionsData, loading]);

  const handleCreateProgressions = () => {
    const ids = progressions[activeSkills]
      .map((p: Types.StudentTeamProgression) => {
        if (!p.is_exist) return p.id;

        return null;
      })
      .filter((n) => n);

    if (window.confirm("Add skill set for this student?")) {
      addStudentTeamProgressions({
        variables: {
          addStudentProgressionsInput: {
            student_id: Number(studentId),
            team_id: Number(teamId),
            progression_ids: ids,
            status: 0,
          },
        },
        refetchQueries: [
          {
            query: GET_STUDENT_TEAM_PROGRESSION,
            variables: {
              teamId: Number(teamId),
              studentId: Number(studentId),
            },
          },
        ],
      });
    }
  };

  return (
    <Card statusColor="blue">
      <Card.Header>
        <Card.Title>
          <Icon name="check-circle" className="mr-2 ml-0 text-primary" />
          Skills Progression
        </Card.Title>
        {(!isAdmin || isCoachSuperAdmin) && (
          <Card.Options>
            <Button.List>
              {Object.keys(progressions).map((key) => (
                <Button
                  outline
                  key={"header-" + key}
                  //  icon={progressions[key].filter((p: Types.StudentTeamProgression) => !p.is_exist).length > 0 ? 'plus' : ''}
                  color="primary"
                  size="sm"
                  className="text-uppercase"
                  onClick={() => {
                    if (key === activeSkills) {
                      if (
                        progressions[key].filter(
                          (p: Types.StudentTeamProgression) => !p.is_exist,
                        ).length > 0
                      )
                        handleCreateProgressions();
                    } else setActiveSkills(key);
                  }}
                >
                  {key === activeSkills &&
                  progressions[key].filter(
                    (p: Types.StudentTeamProgression) => !p.is_exist,
                  ).length > 0
                    ? "ADD SKILL SET"
                    : key}{" "}
                  {progressions[key].filter(
                    (p: Types.StudentTeamProgression) => p.is_exist,
                  ).length > 0 && (
                    <span>
                      {(
                        (progressions[key].filter(
                          (p: Types.StudentTeamProgression) =>
                            p.is_exist && p.studentProgressionStatus === 1,
                        ).length /
                          progressions[key].length) *
                        100
                      ).toFixed(0)}
                      %
                    </span>
                  )}
                </Button>
              ))}
              {activeSkills && (
                <Button
                  icon="x"
                  color="white"
                  size="sm"
                  onClick={() => {
                    setActiveSkills("");
                  }}
                />
              )}
            </Button.List>
          </Card.Options>
        )}
      </Card.Header>
      <Card.Body>
        {Object.keys(progressions).map(
          (key) =>
            activeSkills === key && (
              <StudentTeamProgressionsHeading
                heading={key}
                studentProgressHeading={progressions[key]}
                key={key}
                studentId={studentId}
              />
            ),
        )}
      </Card.Body>
      <Card.Footer>
        {activeSkills && isModal && (
          <PrintStudentSkillsButton
            heading={activeSkills}
            studentId={studentId}
            progression={progressions[activeSkills]}
            studentDetails={studentDetails}
          />
        )}
      </Card.Footer>
    </Card>
  );
};

export default observer(StudentTeamProgressions);
