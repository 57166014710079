import gql from 'graphql-tag'

export const GET_TEAMS = gql`
  query GET_TEAMS($filter: TeamFilter) {
    teams(filter: $filter) {
      id
      name
      coach_business_type
      default_account_type_id
      default_currency
      default_location_id
      default_location_name
      feature_accounting
      feature_alpha
      feature_app_reports
      feature_booking_calendar
      feature_camps
      feature_cancel_transactions
      feature_coach_account
      feature_crm
      feature_dashboard
      feature_dashboard_groups
      feature_disable_students
      feature_dzm_account
      feature_edit_transactions
      feature_locked_account_actions
      feature_manifest
      feature_open_add_students
      feature_paid_status
      feature_payments
      feature_products
      feature_recurring_transactions
      feature_registrations
      feature_require_uspa
      feature_rigger_account
      feature_schedule_open
      feature_slot_registrations
      feature_student_progression
      feature_transfer_time
      is_public
      is_published
      payment_fee_customer
      payment_fee_flat
      payment_fee_percentage
      paypal_me
      preference_units
      slug
      stripe_account_id
      student_contact_required
      team_bio
      team_coach_hour_fee
      team_email
      team_favicon
      team_logo_header
      team_logo_square_image
      team_logo_wide_image
      team_phone
      team_phone_country_code
      team_promo_image
      team_url
      feature_invoices
      feature_waivers

      locations {
        name
      }

      registration_requirements {
        id
        name
        description
        requirement_type_id
        team_id
        location_id
        location_name
      }

      students {
        id
        first_name
        last_name
      }
      sports {
        id
        slug
        name
        is_active
      }
    }

    feeTypes {
      id
      name
    }

    getRegistrationOptionTypes {
      id
      slug
      name
    }
  }
`
