import { useMutation, useQuery } from "@apollo/client";
import { observer } from "mobx-react";
import { applySnapshot } from "mobx-state-tree";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "tabler-react";
import useReactRouter from "use-react-router";

import { EventStoreContext } from "../../../contexts/eventStoreContext";
import { ADD_CAMP } from "../../../graphql/ADD_CAMP";
import { GET_CALENDAR_EVENT_ENTRY_QUERIES } from "../../../graphql/GET_CALENDAR_EVENT_ENTRY_QUERIES";
import { GET_CAMPS } from "../../../graphql/GET_CAMPS";
import { GET_CAMPS as GET_CAMPS_TYPE } from "../../../graphql/types/GET_CAMPS";
import { useRootStore } from "../../../hooks/useRootStore";
import { EInputTypes } from "../../FormField";
import Modal from "../../Modal";
import EventActivityLocationForm from "./EventActivityLocationForm";
import EventEntryForm from "./EventEntryForm";
import EventRegistrationRequiredForm from "./EventRegistrationRequiredForm";
import EventTypesForm from "./EventTypesForm";

interface ICalendarEventEntryProps {
  isModalOpen: boolean;
  toggleModal: () => void;
}

const TOTAL_STEPS = 4;

const EventEntryModal = ({
  isModalOpen,
  toggleModal,
}: ICalendarEventEntryProps) => {
  const eventStore = useContext(EventStoreContext);
  const { eventEntry, eventEntryActivities, eventTypes } = eventStore;

  const formRef = useRef(null);
  const { currentCoachTeam, currentUser } = useRootStore();
  const { history } = useReactRouter();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [
    addCamp,
    { loading: addCampLoading, error: addCampError, data: addCampData },
  ] = useMutation(ADD_CAMP, {
    onCompleted: () => toast.success("Event Added."),
  });

  const { loading, error, data } = useQuery(GET_CALENDAR_EVENT_ENTRY_QUERIES, {
    variables: {
      team_id: currentCoachTeam?.id.toString() || "",
    },
  });

  useEffect(() => {
    if (!loading && !error && data) {
      const { events, teamSports } = data;

      applySnapshot(eventTypes, events);
      applySnapshot(eventEntryActivities, teamSports);
    }
  }, [data]);

  useEffect(() => {
    if (!addCampLoading && !addCampError && addCampData) {
      eventStore.addEvent(addCampData.addCamp);
      history.push(`/user/calendar/${addCampData.addCamp.id}`);
    }
  }, [addCampData]);

  if (loading) {
    return null;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const mstHandleChange = (e) => {
    const target = e.target;

    let value: string | number;

    switch (target.type) {
      case EInputTypes.checkbox:
        value = target.checked;
        break;

      case EInputTypes.radio:
        value =
          target.name !== "status" ? parseInt(target.value) : target.value;
        break;

      default:
        value = target.value;
        break;
    }

    eventEntry.set(target.name, value);
  };

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  const handleNext = () =>
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleSubmit = async () => {
    if (!formRef.current.reportValidity()) {
      return;
    }

    const { end_date, end_time, start_date, start_time, ...rest } = eventEntry;

    const start = moment(
      `${eventEntry.start_date} ${eventEntry.start_time}`,
    ).format(`YYYY-MM-DD HH:mm:ss`);

    const end = moment(`${eventEntry.end_date} ${eventEntry.end_time}`).format(
      `YYYY-MM-DD HH:mm:ss`,
    );

    await addCamp({
      update: (cache, { data: addCamp }) => {
        const campsQuery = {
          query: GET_CAMPS,
          variables: {
            filter: {
              isDateAgnostic: true,
              team_id: currentCoachTeam.id.toString(),
            },
          },
        };

        const { getCamps } = cache.readQuery<GET_CAMPS_TYPE>(campsQuery);

        return cache.writeQuery({
          data: {
            getCamps: { ...getCamps, addCamp },
          },
          ...campsQuery,
        });
      },
      variables: {
        newCamp: {
          ...rest,
          created_by: currentUser.id,
          end,
          is_public: false,
          start,
          team_id: currentCoachTeam.id,
        },
      },
    });
  };

  const handleToggleModal = () => {
    setActiveStep(0);
    eventStore.resetEventEntry();
    toggleModal();
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <EventTypesForm
            eventTypes={eventTypes}
            handleNext={handleNext}
            mstHandleChange={mstHandleChange}
          />
        );
      case 1:
        return (
          <EventActivityLocationForm
            currentCoachTeam={currentCoachTeam}
            eventEntryActivities={eventEntryActivities}
            handleNext={handleNext}
            mstHandleChange={mstHandleChange}
          />
        );
      case 2:
        return (
          <EventRegistrationRequiredForm mstHandleChange={mstHandleChange} />
        );
      case 3:
        return (
          <EventEntryForm
            mstHandleChange={mstHandleChange}
            errorMessage={errorMessage}
          />
        );
      default:
        return "Unknown step";
    }
  };

  const lastStep: boolean = activeStep === TOTAL_STEPS - 1;
  const disableNextNav = !eventEntry.event_type_id || !eventEntry.location_id;

  return (
    <Modal
      actions={
        <>
          <Button.List align="left">
            <Button
              size="sm"
              color="white"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              BACK
            </Button>
          </Button.List>
          <Button
            size="sm"
            color={lastStep ? "gray-dark" : "primary"}
            disabled={disableNextNav}
            form="addCamp"
            onClick={(e) => {
              e.preventDefault();

              if (lastStep) {
                if (
                  moment(eventEntry.end_date).isBefore(
                    moment(eventEntry.start_date),
                  )
                ) {
                  setErrorMessage("Start date must be earlier than end date");
                  return;
                }
                handleSubmit();
              } else {
                handleNext();
              }
            }}
          >
            {lastStep ? "ADD EVENT" : "NEXT >>"}
          </Button>
        </>
      }
      content={
        <form id="addCamp" ref={formRef}>
          {getStepContent(activeStep)}
        </form>
      }
      open={isModalOpen}
      title="Add Event"
      onClose={handleToggleModal}
    />
  );
};

export default observer(EventEntryModal);
