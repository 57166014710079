import React from 'react';

import Wrapper from 'pages/layout';

const JumpManifestPage = (props) => {
  return (
    <Wrapper {...props} title="DZM - Manifest">
      <div className="container">Public Manifest</div>
    </Wrapper>
  );
};

export default JumpManifestPage;
