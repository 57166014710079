import { useMutation, useQuery } from '@apollo/client'
import { CREATE_CAMP_REGISTRATION_OPTION } from 'graphql/CREATE_CAMP_REGISTRATION_OPTION'
import { DELETE_CAMP_REGISTRATION_OPTION } from 'graphql/DELETE_CAMP_REGISTRATION_OPTION'
import { GET_CAMP_REGISTRATION_OPTIONS } from 'graphql/GET_CAMP_REGISTRATION_OPTIONS'
import { useRootStore } from 'hooks'
import React from 'react'
import { toast } from 'react-toastify'
import { Card, Form, Grid, Text } from 'tabler-react'
import { formatMoney } from 'utils/numberFormat'
import { Types } from '../../../types/graphql'

interface ICalendarEventRegistrationsProp {
  event: Types.Camp
}

const CalendarEventRegistrations = ({
  event
}: ICalendarEventRegistrationsProp) => {
  const { currentCoachTeam } = useRootStore()
  const { data, loading } = useQuery(GET_CAMP_REGISTRATION_OPTIONS, {
    variables: {
      campId: Number(event.id),
      teamId: currentCoachTeam?.id
    }
  })

  const [addCampRegistrationOptions] = useMutation(
    CREATE_CAMP_REGISTRATION_OPTION,
    {
      onCompleted: (result) => {
        if (result.addCampRegistrationOptions) {
          toast.success('Registration option added to camp')
        } else {
          toast.error('Something went wrong while trying to add option.')
        }
      }
    }
  )

  const [deleteCampRegistrationOption] = useMutation(
    DELETE_CAMP_REGISTRATION_OPTION,
    {
      onCompleted: (result) => {
        if (result.deleteCampRegistrationOption) {
          toast.success('Registration option deleted')
        } else {
          toast.error('Something went wrong while trying to delete option.')
        }
      }
    }
  )

  const optionToggle = (option, isChecked) => {
    if (isChecked) {
      addCampRegistrationOptions({
        variables: {
          campRegistrationOption: {
            camp_id: Number(event.id),
            registration_option_id: option.id
          }
        },
        refetchQueries: [
          {
            query: GET_CAMP_REGISTRATION_OPTIONS,
            variables: {
              campId: Number(event.id),
              teamId: currentCoachTeam?.id
            }
          }
        ]
      })
    } else {
      if (
        window.confirm(
          'Are you sure you want to delete this option: ' + option.name
        )
      ) {
        deleteCampRegistrationOption({
          variables: {
            campId: Number(event.id),
            registrationOptionId: option.id
          },
          refetchQueries: [
            {
              query: GET_CAMP_REGISTRATION_OPTIONS,
              variables: {
                campId: Number(event.id),
                teamId: currentCoachTeam?.id
              }
            }
          ]
        })
      }
    }
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Options</Card.Title>
      </Card.Header>
      <Card.Body>
        {!loading &&
          data?.getAvailableCampRegistrationOptions.map((option) => {
            return (
              <Grid.Row key={option.id}>
                <Grid.Col>
                  <Form.Checkbox
                    name="status"
                    label={
                      <>
                        <p className="mb-0">{option.name}</p>
                        <Text.Small className="text-muted">
                          {formatMoney(Number(option.option_value))}{' '}
                          {option.fee_type_name}
                        </Text.Small>
                      </>
                    }
                    checked={option.is_exist}
                    onChange={(e) => {
                      optionToggle(option, e.target.checked)
                    }}
                  />
                </Grid.Col>
              </Grid.Row>
            )
          })}
      </Card.Body>
    </Card>
  )
}

export default CalendarEventRegistrations
