import { Formik } from 'formik';
import Modal from '../../Modal';
import React from 'react';
import { Button, Form, Grid } from 'tabler-react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { UPDATE_STUDENT_MUTATION } from '../../../graphql/UPDATE_STUDENT_MUTATION';
import { GET_USER_REGISTRATIONS_BY_IDS } from '../../../graphql/GET_USER_REGISTRATIONS_BY_IDS';
import PhoneInput from 'react-phone-input-2';
import { GET_EMAIL_VALIDATION } from '../../../graphql/GET_EMAIL_VALIDATION';
import { GET_PHONE_VALIDATION } from '../../../graphql/GET_PHONE_VALIDATION';

interface IRegistrationStudentDetailContactModalProps {
  isModalOpen: boolean;
  toggleModal: any;
  student: any;
}

const RegistrationStudentDetailContactModal = ({
  isModalOpen,
  toggleModal,
  student
}: IRegistrationStudentDetailContactModalProps) => {
  const [updateStudent] = useMutation(UPDATE_STUDENT_MUTATION);

  const [validateEmail, { data: emailData }] = useLazyQuery(
    GET_EMAIL_VALIDATION,
    { fetchPolicy: 'network-only' }
  );
  const [validatePhone, { data: phoneData }] = useLazyQuery(
    GET_PHONE_VALIDATION,
    { fetchPolicy: 'network-only' }
  );

  const HandleValidateEmail = async (email) => {
    validateEmail({
      variables: { email }
    });
  };

  const HandleValidatePhone = async (phone) => {
    validatePhone({
      variables: { phone }
    });
  };

  const renderForm = ({
    values,
    handleSubmit,
    isSubmitting,
    setFieldValue
  }) => (
    <form onSubmit={handleSubmit}>
      <Grid.Row className="mb-4">
        <Grid.Col xs={12} sm={12} lg={6}>
          <Form.Group label="Phone">
            <PhoneInput
              inputProps={{
                className: 'form-control w-100',
                name: 'phone',
                onChange: (e) => {
                  e.preventDefault();
                  const target = e.target;

                  const phone = target.value.split(' ');
                  const countryCode = phone.shift().replace(/\D/g, '');

                  setFieldValue(
                    'phone_number',
                    phone.join('').replace(/\D/g, '')
                  );
                  setFieldValue('phone_country_code', Number(countryCode));
                },
                onBlur: async (e) => {
                  e.preventDefault();
                  await HandleValidatePhone(
                    e.target.value.split(' ').join('').replace(/\D/g, '')
                  );
                }
              }}
              value={`${values.phone_country_code}${values.phone_number}`}
            />
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="mb-4">
        <Grid.Col xs={12} sm={12} lg={6}>
          <Form.Input
            type={'email'}
            label={'Email'}
            name={'email'}
            value={values.email}
            onChange={(e) => {
              setFieldValue('email', e.target.value);
            }}
            onBlur={async (e) => {
              await HandleValidateEmail(e.target.value);
            }}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col className="pt-2" xs={12} sm={12} lg={12}>
          <Button
            type="submit"
            disabled={isSubmitting}
            pill
            className="btn-gray-dark float-right btn-sm"
          >
            {isSubmitting ? 'Processing...' : 'SUBMIT'}
          </Button>
        </Grid.Col>
      </Grid.Row>
    </form>
  );
  return (
    <Modal
      content={
        <Formik
          enableReinitialize={true}
          initialValues={{
            phone_number: student?.phone_number ?? '',
            phone_country_code: student?.phone_country_code ?? '',
            email: student?.email ?? ''
          }}
          onSubmit={async (
            values,
            { resetForm, setSubmitting, setFieldError }
          ) => {
            let errorFound = false;

            if (emailData?.validateEmail) {
              setFieldError('email', 'Email already exists. Contact SKYCRU.');
              errorFound = true;
            }

            if (phoneData?.validatePhone) {
              setFieldError('phone_number', 'Phone number already exists. Contact SKYCRU.');
              errorFound = true;
            }

            if (errorFound) {
              setSubmitting(false);
              return false;
            }

            updateStudent({
              variables: {
                student: {
                  id: student?.student_id,
                  phone_number: values?.phone_number,
                  phone_country_code: values.phone_country_code,
                  email: values.email
                }
              },
              refetchQueries: [
                {
                  query: GET_USER_REGISTRATIONS_BY_IDS,
                  variables: {
                    userRegistrationsId: Number(student?.user_registrations_id)
                  }
                }
              ]
            });
            toggleModal(!isModalOpen);
            setSubmitting(false);
            resetForm();
          }}
        >
          {(formikData) => renderForm(formikData)}
        </Formik>
      }
      open={isModalOpen}
      title={`${student.first_name} ${student.last_name}`}
      onClose={() => toggleModal(!isModalOpen)}
    />
  );
};

export default RegistrationStudentDetailContactModal;
