import { useQuery } from '@apollo/client';
import FullCalendar, { DateSelectArg, EventChangeArg, EventClickArg, EventContentArg } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { observer } from 'mobx-react';
import { applySnapshot } from 'mobx-state-tree';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { Dimmer, Icon } from 'tabler-react';
import useReactRouter from 'use-react-router';

import { EUserTypes } from '../User/userTypes';
import { EventStoreContext } from '../../contexts/eventStoreContext';
import { GET_CAMPS } from '../../graphql/GET_CAMPS';
import { GET_CAMPS as GET_CAMPS_TYPE } from '../../graphql/types/GET_CAMPS';
import { useRootStore } from '../../hooks/useRootStore';

interface ICalendarViewProps {
  toggleModal: () => void;
}

const CalendarView = ({ toggleModal }: ICalendarViewProps) => {
  const eventStore = useContext(EventStoreContext);
  const { events } = eventStore;

  const { currentCoachTeam, currentUser } = useRootStore();
  const { history } = useReactRouter();

  const canAddEvent: boolean = [
    EUserTypes.coach.toString(),
    EUserTypes.events.toString(),
    EUserTypes.dzm.toString()
  ].includes(currentUser?.type);

  let filterOptions: object = { isDateAgnostic: true };

  if (currentCoachTeam) {
    filterOptions = {
      ...filterOptions,
      team_id: currentCoachTeam?.id.toString()
    };
  }

  if (currentUser?.type === EUserTypes.student) {
    filterOptions = {
      ...filterOptions,
      isPublic: true,
      isPublished: true
    };
  }

  const { loading, error, data } = useQuery(GET_CAMPS, {
    variables: { filter: { ...filterOptions } }
  });

  useEffect(() => {
    eventStore.resetEventEntry();

    if (!loading && data) {
      const modifiedEvents = data.getCamps.map((camp) =>
        Object.assign(
          {},
          {
            ...camp,
            title: camp.camp_name,
            allDay: camp.all_day,
            end: camp.all_day ? moment(camp.end).add(1, 'd').format() : camp.end
          }
        )
      );

      applySnapshot(events, modifiedEvents);
    }
  }, [data]);  

  if (error) {
    return <p>{`Error: ${error.message}`}</p>;
  }

  function handleDateSelect(selectInfo: DateSelectArg) {
    if (!canAddEvent) {
      return null;
    }

    eventStore.initEventEntry(selectInfo);
    toggleModal();
  }

  const handleEventClick = (clickInfo: EventClickArg) => {
    history.push(`/user/calendar/${clickInfo.event.id}`);
  };

  const handleEventChange = (changeInfo: EventChangeArg) => {
    eventStore.changeEvent(changeInfo);
  };

  const renderEventContent = (eventContent: EventContentArg) => {
    const { event } = eventContent;

    return (
      <div
      className={`${event.extendedProps.is_published ? 'cursor-pointer pl-1 text-white' : 'cursor-pointer pl-1 bg-light text-primary'} `}
      >
        {event.title}
        <Icon className="ml-2" name={`${event.extendedProps.is_public ? 'calendar' : 'users'} `} />
      </div>
    );
  };

  return (
    <Dimmer active={loading} loader={loading}>
      <FullCalendar
        dayMaxEvents={true}
        events={events.slice()}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        }}
        initialView="dayGridMonth"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        selectable={true}
        selectMirror={true}
        select={handleDateSelect}
        eventContent={renderEventContent}
        eventClick={handleEventClick}
        eventChange={handleEventChange}
      />
    </Dimmer>
  );
};

export default observer(CalendarView);
