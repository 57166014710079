import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Button, Form, Grid } from 'tabler-react';
import Modal from '../../Modal';
import React from 'react';
import { observer } from 'mobx-react';
import { useMutation } from '@apollo/client';
import { UPDATE_TRANSACTION } from '../../../graphql/UPDATE_TRANSACTION';
import { accountTransactionTypes } from '../../constants';
import Select from 'react-select';
import { DEFAULT_LIMIT } from '../TransactionList';
import { GET_TRANSACTIONS } from '../../../graphql/GET_TRANSACTIONS';
import { GET_TRANSACTIONS as GET_TRANSACTIONS_TYPE } from '../../../graphql/types/GET_TRANSACTIONS';
import { useRootStore } from '../../../hooks';
import moment from 'moment';

interface IEditTransactionFormProps {
  isModalOpen: boolean;
  toggleModal: any;
  transaction: any; //GET_TRANSACTIONS_getTransactions_transactions;
}

const EditTransactionForm = ({ isModalOpen, toggleModal, transaction }: IEditTransactionFormProps) => {
  const rootStore = useRootStore();
  const { currentUser } = rootStore;

  const [updateTransaction] = useMutation(UPDATE_TRANSACTION, {
    refetchQueries: ['GET_STUDENT_TIME_TRANSACTION_HISTORY']
  });
  const handleClose = () => {
    toggleModal(false);
  };
  const renderForm = ({
    values,
    handleSubmit,
    isSubmitting,
    handleChange,
    setFieldValue,
    errors
  }) => (
    <form onSubmit={handleSubmit}>
      <Grid.Row className="mb-4">
        <Grid.Col xs={12} sm={12} lg={12}>
          <Form.Group label="">
            <Select
              name="transaction_type_id"
              placeholder="Select transaction type..."
              isMulti={false}
              getOptionValue={(option) => option.id}
              options={accountTransactionTypes[transaction.timeAdded]}
              onChange={(param) =>
                setFieldValue('transaction_type_id', param.id)
              }
              value={accountTransactionTypes[transaction.timeAdded].filter(
                (transactionType) =>
                  transactionType.id === values.transaction_type_id
              )}
            />
            {errors.transaction_type_id && (
              <span className="field-error text-danger">
                {errors.transaction_type_id}
              </span>
            )}
          </Form.Group>
        </Grid.Col>
        <Grid.Col xs={12} sm={12} lg={12}>
          <Form.Group label="">
            <Form.Textarea
              //  className={errors.note && 'is-invalid'}
              name="notes"
              placeholder="Add notes"
              rows={3}
              type="text"
              onChange={handleChange}
              value={values.notes}
            />
            {errors.notes && (
              <span className="field-error text-danger">{errors.notes}</span>
            )}
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="mb-5"></Grid.Row>

      <Button
        disabled={isSubmitting}
        pill
        className="btn-gray-dark float-right btn-sm"
        onClick={handleSubmit}
      >
        {isSubmitting ? 'Processing...' : 'UPDATE'}
      </Button>
      <Button
        pill
        color="danger"
        className="btn-sm"
        onClick={(e) => {
          e.preventDefault();
          setFieldValue('transaction_type_id', 6);
          handleSubmit();
        }}
      >
        VOID
      </Button>
    </form>
  );
  return (<Modal
    content={
      <Formik
        enableReinitialize={true}
        initialValues={{
          id: transaction?.id ?? null,
          notes: transaction?.notes ?? '',
          transaction_type_id: transaction?.transactionTypeId ?? undefined

        }}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          const canceled_options = {
            canceled_by: undefined,
            canceled_on: undefined
          };
          if (values.transaction_type_id === 6) {
            canceled_options.canceled_by = currentUser.id;
            canceled_options.canceled_on = moment();
          }
          await updateTransaction({
            update: (cache, { data: { updateTransaction } }) => {
              const { getTransactions } = cache.readQuery<GET_TRANSACTIONS_TYPE>({
                query: GET_TRANSACTIONS,
                variables: {
                  variables: {
                    limit: DEFAULT_LIMIT,
                    page: 1,
                    filter: {}
                  }
                }
              });

              return cache.writeQuery({
                query: GET_TRANSACTIONS,
                data: {
                  // XXX: This is totally broken and shouldn't work
                  // @ts-expect-error: Not good
                  getTransactions: [updateTransaction, ...getTransactions]
                }
              });
            },
            variables: {
              updateTransactionInput: {
                id: parseInt(values.id),
                notes: values.notes,
                transaction_type_id: values.transaction_type_id,
                ...canceled_options
              }
            }
          });
          setSubmitting(false);
          resetForm();
          toast.success("Transaction updated!");
          handleClose();

        }}
      >
        {(formikData) => renderForm(formikData)}
      </Formik>
    }
    open={isModalOpen}
    onClose={() => toggleModal(!isModalOpen)}
    title={`Transaction ID: ${transaction.id}`}
  />);
};

export default observer(EditTransactionForm);
