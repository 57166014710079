import React, { FC } from 'react';
import { formatAccountBalance } from 'utils/formatAccountBalance';

type AccountBalanceProps = {
  accountBalance: number;
  slug: string;
};

const AccountBalance: FC<AccountBalanceProps> = ({ accountBalance, slug }) => {
  return (
    <span
      className={`${
        accountBalance === 0
          ? 'text-secondary'
          : accountBalance < 0
          ? 'text-danger'
          : 'text-success'
      } font-weight-bold mb-0`}
    >
      {formatAccountBalance(accountBalance, slug)}
    </span>
  );
};

export default AccountBalance;
