import { usePDF } from "@react-pdf/renderer";
import React, { useEffect } from "react";
import { Button } from "tabler-react";
import { Types } from "../../../types/graphql";
import skillsPdf from "./SkillsPdf";

interface PrintStudentSkillsButtonProps {
  progression: Types.StudentTeamProgression[];
  studentId: number;
  heading: string;
  studentDetails: any;
}

const PrintStudentSkillsButton = ({
  progression,
  studentId,
  heading,
  studentDetails,
}: PrintStudentSkillsButtonProps) => {
  const document = skillsPdf({ progression, heading, studentDetails });
  const [instance, updateInstance] = usePDF({ document });
  useEffect(() => {
    updateInstance(document);
  }, [progression, heading]);
  return (
    <a
      href={instance.url}
      download={`Student_skill_progression ${studentId}.pdf`}
      key={studentId}
      className="d-print-none"
    >
      <Button icon="download" color="primary" size="md">
        PDF
      </Button>
    </a>
  );
};

export default PrintStudentSkillsButton;
