export enum EUserTypes {
  admin = 'admin',
  coach = 'coach',
  dzm = 'dzm',
  events = 'events',
  manufacturer = 'manufacturer',
  org = 'org',
  rigger = 'rigger',
  student = 'student',
  wtm = 'wtm'
}
