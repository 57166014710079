import { IconQrcode } from "@tabler/icons-react";
import Modal from "components/Modal";
import moment from "moment/moment";
import React, { useState } from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router";
import { Avatar, Button, Text } from "tabler-react";
import { Types } from "types/graphql";
import { Loading } from "../../../../../components/Loading";

interface QRCodeUserRegistrationProps {
  userRegistration: Types.UserRegistrationsById;
}

const QRCodeUserRegistration = ({
  userRegistration,
}: QRCodeUserRegistrationProps) => {
  const { userRegId, studentId } = useParams<{
    userRegId: string;
    studentId: string;
  }>();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  if (!userRegistration) return <Loading />;
  return (
    <>
      <Button
        block
        color="primary"
        className="d-flex justify-content-center mb-5"
        onClick={toggleModal}
      >
        <IconQrcode className="mr-2" /> QR Code
      </Button>
      <Modal
        title="SCAN QR Code"
        content={
          <div className="text-center">
            <h3>{userRegistration.camp_name}</h3>
            <QRCode
              value={`https://portal.skycru.com/events/registrations/${userRegId}/${studentId}`}
            />
            <div>
              <Avatar
                className="avatar"
                imageURL={
                  (userRegistration.profile_avatar &&
                    `/api/s3/uploads/${userRegistration.profile_avatar}`) ||
                  "//www.gravatar.com/avatar?d=mp"
                }
                size="xxl"
              />
            </div>
            <h2 className="mt-5">
              {userRegistration.first_name} {userRegistration.last_name}
            </h2>
            <Text>SKY.D: {studentId}</Text>
            <Text>Registration code: {userRegistration.registration_code}</Text>
            <Text>
              {moment(userRegistration?.start).format("MMM DD")} -{" "}
              {moment(userRegistration?.end).format("MMM DD")}
            </Text>
          </div>
        }
        open={isModalOpen}
        onClose={toggleModal}
      />
    </>
  );
};

export default QRCodeUserRegistration;
