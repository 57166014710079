import React from 'react';
import Modal from '../../Modal';
import { toast } from 'react-toastify';
import StudentSearchField from '../../Students/Forms/StudentSearchField';
import { Button, Card, Form, Grid } from 'tabler-react';
import { useMutation } from '@apollo/client';
import { ADD_STUDENT_TO_GROUP } from '../../../graphql/ADD_STUDENT_TO_GROUP';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { RegistrationGroupRoles } from '../../constants';
import { Types } from '../../../types/graphql'

interface AddStudentToRegistrationGroupModalProps {
  group: Types.Group;
  isOpen: boolean;
  toggle: () => void;
}

const AddStudentToRegistrationGroupValidationSchema = Yup.object().shape({
  student_id: Yup.number().required('This field is required.'),
  role_id: Yup.number().required('This field is required.')
});
const AddStudentToRegistrationGroupModal = ({
  group,
  isOpen,
  toggle
}: AddStudentToRegistrationGroupModalProps) => {
  const [addStudentToGroup] = useMutation(ADD_STUDENT_TO_GROUP, {
    onCompleted: () => {
      toast.success('User added to group');
      toggle();
    },
    refetchQueries: ['GET_GROUPS']
  });

  const renderForm = ({
    values,
    setFieldValue,
    handleSubmit,
    errors,
    touched
  }) => {
    return (
      <Card>
        <Card.Body>
          <Grid.Row>
            <Grid.Col>
              <Form.Group>
                <StudentSearchField
                  autoFocus={true}
                  onChange={(param) => {
                    if (!param) return;

                    if (
                      group.members.find((student) => student.id === param.id)
                    ) {
                      toast.error(
                        'Participant is already a member of this group'
                      );
                      return;
                    }
                    setFieldValue('student_id', Number(param.id));
                  }}
                  defaultValue={''}
                />
                <span className="field-error text-danger">
                  {errors.student_id && touched.student_id && errors.student_id}
                </span>
              </Form.Group>
            </Grid.Col>

            <Grid.Col>
              <Form.Group>
                <Select
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  options={RegistrationGroupRoles}
                  name="role_id"
                  onChange={(option) => {
                    setFieldValue('role_id', option.value);
                  }}
                  value={RegistrationGroupRoles.find(
                    (i) => i.value === values.role_id
                  )}
                />
                <span className="field-error text-danger">
                  {errors.role_id && touched.role_id && errors.role_id}
                </span>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
        <Card.Footer>
          <Button
            pill
            size={'small'}
            color={'gray-dark'}
            className={'float-right'}
            onClick={handleSubmit}
          >
            SUBMIT
          </Button>
          <Button
            pill
            size={'small'}
            color={'white'}
            className={'float-right'}
            onClick={toggle}
          >
            CANCEL
          </Button>
        </Card.Footer>
      </Card>
    );
  };
  return (
    <Modal
      open={isOpen}
      onClose={toggle}
      title={'Add Student to Group'}
      content={
        <Formik
          initialValues={{
            group_id: group.id,
            team_id: group.team_id,
            role_id: null,
            student_id: null
          }}
          validationSchema={AddStudentToRegistrationGroupValidationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            addStudentToGroup({
              variables: {
                addGroupAssociatedUsersInput: {
                  ...values
                }
              }
            });

            setSubmitting(false);
            resetForm();
          }}
        >
          {(formData) => renderForm(formData)}
        </Formik>
      }
    />
  );
};

export default AddStudentToRegistrationGroupModal;
