import gql from 'graphql-tag';

export const CREATE_REGISTRATION_OPTION = gql`
  mutation CREATE_REGISTRATION_OPTION(
    $name: String!
    $description: String!
    $team_id: ID!
    $option_value: String
    $option_type_id: ID!
    $fee_type_id: ID
    $location_id: ID
  ) {
    createNewRegistrationOption(
      registrationOption: {
        name: $name
        description: $description
        team_id: $team_id
        option_value: $option_value
        option_type_id: $option_type_id
        fee_type_id: $fee_type_id
        location_id: $location_id
      }
    ) {
      description
      fee_type_id
      name
      id
      option_type_id
      option_value
      team_id
    }
  }
`;
