import { Document, Page, Text as PDFText, View } from '@react-pdf/renderer';
import React from 'react';
import moment from 'moment';
import { formatPhoneNumber } from 'utils/phoneFormat';
import { formatMoney } from 'utils/numberFormat';
import styles from 'utils/pdfStyles';

interface IInvoicePDFprops {
  invoiceId: number,
  invoiceItems: any,//Types.Job[],
  pdfDetails: {
    company_name: string;
    company_phone: string;
    company_email: string;
    customer_name: string;
    customer_phone: string;
    customer_email: string;
    created_on: string;
    invoice_type: number;
    invoice_terms: string;
  },
  total: number
}

const InvoicePDF = (
  {
    invoiceId,
    invoiceItems,
    pdfDetails,
    total
  }: IInvoicePDFprops
) => {
  return (
    <Document>
      <Page style={styles.body}>
        <View
          style={{
            flexDirection: 'column',
            gap: 30
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <View>
              <PDFText>{pdfDetails.company_name}</PDFText>
              <PDFText style={styles.textSmall}>
                {pdfDetails.company_phone}
              </PDFText>
              <PDFText style={styles.textSmall}>
                {pdfDetails.company_email}
              </PDFText>
            </View>
            <View
              style={{
                textAlign: 'right'
              }}
            >
              <PDFText>{pdfDetails.customer_name}</PDFText>
              <PDFText style={styles.textSmall}>
                {pdfDetails?.customer_phone &&
                  formatPhoneNumber(`${pdfDetails.customer_phone.toString()}`)}
              </PDFText>
              <PDFText style={styles.textSmall}>
                {pdfDetails.customer_email}
              </PDFText>
            </View>
          </View>

          <View>
            <PDFText
              style={styles.title}>{`${pdfDetails.invoice_type === 1 ? 'INV: ' + invoiceId : 'ESTIMATE'}`}</PDFText>
            <PDFText style={styles.textSmall}>
              {moment(pdfDetails.created_on).format('D MMM, YYYY')}
            </PDFText>
          </View>

          <View style={styles.table}>
            <View
              style={styles.tableRow}
            >
              <PDFText style={{ ...styles.tableCol, width: "40%" }}>PRODUCT/SERVICE</PDFText>
              <PDFText style={{ ...styles.tableCol, textAlign: 'right' }}>UNIT</PDFText>
              <PDFText style={{ ...styles.tableCol, textAlign: 'center' }}>QTY</PDFText>
              <PDFText style={{ ...styles.tableCol, textAlign: 'right' }}>AMOUNT</PDFText>
            </View>
            {invoiceItems.map((item) => {
              if (item.job_id) {
                return <View
                  key={'job' + item.job.id}
                  style={styles.tableRow}
                >
                  <View
                    style={{ ...styles.tableCol, width: "40%" }}
                  >
                    <PDFText style={styles.jobName}><strong>{item.job.name}</strong></PDFText>
                    <PDFText style={styles.textSmall}>
                      Intake: {moment(item.job.created_on).format('D MMM, yyyy')}
                      {item.job?.user_gear?.gear_model?.name && (' | ' + (item.job?.user_gear?.gear_model?.name))}{' '}
                      {item.job?.user_gear?.gear_canopy_size > 0 && item.job?.user_gear?.gear_canopy_size}
                      {item.job?.user_gear?.gear_serial && (
                        <> | S/N: {item.job?.user_gear?.gear_serial}</>
                      )}
                      {item.job?.user_gear?.gear_dom && (
                        <>
                          {' | D.O.M. '}{' '}
                          {moment(item.job?.user_gear?.gear_dom).format('MMM yyyy')}
                        </>
                      )}
                    </PDFText>
                    <PDFText style={styles.textSmall}>{item.job.job_notes}</PDFText>
                  </View>
                  <PDFText style={{ ...styles.tableCol, textAlign: 'right', ...styles.textSmall }}>
                    {formatMoney(item.job.unit_price)}
                  </PDFText>
                  <PDFText style={{ ...styles.tableCol, textAlign: 'center', ...styles.textSmall }}>
                    {item.job.quantity}
                  </PDFText>
                  <PDFText style={{ ...styles.tableCol, textAlign: 'right', ...styles.textSmall }}>
                    {formatMoney(item.job.unit_price * item.job.quantity)}
                  </PDFText>
                </View>;
              } else {
                return <View
                  key={'prodyct' + item.product.id}
                  style={styles.tableRow}
                >
                  <View
                    style={{ ...styles.tableCol, width: "40%" }}
                  >
                    <PDFText style={styles.jobName}><strong>{item.product.title}</strong></PDFText>
                    <PDFText style={styles.textSmall}>
                    </PDFText>
                  </View>
                  <PDFText style={{ ...styles.tableCol, textAlign: 'right', ...styles.textSmall }}>
                    {formatMoney(item.product.amount)}
                  </PDFText>
                  <PDFText style={{ ...styles.tableCol, textAlign: 'center', ...styles.textSmall }}>
                    {1}
                  </PDFText>
                  <PDFText style={{ ...styles.tableCol, textAlign: 'right', ...styles.textSmall }}>
                    {formatMoney(item.product.amount)}
                  </PDFText>
                </View>;
              }
            })}
            <View
              style={{ border: "none", ...styles.tableRow }}
            >
              <PDFText style={{ ...styles.tableCol, width: "40%" }}></PDFText>
              <PDFText style={styles.tableCol}></PDFText>
              <PDFText style={{ ...styles.tableCol, textAlign: 'center' }}>TOTAL</PDFText>
              <PDFText
                style={{ ...styles.tableCol, textAlign: 'right', ...styles.textMedium }}>{formatMoney(total)}</PDFText>
            </View>
          </View>
        </View>
        <PDFText style={styles.invoiceTerms}>{pdfDetails.invoice_terms}</PDFText>
        {/* temporarily hide, we will add back later
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
*/}
      </Page>
    </Document>
  );
}

export default InvoicePDF;
