import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import { Button, Form, Grid, Text } from "tabler-react";
import { GET_USER_REGISTRATIONS_BY_IDS } from "../../../graphql/GET_USER_REGISTRATIONS_BY_IDS";
import { UPDATE_STUDENT_MUTATION } from "../../../graphql/UPDATE_STUDENT_MUTATION";
import FormField from "../../FormField";
import Modal from "../../Modal";

interface IRegistrationStudentDetailWeightModalProps {
  isModalOpen: boolean;
  toggleModal: any;
  student: any;
}

const RegistrationStudentDetailWeightModal = ({
                                                isModalOpen,
                                                toggleModal,
                                                student
                                              }: IRegistrationStudentDetailWeightModalProps) => {

  const [updateStudent] = useMutation(UPDATE_STUDENT_MUTATION);

  const renderForm = ({
    values,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  }) => (
    <form onSubmit={handleSubmit}>
      <Grid.Row className="mb-4">
        <Grid.Col xs={12} sm={12} lg={6}>
          <Form.Group label="Weight">
            <FormField
              appendright={
                <Form.InputGroupAppend>
                  <Form.InputGroupText>lbs</Form.InputGroupText>
                </Form.InputGroupAppend>
              }
              appendBottom={
                values.weight > 0 && (
                  <Text.Small className={"ml-1"}>
                    {(values.weight * 0.45).toFixed(1) + " kg"}
                  </Text.Small>
                )
              }
              type="number"
              placeholder="weight"
              name="weight"
              onChange={(e) =>
                setFieldValue("weight", Math.floor(Number(e.target.value)))
              }
              value={values.weight}
            />
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col className="pt-2" xs={12} sm={12} lg={12}>
          <Button
            type="submit"
            disabled={isSubmitting}
            pill
            className="btn-gray-dark float-right btn-sm"
          >
            {isSubmitting ? "Processing..." : "SUBMIT"}
          </Button>
        </Grid.Col>
      </Grid.Row>
    </form>
  );
  return (<Modal
    content={
      <Formik
        enableReinitialize={true}
        initialValues={{
          weight: student?.weight
        }}
        onSubmit={async (values, { resetForm, setSubmitting, setFieldError }) => {
          updateStudent({
            variables: {
              student: {
                id: student?.student_id,
                weight: values.weight <= 0 ? null : values.weight
              }
            },
            refetchQueries: [
              {
                query: GET_USER_REGISTRATIONS_BY_IDS,
                variables: {
                  userRegistrationsId: Number(student?.user_registrations_id)
                }
              }
            ]
          });
          toggleModal(!isModalOpen);
          setSubmitting(false);
          resetForm();

        }}
      >
        {(formikData) => renderForm(formikData)}
      </Formik>
    }
    open={isModalOpen}
    title={`${student.first_name} ${student.last_name}`}
    onClose={() => toggleModal(!isModalOpen)}
  />);
};

export default RegistrationStudentDetailWeightModal;
