import gql from 'graphql-tag';

export const LOAD_LIST_QUERIES = gql`
  query LOAD_LIST_QUERIES($team_id: ID!, $date: Date) {
    loads(teamId: $team_id, date: $date) {
      created_on
      id
      load_complete
      load_departure
      team_id
      load_name
      load_notes

      aircraft {
        id
        name
        tail_number
        aircraft_type
        aircraft_notes
        capacity_max
        capacity_min
      }

      location {
        id
        type
        name
      }

      status {
        slug
        name
      }

      passengers {
        id
        studentDetails {
          id
          first_name
          last_name
        }
        sequence
        jump_type_name
        jump_type_id
        jumpType {
          id
          slug
          name
          seq
        }
        association_type_name
        association_type_id
        associationType {
          id
          slug
          name
        }
      }
    }

    teamAircrafts(teamId: $team_id) {
      id
      name
      tail_number
      aircraft_type
      aircraft_notes
      capacity_max
      capacity_min
    }
  }
`;
