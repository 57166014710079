import React from 'react';
import AddLoadPassenger from './AddLoadPassenger';
import Modal from '../../Modal';

interface IAddLoadUserModalProps {
  isModalOpen: boolean;
  toggleModal: () => void;
  loadId: number;
  currentPassengers: string[];
}

const AddLoadPassengerModal = ({
  isModalOpen,
  toggleModal,
  loadId,
  currentPassengers
}: IAddLoadUserModalProps) => {
  return (
    <Modal
      content={
        <AddLoadPassenger
          toggleModal={toggleModal}
          loadId={loadId}
          currentPassengers={currentPassengers}
        />
      }
      open={isModalOpen}
      title="Jumper Manifest"
      onClose={toggleModal}
    />
  );
};

export default AddLoadPassengerModal;
