import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { Avatar, Card, Container, Dimmer, Grid, Icon } from 'tabler-react';
import Wrapper from '../layout';
import { GET_REGISTERED_STUDENTS } from '../../graphql/GET_REGISTERED_STUDENTS';
import { useQuery } from '@apollo/client';

const StudentParticipants = (props) => {
  const { campId } = useParams<{ campId: string }>();
  console.log(campId);

  const { data, loading } = useQuery(GET_REGISTERED_STUDENTS, {
    variables: { campId }
  });
  const participants = useMemo(() => {
    if (!data) return [];
    return (
      data.getRegisteredStudents.filter(
        (student) => student.status === 'Confirmed'
      ) || []
    );
  }, [data]);
  return (
    <Wrapper {...props} title="Participants">
      <Container>
        <Card>
          <Card.Header>
            <Card.Title>
              <Icon name="users" className="mr-2 ml-0 text-blue" />
              Participants
            </Card.Title>
          </Card.Header>
        </Card>
        <Card>
          <Card.Body>
            <Dimmer active={loading} loader={loading}>
              {!loading && participants.length > 0 && (
                <Grid.Row>
                  {participants.map((student, index) => (
                    <Grid.Col sm={6} lg={3} key={index}>
                      <Card key={index}>
                        <Card.Header>
                          <Card.Title>
                            {student.first_name} {student.last_name}
                          </Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <Avatar
                            key={student.id}
                            imageURL={`/api/s3/uploads/${student.profile_avatar}`}
                          />
                        </Card.Body>
                      </Card>
                    </Grid.Col>
                  ))}
                </Grid.Row>
              )}
            </Dimmer>
          </Card.Body>
        </Card>
      </Container>
    </Wrapper>
  );
};

export default StudentParticipants;
