import React, { useState } from 'react';
import { observer } from 'mobx-react';
import StudentSignupForm from '../Students/Forms/StudentSignupForm';
import StudentSignupGearRig from '../Gear/StudentSignupGearRig';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import StudentSignupActivity from '../Students/Forms/StudentSignupActivity';
import StudentSignupMerits from '../Students/Forms/StudentSignupMerits';

const StudentSignup = () => {
  const [step, setStep] = useState(0);

  return (<>

      {step === 0 &&
        <div className="col col-login mx-auto">
          <div className="text-center mb-6">
            <Link to="/">
              <img src={logo} className="h-6" alt="logo" />
            </Link>
          </div>
          <StudentSignupForm setStep={setStep} />
        </div>
      }

      {step === 1 &&
        <div className='col mx-auto'>
          <div className='text-center mb-6'>
            <Link to='/'>
              <img src={logo} className='h-6' alt='logo' />
            </Link>
          </div>
          <StudentSignupActivity setStep={setStep} />
        </div>
      }

      {step === 2 &&
        <div className="col mx-auto">
          <div className="text-center mb-6">
            <Link to="/">
              <img src={logo} className="h-6" alt="logo" />
            </Link>
          </div>
          <StudentSignupGearRig setStep={setStep} />
        </div>
      }

      {step === 3 &&
        <div className='col mx-auto'>
          <div className='text-center mb-6'>
            <Link to='/'>
              <img src={logo} className='h-6' alt='logo' />
            </Link>
          </div>
          <StudentSignupMerits setStep={setStep} />
        </div>
      }

      {step === 4 &&
        <div className="col col-login mx-auto text-center">
          <div className="text-center mb-6">
            <Link to="/">
              <img src={logo} className="h-6" alt="logo" />
            </Link>
          </div>
          Thank you!
        </div>}

    </>
  );
};

export default observer(StudentSignup);
