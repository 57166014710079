import { useQuery } from '@apollo/client';
import { ETransactionTypes, EUserEvents } from 'components/User/UserEvents'
import { UserStoreContext } from 'contexts/userStoreContext'
import { GET_ALL_USER_EVENTS } from 'graphql/GET_ALL_USER_EVENTS'
import { GET_STUDENTS_BY_FILTER_QUERY } from 'graphql/GET_STUDENTS_BY_FILTER_QUERY'
import { useRootStore } from 'hooks/useRootStore'
import { observer } from 'mobx-react'
import { applySnapshot } from 'mobx-state-tree'
import {
  useGetUserIsAdmin,
  useGetUserIsStudent
} from 'modules/common/hooks/useGetCurrentUserType'
import useGetNodeEnvironment, {
  ENodeEnvironment
} from 'modules/common/hooks/useGetNodeEnvironment'
import moment from 'moment'
import HeaderNavItems from 'pages/layout/HeaderNavItems'
import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import Moment from 'react-moment'
import {
  Avatar,
  Button,
  Container,
  Dropdown,
  Nav,
  Notification,
  Tag
} from 'tabler-react'
import useReactRouter from 'use-react-router'
import logo from '../../assets/images/logo.png'

interface HeaderProps {
  collapse: boolean,
  setCollapse: (collapse: boolean) => void
}

type TeamsArray =
  | {
      value: string;
      onClick: () => void;
    }
  | { isDivider?: boolean };

const Header: FC<HeaderProps> = ({ collapse, setCollapse }) => {
  const { history } = useReactRouter();
  const rootStore = useRootStore();
  const isStudent = useGetUserIsStudent()
  const isAdmin = useGetUserIsAdmin()

  const { currentCoachTeam, currentUser } = rootStore;

  const nodeEnv = useGetNodeEnvironment();

  const teamsDropdownItems = useMemo(() => {
    const teamsArr: TeamsArray[] = rootStore.coachTeams.map((coachTeam) => {
      return {
        value: coachTeam.name,
        onClick: () => {
          applySnapshot(rootStore, {
            ...rootStore,
            currentCoachTeam: { ...coachTeam },
            currentUser: {
              ...currentUser,
              type: coachTeam?.coach_business_type ?? currentUser?.type
            }
          });
          history.push('/');
        }
      };
    });

    teamsArr.push({ isDivider: true });

    if (rootStore.currentCoachTeam?.slug) {
      teamsArr.push({
        value: 'Public Page',
        onClick: () => {
          history.push(`/${rootStore.currentCoachTeam?.slug}`);
        }
      });
    }

    teamsArr.push({
      value: 'Settings',
      onClick: () => {
        history.push(`/team-details/${rootStore.currentCoachTeam?.id}`);
      }
    });

    return teamsArr;
  }, [rootStore.coachTeams, rootStore.currentCoachTeam, currentUser]);  

  const userStore = useContext(UserStoreContext);

  const [latestNotifications, setLatestNotifications] = useState([]);

  const { loading: eventsLoading, data: eventsData } = useQuery(
    GET_ALL_USER_EVENTS,
    {
      variables: {
        limit: 30,
        page: 1,
        filter: {}
      }
    }
  );

  useEffect(() => {
    if (!eventsLoading && eventsData) {
      const { getAllUserEvents, getTransactions } = eventsData;

      const userEvents = [
        ...getAllUserEvents.userEvents,
        ...getTransactions.transactions
      ];

      userEvents.sort(
        (userEventA, userEventB) =>
          new Date(userEventB.created_on).getTime() -
          new Date(userEventA.created_on).getTime()
      );

      setLatestNotifications(
        userEvents.slice(0, 10).map((userEvent) => {
          if (userEvent.user_events_id) {
            const { created_on, first_name, last_name, status, slug } =
              userEvent;

            return {
              message: (
                <>
                  <strong>
                    {first_name && last_name
                      ? `${first_name} ${last_name}`
                      : status}
                  </strong>{' '}
                  {EUserEvents[slug]}
                </>
              ),
              time: <Moment fromNow>{moment(created_on).format()}</Moment>
            };
          } else {
            const {
              coachFirstName,
              coachLastName,
              created_on,
              minutes,
              studentFirstName,
              studentLastName,
              teamName,
              transactionTypeSlug
            } = userEvent;

            return {
              message: (
                <>
                  <span>
                    <strong>
                      {coachFirstName} {coachLastName}
                    </strong>{' '}
                    updated an account at <strong>{teamName}</strong>.{' '}
                    <strong>{minutes} minutes</strong>{' '}
                    {ETransactionTypes[transactionTypeSlug]}{' '}
                    <strong>
                      {studentFirstName} {studentLastName}
                    </strong>
                  </span>
                </>
              ),
              time: <Moment fromNow>{moment(created_on).format()}</Moment>
            }
          }
        })
      );
    }
  }, [eventsLoading, eventsData]);  

  const { loading, error, data } = useQuery(GET_STUDENTS_BY_FILTER_QUERY, {
    variables: {
      filter: {
        limit: 1,
        student_id: currentUser.id.toString()
      }
    }
  });

  // Set filtered students everytime data changes
  useEffect(() => {
    if (!loading && !error && data) {
      if (currentUser.type === 'student') {
        userStore.loadUser(data[Object.keys(data)[0]][0]);
      }
    }
  }, [data]);  

  const { first_name, last_name, email, profile_avatar } = currentUser;

  return (
    <div className="header py-4">
      <Container>
        <div className="d-flex justify-content-between">
          <span
            className="header-toggler d-lg-none ml-lg-0 cursor-pointer"
            onClick={() => setCollapse(!collapse)}
          >
            <span className="header-toggler-icon" />
          </span>
          <span
            className="header-brand cursor-pointer"
            onClick={() => history.push(`/`)}
          >
            {currentCoachTeam?.team_logo_header ? (
              <div
                className="header-team-logo-wide d-none d-lg-block"
                style={{
                  backgroundImage: `url(/api/s3/uploads/${currentCoachTeam?.team_logo_header})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  height: '40px',
                  width: '250px',
                  backgroundPosition: 'left'
                }}
              />
            ) : (
              <img src={logo} className="d-none d-lg-block" alt="SKYCRU logo" />
            )}
          </span>
          {nodeEnv !== ENodeEnvironment.PRODUCTION && (
            <div className="mr-3 mt-1">
              <Tag
                className="text-danger d-none d-lg-block"
                color="white"
                onClick={() => setCollapse(!collapse)}
              >
                {nodeEnv} <i className="fe fe-help-circle" />
              </Tag>
              <Tag
                className="text-danger d-lg-none"
                color="white"
                onClick={() => setCollapse(!collapse)}
              >
                {nodeEnv}
              </Tag>
            </div>
          )}
          <div className="d-flex">
            {!currentUser && (
              <Button
                color="primary"
                size="sm"
                onClick={() => history.push(`/login`)}
              >
                Login
              </Button>
            )}
          </div>
          <div className="d-flex">
            {isAdmin && (
              <>
                <div className="d-flex">
                  <Dropdown
                    className="user-events-dropdown"
                    toggle={false}
                    icon="activity"
                    isNavLink={true}
                    position="bottom-end"
                    arrow={true}
                    arrowPosition="right"
                    flex
                    items={
                      <>
                        {latestNotifications?.map((n, i) => (
                          <Dropdown.Item className="cursor-pointer" key={i}>
                            <Notification
                              avatarURL={n.avatarURL}
                              message={n.message}
                              time={n.time}
                            />
                          </Dropdown.Item>
                        ))}
                        <Dropdown.ItemDivider />
                        <Dropdown.Item
                          className="cursor-pointer text-center text-muted-dark"
                          onClick={() => history.push(`/admin/user-events`)}
                        >
                          See all user activity
                        </Dropdown.Item>
                      </>
                    }
                  />
                </div>
              </>
            )}
            <div className="dropdown d-flex">
              {!isAdmin && !isStudent && (
                <>
                  <Dropdown
                    triggerContent={rootStore.currentCoachTeam?.name}
                    className="mt-1 text-secondary cursor-pointer"
                    itemsObject={teamsDropdownItems}
                  />
                  {!currentCoachTeam && !isStudent && (
                    <Tag color="white" size="sm" className="text-muted"></Tag>
                  )}
                </>
              )}
              <Dropdown
                as={Nav.Item}
                link={false}
                triggerClassName="d-flex align-items-center py-0 px-lg-0 px-2 text-color ml-2 cursor-pointer"
                triggerAs={Nav.Link}
                triggerContent={
                  <React.Fragment>
                    <Avatar
                      imageURL={
                        profile_avatar
                          ? `/api/s3/uploads/${profile_avatar}`
                          : '//www.gravatar.com/avatar?d=mp'
                      }
                    />
                    <span className="ml-2 d-none d-lg-block leading-none">
                      <span>{`${first_name} ${last_name}`}</span>
                      <span className="text-muted d-block mt-1 text-h6">
                        {email}
                      </span>
                    </span>
                  </React.Fragment>
                }
                position="bottom-end"
                arrow={true}
                arrowPosition="right"
                toggle={false}
                items={<HeaderNavItems />}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default observer(Header);
