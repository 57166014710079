import { observer } from 'mobx-react';
import Modal from '../../Modal';
import { Card, Grid, Header, Text } from 'tabler-react';
import React, { useMemo } from 'react';
import { formatAccountBalance } from '../../../utils/formatAccountBalance';
import { useRootStore } from '../../../hooks';

const ViewTransactionDetails = ({
  transaction,
  isModalOpen,
  toggleModal,
  teamId,
  studentId
}) => {
  const { currentCoachTeam } = useRootStore();
  // const { data: accountBalance } = useQuery<GET_ACCOUNT_BALANCE_TYPE>(
  //   GET_ACCOUNT_BALANCE,
  //   {
  //     variables: {
  //       studentId,
  //       teamId
  //     }
  //   }
  // );

  const amount = useMemo(() => {
    return formatAccountBalance(
      transaction.minutes,
      currentCoachTeam?.account_type_slug ?? transaction.accountTypeSlug
    );
  }, [transaction, currentCoachTeam]);
  return (
    <Modal
      content={
        <Card className={'align-content-center'}>
          <Card.Body className="text-center">
            <Grid.Row>
              <Grid.Col>
                <h2>
                  {transaction.studentFirstName} {transaction.studentLastName}
                </h2>
                <Text>{transaction.teamName}</Text>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row className={'my-3'}>
              <Grid.Col>
                <Header.H1>
                  {transaction.timeAdded ? (
                    <Text.Small color="success">+ {amount}</Text.Small>
                  ) : (
                    <Text.Small color="danger">- {amount}</Text.Small>
                  )}
                </Header.H1>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                {transaction.coachFirstName} {transaction.coachLastName}
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                {new Date(transaction.created_on).toLocaleString()}
              </Grid.Col>
            </Grid.Row>
            <Grid.Row className={'mt-3'}>
              <Grid.Col>{transaction.transactionTypeName}</Grid.Col>
            </Grid.Row>
          </Card.Body>
          <Card.Footer className="text-center">
            <Grid.Row>
              <Grid.Col>Transaction ID: {transaction.id}</Grid.Col>
            </Grid.Row>
          </Card.Footer>
        </Card>
      }
      open={isModalOpen}
      onClose={() => toggleModal(!isModalOpen)}
    />
  );
};

export default observer(ViewTransactionDetails);
