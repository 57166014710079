import React, { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Table, Text } from 'tabler-react';
import useReactRouter from 'use-react-router';
import { UserStoreContext } from '../../contexts/userStoreContext';
import { useLazyQuery } from '@apollo/client';
import { GET_STUDENT_BY_ID } from '../../graphql/GET_STUDENT_BY_ID';
import EditTransactionForm from './Modal/EditTransactionForm';
import ViewTransactionDetails from './Modal/ViewTransactionDetails';
import { formatAccountBalance } from '../../utils/formatAccountBalance';

interface ITransactionListItemProps {
  transaction: any;
}

const TransactionListItem = ({ transaction }: ITransactionListItemProps) => {
  const { history } = useReactRouter();
  const userStore = useContext(UserStoreContext);
  const { user } = userStore;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [getStudent] = useLazyQuery(GET_STUDENT_BY_ID, {
    onCompleted: (student) => {
      console.log(student);
      userStore.detach(user);
      userStore.loadUser(student?.getStudentById);
      history.push('/user-details');
    }
  });
  const handleTeamClick = (team_id) => {
    history.push(`/team-details/${team_id}`);
  };

  const handleStudentClick = async (student_id) => {
    await getStudent({ variables: { student_id: parseInt(student_id) } });
  };

  const amount = useMemo(() => {
    return formatAccountBalance(
      transaction.minutes,
      transaction.accountTypeSlug
    );
  }, [transaction]);

  return (
    <>
      <EditTransactionForm
        transaction={transaction}
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
      />
      <ViewTransactionDetails
        transaction={transaction}
        isModalOpen={isViewModalOpen}
        toggleModal={setIsViewModalOpen}
        teamId={transaction.teamId}
        studentId={transaction.studentId}
      />
      <Table.Col>
        <Text RootComponent="span" muted>
          {transaction.id}
        </Text>
      </Table.Col>
      <Table.Col>
        <Link to="#" onClick={() => handleStudentClick(transaction.studentId)}>
          {transaction.studentFirstName} {transaction.studentLastName}
        </Link>
      </Table.Col>
      <Table.Col>{transaction.transactionTypeName}</Table.Col>
      <Table.Col>{new Date(transaction.created_on).toLocaleString()}</Table.Col>
      <Table.Col>
        <Link to="#" onClick={() => handleTeamClick(transaction.teamId)}>
          {transaction.teamName}
        </Link>
      </Table.Col>
      <Table.Col>
        {transaction.coachFirstName} {transaction.coachLastName}
      </Table.Col>
      <Table.Col>
        {transaction.timeAdded ? (
          <Text.Small color="success">+{amount}</Text.Small>
        ) : (
          <Text.Small color="danger">-{amount}</Text.Small>
        )}
      </Table.Col>
      <Table.Col>{transaction.notes}</Table.Col>
      <Table.Col>
        <Dropdown
          triggerContent={<Button color={'white'}>Actions</Button>}
          color="secondary"
          size="sm"
          itemsObject={[
            { value: 'Edit', onClick: () => setIsModalOpen(true) },
            { value: 'View', onClick: () => setIsViewModalOpen(true) }
          ]}
        />
      </Table.Col>
    </>
  );
};

export default TransactionListItem;
