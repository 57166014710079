import { useQuery } from "@apollo/client";
import { useGetUserIsStudent } from "modules/common/hooks/useGetCurrentUserType";
import QRCodeUserRegistration from "modules/user/registrations/components/buttons/QRCodeUserRegistration";
import moment from "moment/moment";
import React, { useMemo, useState } from "react";
import { Button, Card, Grid, Text } from "tabler-react";
import { GET_STUDENT_GEARS } from "../../graphql/GET_STUDENT_GEARS";
import { GET_USER_MERITS_BY_USER_ID } from "../../graphql/GET_USER_MERITS_BY_STUDENT_ID";
import { useRootStore } from "../../hooks";
import { Types } from "../../types/graphql";
import { formatHeight, getAge, getWingLoad } from "../../utils/numberFormat";
import {
  EUSPATitles,
  lifetimeMemberTemplateId,
  SHIRT_SIZES,
} from "../constants";
import AADGearModalForm from "../Gear/Modal/AADGearModalForm";
import MainGearLoadLimit from "../Gear/Modal/MainGearLoadLimit";
import MainGearModalForm from "../Gear/Modal/MainGearModalForm";
import ShowGearModal from "../Gear/Modal/ShowGearModal";
import StudentLink from "../Students/StudentLink";
import { EUserTypes } from "../User/userTypes";
import RegistrationStudentDetailContactModal from "./Forms/RegistrationStudentDetailContactModal";
import RegistrationStudentDetailDOBModal from "./Forms/RegistrationStudentDetailDOBModal";
import RegistrationStudentDetailHeightModal from "./Forms/RegistrationStudentDetailHeightModal";
import RegistrationStudentDetailShirtSizeModal from "./Forms/RegistrationStudentDetailShirtSizeModal";
import RegistrationStudentDetailWeightModal from "./Forms/RegistrationStudentDetailWeightModal";

interface IRegistrationStudentDetailsProps {
  userRegistrationDetails: Types.UserRegistrationsById;
  campRequirements: string[];
}

const RegistrationStudentDetails = ({
  userRegistrationDetails,
  campRequirements
}: IRegistrationStudentDetailsProps) => {
  const { currentUser } = useRootStore();
  const isStudent = useGetUserIsStudent()
  const [isHeightModalOpen, setIsHeightModalOpen] = useState<boolean>(false);
  const [isWeightModalOpen, setIsWeightModalOpen] = useState<boolean>(false);
  const [isDOBModalOpen, setIsDOBModalOpen] = useState<boolean>(false);
  const [isShirtSizeModalOpen, setIsShirtSizeModalOpen] =
    useState<boolean>(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState<boolean>(false);
  const [isGearModalOpen, setIsGearModalOpen] = useState<boolean>(false);
  const [isAADModalOpen, setIsAADModalOpen] = useState<boolean>(false);
  const [isShowGearModalOpen, setIsShowGearModalOpen] =
    useState<boolean>(false);
  const [mainGear, setMainGear] = useState(null);
  const [isGearSelectionOpen, setIsGearSelectionOpen] = useState(false);

  //MERITS
  const { data: userMeritsData } = useQuery(GET_USER_MERITS_BY_USER_ID, {
    variables: {
      userId: userRegistrationDetails?.student_id,
      userType: 'student'
    }
  });

  const hasLifetimeMembership = userMeritsData?.userMerits?.some(
    (item) => item.template_id === lifetimeMemberTemplateId
  );

  const memberLicense = useMemo(() => {
    if (hasLifetimeMembership) {
      return userMeritsData?.userMerits.find(
        (item) => item.template_title === 'Lifetime Member'
      );
    }

    return userMeritsData?.userMerits.find(
      (item) => item.template_title === EUSPATitles.Member
    );
  }, [hasLifetimeMembership, userMeritsData?.userMerits]);
  //Gears
  const { data: gearsData, loading: gearsLoading } = useQuery(
    GET_STUDENT_GEARS,
    {
      variables: {
        getUserGearOptions: {
          student_id: Number(userRegistrationDetails?.student_id)
        }
      }
    }
  );

  const studentMainCanopy = useMemo(() => {
    if (!gearsLoading && gearsData?.getUserGears) {
      const canopy = gearsData.getUserGears.filter(
        (gear) => gear.gear_model.gear_type_id === 1
      );

      if (canopy.length >= 1) {
        setMainGear(canopy[0]);
      }

      return canopy ?? null;
    }

    return null;
  }, [gearsData, gearsLoading]);

  const studentAAD = useMemo(() => {
    if (!gearsLoading && gearsData?.getUserGears) {
      const aad = gearsData.getUserGears.filter(
        (gear) => gear.gear_model.gear_type_id === 4
      );

      if (aad.length === 0) return null;

      return aad;
    }

    return null;
  }, [gearsData, gearsLoading]);

  return (
    <>
      {campRequirements &&
        campRequirements.length > 0 &&
        currentUser.type !== EUserTypes.student && (
          <>
            <Card className="registration">
              {userRegistrationDetails?.profile_avatar && (
                <Card.Header
                  className="card-header"
                  backgroundURL={`/api/s3/uploads/${userRegistrationDetails?.profile_avatar}`}
                ></Card.Header>
              )}
              <Card.Body className="pl-3 pr-3">
                <Grid.Row className="mb-2">
                  <Grid.Col>
                    <h3 className="text-center">
                      {currentUser.type !== EUserTypes.student && (
                        <StudentLink
                          studentId={Number(
                            userRegistrationDetails?.student_id,
                          )}
                          studentName={`${userRegistrationDetails.first_name} ${userRegistrationDetails.last_name}`}
                        />
                      )}
                      {currentUser.type === EUserTypes.student &&
                        `${userRegistrationDetails?.first_name} ${userRegistrationDetails?.last_name}`}
                    </h3>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row className="border-top border-bottom mb-3">
                  {campRequirements.includes("email") && (
                    <>
                      <Grid.Col className="text-center border-right">
                        {userRegistrationDetails?.email ? (
                          <Button
                            className="my-3 text-primary"
                            icon="mail"
                            color={"white"}
                            onClick={(e) => {
                              e.preventDefault();
                              window.location.href =
                                "mailto:" + userRegistrationDetails?.email;
                            }}
                          >
                            EMAIL
                          </Button>
                        ) : (
                          <Button
                            className="my-3 text-muted"
                            icon="mail"
                            color={"white"}
                            onClick={() => setIsContactModalOpen(true)}
                          >
                            No Email
                          </Button>
                        )}
                      </Grid.Col>
                    </>
                  )}

                  {campRequirements.includes("phone") && (
                    <>
                      <Grid.Col className="text-center border-0">
                        {userRegistrationDetails?.phone_number ? (
                          <Button
                            className="my-3 text-primary"
                            icon="phone"
                            color={"white"}
                            onClick={(e) => {
                              e.preventDefault();
                              window.location.href =
                                "tel:" +
                                userRegistrationDetails?.phone_country_code +
                                userRegistrationDetails?.phone_number;
                            }}
                          >
                            CALL
                          </Button>
                        ) : (
                          <Button
                            className="my-3 text-muted"
                            icon="phone"
                            color={"white"}
                            onClick={() => setIsContactModalOpen(true)}
                          >
                            No Phone
                          </Button>
                        )}
                      </Grid.Col>
                    </>
                  )}
                </Grid.Row>
                <Grid.Row className="mb-3">
                  {campRequirements.includes("height") && ""}
                  <Grid.Col md={4} sm={6} lg={6} className="text-center">
                    <Text className="text-muted">Height</Text>
                    <h4
                      onClick={() => setIsHeightModalOpen(true)}
                      className="cursor-pointer"
                    >
                      {userRegistrationDetails?.height > 0 ? (
                        formatHeight(userRegistrationDetails?.height)
                      ) : (
                        <Button color="white" size="sm" className="text-muted">
                          Unknown
                        </Button>
                      )}
                    </h4>
                  </Grid.Col>
                  {campRequirements.includes("weight") && ""}
                  <Grid.Col md={4} sm={6} lg={6} className="text-center">
                    <Text className="text-muted">Weight</Text>
                    <h4
                      onClick={() => setIsWeightModalOpen(true)}
                      className="cursor-pointer"
                    >
                      {userRegistrationDetails?.weight > 0 ? (
                        userRegistrationDetails?.weight + " lbs"
                      ) : (
                        <Button color="white" size="sm" className="text-muted">
                          Unknown
                        </Button>
                      )}
                    </h4>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  {campRequirements.includes("dob") && (
                    <Grid.Col md={4} sm={6} lg={6} className="text-center">
                      <Text className="text-muted">Age</Text>
                      <h4
                        onClick={() => setIsDOBModalOpen(true)}
                        className="cursor-pointer"
                      >
                        {userRegistrationDetails?.dob ? (
                          getAge(userRegistrationDetails?.dob)
                        ) : (
                          <Button
                            color="white"
                            size="sm"
                            className="text-muted"
                          >
                            Unknown
                          </Button>
                        )}
                      </h4>
                    </Grid.Col>
                  )}
                  {/* To Do: only display Wing Load if camp type is Skydiving */}
                  {campRequirements.includes("wing_load") && ""}
                  <Grid.Col md={4} sm={6} lg={6} className="text-center">
                    <Text className="text-muted">Wing Load</Text>
                    <h4>
                      {!!studentMainCanopy &&
                      mainGear &&
                      userRegistrationDetails?.weight > 0 ? (
                        <span
                          className={"cursor-pointer"}
                          onClick={() => {
                            if (studentMainCanopy.length > 1) {
                              setIsGearSelectionOpen(true);
                            }
                          }}
                        >
                          {getWingLoad(
                            Number(mainGear.gear_canopy_size),
                            Number(userRegistrationDetails?.weight),
                          )}
                          {studentMainCanopy.length > 1 && (
                            <i className="fe fe-more-horizontal ml-2" />
                          )}
                          <Text.Small className="text-muted d-block">
                            {mainGear.gear_model.name}{" "}
                            {mainGear.gear_canopy_size}
                          </Text.Small>
                        </span>
                      ) : (
                        <Button
                          color="white"
                          size="sm"
                          className="text-muted"
                          onClick={() => setIsGearModalOpen(true)}
                        >
                          Unknown
                        </Button>
                      )}
                    </h4>
                  </Grid.Col>
                </Grid.Row>
              </Card.Body>
              <Card.Footer className="pl-4 pr-4">
                {campRequirements.includes("shirt_size") && (
                  <Button
                    color="white"
                    size="sm"
                    onClick={() => setIsShirtSizeModalOpen(true)}
                    className="float-left text-muted"
                  >
                    Shirt Size:{" "}
                    {userRegistrationDetails?.shirt_size_id
                      ? SHIRT_SIZES.map((size) => {
                          if (
                            size.value ===
                            userRegistrationDetails?.shirt_size_id
                          ) {
                            return size.name;
                          }
                        })
                      : "Unknown"}
                  </Button>
                )}
                {/* To Do: only display AAD button list if camp type is Skydiving */}
                {campRequirements.includes("aad") && ""}
                <Button.List align="right">
                  {!studentAAD && (
                    <Button
                      icon="plus-circle"
                      color="white"
                      size="sm"
                      className="text-muted"
                      onClick={() => setIsAADModalOpen(true)}
                    >
                      AAD
                    </Button>
                  )}
                  {studentAAD && (
                    <Button
                      icon="check-circle"
                      color="white"
                      size="sm"
                      className="text-success"
                      onClick={() => setIsShowGearModalOpen(true)}
                    >
                      AAD
                    </Button>
                  )}
                </Button.List>
              </Card.Footer>
              {campRequirements.includes("merits") && (
                <>
                  {memberLicense && (
                    <Card.Footer className="bg-light">
                      <Grid.Row width={12}>
                        <Grid.Col lg={6}>
                          {memberLicense?.merits_uspa_member_id && (
                            <Text.Small>
                              USPA #:{" "}
                              <strong>
                                {memberLicense?.merits_uspa_member_id}
                              </strong>
                            </Text.Small>
                          )}
                        </Grid.Col>
                        <Grid.Col className="text-right" lg={6}>
                          {memberLicense?.merits_uspa_member_expiration && (
                            <Text.Small className="text-right">
                              Expires:{" "}
                              <strong>
                                {moment(
                                  memberLicense?.merits_uspa_member_expiration,
                                ).format("MMM D, YYYY")}
                              </strong>
                            </Text.Small>
                          )}
                          {memberLicense?.merits_uspa_member_id &&
                            !memberLicense?.merits_uspa_member_expiration && (
                              <Text.Small className="text-right">
                                <i className="fe fe-star text-warning mr-1" />
                                Lifetime Member
                              </Text.Small>
                            )}
                        </Grid.Col>
                      </Grid.Row>
                    </Card.Footer>
                  )}
                </>
              )}
            </Card>
            {isStudent && (
              <QRCodeUserRegistration
                userRegistration={userRegistrationDetails}
              />
            )}
            <MainGearModalForm
              isModalOpen={isGearModalOpen}
              toggleModal={setIsGearModalOpen}
              student_id={Number(userRegistrationDetails?.student_id)}
              team_id={Number(userRegistrationDetails?.team_id)}
              student={userRegistrationDetails}
            />
            <AADGearModalForm
              isModalOpen={isAADModalOpen}
              toggleModal={setIsAADModalOpen}
              student_id={Number(userRegistrationDetails?.student_id)}
              team_id={Number(userRegistrationDetails?.team_id)}
              student={userRegistrationDetails}
            />
            <RegistrationStudentDetailHeightModal
              isModalOpen={isHeightModalOpen}
              toggleModal={setIsHeightModalOpen}
              student={userRegistrationDetails}
            />
            <RegistrationStudentDetailWeightModal
              isModalOpen={isWeightModalOpen}
              toggleModal={setIsWeightModalOpen}
              student={userRegistrationDetails}
            />
            <RegistrationStudentDetailDOBModal
              isModalOpen={isDOBModalOpen}
              toggleModal={setIsDOBModalOpen}
              student={userRegistrationDetails}
            />
            <RegistrationStudentDetailShirtSizeModal
              isModalOpen={isShirtSizeModalOpen}
              toggleModal={setIsShirtSizeModalOpen}
              student={userRegistrationDetails}
            />
            <RegistrationStudentDetailContactModal
              isModalOpen={isContactModalOpen}
              toggleModal={setIsContactModalOpen}
              student={userRegistrationDetails}
            />
            <MainGearLoadLimit
              student={userRegistrationDetails}
              gears={studentMainCanopy}
              toggleModal={setIsGearSelectionOpen}
              isModalOpen={isGearSelectionOpen}
              setMainGear={setMainGear}
            />
            <ShowGearModal
              toggleModal={setIsShowGearModalOpen}
              isModalOpen={isShowGearModalOpen}
              userGear={studentAAD && studentAAD[0]}
            />
          </>
        )}
    </>
  );
};
export default RegistrationStudentDetails;
