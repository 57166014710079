import { useApolloClient } from '@apollo/client';

export interface IHandleOptionSelect {
  __isNew__: boolean
  value: string
  label: string
}

interface IAutoCompleteProps {
  query: any;
  options?: any;
  minInputLength?: number;
}

export interface IArgsOptions {
  order: string;
  filter: object;
}

export enum EOrderOptions {
  newest = 'newest',
  oldest = 'oldest',
  alphabet = 'alphabet'
}

const MIN_INPUT_SEARCH_LENGTH = 3;

export const useAutocomplete = (props: IAutoCompleteProps) => {
  const client = useApolloClient();
  const minInputLength = props.minInputLength || MIN_INPUT_SEARCH_LENGTH;

  const loadOptions = async (input: string) => {
    if (!input || input.length < minInputLength) {
      return;
    }

    const { data } = await client.query({
      query: props.query,
      variables: { filter: input }
    });

    if (props?.options?.filter) {
      if (Array.isArray(props?.options?.filter)) {
        return data[Object.keys(data)[0]].filter((item) =>
          props?.options?.filter.every(
            (filter: { field: string | number; value: any }) =>
              item[filter.field] === filter.value
          )
        )
      } else {
        if (Array.isArray(props?.options?.filter.value))
          return data[Object.keys(data)[0]].filter((item) =>
            props?.options?.filter.value.includes(
              item[props?.options?.filter.field]
            )
          )
        else
          return data[Object.keys(data)[0]].filter(
            (item) =>
              item[props?.options?.filter.field] ===
              props?.options?.filter.value
          )
      }
    } else
      return data[Object.keys(data)[0]];
  };

  return {
    loadOptions
  };
};
