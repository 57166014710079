import React from 'react';

import Wrapper from 'pages/layout';

const PreferencesPage = (props) => {
  return (
    <Wrapper {...props} title="Preferences">
      <div className="container">Dropzone Preferences</div>
    </Wrapper>
  );
};

export default PreferencesPage;
