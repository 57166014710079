import React, { useContext } from 'react';
import { Alert, Button, Card } from 'tabler-react';
import useReactRouter from 'use-react-router';
import { UserStoreContext } from 'contexts/userStoreContext';
import { Types } from '../../types/graphql'

interface UserTeamsListProps {
  teams: Types.Team[];
}

const UserTeamsList = ({ teams }: UserTeamsListProps) => {
  const { user } = useContext(UserStoreContext);

  const { history } = useReactRouter();

  return (
    <Card statusColor="blue">
      <Card.Header>
        <Card.Title>
          <i className='fe fe-users mr-2 text-info' />
          Team Associations (student_teams)
        </Card.Title>
        <Card.Options>
          <Button
            icon="plus"
            color="primary"
            size="sm"
            disabled
          />
        </Card.Options>
      </Card.Header>
      <Card.Body>
        {teams.length === 0 ? (
          <Alert type="info text-center">
            <strong>No Data</strong>
          </Alert>
        ) : (
          <Button.List>
            {teams.map((team) => (
              <Button
                outline
                key={team.id}
                color="primary"
                size="sm"
                className="mr-1"
                onClick={() => {
                  history.push(
                    `/user-details/${user.id}/student-teams/${team.id}`
                  );
                }}
              >
                {team.name}
              </Button>
            ))}
          </Button.List>
        )}
      </Card.Body>
    </Card>
  );
};

export default UserTeamsList;
