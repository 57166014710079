import { useMutation, useQuery } from "@apollo/client";
import React, { useMemo } from "react";
import { toast } from "react-toastify";
import { Dimmer, Grid } from "tabler-react";
import { ADD_STUDENT_TO_GROUP } from "../../graphql/ADD_STUDENT_TO_GROUP";
import { DELETE_STUDENT_FROM_GROUP } from "../../graphql/DELETE_STUDENT_FROM_GROUP";
import { GET_GROUPS } from "../../graphql/GET_GROUPS";
import { useRootStore } from "../../hooks";
import DashboardGroupsItem from "./DashboardGroupsItem";

const DashboardGroups = () => {
  const { currentCoachTeam } = useRootStore();
  const { data: groupsData, loading } = useQuery(GET_GROUPS, {
    variables: {
      filter: {
        team_id: currentCoachTeam.id,
        camp_id: null,
        status: "Active",
      },
    },
  });
  const [removeStudent] = useMutation(DELETE_STUDENT_FROM_GROUP);
  const groups = useMemo(() => {
    return groupsData?.groupList || [];
  }, [groupsData]);
  const [addStudentToGroup] = useMutation(ADD_STUDENT_TO_GROUP, {
    onCompleted: () => {
      toast.success("User added to group");
    },
  });

  const handleDrop = (e, groupId) => {
    const group = groupsData.groupList.find((group) => group.id === groupId);
    const studentId = e.dataTransfer.getData("studentId");
    if (!studentId) {
      return;
    }
    if (group.members.find((member) => member.id === studentId)) {
      toast.error("User already in group");
    } else {
      addStudentToGroup({
        variables: {
          addGroupAssociatedUsersInput: {
            group_id: groupId,
            student_id: e.dataTransfer.getData("studentId"),
          },
        },
        refetchQueries: [
          {
            query: GET_GROUPS,
            variables: {
              filter: {
                camp_id: null,
                team_id: currentCoachTeam.id,
                status: "Active",
              },
            },
          },
        ],
      });
      if (e.dataTransfer.getData("action") === "transfer") {
        removeStudent({
          variables: {
            groupId: e.dataTransfer.getData("previousGroupId"),
            studentId: studentId,
          },
          refetchQueries: [
            {
              query: GET_GROUPS,
              variables: {
                filter: {
                  camp_id: Number(group.camp_id),
                  team_id: Number(group.team_id),
                  status: "Active",
                },
              },
            },
          ],
        });
      }
    }
  };
  return (
    <Dimmer active={loading} loader={loading}>
      <Grid.Row>
        {groups &&
          groups.map((group) => (
            <DashboardGroupsItem
              group={group}
              key={group.id}
              handleDrop={handleDrop}
            />
          ))}
      </Grid.Row>
    </Dimmer>
  );
};

export default DashboardGroups;
