import { UserStoreContext } from 'contexts/userStoreContext';
import UserRoleModal from 'modules/user/roles/components/modals/UserRoleModal';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button, Dropdown, Icon, Table, Tag, Text } from 'tabler-react';
import useReactRouter from 'use-react-router';
import formatSeal from 'utils/formatSeal';
import { formatPhoneNumber } from 'utils/phoneFormat';
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType';
import { Types } from '../../types/graphql'

interface IStaffListItemProps {
  staff: {
    roles: Types.UserRole[];
    seal: Types.UserRiggerSeal;
    studentDetails: Types.Student;
    team: Types.Team;
  };
}

const StaffListItem = ({ staff }: IStaffListItemProps) => {
  const userStore = useContext(UserStoreContext);
  const { isAdmin, isCoachAdmin, isCoachSuperAdmin } = useGetCurrentUserType();
  const { history } = useReactRouter();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const routeToStudent = (student) => {
    userStore.loadUser(student);
    history.push('/user-details');
  };

  return (
    <>
      <Table.Col>
        <Avatar
          size="md"
          className="float-left mr-5 cursor-pointer"
          status={`${
            staff.studentDetails.registration_id ? 'success' : 'secondary'
          }`}
          imageURL={
            (staff.studentDetails.profile_avatar &&
              `/api/s3/uploads/${staff.studentDetails.profile_avatar}`) ||
            '//www.gravatar.com/avatar?d=mp'
          }
          to="#"
        />
        <Link
          className="text-inherit"
          onClick={(e) => {
            e.preventDefault();
            routeToStudent(staff.studentDetails);
          }}
          to="#"
        >
          {staff.studentDetails.first_name} {staff.studentDetails.last_name}
        </Link>
        <Text.Small className="d-block" color="secondary">
          SKY.D: {staff.studentDetails.id}{' '}
          <Tag className="ml-2">{formatSeal(staff?.seal?.rigger_seal)}</Tag>
        </Text.Small>
      </Table.Col>
      <Table.Col>
        {staff.studentDetails.email && (
          <a href={'mailto:' + staff.studentDetails.email}>
            <Icon name="mail" className="mr-2 d-block d-lg-none" />
            <span className="d-none d-lg-block">
              {staff.studentDetails.email}
            </span>
          </a>
        )}
      </Table.Col>
      <Table.Col>
        {staff.studentDetails.phone_number && (
          <a href={'tel:' + staff.studentDetails.phone_number}>
            <Icon name="phone" className="mr-2 d-block d-lg-none" />
            <span className="d-none d-lg-block">
              {formatPhoneNumber(staff.studentDetails.phone_number)}
            </span>
          </a>
        )}
      </Table.Col>
      {(isAdmin || isCoachAdmin || isCoachSuperAdmin) && (
        <>
          <Table.Col>
            <span className="mr-2" onClick={toggleModal}>
              <i className="fe fe-user-check btn btn-md btn-secondary text-muted" />
            </span>
            <React.Fragment>
              <Button.Dropdown
                value="Actions"
                color="secondary"
                size="sm"
                disabled
              >
                <Dropdown.Item>Reset Password</Dropdown.Item>
                <Dropdown.Item>Update</Dropdown.Item>
                <Dropdown.ItemDivider />
                <Dropdown.Item>Disable</Dropdown.Item>
              </Button.Dropdown>
            </React.Fragment>
            <Text.Small className="d-block text-muted mt-1">
              {staff.roles.map((role) => (
                <Tag
                  className="mr-1"
                  key={`${staff.studentDetails.id}-${role.name}`}
                >
                  {role.name}
                </Tag>
              ))}
            </Text.Small>
          </Table.Col>
        </>
      )}
      {staff?.team && (
        <UserRoleModal
          action="addStudentRole"
          isModalOpen={isModalOpen}
          name={`${staff.studentDetails.first_name} ${staff.studentDetails.last_name}`}
          studentId={Number(staff.studentDetails.id)}
          toggleModal={toggleModal}
        />
      )}
    </>
  );
};

export default StaffListItem;
