import gql from 'graphql-tag';

export const GET_TEAM_ORGS = gql`
  query GET_TEAM_ORGS($teamId: ID) {
    teamOrgs(teamId: $teamId) {
      org_id
      slug
      name
      image
      exist
      team_id
      is_primary
    }
  }
`;
