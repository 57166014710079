import gql from 'graphql-tag';

export const GET_PAYMENT = gql`
  query GET_PAYMENT($payment_id: Int!) {
    getPayment(payment_id: $payment_id) {
      id
      amount
      camp_name
      card_brand
      card_country
      card_customer_name
      created_on
      currency
      description
      first_name
      invoice_id
      last_name
      last4
      manual_payment
      processed_by
      product_title
      status
      team_name
    }
  }
`;
