import React, { useContext } from 'react';
import Modal from '../../Modal';
import { Formik } from 'formik';
import FormField from '../../FormField';
import { Button, Form, Grid, Text } from 'tabler-react';
import * as Yup from 'yup';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ADD_STUDENT } from '../../../graphql/ADD_STUDENT';
import useReactRouter from 'use-react-router';
import { UserStoreContext } from '../../../contexts/userStoreContext';
import PhoneInput from 'react-phone-input-2';
import { useRootStore } from '../../../hooks';
import { EUserTypes } from '../../User/userTypes';
import { GET_EMAIL_VALIDATION } from '../../../graphql/GET_EMAIL_VALIDATION';
import { GET_PHONE_VALIDATION } from '../../../graphql/GET_PHONE_VALIDATION';
import { Link } from 'react-router-dom';
import { useGetUserIsCoach } from '../../../modules/common/hooks/useGetCurrentUserType';

interface StudentCreateFromSearchProps {
  isModalOpen: boolean;
  toggleModal: any;
  name: any;
}

const addStudentValidationSchema = Yup.object().shape({
  first_name: Yup.string().required('First Name is required.'),
  last_name: Yup.string().required('Last Name is required.')
});

const StudentCreateFromSearch = ({
  isModalOpen,
  toggleModal,
  name
}: StudentCreateFromSearchProps) => {
  const isCoach = useGetUserIsCoach()
  const { history } = useReactRouter();
  const userStore = useContext(UserStoreContext);
  //const { user } = userStore;
  const rootStore = useRootStore();
  const { currentUser, currentCoachTeam } = rootStore;

  const [addStudent] = useMutation(ADD_STUDENT);

  const [validateEmail, { data: emailData }] = useLazyQuery(
    GET_EMAIL_VALIDATION,
    {
      fetchPolicy: 'network-only',
      onCompleted: (result) => {
        if (result.validateEmail) {
          userStore.loadUser(result.validateEmail);
        }
      }
    }
  );
  const [validatePhone, { data: phoneData }] = useLazyQuery(
    GET_PHONE_VALIDATION,
    {
      fetchPolicy: 'network-only',
      onCompleted: (result) => {
        if (result.validatePhone) {
          userStore.loadUser(result.validatePhone);
        }
      }
    }
  );

  const HandleValidateEmail = async (email) => {
    if (email === '') return;
    validateEmail({
      variables: { email }
    });
  };

  const HandleValidatePhone = async (phone) => {
    if (phone.length <= 1) return;
    return validatePhone({
      variables: { phone }
    });
  };

  const renderForm = ({
    values,
    handleSubmit,
    isSubmitting,
    handleChange,
    setFieldValue,
    errors,
    touched
  }) => (
    <form onSubmit={handleSubmit}>
      <Grid.Row className="mb-4">
        <Grid.Col xs={12} sm={12} lg={6}>
          <Form.Group label="">
            <Form.Group label="First name:*">
              <FormField
                type="text"
                placeholder="First name"
                name="first_name"
                onChange={handleChange}
                value={values.first_name}
              />
            </Form.Group>
          </Form.Group>
        </Grid.Col>
        <Grid.Col xs={12} sm={12} lg={6}>
          <Form.Group label="Last name:*">
            <FormField
              type="text"
              placeholder="Last name"
              name="last_name"
              onChange={handleChange}
              value={values.last_name}
            />
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="mb-4">
        <Grid.Col sm={12} md={12} lg={6} className="pl-3 pr-0">
          <Form.Group label="Phone:">
            <PhoneInput
              inputProps={{
                className: 'form-control w-100',
                name: 'phone_number',
                //  required: true,
                onChange: (e) => {
                  e.preventDefault();
                  const target = e.target;
                  const phone = target.value.split(' ');
                  const countryCode = phone.shift().replace(/\D/g, '');
                  setFieldValue(
                    'phone_number',
                    phone.join('').replace(/\D/g, '')
                  );
                  setFieldValue('phone_country_code', Number(countryCode));
                },
                onBlur: async (e) => {
                  e.preventDefault();
                  await HandleValidatePhone(
                    e.target.value.split(' ').join('').replace(/\D/g, '')
                  );
                }
              }}
              value={`${values.phone_country_code}${values.phone_number}`}
            />
            <span className="field-error text-danger">
              {errors.phone_number && touched.phone_number && (
                <>
                  {errors.phone_number}{' '}
                  {isCoach && <Link to={'/user-details'}>VIEW USER</Link>}
                </>
              )}
            </span>
          </Form.Group>
        </Grid.Col>
        <Grid.Col xs={12} sm={12} lg={6}>
          <Form.Group label="Email:">
            <FormField
              type="email"
              placeholder="Email"
              name="email"
              onChange={handleChange}
              onBlur={(e) => HandleValidateEmail(e.target.value)}
              value={values.email}
            />
            <span className="field-error text-danger">
              {errors.email && touched.email && (
                <>{isCoach && <Link to={'/user-details'}>VIEW USER</Link>}</>
              )}
            </span>
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col className="pt-2" xs={12} sm={12} lg={12}>
          <Text.Small className="text-muted">
            Phone or Email is required as a unique identifier.
          </Text.Small>
          <Button
            type="submit"
            disabled={isSubmitting}
            pill
            className="btn-gray-dark float-right btn-sm"
          >
            {isSubmitting ? 'Processing...' : 'SUBMIT'}
          </Button>
        </Grid.Col>
      </Grid.Row>
    </form>
  );

  return (
    <Modal
      content={
        <Formik
          enableReinitialize={true}
          validationSchema={addStudentValidationSchema}
          initialValues={{
            first_name: name,
            last_name: '',
            email: '',
            phone_number: '',
            phone_country_code: 1
          }}
          onSubmit={async (
            values,
            { resetForm, setSubmitting, setFieldError }
          ) => {

            if (!values.email && !values.phone_number) {
              console.log(values.phone_number, values.email);
              setFieldError('email', ' ');
              setFieldError(
                'phone_number',
                'Please provide an email or phone number.'
              );
              setSubmitting(false);
              return false;
            }
            let errorFound = false;

            if (emailData?.validateEmail) {
              setFieldError('email', 'Email already exists.');
              errorFound = true;
            }

          if (phoneData?.validatePhone) {
              setFieldError('phone_number', 'Phone number already exists.');
              errorFound = true;
            }

            if (errorFound) {
              setSubmitting(false);
              return false;
            }

            const option = { team_id: undefined };
            if (
              [
                EUserTypes.coach.toString(),
                EUserTypes.rigger.toString()
              ].includes(currentUser.type)
            )
              option.team_id = currentCoachTeam.id;
            const newStudent = await addStudent({
              variables: {
                student: {
                  ...values,
                  ...option
                }
              }
            });
            setSubmitting(false);
            resetForm();
            //if (user) userStore.detach(user);
            userStore.loadUser(newStudent?.data.addStudent);
            history.push('/user-details');
            toggleModal(!isModalOpen);
          }}
        >
          {(formikData) => renderForm(formikData)}
        </Formik>
      }
      open={isModalOpen}
      title={'Add User'}
      onClose={() => toggleModal(!isModalOpen)}
    />
  );
};

export default StudentCreateFromSearch;
