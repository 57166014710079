import { useQuery } from "@apollo/client";
import { Loading } from "components/Loading";
import { values } from "mobx";
import { observer } from "mobx-react";
import { applySnapshot } from "mobx-state-tree";
import moment from "moment";
import React, { useContext, useState } from "react";
import { Button, Card, Grid, Icon, Table, Text } from "tabler-react";

import { EventStoreContext } from "../../contexts/eventStoreContext";
import { GET_CAMPS } from "../../graphql/GET_CAMPS";
import { useRootStore } from "../../hooks";
import { useGetUserIsAdmin } from "../../modules/common/hooks/useGetCurrentUserType";
import { Types } from "../../types/graphql";
import EventEntryModal from "../Calendar/Forms/EventEntryModal";
import EventListItem from "./EventListItem";
import EventListItemCard from "./EventListItemCard";

const EventList = () => {
  const rootStore = useRootStore();
  const { currentCoachTeam } = rootStore;
  const filter = { team_id: undefined };
  const isAdmin = useGetUserIsAdmin();

  if (!isAdmin) {
    filter.team_id = currentCoachTeam.id;
  }

  const { loading, error, data } = useQuery(GET_CAMPS, {
    variables: { filter: { ...filter, isDateAgnostic: true } },
  });

  if (loading || !data) {
    return <Loading />;
  }

  if (error) {
    return <p>{`Error: ${error.message}`}</p>;
  }

  const camps: Types.Camp[] = data?.getCamps
    ? data?.getCamps.toSorted((a: Types.Camp, b: Types.Camp) => {
        const da = new Date(a.start);
        const db = new Date(b.start);

        return da > db ? -1 : 1;
      })
    : [];

  const props = {
    camps,
  };

  return <EventListInner {...props} />;
};

const EventListInner = ({camps}) => {
  const { events } = useContext(EventStoreContext);
  const isAdmin = useGetUserIsAdmin()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  if (!events.length || camps.length !== events.length) {
    applySnapshot(events, camps);
  }

  const pastEvents = events.filter((event) => {
    return moment(event.end) < moment()
  })

  const futureEvents = events.filter((event) => {
    return moment(event.end) >= moment()
  })



  const newEventButton = () => (
    <>
      <Button icon="plus" color="primary" onClick={toggleModal}>
        EVENT
      </Button>
      <EventEntryModal isModalOpen={isModalOpen} toggleModal={toggleModal} />
    </>
  );

  if (events.length <= 0) {
    return (
        <div className="blank-page">
          <Grid.Row className="text-center">
            <Grid.Col>
              <Text className="d-block mb-3">You need to create an event!</Text>
              {newEventButton()}
            </Grid.Col>
          </Grid.Row>
        </div>
    );
  }

  return (
    <>
      {futureEvents.length > 0 && (
          <Card>
            <Card.Header>
              <Card.Title>
                <Icon name="calendar" className="mr-2 ml-0 text-primary" />
                Upcoming Events
              </Card.Title>
              <Card.Options>{newEventButton()}</Card.Options>
            </Card.Header>
            <Card.Body>
              <Grid.Row>
                {values(events)
                  .filter(
                    (event) => moment(event.end) > moment()
                  )
                  .reverse()
                  .map((event) => (
                    <Grid.Col sm="12" md="6" lg="4" key={event.id}>
                      <EventListItemCard event={event} key={event.id} />
                    </Grid.Col>
                  ))}
              </Grid.Row>
            </Card.Body>
          </Card>
      )}

      {pastEvents.length > 0 && (
          <Card>
            <Card.Header>
              <Card.Title>
                <Icon name="calendar" className="mr-2 ml-0 text-blue" />
                Past Events
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Grid.Col width={12}>
                <Table
                  responsive
                  className="card-table table-vcenter text-nowrap"
                >
                  <Table.Header>
                    <Table.Row>
                      {isAdmin && (
                        <Table.ColHeader className="w-1">ID</Table.ColHeader>
                      )}
                      <Table.ColHeader></Table.ColHeader>
                      <Table.ColHeader>Name</Table.ColHeader>
                      <Table.ColHeader>Location</Table.ColHeader>
                      <Table.ColHeader>Starts</Table.ColHeader>
                      <Table.ColHeader></Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {pastEvents.map((event) => (
                        <Table.Row key={event.id}>
                          <EventListItem event={event} />
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </Grid.Col>
            </Card.Body>
          </Card>
      )}
    </>
  );
};

export default observer(EventList);
