import React from 'react';
import { Formik } from 'formik';
import Modal from '../../Modal';
import { Button, Form } from 'tabler-react';
import { useMutation } from '@apollo/client';
import { ADD_COMPENSATION_TYPE } from '../../../graphql/ADD_COMPENSATION_TYPE';
import { NumericFormat } from 'react-number-format';
import { toast } from 'react-toastify';
import { LoadAssocType } from '../../constants';
import Select from 'react-select';
import { DZMUserRoleMap } from '../../../modules/user/roles/constants';
import { EDIT_COMPENSATION_TYPE } from '../../../graphql/EDIT_COMPENSATION_TYPE';
import { Types } from '../../../types/graphql'

interface CompensationTypeAddModalFormProps {
  isOpen: boolean;
  toggleModal: () => void;
  teamId?: number;
  compensationType?: Types.CompensationType
}

const CompensationTypeModalForm = ({
  isOpen,
  toggleModal,
  teamId,
  compensationType
}: CompensationTypeAddModalFormProps) => {
  const [addCompensationtype] = useMutation(ADD_COMPENSATION_TYPE, {
    refetchQueries: ['GET_COMPENSATION_TYPE_LIST'],
    onCompleted: () => {
      toast.success('Compensation Type Added');
    }
  });

  const [editCompensationType] = useMutation(EDIT_COMPENSATION_TYPE, {
    refetchQueries: ['GET_COMPENSATION_TYPE_LIST'],
    onCompleted: () => {
      toast.success('Compensation Type Updated');
    }
  });

  const renderForm = ({
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched
  }) => {
    return (
      <>
          <Form.Group label="Name">
            <Form.Input
              name="name"
              value={values.name}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group label={'Assoc type'}>
            <Select
              name={'load_association_type_id'}
              placeholder="Select Assoc..."
              options={LoadAssocType}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              value={LoadAssocType.find(
                (lat) => lat.value === values.load_association_type_id
              )}
              onChange={(option) => {
                setFieldValue('load_association_type_id', option.value);
              }}
            />
            <span className="field-error text-danger">
              {errors.load_association_type_id &&
                touched.load_association_type_id &&
                errors.load_association_type_id}
            </span>
          </Form.Group>
          <Form.Group label={'Role'}>
            <Select
              name={'role_id'}
              placeholder="Select role..."
              options={DZMUserRoleMap}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              value={DZMUserRoleMap.find((lat) => lat.value === values.role_id)}
              onChange={(option) => {
                setFieldValue('role_id', option.value);
              }}
            />
            <span className="field-error text-danger">
              {errors.role_id && touched.role_id && errors.role_id}
            </span>
          </Form.Group>
          dzmRoles
          <Form.Group label="Amount">
            <NumericFormat
              customInput={Form.Input}
              name={'amount'}
              prefix="$"
              thousandSeparator
              onChange={(e) => {
                const value = e.target.value.replace(/[$,]+/g, '');
                setFieldValue('amount', Number(value));
              }}
              value={values.amount}
            />
          </Form.Group>
          <Button.List align="right">
          <Button
            color="primary"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            SAVE
          </Button>
          </Button.List>
          </>
    );
  };
  return (
    <Modal
      content={
        <Formik
          initialValues={{
            id: compensationType ? compensationType.id : undefined,
            name: compensationType ? compensationType.name : '',
            amount: compensationType ? compensationType.amount : 0,
            team_id: compensationType ? compensationType.team_id : teamId,
            load_association_type_id: compensationType
              ? Number(compensationType.load_association_type_id)
              : 0,
            role_id: compensationType ? Number(compensationType.role_id) : 0
          }}
          onSubmit={async (values, { resetForm }) => {
            if (values.id) {
              await editCompensationType({
                variables: {
                  updateCompensationType: values
                }
              });
            } else {
              await addCompensationtype({
                variables: {
                  addCompensationType: values
                }
              });
            }

            resetForm();
            toggleModal();
          }}
        >
          {(formData) => renderForm(formData)}
        </Formik>
      }
      open={isOpen}
      onClose={toggleModal}
      title="Compensation"
    />
  );
};

export default CompensationTypeModalForm;
