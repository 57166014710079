import React, { useMemo } from 'react';
import { Button, Form, Grid } from 'tabler-react';
import { useMutation } from '@apollo/client';
import { ADD_STUDENT_ORG_CREDENTIAL } from '../../../graphql/ADD_STUDENT_ORG_CREDENTIAL';
import { toast } from 'react-toastify';
import { EDIT_STUDENT_ORG_CREDENTIAL } from '../../../graphql/EDIT_STUDENT_ORG_CREDENTIAL';
import { Formik } from 'formik';
import moment from 'moment/moment';
import useGetCurrentUserType from '../../../modules/common/hooks/useGetCurrentUserType';
import { useRootStore } from '../../../hooks';
import Select from 'react-select';
import {
  ABCDLicensesAPFOptions,
  ABCDLicensesCSPAOptions,
  ABCDLicensesOptions
} from '../../constants';

const ManualAdLicenseForm = ({ license, licenses }) => {
  const { isStudent, isAdmin } = useGetCurrentUserType();
  const { currentUser } = useRootStore();
  const [addStudentOrgCredential] = useMutation(ADD_STUDENT_ORG_CREDENTIAL, {
    refetchQueries: ['GET_ORG_CREDENTIALS'],
    onCompleted: () => {
      toast.success('Credential added successfully');
    }
  });

  const [updateStudentOrgCredential] = useMutation(
    EDIT_STUDENT_ORG_CREDENTIAL,
    {
      refetchQueries: ['GET_ORG_CREDENTIALS'],
      onCompleted: () => {
        toast.success('Credential updated successfully');
      }
    }
  );

  const licenseList = useMemo(() => {
    let selectedList = ABCDLicensesOptions;
    if (Number(license?.org_id) === 2) selectedList = ABCDLicensesCSPAOptions;
    if (Number(license?.org_id) === 3) selectedList = ABCDLicensesAPFOptions;

    return selectedList.map((option) => {
      return {
        value: option,
        label: option
      };
    });
  }, [license]);

  const renderForm = ({
    handleChange,
    setFieldValue,
    handleSubmit,
    values,
    dirty
  }) => {
    return (
      <Grid.Row>
        <Grid.Col xs={12} sm={12} md={6} lg={6}>
          <Form.Group label="">
            <Form.InputGroup>
              <Select
                className={'w-50'}
                name="license"
                placeholder="Select license..."
                searchText={false}
                options={licenseList.filter(
                  (i) => !licenses.find((l) => l.license === i.value)
                )}
                onChange={(e) => {
                  setFieldValue('license', e.value);
                }}
                value={
                  licenseList.find((i) => i.value === values.license) || null
                }
              />

              {values.id && (
                <Form.InputGroupAppend>
                  <Button
                    icon={values.verified_on ? 'check-circle' : 'alert-circle'}
                    color="white"
                    className={
                      values.verified_on ? 'text-success' : 'text-danger'
                    }
                    disabled={values.verified_on || isStudent}
                    onClick={() => {
                      const licenseClone = { ...values };
                      licenseClone.verified_by = currentUser.id.toString();
                      licenseClone.verified_on = moment().format('YYYY-MM-DD');
                      updateStudentOrgCredential({
                        variables: {
                          updateOrgCredentialInput: licenseClone
                        }
                      });
                    }}
                  >
                    {values.verified_on ? 'Verified' : !values.verified_on && isStudent ? 'Unverified' : 'Verify'}
                  </Button>
                </Form.InputGroupAppend>
              )}
            </Form.InputGroup>
          </Form.Group>
        </Grid.Col>
        <Grid.Col sm={6} md={6}>
          <Form.Group label="">
            <Form.InputGroup>
              <Form.Input
                name="license_number"
                placeholder=""
                value={values?.license_number}
                onChange={handleChange}
                disabled={values.verified_on && !isAdmin}
              />
              {dirty && (
                <Form.InputGroupAppend>
                  <Button
                    color="primary"
                    className="ml-2"
                    onClick={handleSubmit}
                  >
                    {values.id ? 'UPDATE' : 'SAVE'}
                  </Button>
                </Form.InputGroupAppend>
              )}
            </Form.InputGroup>
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
    );
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: license?.id || undefined,
        org_id: license?.org_id,
        student_id: license?.student_id,
        verified_on: license?.verified_on || null,
        verified_by: license?.verified_by || null,
        license: license?.license || '',
        license_number: license?.license_number || ''
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (license.id) {
          if (isStudent) {
            values.verified_by = null;
            values.verified_on = null;
          }
          updateStudentOrgCredential({
            variables: {
              updateOrgCredentialInput: {
                ...values
              }
            }
          });
        } else {
          if (!isStudent) {
            values.verified_by = currentUser.id.toString();
            values.verified_on = moment().format('YYYY-MM-DD');
          }
          addStudentOrgCredential({
            variables: {
              addOrgCredentialInput: {
                ...values
              }
            }
          });
        }
        resetForm();
        setSubmitting(false);
      }}
    >
      {(formData) => renderForm(formData)}
    </Formik>
  );
};

export default ManualAdLicenseForm;
