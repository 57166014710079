export function convertToTime(time: number) {
  const isNegative = time < 0;
  const hours = Math.floor(Math.abs(time) / 60);
  const minutes = Math.abs(time) % 60;
  const sign = isNegative ? '-' : '';

  return `${sign}${hours}:${minutes.toString().padStart(2, '0')}`;
}

export function toHoursAndMinutes(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60
  return `${hours.toString().padStart(2, '0')}h:${minutes
    .toString()
    .padStart(2, '0')}m`
}
