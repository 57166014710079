import React from 'react';
import { Avatar, Button, Dropdown, Grid, Icon, Text } from 'tabler-react';
import AddStudentToRegistrationGroupModal from './Modals/AddStudentToRegistrationGroupModal';
import { Types } from '../../types/graphql'

interface RegistrationGroupItemProps {
  group: Types.Group;
}

const RegistrationGroupItem = ({ group }: RegistrationGroupItemProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <Grid.Row>
        <Grid.Col className="mt-1">
          <strong>{group.name}</strong>
        </Grid.Col>
        <Grid.Col>
          <Button
            icon={'plus'}
            color="white"
            size="sm"
            className={'float-right'}
            onClick={() => setIsOpen(true)}
          />
          <AddStudentToRegistrationGroupModal
            group={group}
            isOpen={isOpen}
            toggle={() => setIsOpen(false)}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col className={'ml-4 mt-4'}>
          {group?.members.length > 0 &&
            group?.members.map((member) => (
              <div className="cursor-pointer">
                <Grid.Row>
                  <Grid.Col className="ml-0 pl-0">
                    <Avatar
                      size="md"
                      className="float-left mr-5 cursor-pointer"
                      imageURL={
                        (member.profile_avatar &&
                          `/api/s3/uploads/${member.profile_avatar}`) ||
                        '//www.gravatar.com/avatar?d=mp'
                      }
                    />
                    <Text>
                      <b>{`${member.first_name} ${member.last_name}`}</b>
                    </Text>
                    <Text>{member.roleName && `${member.roleName}`}</Text>
                  </Grid.Col>
                  <Grid.Col md={2} sm={2}>
                    <Dropdown
                      className={
                        'float-right cursor-pointer ' +
                        (group.color && 'text-white')
                      }
                      toggle={false}
                      arrow
                      triggerContent={<Icon name="more-vertical" />}
                      itemsObject={[
                        {
                          icon: 'user',
                          value: 'View'
                        },
                        {
                          icon: 'edit',
                          value: 'EDIT'
                        },

                        {
                          icon: 'x-circle',
                          value: 'DELETE'
                        }
                      ]}
                    />
                  </Grid.Col>
                </Grid.Row>
              </div>
            ))}
          {group.members.length <= 0 && (
            <div className="cursor-pointer">No members</div>
          )}
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default RegistrationGroupItem;
