import React, { useRef } from 'react'
import { Formik, FormikValues } from 'formik'
import { Button, Card, Form, Text } from 'tabler-react'
import * as Yup from 'yup'

import FormField from 'components/FormField'
import { useRootStore } from '../../../hooks'

const LoginPostForm = () => {
  const { redirectUrl } = useRootStore()
  const formEl = useRef(null)
  const doHtmlFormPost = (values: any) => {
    const current: any = formEl.current
    current.submit()
  }

  const loginValidationSchema = Yup.object().shape({
    email: Yup.string().required('This field is required.'),
    password: Yup.string().required('This field is required.')
  })

  const renderForm = ({ handleSubmit, isSubmitting }: FormikValues) => {
    return (
      <>
        <form
          className="login-form "
          method="POST"
          action="/auth/login"
          ref={formEl}
          onSubmit={handleSubmit}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSubmit()
            }
          }}
        >
          <Card>
            <Card.Body>
              <FormField
                appendleft={
                  <Button
                    icon={'mail'}
                    color={'secondary text-muted'}
                    disabled
                  />
                }
                name="email"
                label=""
                placeholder="Email"
                type="text"
              />
              <FormField
                appendleft={
                  <Button
                    icon={'lock'}
                    color={'secondary text-muted'}
                    disabled
                  />
                }
                name="password"
                type="password"
                label=""
                placeholder="Password"
                //  appendright={<Button icon={'eye'} color={'white text-muted'} disabled />}
              />
              <FormField name="redirect" type="hidden" value={redirectUrl} />
              <Form.Footer className="mb-3">
                <Button
                  block
                  type="submit"
                  color="primary"
                  className={`${isSubmitting && 'btn-loading'}`}
                  disabled={isSubmitting}
                >
                  LOGIN
                </Button>
              </Form.Footer>
              <Text className="text-center text-muted">
                <Text.Small>
                  <a href="/reset-password" className="">
                    Forgot Password?
                  </a>
                </Text.Small>
              </Text>
            </Card.Body>
          </Card>
        </form>
        <Text className="text-center text-muted">
          Don't have an account yet?{' '}
          <a href="/signup" className="">
            SIGNUP
          </a>
        </Text>
        <Text className="text-center text-muted">
          <a href="/help" className="text-info">
            Need Help?
          </a>
        </Text>
        <Text className="d-block text-center text-muted mt-5">
          Request a special account:
        </Text>
        <div className="text-center">
          <a
            href="https://skycru.com/coaching"
            target="_blank"
            rel="noopener noreferrer"
            className="mr-3 text-info"
          >
            Coaches
          </a>
          <a
            href="https://skycru.com/dzm"
            target="_blank"
            rel="noopener noreferrer"
            className="mr-3 text-info"
          >
            Dropzones
          </a>
          <a
            href="https://skycru.com/event_organizing"
            target="_blank"
            rel="noopener noreferrer"
            className="mr-3 text-info"
          >
            Event Organizers
          </a>
          <br />
          <a
            href="https://skycru.com/orgs"
            target="_blank"
            rel="noopener noreferrer"
            className="mr-3 text-info"
          >
            Organizations
          </a>
          {/* save for later
          <a href='https://skycru.com/packer' rel='noopener noreferrer' target='_blank' className='mr-3 text-info'>
            Packers
          </a>
          */}
          <a
            href="https://skycru.com/rigging"
            rel="noopener noreferrer"
            target="_blank"
            className="text-info"
          >
            Riggers & Lofts
          </a>
        </div>
      </>
    )
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        redirect: redirectUrl
      }}
      validationSchema={loginValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        console.log(values)
        setSubmitting(false)
        doHtmlFormPost(values)
      }}
    >
      {(formikData) => renderForm(formikData)}
    </Formik>
  )
}

export default LoginPostForm;
