import { useAutocomplete } from '../../../hooks/useAutocomplete'
import { Formik } from 'formik'
import React, { useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_USER_LOCATION } from '../../../graphql/UPDATE_USER_LOCATION'
import { toast } from 'react-toastify'
import { DELETE_USER_LOCATION } from '../../../graphql/DELETE_USER_LOCATION'
import { GET_LOCATIONS_BY_FILTER_QUERY } from '../../../graphql/GET_LOCATIONS_BY_FILTER_QUERY'
import { Button, Card, Form, Grid, Icon } from 'tabler-react'
import autoCompleteStyles from '../../Students/Forms/autocompleteStyles'
import AsyncSelect from 'react-select/async'
import { GET_USER_LOCATION } from '../../../graphql/GET_USER_LOCATION'
import { Types } from '../../../types/graphql'

interface IUserDzLocationFormProp {
  user: Types.Student
}

const UserDzLocationForm = ({ user }: IUserDzLocationFormProp) => {
  const [submitStudentLocation] = useMutation(UPDATE_USER_LOCATION)
  const [deleteUserLocation] = useMutation(DELETE_USER_LOCATION, {
    onCompleted: (result) => {
      if (result.deleteUserLocation) {
        toast.success('User location removed')
      }
    }
  })
  const { data: userLocation, loading: userLocationLoading } = useQuery(
    GET_USER_LOCATION,
    {
      variables: {
        student_id: Number(user?.id),
        flag_type_id: 6,
        location_type: 'dropzone'
      }
    }
  )

  const defaultHomeLocation = useMemo(() => {
    if (!userLocationLoading && !!userLocation?.getUserLocation)
      return userLocation.getUserLocation
    return null
  }, [userLocation, userLocationLoading])

  const { loadOptions } = useAutocomplete({
    query: GET_LOCATIONS_BY_FILTER_QUERY,
    options: { filter: { field: 'type', value: 'dropzone' } }
  })

  const refetchDz = [
    {
      query: GET_USER_LOCATION,
      variables: {
        student_id: Number(user?.id),
        flag_type_id: 6,
        location_type: 'dropzone'
      }
    }
  ]

  // TUNNEL
  const { data: dataTunnelLocation, loading: tunnelLocationLoading } = useQuery(
    GET_USER_LOCATION,
    {
      variables: {
        student_id: Number(user?.id),
        flag_type_id: 6,
        location_type: 'tunnel'
      }
    }
  )

  const defaultTunnelLocation = useMemo(() => {
    if (!tunnelLocationLoading && !!dataTunnelLocation?.getUserLocation)
      return dataTunnelLocation.getUserLocation
    return null
  }, [dataTunnelLocation, tunnelLocationLoading])

  const { loadOptions: tunnelOptions } = useAutocomplete({
    query: GET_LOCATIONS_BY_FILTER_QUERY,
    options: { filter: { field: 'type', value: 'tunnel' } }
  })

  const refetchT = [
    {
      query: GET_USER_LOCATION,
      variables: {
        student_id: Number(user?.id),
        flag_type_id: 6,
        location_type: 'tunnel'
      }
    }
  ]

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        dz_id: defaultHomeLocation?.user_location_id ?? undefined,
        dz_location_id: Number(defaultHomeLocation?.id),
        dz_location_name: defaultHomeLocation?.name ?? '',
        student_id: Number(user?.id),
        flag_type_id: 6,
        t_id: defaultTunnelLocation?.user_location_id ?? undefined,
        t_location_id: Number(defaultTunnelLocation?.id),
        t_location_name: defaultTunnelLocation?.name ?? ''
      }}
      onSubmit={async (
        values,
        { setSubmitting, setFieldValue, resetForm, setFieldError }
      ) => {
        let error = false
        if (!values.dz_location_id) {
          setFieldError('dz_location_id', 'Please select a valid location')
          error = true
        }

        if (!values.t_location_id) {
          setFieldError('t_location_id', 'Please select a valid location')
          error = true
        }

        if (error) {
          setSubmitting(false)
          return false
        }
        submitStudentLocation({
          variables: {
            options: {
              id: values.dz_id,
              student_id: values.student_id,
              location_id: values.dz_location_id,
              flag_type_id: 6
            }
          },
          refetchQueries: refetchDz
        })

        submitStudentLocation({
          variables: {
            options: {
              id: values.t_id,
              student_id: values.student_id,
              location_id: values.t_location_id,
              flag_type_id: 6
            }
          },
          refetchQueries: refetchT
        })
        toast.success('User locations updated')
        setSubmitting(false)
      }}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        setFieldValue,
        errors,
        touched
      }) => (
        <Form onSubmit={handleSubmit}>
          <Card>
            <Card.Header>
              <Card.Title>
                <Icon name="map-pin" className="mr-2 ml-0 text-blue" />
                My Locations
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Grid.Col md={12}>
                <Form.Group label="Home DZ:">
                  {!userLocationLoading && (
                    <>
                      <AsyncSelect
                        backspaceRemovesValue={true}
                        className="input-group"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                          LoadingIndicator: () => null
                        }}
                        escapeClearsValue={true}
                        getOptionLabel={(
                          option: Types.Location
                        ) => option.name}
                        getOptionValue={(
                          option: Types.Location
                        ) => option.id}
                        isClearable={true}
                        loadOptions={loadOptions}
                        name="dz_location_id"
                        onChange={(e, change) => {
                          if (change.action === 'clear') {
                            if (defaultHomeLocation?.user_location_id)
                              deleteUserLocation({
                                variables: {
                                  id: defaultHomeLocation?.user_location_id
                                },
                                refetchQueries: refetchDz
                              })
                          } else {
                            if (e) {
                              setFieldValue('dz_location_id', Number(e.id))
                              setFieldValue('dz_location_name', e.name)
                            } else setFieldValue('dz_location_id', null)
                          }
                        }}
                        value={
                          values.dz_location_id
                            ? {
                                id: values.dz_location_id,
                                name: values.dz_location_name
                              }
                            : null
                        }
                        placeholder="Select your home DZ (optional)"
                        styles={autoCompleteStyles}
                      />
                      <span className="field-error text-danger">
                        {errors.dz_location_id &&
                          touched.dz_location_id &&
                          errors.dz_location_id}
                      </span>
                    </>
                  )}
                </Form.Group>

                <Form.Group label="Home Tunnel:">
                  {!tunnelLocationLoading && (
                    <>
                      <AsyncSelect
                        backspaceRemovesValue={true}
                        className="input-group"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                          LoadingIndicator: () => null
                        }}
                        escapeClearsValue={true}
                        getOptionLabel={(
                          option: Types.Location
                        ) => option.name}
                        getOptionValue={(
                          option: Types.Location
                        ) => option.id}
                        isClearable={true}
                        loadOptions={tunnelOptions}
                        name="t_location_id"
                        onChange={(e, change) => {
                          if (change.action === 'clear') {
                            if (defaultTunnelLocation?.user_location_id)
                              deleteUserLocation({
                                variables: {
                                  id: defaultTunnelLocation?.user_location_id
                                },
                                refetchQueries: refetchT
                              })
                          } else {
                            if (e) {
                              setFieldValue('t_location_id', Number(e.id))
                              setFieldValue('t_location_name', e.name)
                            } else setFieldValue('t_location_id', null)
                          }
                        }}
                        value={
                          values.t_location_id
                            ? {
                                id: values.t_location_id,
                                name: values.t_location_name
                              }
                            : null
                        }
                        placeholder="Set home tunnel (optional)"
                        styles={autoCompleteStyles}
                      />
                      <span className="field-error text-danger">
                        {errors.t_location_id &&
                          touched.t_location_id &&
                          errors.t_location_id}
                      </span>
                    </>
                  )}
                </Form.Group>
              </Grid.Col>
            </Card.Body>
            <Card.Footer className="text-right">
              <Button
                pill
                className="btn btn-gray-dark"
                size="sm"
                onClick={(e) => {
                  e.preventDefault()
                  setFieldValue('action', 'add')
                  handleSubmit()
                }}
              >
                UPDATE
              </Button>
            </Card.Footer>
          </Card>
        </Form>
      )}
    </Formik>
  )
}

export default UserDzLocationForm
