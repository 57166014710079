import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { IconParachute } from "@tabler/icons-react";
import { DEFAULT_LIMIT } from "components/constants";
import { Loading } from "components/Loading";
import { Formik } from "formik";
import { observer } from "mobx-react";
import {
  adminStatusList,
  baseStatusList,
} from "modules/job/constants/statusList";
import moment from "moment";
import React, { useState } from "react";
import { NumericFormat } from "react-number-format";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Form, Grid, Tag } from "tabler-react";
import * as Yup from "yup";

import { ADD_JOB } from "../../../graphql/ADD_JOB";
import { ADD_USER_EVENT } from "../../../graphql/ADD_USER_EVENT";
import { GET_INVOICE } from "../../../graphql/GET_INVOICE";
import { GET_INVOICE_ITEMS } from "../../../graphql/GET_INVOICE_ITEMS";
import { GET_JOB_LIST } from "../../../graphql/GET_JOB_LIST";
import { GET_PRODUCTS_BY_TEAM } from "../../../graphql/GET_PRODUCTS_BY_TEAM";
import { GET_STUDENT_GEARS } from "../../../graphql/GET_STUDENT_GEARS";
import { TEAM_DETAILS_QUERY } from "../../../graphql/TEAM_DETAILS_QUERY";
import { UPDATE_INVOICE } from "../../../graphql/UPDATE_INVOICE";
import { UPDATE_INVOICE_ITEMS } from "../../../graphql/UPDATE_INVOICE_ITEMS";
import { UPDATE_JOB } from "../../../graphql/UPDATE_JOB";
import { useRootStore } from "../../../hooks";
import useGetCurrentUserType from "../../../modules/common/hooks/useGetCurrentUserType";
import { Types } from "../../../types/graphql";
import FormField from "../../FormField";
import Modal from "../../Modal";
import StudentSearchField from "../../Students/Forms/StudentSearchField";
import { EUserEvents, EUserEventsId } from "../../User/UserEvents";
import GearForm from "../Form/GearForm";

const StudentGearsLoader = () => {
  const [get, { data, loading, error }] = useLazyQuery(GET_STUDENT_GEARS);

  const gears = [...(data?.getUserGears ?? [])].sort((a, b) => {
    return a.gear_model.name.localeCompare(b.gear_model.name);
  });

  return {
    get: (options: Types.GetUserGearOptions) => {
      get({ variables: { getUserGearOptions: options } });
    },
    loading,
    error,
    data: gears,
  };
};

const ProductsByTeamLoader = (variables: Types.GET_PRODUCTS_BY_TEAMQueryVariables) => {
  const { loading, error, data, refetch } = useQuery(GET_PRODUCTS_BY_TEAM, {variables})
  const products = data?.getProductsByTeam || []
  return {
    get: (refetchVars: Types.GET_PRODUCTS_BY_TEAMQueryVariables) => {
      refetch({variables: refetchVars})
    },
    loading,
    error,
    data: products
  }
}

interface GearJobModalFormProps {
  isModalOpen: boolean;
  toggleModal: any;
  userGearJob: any;
  autoClose?: boolean;
}

const addUserGearValidationSchema = Yup.object().shape({
  product_id: Yup.number().required('This field is required.'),
  student_id: Yup.number().required('This field is required.')
})

const GearJobModalForm = (props: GearJobModalFormProps) => {
  const rootStore = useRootStore()
  const { currentCoachTeam } = rootStore

  const { loading, error, data: dataCoaches } = useQuery(TEAM_DETAILS_QUERY, {
    variables: {
      team_id: currentCoachTeam?.id ?? props.userGearJob?.product?.team_id
    }
  })

  const ProductsByTeam = ProductsByTeamLoader({
    filter: {
      team_id:
        currentCoachTeam?.id.toString() ??
        props.userGearJob?.product?.team_id.toString(),
      status: "active" as Types.ProductStatus,
    },
  });

  if (loading || ProductsByTeam.loading) {
    return <Loading />
  }

  for (const e of [error, ProductsByTeam.error]) {
    if (e) {
      console.log(e)
      return <p>Error: {e.message}</p>
    }
  }

  const products = ProductsByTeam.data
  const coaches = dataCoaches?.geCoachesByTeamId || []

  return <GearJobModalFormInner {...props}
    products={products}
    coaches={coaches}
    />
}

const GearJobModalFormInner = ({
  isModalOpen,
  toggleModal,
  userGearJob,
  autoClose,
  products,
  coaches,
}: any) => {
  const rootStore = useRootStore();
  const { currentUser, currentCoachTeam } = rootStore;
  const { isAdmin } = useGetCurrentUserType();
  const [studentId, setStudentId] = useState(userGearJob.student_id ?? null);

  const StudentGears = StudentGearsLoader();

  const [showAddGear, setShowAddGear] = useState(false);

  const [addUserEvent] = useMutation(ADD_USER_EVENT);

  const [addJob] = useMutation(ADD_JOB, {
    onCompleted: (result) => {
      if (result?.addJob) {
        addUserEvent({
          variables: {
            userEvent: {
              created_on: moment().format("YYYY-MM-DD"),
              location_id: currentCoachTeam.default_location_id,
              status: EUserEvents.job_added,
              student_id: studentId,
              team_id: currentCoachTeam.id,
              job_id: result?.addJob.id,
              coach_id: currentUser.id,
              user_event_type_id: EUserEventsId.job_added,
            },
          },
        });
      }
    },
  });

  const [updateJob] = useMutation(UPDATE_JOB);
  const [updateInvoice] = useMutation(UPDATE_INVOICE);
  const [updateInvoiceItems] = useMutation(UPDATE_INVOICE_ITEMS);
  const handleClose = () => {
    toggleModal(false);
  };

  const customFilter = (option, searchText) => {
    if (
      option.data.gear_model.name
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      option.data.name.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.gear_serial
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      option.data.rig?.name.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  const renderForm = ({
    values,
    handleSubmit,
    isSubmitting,
    handleChange,
    setFieldValue,
    errors,
    touched,
  }) => (
    <>
      {!showAddGear && (
        <form onSubmit={handleSubmit}>
          <Grid.Row className="mb-4">
            <Grid.Col xs={12} sm={12} lg={6}>
              <Form.Group label="Customer:">
                <StudentSearchField
                  autoFocus={true}
                  isDisabled={!!studentId}
                  onChange={(param) => {
                    if (!param) return;
                    setFieldValue("student_id", param.id);
                    setStudentId(param.id);
                    StudentGears.get({ student_id: parseInt(param.id) });
                  }}
                  defaultValue={
                    userGearJob?.student
                      ? `${userGearJob?.student.first_name} ${userGearJob?.student.last_name}`
                      : ""
                  }
                />
                <span className="field-error text-danger">
                  {errors.student_id && touched.student_id && errors.student_id}
                </span>
              </Form.Group>
            </Grid.Col>
            {values?.student_id && (
              <Grid.Col xs={12} sm={12} lg={6}>
                {!showAddGear && (
                  <Form.Group
                    label={
                      <label className={"form-label"}>
                        Gear:{" "}
                        {
                          <Tag
                            color="white"
                            className="text-primary float-right cursor-pointer font-weight-bold"
                            onClick={() => setShowAddGear(true)}
                          >
                            + GEAR
                          </Tag>
                        }
                      </label>
                    }
                  >
                    <>
                      <Select
                        name="user_gear_id"
                        placeholder="Select Gear"
                        isMulti={false}
                        getOptionLabel={(option: Types.UserGear) => {
                          return (
                            <>
                              {option?.rig?.name ? (
                                <Tag color="primary" className="float-right">
                                  {option?.rig?.name}
                                </Tag>
                              ) : (
                                ""
                              )}
                              {option.name} {option.gear_model.name}{" "}
                              {option.gear_canopy_size > 0
                                ? option.gear_canopy_size
                                : ""}{" "}
                              {
                                <span className={"text-muted"}>
                                  {option.gear_serial}
                                </span>
                              }
                            </>
                          );
                        }}
                        getOptionValue={(option: Types.UserGear) => option.id}
                        filterOption={customFilter}
                        options={StudentGears.data}
                        onChange={(param: Types.UserGear) => {
                          setFieldValue("user_gear_id", param.id);
                        }}
                        value={StudentGears.data.filter(
                          (gear: Types.UserGear) =>
                            gear.id === values?.user_gear_id,
                        )}
                      />
                      <span className="field-error text-danger">
                        {errors.rigger_id &&
                          touched.rigger_id &&
                          errors.rigger_id}
                      </span>
                    </>
                  </Form.Group>
                )}
              </Grid.Col>
            )}
          </Grid.Row>
          <Grid.Row className="mb-4">
            <Grid.Col xs={12} sm={12} lg={6}>
              <Form.Group label="Job/Service:">
                <Select
                  name="product_id"
                  placeholder="Select a service/product..."
                  isMulti={false}
                  getOptionLabel={(option: Types.Product) => option.title}
                  getOptionValue={(option: Types.Product) => option.id}
                  options={products}
                  onChange={(param: Types.Product) => {
                    setFieldValue("name", param.title);
                    setFieldValue("unit_price", param.amount);
                    setFieldValue("product_id", param.id);
                    setFieldValue(
                      "job_type_id",
                      param.title.startsWith("Reserve Repack") ? 2 : 1,
                    );
                  }}
                  value={products.filter(
                    (product: Types.Product) =>
                      product.id === values?.product_id,
                  )}
                />
                <span className="field-error text-danger">
                  {errors.product_id && touched.product_id && errors.product_id}
                </span>
              </Form.Group>
            </Grid.Col>
            <Grid.Col lg={2} xs={4}>
              <NumericFormat
                customInput={FormField}
                name="unit_price"
                label="Price:"
                placeholder="Price"
                value={values.unit_price}
                prefix="$"
                thousandSeparator=","
                onChange={(e) =>
                  setFieldValue(
                    "unit_price",
                    parseFloat(
                      e.target.value.replace("$", "").replaceAll(",", ""),
                    ),
                  )
                }
              />
            </Grid.Col>
            <Grid.Col xs={4} sm={4} lg={2}>
              <Form.Group label="Qty:">
                <NumericFormat
                  customInput={FormField}
                  name="quantity"
                  placeholder="Qty"
                  additionalgroupclasses={["mb-0"]}
                  value={values.quantity}
                  decimalScale={2}
                  allowLeadingZeros={false}
                  allowNegative={false}
                  onChange={(e) =>
                    setFieldValue("quantity", parseFloat(e.target.value))
                  }
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col xs={4} sm={4} lg={2}>
              <Form.Group label="Storage:">
                <FormField
                  type="number"
                  name="job_storage"
                  placeholder="Bin"
                  onChange={handleChange}
                  value={values.job_storage}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <hr />
          <Grid.Row className="mb-4">
            <Grid.Col xs={12} sm={12} lg={6}>
              <Form.Group label="Rigger:">
                <Select
                  name="rigger_id"
                  placeholder="Search or select..."
                  isMulti={false}
                  getOptionLabel={(option: Types.TeamCoach) =>
                    `${option.first_name} ${option.last_name}`
                  }
                  getOptionValue={(option: Types.TeamCoach) => option.id}
                  options={coaches.filter(
                    (coach: Types.TeamCoach) => coach.is_active === true,
                  )}
                  onChange={(param) => {
                    setFieldValue("rigger_id", param.id);
                  }}
                  value={coaches.filter(
                    (coach: Types.TeamCoach) => coach.id === values?.rigger_id,
                  )}
                />
                <span className="field-error text-danger">
                  {errors.rigger_id && touched.rigger_id && errors.rigger_id}
                </span>
              </Form.Group>
            </Grid.Col>
            {userGearJob?.invoice_id && (
              <Grid.Col width={4}>
                <Form.Group label="Completed On:">
                  <FormField
                    type="date"
                    name="completed_on"
                    placeholder="Date"
                    onChange={handleChange}
                    value={values.completed_on}
                  />
                </Form.Group>
              </Grid.Col>
            )}
          </Grid.Row>
          <hr />
          <Grid.Row className="mb-4">
            <Grid.Col xs={12} sm={12} lg={6}>
              <Form.Group label="Received By:">
                <Select
                  name="created_by"
                  isMulti={false}
                  getOptionLabel={(option: Types.TeamCoach) =>
                    `${option.first_name} ${option.last_name}`
                  }
                  getOptionValue={(option: Types.TeamCoach) => option.id}
                  options={coaches.filter(
                    (coach: Types.TeamCoach) => coach.is_active === true,
                  )}
                  onChange={(param) => {
                    setFieldValue("created_by", param.id);
                  }}
                  value={coaches.filter(
                    (coach: Types.TeamCoach) => coach.id === values?.created_by,
                  )}
                />
                <span className="field-error text-danger">
                  {errors.created_by && touched.created_by && errors.created_by}
                </span>
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={4}>
              <Form.Group label="Due Date:">
                <FormField
                  type="date"
                  name="due_on"
                  placeholder="Date"
                  onChange={handleChange}
                  value={values.due_on}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col width={12}>
              <Form.Group label="">
                <Form.Textarea
                  name="job_notes"
                  onChange={handleChange}
                  placeholder="Add notes"
                  rows={3}
                  type="text"
                  value={values.job_notes}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <IconParachute
            className={`${
              values.job_type_id === 2 ? "text-primary" : "text-muted"
            }`}
            size={20}
            onClick={() =>
              setFieldValue("job_type_id", values.job_type_id === 1 ? 2 : 1)
            }
          />
          <Button
            disabled={isSubmitting}
            pill
            className="btn-gray-dark float-right btn-sm"
            onClick={handleSubmit}
          >
            {isSubmitting ? "Processing..." : "SUBMIT"}
          </Button>
          <Button
            pill
            color="white"
            className="float-right btn-sm"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
          >
            CANCEL
          </Button>
        </form>
      )}

      {
        showAddGear && (
          <GearForm
            userGear={{
              user_gear_id: null,
              student_id: Number(studentId),
            }}
            toggleModal={setShowAddGear}
            isModal={false}
            studentId={Number(studentId)}
            setValue={setFieldValue}
          />
        )
        // <GearAddByModel studentId={Number(userGearJobClone?.student_id)} setValue={setFieldValue}
        //                 toggleForm={setShowAddGear} />
      }
    </>
  );

  return (
    <Modal
      content={
        <Formik
          enableReinitialize={true}
          validationSchema={addUserGearValidationSchema}
          initialValues={{
            id: userGearJob?.id ?? null,
            student_id: studentId,
            user_gear_id: userGearJob?.user_gear_id,
            rigger_id: userGearJob?.rigger_id ?? undefined,
            job_type_id: userGearJob?.job_type_id ?? 1,
            product_id: userGearJob?.product_id ?? "",
            priority: userGearJob?.priority ?? "Normal",
            status: userGearJob?.status ?? "Open",
            job_notes: userGearJob?.job_notes ? userGearJob?.job_notes : "",
            job_storage: userGearJob?.job_storage
              ? userGearJob?.job_storage
              : "",
            created_by: userGearJob?.created_by ?? currentUser.id,
            due_on:
              userGearJob?.due_on ?? moment().add(6, "d").format("YYYY-MM-DD"),
            team_id: userGearJob?.team_id ?? currentCoachTeam?.id,
            name: userGearJob?.name ?? "",
            unit_price: userGearJob?.unit_price ?? 0,
            quantity: userGearJob?.quantity ?? 1,
            completed_on: userGearJob?.completed_on,
          }}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            const refetch = {
              query: GET_JOB_LIST,
              variables: {
                jobListFilter: {
                  status: isAdmin ? adminStatusList : baseStatusList,
                  team_id: isAdmin ? undefined : currentCoachTeam?.id,
                  limit: DEFAULT_LIMIT,
                  offset: 0,
                },
              },
            };

            if (values.id) {
              if (values.status === "Open" && values.rigger_id) {
                values.status = "Active";
              }
              await updateJob({
                variables: {
                  updateJobInput: {
                    id: values.id,
                    user_gear_id: values.user_gear_id,
                    rigger_id: values.rigger_id,
                    job_type_id: values.job_type_id,
                    product_id: values.product_id,
                    priority: values.priority,
                    status: values.status,
                    job_notes: values.job_notes,
                    job_storage: values.job_storage ? values.job_storage : null,
                    created_by: values.created_by,
                    due_on: values.due_on,
                    team_id: values.team_id,
                    name: values.name,
                    unit_price: values.unit_price,
                    quantity: values.quantity,
                  },
                },
                                refetchQueries: [refetch],
                              });
              resetForm();
              setSubmitting(false);
              toggleModal(!isModalOpen);
            } else {
              const completed_on = userGearJob?.invoice_id
                ? { completed_on: values.completed_on }
                : undefined;
              const newJob = await addJob({
                variables: {
                  addJobInput: {
                    student_id: parseInt(values.student_id),
                    user_gear_id: values.user_gear_id,
                    rigger_id: values.rigger_id,
                    job_type_id: values.job_type_id,
                    product_id: values.product_id,
                    priority: values.priority,
                    status: userGearJob?.invoice_id
                      ? "Invoice"
                      : values.rigger_id
                        ? "Active"
                        : "Open",
                    job_notes: values.job_notes,
                    job_storage: values.job_storage ? values.job_storage : null,
                    created_by: values.created_by,
                    due_on: values.due_on,
                    team_id: values.team_id,
                    name: values.name,
                    unit_price: values.unit_price,
                    quantity: values.quantity,
                    ...completed_on,
                  },
                },
                                            refetchQueries: [refetch],
                                          });

              if (!!newJob?.data.addJob && userGearJob?.invoice_id) {
                await updateInvoiceItems({
                  variables: {
                    updateInvoiceItemsInput: {
                      invoice_id: parseInt(userGearJob.invoice_id),
                      job_ids: [newJob?.data.addJob.id],
                    },
                  },
                  refetchQueries: [
                    {
                      query: GET_JOB_LIST,
                      variables: {
                        jobListFilter: {
                          invoice_id: parseInt(userGearJob.invoice_id),
                        },
                      },
                    },
                    {
                      query: GET_INVOICE_ITEMS,
                      variables: {
                        invoice_id: parseInt(userGearJob.invoice_id),
                      },
                    },
                  ],
                                         });
                await updateInvoice({
                  variables: {
                    updateInvoiceInput: {
                      id: parseInt(userGearJob.invoice_id),
                      invoice_amount:
                        parseFloat(userGearJob.invoice_total) +
                        values.unit_price * values.quantity,
                    },
                  },
                  refetchQueries: [
                    {
                      query: GET_INVOICE,
                      variables: {
                        id: parseInt(userGearJob.invoice_id),
                      },
                    },
                  ],
                                    });
              }
              resetForm();
              setSubmitting(false);
              if (!autoClose) {
                toggleModal(!isModalOpen);
              }
            }
            toast.success("Job saved!");
          }}
        >
          {(formikData) => renderForm(formikData)}
        </Formik>
      }
      open={isModalOpen}
      title="Job Intake Form"
      onClose={() => {
        setShowAddGear(false);
        toggleModal(!isModalOpen);
      }}
    />
  );
};

export default observer(GearJobModalForm)
