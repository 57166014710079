import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import logo from '../assets/images/logo.png';
import FreshChatWidget from '../widgets/FreshChatWidget';
import SignUpForm from '../components/User/Forms/SignUpForm';
import { UserStoreProvider } from '../contexts/userStoreContext';
import StudentSignupGearRig from '../components/Gear/StudentSignupGearRig';
import StudentSignupMerits from '../components/Students/Forms/StudentSignupMerits';
import useReactRouter from 'use-react-router';

const SignUpPage = (props: any) => {
  const { history } = useReactRouter();
  const [step, setStep] = useState(0);
  useEffect(() => {
    if (step === 3) {
      history.push('/login');
    }
     
  }, [step]);
  return (
    <>
      <div className="page">
        <div className="page-single">
          <div className="container">
            <div className="row">
              <div className={`col ${step === 0 && 'col-login'} mx-auto`}>
                <div className="text-center mb-6">
                  <Link to="/">
                    <img src={logo} className="h-6" alt="logo" />
                  </Link>
                </div>
                <UserStoreProvider>
                  {step === 0 && (
                    <div className="col col-login mx-auto">
                      <SignUpForm {...props} setStep={setStep} />
                    </div>
                  )}

                  {/*{step === 1 &&*/}
                  {/*  <div className='col mx-auto'>*/}
                  {/*    <StudentSignupActivity setStep={setStep} />*/}
                  {/*  </div>*/}
                  {/*}*/}

                  {step === 1 && (
                    <div className="col mx-auto">
                      <StudentSignupGearRig setStep={setStep} />
                    </div>
                  )}

                  {step === 2 && (
                    <div className="col mx-auto">
                      <StudentSignupMerits setStep={setStep} />
                    </div>
                  )}
                </UserStoreProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FreshChatWidget />
    </>
  );
};

export default SignUpPage;
