import React from 'react'
import * as Yup from 'yup'
import { useMutation, useQuery } from '@apollo/client'
import { ADD_OR_EDIT_USER_GEAR } from '../../../graphql/ADD_OR_EDIT_USER_GEAR'
import { toast } from 'react-toastify'
import { GET_ALL_GEAR_MODELS } from '../../../graphql/GET_ALL_GEAR_MODELS'
import FormField from '../../FormField'
import { Formik } from 'formik'
import { Button, Form, Grid } from 'tabler-react'
import Select from 'react-select'
import { Types } from '../../../types/graphql'

interface MainGearFormProps {
  toggle: any
  student_id: number
  team_id: number
}

const addUserGearValidationSchema = Yup.object().shape({
  gear_model_id: Yup.number().required('This field is required.')
})
const MainGearForm = ({ toggle, student_id, team_id }: MainGearFormProps) => {
  const [updateUserGear] = useMutation(ADD_OR_EDIT_USER_GEAR, {
    onCompleted: () => {
      toggle()
      toast.success('Gear submitted.')
    },
    refetchQueries: ['GET_STUDENT_GEARS']
  })

  const { data } = useQuery(GET_ALL_GEAR_MODELS)

  const renderForm = ({
    values,
    handleSubmit,
    isSubmitting,
    handleChange,
    setFieldValue,
    errors,
    touched
  }) => (
    <form onSubmit={handleSubmit}>
      <Grid.Row className="mb-4">
        <Grid.Col xs={12} sm={12} lg={6}>
          <Form.Group label="Main Canopy">
            <Select
              name="gear_model_id"
              autoFocus={true}
              placeholder="Search main canopy models..."
              isMulti={false}
              getOptionLabel={(option: Types.GearModel) =>
                option.name
              }
              getOptionValue={(option: Types.GearModel) =>
                option.id
              }
              options={data?.getGearModels.filter(
                (model) => model.gear_type_id === 1
              )}
              onChange={(param) => {
                setFieldValue('gear_model_id', param.id)
              }}
              // value={data?.getGearModels.filter((gearType) => gearType.id === values?.gear_model_id)}
            />
            <span className="field-error text-danger">
              {errors.gear_model_id &&
                touched.gear_model_id &&
                errors.gear_model_id}
            </span>
          </Form.Group>
        </Grid.Col>
        <Grid.Col width={4}>
          <Form.Group label="Canopy Size">
            <FormField
              type="number"
              placeholder=""
              name="gear_canopy_size"
              onChange={handleChange}
              value={values.gear_canopy_size}
            />
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col className="pt-2" xs={12} sm={12} lg={12}>
          <Button
            type="submit"
            disabled={isSubmitting}
            pill
            className="btn-gray-dark float-right btn-sm"
          >
            {isSubmitting ? 'Processing...' : 'SAVE'}
          </Button>
          <Button
            pill
            color="white"
            className="float-right btn-sm mr-2"
            onClick={(e) => {
              e.preventDefault()
              toggle()
            }}
          >
            CANCEL
          </Button>
        </Grid.Col>
      </Grid.Row>
    </form>
  )
  return (
    <Formik
      enableReinitialize={true}
      validationSchema={addUserGearValidationSchema}
      initialValues={{
        name: '',
        student_id: student_id,
        team_id: team_id,
        gear_model_id: '',
        gear_serial: '',
        gear_dom_day: '',
        gear_dom_month: '',
        gear_dom_year: '',
        gear_canopy_size: '',
        gear_notes: ''
      }}
      onSubmit={async (values, { resetForm, setSubmitting, setFieldError }) => {
        let day: any = 1

        if (values?.gear_dom_day) day = values?.gear_dom_day
        const dom = new Date(
          values?.gear_dom_year +
            '-' +
            (parseInt(values?.gear_dom_month.toString()) + 1) +
            '-' +
            day
        )

        if (!values.gear_model_id) {
          setFieldError('gear_model_id', 'Select Model')
          setSubmitting(false)
          return false
        }

        if (
          !values.gear_canopy_size ||
          Number(values.gear_canopy_size) < 50 ||
          Number(values.gear_canopy_size) > 500
        ) {
          setFieldError(
            'gear_canopy_size',
            'Canopy size must be between 50 and 500'
          )
          setSubmitting(false)
          return false
        }

        await updateUserGear({
          variables: {
            updateUserGearInput: {
              name: values?.name,
              student_id: values?.student_id,
              team_id: values?.team_id,
              gear_model_id: values?.gear_model_id,
              gear_serial: values?.gear_serial,
              gear_dom: dom,
              gear_canopy_size: Number(values?.gear_canopy_size),
              gear_notes: values?.gear_notes,
              // legacy START - remove when DB is updated
              gear_manufacturer: '',
              gear_model: ''
              //   legacy END
            }
          }
        })
        setSubmitting(false)
        resetForm()
      }}
    >
      {(formikData) => renderForm(formikData)}
    </Formik>
  )
}

export default MainGearForm
