import React, { FC } from 'react';
import { Container, Nav } from 'tabler-react';

interface NavbarProps {
  collpase: boolean;
}

const Navbar: FC<NavbarProps> = ({ children, collpase }) => {
  return (
    <div className={`header d-lg-flex p-0 ${collpase && 'collapse'}`}>
      <Container>
        <Nav className="border-0 flex-column flex-lg-row">{children}</Nav>
      </Container>
    </div>
  );
};

export default Navbar;
