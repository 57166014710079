import React from 'react'
import {
  registrationStatusColor,
  registrationStatusIcon,
  registrationStatusType
} from '../../constants'
import { Button, Card, Form, Grid } from 'tabler-react'
import Select from 'react-select'
import { useMutation } from '@apollo/client'
import { TOGGLE_TEAM_REGISTRATION_STATUS } from '../../../graphql/TOGGLE_TEAM_REGISTRATION_STATUS'
import { UPDATE_TEAM_REGISTRATION_STATUS } from '../../../graphql/UPDATE_TEAM_REGISTRATION_STATUS'
import { toast } from 'react-toastify'

const RegistrationStatusItem = ({ status, team, loading }) => {
  const [statusOptions, setStatusOptions] = React.useState({
    icon: status.icon ?? null,
    color: status.color ?? null,
    type: status.type ?? null
  })
  const [showOptions, setShowOptions] = React.useState(false)
  const [toggleStatus, { loading: submitting }] = useMutation(
    TOGGLE_TEAM_REGISTRATION_STATUS,
    {
      refetchQueries: ['GET_TEAM_REGISTRATION_STATUSES']
    }
  )
  const [teamRegistrationStatus] = useMutation(
    UPDATE_TEAM_REGISTRATION_STATUS,
    {
      refetchQueries: ['GET_TEAM_REGISTRATION_STATUSES'],
      onCompleted: () => {
        toast.success('Registration Status Updated')
      }
    }
  )

  const handleToggleStatus = (status_id) => {
    toggleStatus({
      variables: { team_id: team.id, status_id }
    })
  }

  const handleSave = () => {
    teamRegistrationStatus({
      variables: {
        teamRegistrationStatusInput: {
          team_registration_status_id: status.team_registration_status_id,
          icon: statusOptions.icon,
          color: statusOptions.color,
          type: statusOptions.type
        }
      }
    })
    console.log(statusOptions)
  }
  return (
    <div className={'mb-2'}>
      <span>{status.name}</span>{' '}
      <Form.Switch
        disabled={loading || submitting}
        className="float-right"
        name="status"
        checked={status.is_exists}
        onClick={() => handleToggleStatus(status.id)}
      />
      {status.is_exists && (
        <Button
          className="float-right"
          size={'sm'}
          icon={status.icon}
          color={status.color ?? 'white'}
          onClick={() => setShowOptions(!showOptions)}
        >
          EDIT
        </Button>
      )}
      {showOptions && (
        <Card className={'mt-2'}>
          <Card.Header>
            <Card.Title>{status.name}</Card.Title>
          </Card.Header>
          <Card.Body>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={'Icon'}>
                  <Select
                    options={registrationStatusIcon}
                    value={registrationStatusIcon.find(
                      (icon) => icon.value === statusOptions.icon
                    )}
                    onChange={(e) => {
                      setStatusOptions({ ...statusOptions, icon: e.value })
                    }}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col>
                <Form.Group label={'Color'}>
                  <Select
                    options={registrationStatusColor}
                    value={registrationStatusColor.find(
                      (color) => color.value === statusOptions.color
                    )}
                    onChange={(e) => {
                      setStatusOptions({ ...statusOptions, color: e.value })
                    }}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={'Type'}>
                  <Select
                    isClearable={true}
                    options={registrationStatusType}
                    value={registrationStatusType.find(
                      (type) => type.value === statusOptions.type
                    )}
                    onChange={(e) => {
                      setStatusOptions({
                        ...statusOptions,
                        type: e?.value ?? null
                      })
                    }}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
          <Card.Footer>
            <Button
              className={'float-right'}
              pill
              size={'sm'}
              color={'gray-dark'}
              onClick={() => {
                handleSave()
                setShowOptions(false)
              }}
            >
              Save
            </Button>
            <Button
              className={'float-right'}
              pill
              size={'sm'}
              color={'white'}
              onClick={() => {
                setShowOptions(false)
              }}
            >
              Cancel
            </Button>
          </Card.Footer>
        </Card>
      )}
    </div>
  )
}

export default RegistrationStatusItem
