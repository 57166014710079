import parsePhoneNumber from 'libphonenumber-js';

// Format phone number using libphonenumber-js
export const formatPhoneNumber = (phoneNumber: string) => {
  const phone = parsePhoneNumber(phoneNumber, 'US');
  if (!phone) {
    return '';
  }

  return phone.formatInternational();
};

// +1 (951) 519-5402
// Strip phone number of all non-numeric characters
export const stripPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/\D/g, '');
};
