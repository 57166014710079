import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../assets/images/logo.png';
import ResetPasswordForm from '../components/User/Forms/ResetPasswordForm';
import { UserStoreProvider } from '../contexts/userStoreContext';
import FreshChatWidget from '../widgets/FreshChatWidget';

const ResetPasswordPage = (props: any) => {
  return (
    <>
      <div className="page">
        <div className="page-single">
          <div className="container">
            <div className="row">
              <div className="col col-login mx-auto">
                <div className="text-center mb-6">
                  <Link to="/">
                    <img src={logo} className="h-6" alt="logo" />
                  </Link>
                </div>
                <UserStoreProvider>
                  <ResetPasswordForm {...props} />
                </UserStoreProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FreshChatWidget />
    </>
  );
};

export default ResetPasswordPage;
