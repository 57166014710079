import gql from 'graphql-tag';

export const GET_STUDENT_TEAM_PROGRESSION = gql`
  query GET_STUDENT_TEAM_PROGRESSION($teamId: Int, $studentId: Int) {
    getStudentTeamProgressions(teamId: $teamId, studentId: $studentId) {
      id
      heading
      title
      is_disabled
      subheading
      team_id
      studentProgressionId
      studentProgressionStatus
      studentProgressionNotes
      is_exist
      rating
    }
  }
`;
