import { useMutation, useQuery } from '@apollo/client'
import FormField from 'components/FormField'
import { Formik } from 'formik'
import { CAMP_HAS_JUMP_TICKETS } from 'graphql/CAMP_HAS_JUMP_TICKETS'
import { CREATE_USER_REGISTRATION_OPTION } from 'graphql/CREATE_USER_REGISTRATION_OPTION'
import { GET_CAMP_BY_ID } from 'graphql/GET_CAMP_BY_ID'
import { GET_CAMP_REGISTRATION_OPTIONS } from 'graphql/GET_CAMP_REGISTRATION_OPTIONS'
import { GET_REGISTERED_STUDENTS } from 'graphql/GET_REGISTERED_STUDENTS'
import { GET_CAMP_BY_ID as GET_CAMP_BY_ID_TYPE } from 'graphql/types/GET_CAMP_BY_ID'
import { GET_CAMP_REGISTRATION_OPTIONS as GET_CAMP_REGISTRATION_OPTIONS_TYPE } from 'graphql/types/GET_CAMP_REGISTRATION_OPTIONS'
import { useRootStore } from 'hooks'
import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Button, Grid, Text } from 'tabler-react'

type AddJumpTicketsFormProps = {
  toggleModal: () => void
}

const AddJumpTicketsForm = ({ toggleModal }: AddJumpTicketsFormProps) => {
  const { currentCoachTeam } = useRootStore()
  const { campId } = useParams<{ campId: string }>()

  const { data: campData } = useQuery<GET_CAMP_BY_ID_TYPE>(GET_CAMP_BY_ID, {
    variables: {
      campId: Number(campId)
    }
  })

  const { data } = useQuery<GET_CAMP_REGISTRATION_OPTIONS_TYPE>(
    GET_CAMP_REGISTRATION_OPTIONS,
    {
      variables: {
        campId: Number(campId),
        teamId: currentCoachTeam?.id
      }
    }
  )

  const [createUserRegistrationOption, { loading }] = useMutation(
    CREATE_USER_REGISTRATION_OPTION,
    {
      onCompleted: () => {
        toast.success('Jump tickets added successfully')
      }
    }
  )

  const expectedJumps = campData?.getCampById.default_jump_count

  const registrationOptions = useMemo(() => {
    if (data) {
      // Filter fee_type_id = 5 (per jump) and is_exist = true
      return data?.getAvailableCampRegistrationOptions.filter(
        (option) => Number(option.fee_type_id) === 5 && option.is_exist
      )
    }

    return []
  }, [data?.getAvailableCampRegistrationOptions])  

  const renderForm = ({ handleChange, handleSubmit }) => {
    return (
      <form onSubmit={handleSubmit}>
        {registrationOptions.map((option) => (
          <Grid.Row className="mb-4 align-items-center" key={option.id}>
            <Grid.Col width={6}>
              <Text>{option.description}</Text>
            </Grid.Col>
            <Grid.Col sm={6} lg={3}>
              <FormField
                additionalgroupclasses={['mb-0']}
                name={option.id}
                type="number"
                onChange={handleChange}
              />
            </Grid.Col>
          </Grid.Row>
        ))}

        <Grid.Row className="mb-4 align-items-center">
          <Grid.Col width={6}>
            <Text>Expected Jumps</Text>
          </Grid.Col>
          <Grid.Col sm={6} lg={3}>
            <Text className="text-right">{expectedJumps}</Text>
          </Grid.Col>
        </Grid.Row>
        <div className="modal-footer pr-0">
          <Button
            color="primary"
            disabled={loading || registrationOptions.length === 0}
          >
            SUBMIT
          </Button>
        </div>
      </form>
    )
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          const totalJumps = Object.values(values).reduce(
            (acc, value) => Number(acc) + Number(value),
            0
          )

          if (totalJumps !== expectedJumps) {
            toast.error('Total jumps must match expected jumps')
            return
          }

          const userRegistrationOptionInputFields = Object.entries(values).map(
            ([registrationOptionId, quantity]) => ({
              registration_option_id: Number(registrationOptionId),
              quantity: Number(quantity)
            })
          )

          await createUserRegistrationOption({
            variables: {
              createUserRegistrationOptionInput: {
                camp_id: Number(campId),
                userRegistrationOptionInputFields
              }
            },
            refetchQueries: [
              {
                query: GET_REGISTERED_STUDENTS,
                variables: {
                  campId: Number(campId)
                }
              },
              {
                query: CAMP_HAS_JUMP_TICKETS,
                variables: {
                  campId: Number(campId)
                }
              }
            ]
          })

          setSubmitting(false)
          resetForm()
          toggleModal()
        }}
      >
        {(formikData) => renderForm(formikData)}
      </Formik>
    </>
  )
}

export default AddJumpTicketsForm
