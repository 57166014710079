import { useMutation, useQuery } from '@apollo/client';
import FormField from 'components/FormField';
import { Formik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Button, Form, Grid, Text } from 'tabler-react';
import * as Yup from 'yup';

import Modal from '../../../components/Modal';
import { useRootStore } from '../../../hooks/useRootStore';
import { ADD_REGISTRATION_QUESTION } from '../../../graphql/ADD_REGISTRATION_QUESTION';
import { UPDATE_REGISTRATION_QUESTION } from '../../../graphql/UPDATE_REGISTRATION_QUESTION';
import { GET_REGISTRATION_QUESTIONS } from '../../../graphql/GET_REGISTRATION_QUESTIONS';
import {
  GET_TEAM_SPORTS as GET_TEAM_SPORTS_TYPE,
} from '../../../graphql/types/GET_TEAM_SPORTS';
import { GET_TEAM_SPORTS } from '../../../graphql/GET_TEAM_SPORTS';
import Select from 'react-select';
import { GET_TEAM_LOCATIONS } from '../../../graphql/GET_TEAM_LOCATIONS';
import { EQuestionTypes } from '../../constants';
import RegistrationQuestionOption from '../Form/RegistrationQuestionOption';
import { ADD_OR_EDIT_REGISTRATION_QUESTION } from '../../../graphql/ADD_OR_EDIT_REGISTRATION_QUESTION';
import { Types } from '../../../types/graphql'

export enum ERegistrationTypeId {
  uspa_active = 1,
  merits = 2,
  profile_avatar = 3,
  user_active = 4,
  min_skydives = 5,
  min_jumps = 6,
  min_license = 7,
  min_time = 8,
  question = 9,
  home_dz = 10,
  home_tunnel = 11,
  waiver_digital = 12,
  waiver_physical = 13
}

const registrationRequirementValidationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required.'),
  description: Yup.string().required('This field is required.')
});

const RegistrationQuestionsModal = ({
  isModalOpen,
  handleToggleModal,
  registrationQuestion,
  setRegistrationQuestion,
  team
}) => {
  let regReqClone: {
    name: string;
    description: string;
    id: string;
    location_id: number;
    sport_type_id: number;
    is_required: boolean;
    registration_question_type_id: number;
    answer_restriction: number;
  };

  const [registrationQuestionOptions, setRegistrationQuestionOptions] =
    React.useState([]);

  useEffect(() => {
    if (registrationQuestion) {
      setRegistrationQuestionOptions(
        registrationQuestion.question_options ?? []
      );
    }
  }, [registrationQuestion]);

  const handleAddOption = (option, id) => {
    if (id) {
      setRegistrationQuestionOptions(
        registrationQuestionOptions.map((questionOption) => {
          if (questionOption.id === id) {
            return { ...questionOption, name: option };
          }
          return questionOption;
        })
      );
      console.log('registrationQuestionOptions', registrationQuestionOptions);
    } else {
      setRegistrationQuestionOptions([
        ...registrationQuestionOptions,
        { name: option, id }
      ]);
    }
  };

  if (registrationQuestion) {
    regReqClone = { ...registrationQuestion };
  }

  const { currentCoachTeam } = useRootStore();

  const { data: teamSportsData } = useQuery<GET_TEAM_SPORTS_TYPE>(
    GET_TEAM_SPORTS,
    {
      variables: {
        team_id: team.id
      }
    }
  );

  const sportTypes = useMemo<Partial<Types.TeamSport>[]>(
    () =>
      teamSportsData?.teamSports.map((sportType) => ({
        sport_type_id: sportType.sport_type_id,
        name: sportType.name,
        slug: sportType.slug
      })) || [],
    [teamSportsData?.teamSports, teamSportsData]
  );

  const { data: teamLocationsData } = useQuery(GET_TEAM_LOCATIONS, {
    variables: {
      teamId: Number(team.id)
    }
  });

  const teamLocations = useMemo(
    () => teamLocationsData?.getTeamLocations || [],
    [teamLocationsData]
  );

  const [createNewRegistrationQuestion] = useMutation(
    ADD_REGISTRATION_QUESTION,
    {
      onCompleted: () => toast.success(`Registration question saved.`)
    }
  );
  const [updateRegistrationQuestion] = useMutation(
    UPDATE_REGISTRATION_QUESTION,
    {
      onCompleted: () => toast.success(`Registration question updated.`)
    }
  );

  const [updateQuestionOptions] = useMutation(
    ADD_OR_EDIT_REGISTRATION_QUESTION
  );

  const handleUpdateQuestionOptions = async (id) => {
    const refetch = [
      {
        query: GET_REGISTRATION_QUESTIONS,
        variables: {
          filter: {
            teamId: team.id
          }
        }
      }
    ];
    await updateQuestionOptions({
      variables: {
        options: registrationQuestionOptions.map((option) => {
          const { ...rest } = { ...option };
          return {
            ...rest,
            registration_question_id: id
          };
        })
      },
      refetchQueries: refetch
    });
  };

  const renderForm = ({
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched
  }) => (
    <form id="registrationRequirementForm" onSubmit={handleSubmit}>
      <Grid.Row>
        <Grid.Col>
          <FormField
            name="name"
            label="Label"
            placeholder="Enter question label"
            onChange={handleChange}
            value={values.name}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col>
          <Form.Textarea
            multiline="true"
            name="description"
            label="Question"
            placeholder="Enter full question..."
            onChange={handleChange}
            value={values.description}
            rows={3}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <Form.Group label="">
            <Select
              name="registration_question_type_id"
              placeholder="Select Question Type"
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              options={EQuestionTypes}
              value={EQuestionTypes.find(
                (eqt) => eqt.value === values.registration_question_type_id
              )}
              onChange={(e) => {
                setFieldValue('registration_question_type_id', e.value);
                if (![4, 5, 6, 7].includes(e.value)) {
                  setFieldValue('answer_restriction', null);
                }
              }}
            />
            <Text.Small className="field-error text-muted">
              The type of question restricts the answer
            </Text.Small>
            <span className="field-error text-danger">
              {errors.sport_type_id &&
                touched.sport_type_id &&
                errors.sport_type_id}
            </span>
          </Form.Group>
        </Grid.Col>
        <Grid.Col>
          {[4, 5, 6, 7].includes(values.registration_question_type_id) && (
            <FormField
              type={'number'}
              name="answer_restriction"
              placeholder="Restriction"
              onChange={handleChange}
              value={values.answer_restriction}
            />
          )}
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={12} sm={12}>
          {[8, 9].includes(values.registration_question_type_id) && (
            <>
              <b>
                <label>Options</label>
              </b>
              <Text.Small className="field-error text-muted d-block">
                Options are saved after form is submitted.
              </Text.Small>
              {registrationQuestionOptions.map((option) => (
                <RegistrationQuestionOption
                  questionOption={option}
                  handleAddOption={handleAddOption}
                />
              ))}
              <RegistrationQuestionOption
                questionOption={{
                  name: '',
                  id: null,
                  registration_question_id: null
                }}
                handleAddOption={handleAddOption}
              />
            </>
          )}
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <Form.Group label="">
            <Select
              name="sport_type_id"
              placeholder="Select activity (optional)..."
              isClearable={true}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              getOptionLabel={(option: Partial<Types.TeamSport>) =>
                option.name
              }
              getOptionValue={(option: Partial<Types.TeamSport>) =>
                option.sport_type_id
              }
              options={sportTypes}
              value={
                sportTypes.find(
                  (sportType: Partial<Types.TeamSport>) =>
                    sportType.sport_type_id === values.sport_type_id
                ) || null
              }
              onChange={(e) => {
                if (e) setFieldValue('sport_type_id', e.sport_type_id);
                else setFieldValue('sport_type_id', null);
              }}
            />
            <Text.Small className="field-error text-muted">
              If question only applies to this activity.
            </Text.Small>
            <span className="field-error text-danger">
              {errors.sport_type_id &&
                touched.sport_type_id &&
                errors.sport_type_id}
            </span>
          </Form.Group>
        </Grid.Col>
        <Grid.Col>
          <Form.Group label="">
            <Select
              name="location_id"
              placeholder="Select location (optional)..."
              isClearable={true}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              getOptionLabel={(option: Types.Location) =>
                option.name
              }
              getOptionValue={(option: Types.Location) =>
                option.id
              }
              options={teamLocations}
              value={
                teamLocations.find(
                  (location) => location.id === values.location_id
                ) || null
              }
              onChange={(e) => {
                console.log('values', values);
                console.log('e', e);
                if (e) setFieldValue('location_id', e.id);
                else setFieldValue('location_id', null);
              }}
            />
            <Text.Small className="field-error text-muted">
              If question only applies to this location.
            </Text.Small>
            <span className="field-error text-danger">
              {errors.location_id && touched.location_id && errors.location_id}
            </span>
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <Form.Switch
            name="is_required"
            label="Required"
            className="float-left"
            checked={values.is_required === 1}
            onChange={(e) => {
              setFieldValue('is_required', e.target.checked ? 1 : 0);
            }}
          />
          <Button.List align="right">
            <Button
              pill
              color="white"
              size="sm"
              onClick={(e) => {
                e.preventDefault();
                setRegistrationQuestion(null);
                handleToggleModal();
              }}
            >
              CANCEL
            </Button>
            <Button
              pill
              type="submit"
              form="registrationRequirementForm"
              className="btn btn-primary"
              color="gray-dark"
              size="sm"
            >
              SAVE
            </Button>
          </Button.List>
        </Grid.Col>
      </Grid.Row>
    </form>
  );

  return (
    <Modal
      content={
        <Formik
          enableReinitialize={true}
          validationSchema={registrationRequirementValidationSchema}
          initialValues={{
            name: regReqClone?.name ?? '',
            description: regReqClone?.description ?? '',
            is_required: regReqClone?.is_required ? 1 : 0,
            location_id: regReqClone?.location_id ?? null,
            sport_type_id: regReqClone?.sport_type_id ?? null,
            registration_question_type_id:
              regReqClone?.registration_question_type_id ?? 1,
            answer_restriction: regReqClone?.answer_restriction ?? null
          }}
          onSubmit={async (
            values,
            { setSubmitting, resetForm, setFieldError }
          ) => {
            if ([4, 5, 6, 7].includes(values.registration_question_type_id)) {
              if (!values.answer_restriction) {
                setFieldError('answer_restriction', 'This field is required');
                return;
              }
            }
            const refetch = [
              {
                query: GET_REGISTRATION_QUESTIONS,
                variables: {
                  filter: {
                    teamId: team.id
                  }
                }
              }
            ];

            if (regReqClone) {
              await updateRegistrationQuestion({
                variables: {
                  option: {
                    id: regReqClone.id,
                    name: values.name,
                    description: values.description,
                    sport_type_id: values.sport_type_id,
                    location_id: values.location_id,
                    is_required: values.is_required === 1,
                    registration_question_type_id:
                      values.registration_question_type_id,
                    answer_restriction: [4, 5, 6, 7].includes(
                      values.registration_question_type_id
                    )
                      ? values.answer_restriction
                      : null
                  }
                },
                refetchQueries: refetch
              });
              if ([8, 9].includes(values.registration_question_type_id)) {
                await handleUpdateQuestionOptions(regReqClone.id);
              }
            } else {
              const newQuestionId = await createNewRegistrationQuestion({
                variables: {
                  option: {
                    name: values.name,
                    description: values.description,
                    team_id: currentCoachTeam?.id || team.id,
                    is_required: values.is_required === 1,
                    sport_type_id: values.sport_type_id,
                    location_id: values.location_id,
                    registration_question_type_id:
                      values.registration_question_type_id,
                    answer_restriction: [4, 5, 6, 7].includes(
                      values.registration_question_type_id
                    )
                      ? values.answer_restriction
                      : null
                  }
                },
                refetchQueries: refetch
              });
              if ([8, 9].includes(values.registration_question_type_id)) {
                await handleUpdateQuestionOptions(
                  newQuestionId.data.createNewRegistrationQuestion
                );
              }
            }

            setSubmitting(false);
            resetForm();
            setRegistrationQuestion(null);
            handleToggleModal();
          }}
        >
          {(formikData) => renderForm(formikData)}
        </Formik>
      }
      open={isModalOpen}
      title={''}
      onClose={() => {
        setRegistrationQuestion(null);
        setRegistrationQuestionOptions([]);
        handleToggleModal();
      }}
    />
  );
};

export default RegistrationQuestionsModal;
