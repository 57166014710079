import { observer } from 'mobx-react';
import React from 'react';
import Wrapper from '../../pages/layout';
import { Container } from 'tabler-react';
import Manifest from "../../components/manifest/manifest";

const ManifestPage = (props) => {

  return (
    <>
      <Wrapper {...props} title="Manifest">
        <Container>
          <Manifest/>
        </Container>
      </Wrapper>
    </>
  );
};

export default observer(ManifestPage);
