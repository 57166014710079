import { useQuery } from '@apollo/client';
import { observer } from 'mobx-react';
import { applySnapshot } from 'mobx-state-tree';
import React, { useContext, useEffect } from 'react';
import { Button, Card, Dimmer, Grid, Icon } from 'tabler-react';
import FeatureAccessGate from 'modules/common/components/FeatureAccessGate';
import DashboardGroups from '../Coaches/DashboardGroups';
import { UserStoreContext } from '../../contexts/userStoreContext';
import { GET_STUDENTS_ON_DASHBOARD } from '../../graphql/GET_STUDENTS_ON_DASHBOARD';
import { useRootStore } from '../../hooks/useRootStore';
import OnDeckListItem from '../../components/OnDeck/OnDeckListItem';
import GroupFormModal from '../Calendar/Modal/GroupFormModal';

const Manifest = () => {
  const { currentCoachTeam } = useRootStore();
  const { onDeckStudents } = useContext(UserStoreContext);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { loading, error, data } = useQuery(GET_STUDENTS_ON_DASHBOARD, {
    variables: {
      team_id: currentCoachTeam?.id
    }
  });

  useEffect(() => {
    if (!loading && data) {
      applySnapshot(onDeckStudents, data.studentsOnDashboard);
    }
  }, [data]);  

  if (error) {
    return <p>Error: {`Error: ${error.message}`}</p>;
  }
  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>
            <Icon
              name="home"
              className="mr-2 ml-0 text-primary"
            />
            Manifest
          </Card.Title>
          <Card.Options>
          <FeatureAccessGate feature="feature_dashboard_groups">
            <Button
              icon="plus"
              color="primary"
              size="sm"
              className="mr-3"
              onClick={() => setIsModalOpen(true)}
            >
              GROUP
            </Button>
            </FeatureAccessGate>
{/* 
            <Text>
            {new Date().toLocaleDateString() + ""}
            </Text>
*/}
          </Card.Options>
        </Card.Header>
      </Card>
      <Grid.Col className="pl-0 pr-0" width={12}>
        <Dimmer active={loading} loader={loading}>
        <Grid.Col className="pl-0 pr-0" xs={12} sm={12} md={6} lg={9}>
          <DashboardGroups />
        </Grid.Col>
          <Grid.Col className="pl-0 pr-0" width={12}>
            <Grid.Row>
              {onDeckStudents.map((onDeck) => (
                <Grid.Col lg={3} sm={6} xs={12} key={onDeck.id}>
                  <OnDeckListItem onDeck={onDeck} />
                </Grid.Col>
              ))}
            </Grid.Row>
          </Grid.Col>
        </Dimmer>
      </Grid.Col>
      <GroupFormModal
        isOpen={isModalOpen}
        toggle={setIsModalOpen}
        teamId={currentCoachTeam?.id.toString()}
      />
    </>
  );
};

export default observer(Manifest);
