import { useMutation, useQuery } from '@apollo/client'
import TextEditor from 'components/text-editor/TextEditor'
import { Formik } from 'formik'
import { ADD_REGISTRATION_STATUS_CONTENT } from 'graphql/ADD_REGISTRATION_STATUS_CONTENT'
import { EDIT_REGISTRATION_STATUS_CONTENT } from 'graphql/EDIT_REGISTRATION_STATUS_CONTENT'
import { GET_REGISTRATION_STATUS_CONTENT } from 'graphql/GET_REGISTRATION_STATUS_CONTENT'
import { GET_REGISTRATION_STATUS_CONTENT as GET_REGISTRATION_STATUS_CONTENT_TYPE } from 'graphql/types/GET_REGISTRATION_STATUS_CONTENT'
import { UPDATE_CAMP_BY_ID } from 'graphql/UPDATE_CAMP_BY_ID'
import useGetRegistrationStatus from 'modules/team-registration-status/hooks/useGetRegistrationStatus'
import React, { useMemo } from 'react'
import { toast } from 'react-toastify'
import { Button, Grid, Tab, TabbedCard, Text } from 'tabler-react'
import { Types } from '../../../types/graphql'

interface ICaledarItemEditEmail {
  event: Types.Camp
}

const CalendarItemEditEmail = ({ event }: ICaledarItemEditEmail) => {
  //fetch all email content for the event
  const { data: registrationStatusContents } =
    useQuery<GET_REGISTRATION_STATUS_CONTENT_TYPE>(
      GET_REGISTRATION_STATUS_CONTENT,
      {
        variables: { campId: Number(event.id) }
      }
    )

  const [updateCampById] = useMutation(UPDATE_CAMP_BY_ID, {
    refetchQueries: ['GET_CAMP_BY_ID', 'GET_CAMPS']
  })

  const [addRegistrationStatusContent] = useMutation(
    ADD_REGISTRATION_STATUS_CONTENT,
    {
      refetchQueries: ['GET_REGISTRATION_STATUS_CONTENT'],
      onCompleted: () => {
        toast.success('Email content added.')
      }
    }
  )
  const [editRegistrationStatusContent] = useMutation(
    EDIT_REGISTRATION_STATUS_CONTENT,
    {
      refetchQueries: ['GET_REGISTRATION_STATUS_CONTENT'],
      onCompleted: () => {
        toast.success('Email content updated.')
      }
    }
  )

  const statusContents = useMemo(() => {
    return registrationStatusContents?.registrationStatusContents ?? []
  }, [registrationStatusContents?.registrationStatusContents])  

  const { teamStatusOptions } = useGetRegistrationStatus(
    Number(event.team_id) ?? 0
  )

  const handleSubmitStatusContent = async (
    content,
    rsId,
    registration_status_information_id
  ) => {
    if (registration_status_information_id) {
      editRegistrationStatusContent({
        variables: {
          registrationStatusContentUpdateInput: {
            id: registration_status_information_id,
            message: content
          }
        }
      })
    } else {
      addRegistrationStatusContent({
        variables: {
          registrationStatusContentInput: {
            camp_id: Number(event.id),
            message: content,
            registration_status_id: rsId
          }
        }
      })
    }
  }

  const handleSubmitEventInformation = async (content) => {
    await updateCampById({
      variables: {
        camp: {
          id: event.id,
          registration_information: content
        }
      }
    })
  }

  const renderForm = ({ values, setFieldValue }) => (
    <Grid.Col>
      <TabbedCard initialTab="Registration Information">
        <Tab title={'Registration Information'}>
          <Text.Small className="d-block text-muted mb-3">
            Sent in email when registration is <strong>CREATED</strong>.{' '}
            {event.auto_approval === false
              ? 'Initial status is set currently set to Pending.'
              : 'Initial status is currently set to Confirmed.'}
          </Text.Small>
          <TextEditor
            value={values.registration_information}
            placeholder="Enter registration information..."
            onChange={(content) =>
              setFieldValue('registration_information', content)
            }
          />
          <Button
            color="primary"
            className="mt-3"
            type="submit"
            onClick={(e) => {
              e.preventDefault()
              handleSubmitEventInformation(values.registration_information)
            }}
          >
            UPDATE
          </Button>
        </Tab>

        {teamStatusOptions.map((status) => (
          <Tab key={status.id} title={status.name}>
            <Text.Small className="d-block text-muted mb-3">
              Sent in email when status is changed to{' '}
              <strong>{status.name.toUpperCase()}</strong>.
            </Text.Small>
            <TextEditor
              value={
                values[status.slug] ??
                statusContents.find((sc) => sc.slug === status.slug)?.message
              }
              placeholder={`Enter ${status.name} message`}
              onChange={(content) => {
                setFieldValue(status.slug, content)
              }}
            />
            <Button
              color="primary"
              className="mt-3"
              onClick={(e) => {
                e.preventDefault()
                handleSubmitStatusContent(
                  values[status.slug] ??
                    statusContents.find((sc) => sc.slug === status.slug)
                      ?.message,
                  status.id,
                  statusContents.find((sc) => sc.slug === status.slug)
                    ?.registration_status_information_id
                )
              }}
            >
              UPDATE
            </Button>
          </Tab>
        ))}
      </TabbedCard>
    </Grid.Col>
  )

  const initValues = statusContents.reduce((acc, sc) => {
    acc[sc.slug] = sc.message
    return acc
  }, {})

  console.log({ statusContents })

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: event.id,
        registration_information: event.registration_information ?? '',
        ...initValues
      }}
      onSubmit={async (_, { setSubmitting, resetForm }) => {
        setSubmitting(false)
        resetForm()
        toast.success('Event Updated.')
      }}
    >
      {(formikData) => renderForm(formikData)}
    </Formik>
  )
}

export default CalendarItemEditEmail
