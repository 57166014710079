import React from 'react';
import { Nav } from 'tabler-react';
import LocationSearchField from '../../components/Students/Forms/LocationSearchField';

const VisitorNav = () => {
  return (
    <>
      <Nav.Item icon='home' to='/' value='/' />
      <li className='nav-item ml-lg-auto mb-4 mb-lg-0'>
        <LocationSearchField />
      </li>
    </>
  );
};

export default VisitorNav;
