import React from 'react';
import Modal from '../../Modal';
import RegistrationGroupForm from '../Forms/RegistrationGroupForm';

const RegistrationGroupFormModal = ({ isOpen, toggle, group }) => {
  return (
    <Modal
      content={<RegistrationGroupForm group={group} toggle={toggle} />}
      open={isOpen}
      onClose={toggle}
      title={group?.id ? 'Edit Registration Team' : 'Add Registration Group'}
    />
  );
};

export default RegistrationGroupFormModal;
