import { STATUS_ICON_COLOR_MAPPING } from "modules/user/registrations/constants/registrationStatusMapping";
import moment from "moment/moment";
import React from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Card, Dimmer, Icon, Text } from "tabler-react";
import useRouter from "use-react-router";
import { Types } from '../../types/graphql'

interface IStudentCamps {
  camps: Types.StudentCamps[];
  loading: boolean;
  studentId: number;
}

const StudentCamps = ({ loading, camps, studentId }: IStudentCamps) => {
  const { history } = useRouter();
  if (!loading && camps?.length <= 0) {
    return (
      <Card statusColor="gray-dark">
        <Card.Header>
          <Card.Title>
            <i className="fe fe-calendar text-primary mr-2" />
            Event Registrations
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Button.List align="center">
            <Button
              icon="search"
              color="primary"
              size="sm"
              className=""
              onClick={(): void => history.push("/user/calendar")}
            >
              FIND EVENTS
            </Button>
          </Button.List>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Dimmer active={loading} loader={loading}>
      <Card statusColor="gray-dark">
        <Card.Header>
          <Card.Title>
            <i className="fe fe-calendar text-primary mr-2" />
            Event Registrations
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {camps?.filter(
            (event: Types.StudentCamps) =>
              moment(event.end) > moment()
          ).length > 0 && (
            <>
              <h4>Upcoming</h4>
              <Text>
                {!loading &&
                  camps
                    .filter(
                      (event: Types.StudentCamps): boolean =>
                        moment(event.start) > moment()
                    )
                    .reverse()
                    .map((camp: Types.StudentCamps) => (
                      <div key={camp.camp_id}>
                        <span className="ml-3 text-muted float-right">
                          {camp.user_registrations_id && (
                            <Button
                              size="sm"
                              color="white"
                              className="text-success"
                            >
                              <Link
                                to={`/events/registrations/${camp.user_registrations_id}/${studentId}`}
                              >
                                <Icon
                                  name={
                                    STATUS_ICON_COLOR_MAPPING.get(camp?.status)
                                      ?.icon ?? "x-circle"
                                  }
                                  className={`ml-0
                                        ${
                                          STATUS_ICON_COLOR_MAPPING.get(
                                            camp?.status
                                          )?.className ?? "text-danger"
                                        }`}
                                />
                              </Link>
                            </Button>
                          )}
                        </span>
                        <p>
                          <span className="">
                            <Link to={"/user/calendar/" + camp.camp_id}>
                              {camp.camp_name}
                            </Link>
                          </span>
                          <Text.Small className="d-block text-muted">
                            {moment(camp.start).format("MMM D, YYYY")}
                          </Text.Small>
                        </p>
                      </div>
                    ))}
              </Text>
            </>
          )}
        </Card.Body>
      </Card>
    </Dimmer>
  );
};
export default StudentCamps;
