import React from 'react';
import { Card, Form, Grid, Text } from 'tabler-react';
import { useMutation, useQuery } from '@apollo/client';
import { useRootStore } from '../../../hooks';
import { GET_REGISTRATION_QUESTIONS } from '../../../graphql/GET_REGISTRATION_QUESTIONS';
import { toast } from 'react-toastify';
import { CREATE_CAMP_REGISTRATION_QUESTION } from '../../../graphql/CREATE_CAMP_REGISTRATION_QUESTION';
import { DELETE_CAMP_REGISTRATION_QUESTION } from '../../../graphql/DELETE_CAMP_REGISTRATION_QUESTION';
import { Types } from '../../../types/graphql'

interface ICalendarEventQuestionsProp {
  event: Types.Camp
}

const CalendarEventQuestions = ({ event }: ICalendarEventQuestionsProp) => {
  const { currentCoachTeam } = useRootStore();
  /*
  campId: ID
  teamId: ID
  requirementTypeId: ID
 */
  const { data, loading } = useQuery(GET_REGISTRATION_QUESTIONS, {
    variables: {
      filter: {
        campId: Number(event.id),
        teamId: currentCoachTeam?.id
      }
    }
  });

  const [addCampRegistrationQuestion] = useMutation(
    CREATE_CAMP_REGISTRATION_QUESTION,
    {
      onCompleted: (result) => {
        if (result.addCampRegistrationQuestion) {
          toast.success('Registration question added to camp');
        } else {
          toast.error('Something went wrong while trying to add question.');
        }
      }
    }
  );

  const [removeCampRegistrationQuestion] = useMutation(
    DELETE_CAMP_REGISTRATION_QUESTION,
    {
      onCompleted: (result) => {
        if (result.removeCampRegistrationQuestion) {
          toast.success('Registration option deleted');
        } else {
          toast.error('Something went wrong while trying to delete option.');
        }
      }
    }
  );

  const optionToggle = (option, isChecked) => {
    if (isChecked) {
      addCampRegistrationQuestion({
        variables: {
          campId: Number(event.id),
          registrationQuestionId: option.id
        },
        refetchQueries: [
          {
            query: GET_REGISTRATION_QUESTIONS,
            variables: {
              filter: {
                campId: Number(event.id),
                teamId: currentCoachTeam?.id
              }
            }
          }
        ]
      });
    } else {
      if (
        window.confirm(
          'Are you sure you want to delete this question from camp: ' +
            option.name
        )
      ) {
        removeCampRegistrationQuestion({
          variables: {
            campId: Number(event.id),
            registrationQuestionId: option.id
          },
          refetchQueries: [
            {
              query: GET_REGISTRATION_QUESTIONS,
              variables: {
                filter: {
                  campId: Number(event.id),
                  teamId: currentCoachTeam?.id
                }
              }
            }
          ]
        });
      }
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Registration Questionaire</Card.Title>
      </Card.Header>
      <Card.Body>
        {!loading &&
          data?.registrationQuestions.map(
            (option) => {
              if (
                option.sport_type_id &&
                option.sport_type_id !== Number(event.sport_type_id)
              )
                return null;
              if (
                option.location_id &&
                Number(option.location_id) !== event.location_id
              )
                return null;
              return (
                <Grid.Row key={option.id}>
                  <Grid.Col>
                    <Form.Checkbox
                      name="status"
                      label={
                        <>
                          <Text className="">{option.name}</Text>
                          <Text.Small className="text-muted">
                            {option.description}
                          </Text.Small>
                        </>
                      }
                      checked={option?.is_exist}
                      onChange={(e) => {
                        optionToggle(option, e.target.checked);
                      }}
                    />
                  </Grid.Col>
                </Grid.Row>
              );
            }
          )}
      </Card.Body>
    </Card>
  );
};

export default CalendarEventQuestions;
