import gql from 'graphql-tag';

export const GET_CAMP_REGISTRATION_OPTIONS = gql`
  query GET_CAMP_REGISTRATION_OPTIONS($campId: Int!, $teamId: Int!) {
    getAvailableCampRegistrationOptions(campId: $campId, teamId: $teamId) {
      id
      name
      description
      fee_type_id
      option_value
      option_type_id
      camp_id
      team_id
      location_id
      is_exist
      campRegistrationId
    }
  }
`;
