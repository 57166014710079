import { useLazyQuery, useMutation } from '@apollo/client'
import FormField from 'components/FormField'
import { UserStoreContext } from 'contexts/userStoreContext'
import { Formik, FormikValues } from 'formik'
import { ADD_STUDENT } from 'graphql/ADD_STUDENT'
import { GET_EMAIL_VALIDATION } from 'graphql/GET_EMAIL_VALIDATION'
import { GET_PHONE_VALIDATION } from 'graphql/GET_PHONE_VALIDATION'
import { useRootStore } from 'hooks'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import PhoneInput from 'react-phone-input-2'
import { toast } from 'react-toastify'
import { Card, Form } from 'tabler-react'
import * as Yup from 'yup'

const addStudentValidationSchema = Yup.object().shape({
  first_name: Yup.string().required('This field is required.'),
  last_name: Yup.string().required('This field is required.'),
  email: Yup.string().required('This field is required.'),
  phone_number: Yup.string().required('This field is required.')
})

interface IStudentSignupFormProps {
  setStep: any
}

const StudentSignupForm = ({ setStep }: IStudentSignupFormProps) => {
  const userStore = useContext(UserStoreContext)
  const rootStore = useRootStore()
  const { currentCoachTeam } = rootStore

  const [addStudent] = useMutation(ADD_STUDENT)

  const [validateEmail, { data: emailData }] = useLazyQuery(
    GET_EMAIL_VALIDATION,
    { fetchPolicy: 'network-only' }
  )

  const [validatePhone, { data: phoneData }] = useLazyQuery(
    GET_PHONE_VALIDATION,
    { fetchPolicy: 'network-only' }
  )

  const handleValidateEmail = async (email) => {
    if (email === '') return

    validateEmail({
      variables: { email }
    })
  }

  const handleValidatePhone = async (phone) => {
    if (phone.length <= 1) return
    return validatePhone({
      variables: { phone }
    })
  }

  const renderForm = ({
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    errors,
    touched
  }: FormikValues) => {
    return (
      <form
        className="card Create-Account"
        method="POST"
        onSubmit={handleSubmit}
      >
        <Card.Body className="p-6">
          <FormField
            type="text"
            label="First name"
            placeholder="First name"
            name="first_name"
            onChange={handleChange}
            value={values.first_name}
          />
          <FormField
            type="text"
            label="Last name"
            placeholder="Last name"
            name="last_name"
            onChange={handleChange}
            value={values.last_name}
          />
          <div className={'form-group'}>
            <label className="form-label">Phone</label>
            <PhoneInput
              inputProps={{
                className: 'form-control w-100',
                name: 'phone_number',
                required: true,
                onChange: (e) => {
                  e.preventDefault()
                  const target = e.target
                  const phone = target.value.split(' ')
                  const countryCode = phone.shift().replace(/\D/g, '')
                  setFieldValue(
                    'phone_number',
                    phone.join('').replace(/\D/g, '')
                  )
                  setFieldValue('phone_country_code', Number(countryCode))
                },
                onBlur: async (e) => {
                  e.preventDefault()
                  await handleValidatePhone(
                    e.target.value.split(' ').join('').replace(/\D/g, '')
                  )
                }
              }}
              value={`${values.phone_country_code}${values.phone_number}`}
            />
            <span className="field-error text-danger">
              {errors.phone_number &&
                touched.phone_number &&
                errors.phone_number}
            </span>
          </div>
          <FormField
            type="email"
            label="Email"
            placeholder="Email"
            name="email"
            onBlur={(e) => handleValidateEmail(e.target.value)}
            onChange={handleChange}
            value={values.email}
          />
          <Form.Footer className="mb-6">
            <button
              type="submit"
              className={`btn btn-primary btn-block ${
                isSubmitting && 'btn-loading'
              }`}
              disabled={isSubmitting}
            >
              Create Account
            </button>
          </Form.Footer>
        </Card.Body>
      </form>
    )
  }

  return (
    <>
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          phone_number: '',
          phone_country_code: '1',
          team_id: currentCoachTeam.id
        }}
        validationSchema={addStudentValidationSchema}
        onSubmit={async (
          values,
          { setSubmitting, resetForm, setFieldError }
        ) => {
          let errorFound = false

          if (emailData?.validateEmail) {
            setFieldError(
              'email',
              'Email already exists. Login or reset password. Contact SKYCRU if you need further assistance.'
            )
            errorFound = true
          }

          if (phoneData?.validatePhone) {
            setFieldError(
              'phone_number',
              'Phone number already exists. Contact SKYCRU.'
            )
            errorFound = true
          }

          // if (!values.email && !values.phone_number) {
          //   setFieldError('email', "Please provide an email or phone number.");
          //   setFieldError('phone_number', "Please provide an email or phone number.");
          //   errorFound = true;
          // }

          if (errorFound) {
            setSubmitting(false)
            return false
          }

          const newStudent = await addStudent({
            variables: {
              student: {
                ...values
              }
            }
          })

          if (newStudent?.data.addStudent.id) {
            userStore.loadUser(newStudent?.data.addStudent)
            toast.success('User account created!')
            setStep(1)
          }
          setSubmitting(false)
          resetForm()
        }}
      >
        {(formikData) => renderForm(formikData)}
      </Formik>
    </>
  )
}

export default observer(StudentSignupForm)
