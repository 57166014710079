import { useQuery } from '@apollo/client'
import { Loading } from 'components/Loading';
import { GET_CAMP_TOTALS } from 'graphql/GET_CAMP_TOTALS'
import { GET_CAMP_TOTALS as GET_CAMP_TOTALS_TYPE } from 'graphql/types/GET_CAMP_TOTALS'
import React from 'react'
import { useParams } from 'react-router-dom'
import { Grid, StampCard } from 'tabler-react'
import formatNumber, { formatMoney } from 'utils/numberFormat'
import { parseIntNullable } from 'utils/numberParser'
import useReactRouter from 'use-react-router'

const EventRegistrationTotalPayments = () => {
  const { campId } = useParams<{ campId: string }>()
  const campIdValue = parseIntNullable(campId)

  const { loading, error, data } =
    useQuery<GET_CAMP_TOTALS_TYPE>(GET_CAMP_TOTALS, {
      variables: {
        campId: campIdValue
      }
    })

  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    console.log(error)
    return <p>Error: {error.message}</p>
  }

  const payments = data.payments ?? []
  const pending = data.pending ?? {}
  return (
    <EventRegistrationTotalPaymentsInner
      pending={pending} payments={payments}
      />
  )
}

const EventRegistrationTotalPaymentsInner = ({pending, payments}) => {
  const { history } = useReactRouter()
  return (
    <>
      <Grid.Col sm={6} lg={3} xs={12} className="mb-2 px-0">
        <StampCard
          className="mb-0"
          color="success"
          icon="dollar-sign"
          // Total number of payments with Paid status for this camp
          header={
            <>
              <span
                className="cursor-pointer"
                onClick={() => history.push(`/payments`)}
              >
                {formatNumber(payments.total)}{' '}
                <small>Payments</small>
              </span>
            </>
          }
          // Total payment amounts with Paid status, Total Registration Revenue
          footer={
            pending.potential_total
              ? `${formatMoney(payments.totalPaidConfirmed)}`
              : '$0.00'
          }
        />
      </Grid.Col>
      <Grid.Col sm={6} lg={3} xs={12} className="mb-2 px-0">
        <StampCard
          className="mb-0"
          color="secondary"
          icon="dollar-sign"
          header={
            <>
              {formatNumber(payments.totalCompCount)}{' '}
              <small>Comp Payments</small>
            </>
          }
          footer={`${formatMoney(payments.totalComp)}`}
        />
      </Grid.Col>
    </>
  )
}

export default EventRegistrationTotalPayments
