import Modal from 'components/Modal'
import AddJumpTicketsForm from 'modules/registration/options/components/forms/AddJumpTicketsForm'
import React, { useState } from 'react'
import { Dropdown } from 'tabler-react'

const AddJumpTicketsButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <>
      <Dropdown.Item
        icon="cloud"
        color="white"
        className="text-info cursor-pointer"
        onClick={toggleModal}
      >
        JUMP TICKETS
      </Dropdown.Item>
      <Modal
        content={<AddJumpTicketsForm toggleModal={toggleModal} />}
        open={isModalOpen}
        title="Jump Tickets"
        onClose={toggleModal}
      />
    </>
  )
}

export default AddJumpTicketsButton
